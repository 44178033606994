import { childConstants } from "../constants";

const initialState = {
  childInfo: undefined,
  addChildSuccess: false,
  addChildError: "",
  deleteChildSuccess: false,
  uploadChildProfilePicSuccess: false,
  deleteChildError: "",
};

export function childReducer(state = initialState, action) {
  switch (action.type) {
    case childConstants.GET_CHILD_INFO:
      return {
        ...state,
        childInfo: undefined,
      };
    case childConstants.GET_CHILD_INFO_SUCCESS:
      return {
        ...state,
        childInfo: action.payload,
        addChildSuccess: false,
        addChildError: "",
        deleteChildSuccess: false,
        deleteChildError: "",
      };
    case childConstants.ADD_CHILD:
      return {
        ...state,
        addChildSuccess: false,
        addChildError: "",
        deleteChildSuccess: false,
        deleteChildError: "",
      };
    case childConstants.ADD_CHILD_SUCCESS:
      return {
        ...state,
        childInfo: action.payload,
        addChildSuccess: true,
        addChildError: "",
      };
    case childConstants.ADD_CHILD_FAILURE:
      return {
        ...state,
        addChildSuccess: false,
        addChildError: action.payload,
      };
    case childConstants.DELETE_CHILD:
      return {
        ...state,
        deleteChildSuccess: false,
        addChildSuccess: false,
        addChildError: "",
        deleteChildError: "",
      };
    case childConstants.DELETE_CHILD_SUCCESS:
      return {
        ...state,
        childInfo: undefined,
        deleteChildSuccess: true,
        deleteChildError: "",
      };
    case childConstants.DELETE_CHILD_FAILURE:
      return {
        ...state,
        deleteChildSuccess: false,
        deleteChildError: action.payload,
      };
    case childConstants.UPLOAD_CHILD_PROFILE_PIC:
      return {
        ...state,
        childInfo: undefined,
        uploadChildProfilePicSuccess: false,
        deleteChildError: "",
      };
    case childConstants.UPLOAD_CHILD_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        childInfo: undefined,
        uploadChildProfilePicSuccess: true,
        deleteChildError: "",
      };
    case childConstants.UPLOAD_CHILD_PROFILE_PIC_FAILURE:
      return {
        ...state,
        uploadChildProfilePicSuccess: false,
        deleteChildError: "Profile Pic Upload Failed",
      };
    default:
      return state;
  }
}
