export const mathPracticeConcepts = [
    {
        title: "Pre-K",
        // price: '30',
        // subheader: 'Most popular',
        description: ["Math", "Reading"],
        buttonText: "Start Practice",
        buttonVariant: "outlined",

        // practiceChildId: "60fae74602bed02242b2fd5a",
        numOfQuestionsPerDay: 40,
        mathCourseId: "MP",
        concepts: [
            {
                conceptName: "Select Numbers",
                concept: "nmbr",
                cTest: "nmbr-slct-blw10",
                accuracy: 5,
            },
            {
                conceptName: "Count Numbers",
                concept: "nmbr",
                cTest: "nmbr-hmny-blw10",
                accuracy: 5,
            },
            {
                conceptName: "Blocks",
                concept: "blck",
                cTest: "blck-blw10-blw10",
                accuracy: 5,
            },
            {
                conceptName: "Shapes",
                concept: "shp",
                cTest: "shp-any",
                accuracy: 5,
            },
            {
                conceptName: "Addition",
                concept: "add",
                cTest: "add-blw5-blw2-mltln",
                accuracy: 5,
            },
        ]
    },
    {
        title: "Kindergarten",
        // price: '30',
        // subheader: 'Most popular',
        description: ["Math", "Reading"],
        buttonText: "Start Practice",
        buttonVariant: "outlined",

        numOfQuestionsPerDay: 40,
        mathCourseId: "MK",
        concepts: [
            {
                conceptName: "Select Numbers",
                concept: "nmbr",
                cTest: "nmbr-slct-blw10",
                accuracy: 5,
            },
            {
                conceptName: "Count Numbers",
                concept: "nmbr",
                cTest: "nmbr-hmny-blw10",
                accuracy: 5,
            },
            {
                conceptName: "Blocks",
                concept: "blck",
                cTest: "blck-blw10-blw10-blw10",
                accuracy: 5,
            },
            {
                conceptName: "Addition",
                concept: "add",
                cTest: "add-blw10-blw5-mltln",
                accuracy: 3,
            },
            {
                conceptName: "Write Numbers",
                concept: "wrtnum",
                cTest: "wrtnum-rng1to10",
                accuracy: 3,
            },
            {
                conceptName: "Write Words",
                concept: "wrtwrd",
                cTest: "wrtwrd-rng1to10",
                accuracy: 3,
            },
            {
                conceptName: "Subtraction",
                concept: "sub",
                cTest: "sub-blw10-blw10-mltln",
                accuracy: 3,
            },
            {
                conceptName: "Shapes",
                concept: "shp",
                cTest: "shp-any",
                accuracy: 5,
            },
        ],
    },
    {
        title: "First Grade",
        // subheader: 'Most popular',
        // price: '0',
        description: ["Math", "Reading"],
        buttonText: "Start Practice",
        buttonVariant: "outlined",

        numOfQuestionsPerDay: 60,
        mathCourseId: "M1",
        concepts: [
            {
                conceptName: "Addition",
                concept: "add",
                cTest: "add-blw100-blw10-mltln",
                accuracy: 3,
            },
            {
                conceptName: "Subtraction",
                concept: "sub",
                cTest: "sub-blw100-blw50-mltln",
                accuracy: 3,
            },
            {
                conceptName: "Even Odd",
                concept: "evenodd",
                cTest: "evenodd-blw50",
                accuracy: 6,
            },
            {
                conceptName: "Compare Numbers",
                concept: "cmp",
                cTest: "cmp-blw20-blw20",
                accuracy: 6,
            },
            {
                conceptName: "Place Value",
                concept: "plc",
                cTest: "plc-val-blw99-pos1",
                accuracy: 6,
            },
            {
                conceptName: "Money",
                concept: "mny",
                cTest: "mny-slct-coin",
                accuracy: 6,
            },
            {
                conceptName: "Clock",
                concept: "clk",
                cTest: "clk-anlg-idfytime-blw1200",
                accuracy: 6,
            },
            {
                conceptName: "Skip",
                concept: "skp",
                cTest: "skp-boxn-skpnum1",
                accuracy: 3,
            },
        ],
    },
    {
        title: "Second Grade",
        // subheader: 'Most popular',
        // price: '15',
        description: ["Math", "Reading"],
        buttonText: "Start Practice",
        buttonVariant: "outlined",

        numOfQuestionsPerDay: 60,
        mathCourseId: "M2",
        concepts: [
            {
                conceptName: "Addition",
                concept: "add",
                cTest: "add-blw100-blw50-mltln",
                accuracy: 3,
            },
            {
                conceptName: "Subtraction",
                concept: "sub",
                cTest: "sub-blw100-blw50-mltln",
                accuracy: 3,
            },
            {
                conceptName: "Multiplication",
                concept: "mul",
                cTest: "mul-blw100-blw10-mltln",
                accuracy: 3,
            },
            {
                conceptName: "Even Odd",
                concept: "evenodd",
                cTest: "evenodd-blw100",
                accuracy: 6,
            },
            {
                conceptName: "Compare Numbers",
                concept: "compare",
                cTest: "cmp-blw50-blw50",
                accuracy: 6,
            },
            {
                conceptName: "Mixed Operation",
                concept: "mixopr",
                cTest: "mixopr-blw100-add-blw100-sub-blw100",
                accuracy: 3,
            },
            {
                conceptName: "Tables",
                concept: "multbl",
                cTest: "multbl-rng2to10",
                accuracy: 2,
            },
            {
                conceptName: "Clock",
                concept: "clk",
                cTest: "clk-anlg-idfytime-blw1200",
                accuracy: 6,
            },
        ],
    },
    {
        title: "Third Grade",
        // price: '30',
        // subheader: 'Most popular',
        description: ["Math", "Reading"],
        buttonText: "Start Practice",
        buttonVariant: "outlined",

        numOfQuestionsPerDay: 60,
        mathCourseId: "M3",
        concepts: [
            {
                conceptName: "Addition",
                concept: "add",
                cTest: "add-blw1000-blw1000-mltln",
                accuracy: 3,
            },
            {
                conceptName: "Subtraction",
                concept: "sub",
                cTest: "sub-blw1000-blw500-mltln",
                accuracy: 3,
            },
            {
                conceptName: "Multiplication",
                concept: "mul",
                cTest: "mul-blw200-rng1to5-mltln",
                accuracy: 2,
            },
            {
                conceptName: "Division",
                concept: "div",
                cTest: "div-blw50-rng1to5-mltln",
                accuracy: 2,
            },
            {
                conceptName: "Clock",
                concept: "clck",
                cTest: "clk-anlg-blw500",
                accuracy: 4,
            },
            {
                conceptName: "Mixed Operation",
                concept: "mixopr",
                cTest: "mixopr-pars-blw100-add-blw100-parc-sub-blw10",
                accuracy: 2,
            },
            {
                conceptName: "Tables",
                concept: "multbl",
                cTest: "multbl-rng2to10",
                accuracy: 2,
            },
            {
                conceptName: "Addition and Subtraction",
                concept: "addsub",
                cTest: "addsub-blw500-blw30",
                accuracy: 4,
            },
            {
                conceptName: "Standard Form",
                concept: "stndrd",
                cTest: "stndrd-blw1000-optns",
                accuracy: 4,
            },
            {
                conceptName: "Addition with Alphabets",
                concept: "add",
                cTest: "add-alpbts-blw1000-blw1000-mltln",
                accuracy: 4,
            },
            {
                conceptName: "Subtraction with Alphabets",
                concept: "sub",
                cTest: "sub-alpbts-blw1000-blw1000-mltln",
                accuracy: 4,
            },

            {
                conceptName: "Adding 3 numbers",
                concept: "add",
                cTest: "addgrp-blw100-blw100-blw100-mltln",
                accuracy: 4,
            },
            {
                conceptName: "Adding 4 numbers",
                concept: "add",
                cTest: "addgrp-blw100-blw100-blw100-blw100-mltln",
                accuracy: 4,
            },
            {
                conceptName: "Select correct Operation",
                concept: "add",
                cTest: "add-soln-blw1000-blw1000-mltln",
                accuracy: 4,
            },
            {
                conceptName: "Adding Money",
                concept: "add",
                cTest: "decadd-mony-blw1000-blw1000-mltln",
                accuracy: 4,
            },
            {
                conceptName: "Elapsed Time",
                concept: "time",
                cTest: "time-elpsd-blw12-blw60-blw12-blw60",
                accuracy: 4,
            },
            {
                conceptName: "Division Remainder",
                concept: "div",
                cTest: "div-blw100-blw10-mltln-rmndr",
                accuracy: 4,
            },
            {
                conceptName: "Chart Math",
                concept: "chrtmath",
                cTest: "chrtmath-add-blw1000-mltln",
                accuracy: 4,
            },
            {
                conceptName: "Mixed to Improper Fraction",
                concept: "fra",
                cTest: "fra-impr-blw10-blw10-blw10",
                accuracy: 4,
            },
        ],
    },
    {
        title: "Fourth Grade",
        // price: '30',
        // subheader: 'Most popular',
        description: ["Math", "Reading"],
        buttonText: "Start Practice",
        buttonVariant: "outlined",

        numOfQuestionsPerDay: 60,
        mathCourseId: "M4",
        concepts: [
            {
                conceptName: "Addition",
                concept: "add",
                cTest: "add-blw1500-blw500-mltln",
                accuracy: 3,
            },
            {
                conceptName: "Subtraction",
                concept: "sub",
                cTest: "sub-blw1500-blw500-mltln",
                accuracy: 3,
            },

            {
                conceptName: "Multiplication",
                concept: "mul",
                cTest: "mul-blw400-rng5to10-mltln",
                accuracy: 2,
            },
            {
                conceptName: "Division",
                concept: "div",
                cTest: "div-blw200-rng5to10-mltln",
                accuracy: 2,
            },
            {
                conceptName: "Tables",
                concept: "multbl",
                cTest: "multbl-rng5to15",
                accuracy: 2,
            },
            {
                conceptName: "Clock",
                concept: "clck",
                cTest: "clk-anlg-blw1200",
                accuracy: 4,
            },
            {
                conceptName: "Round Off",
                concept: "rndoff",
                cTest: "rndoff-nrst10-blw100",
                accuracy: 4,
            },
            {
                conceptName: "Compare Numbers",
                concept: "compare",
                cTest: "cmp-blw100-blw100-eqn",
                accuracy: 6,
            },
            {
                conceptName: "Roman Numeral",
                concept: "rmnltrs",
                cTest: "rmnltrs-txt-rmn-blw100",
                accuracy: 2,
            },
            {
                conceptName: "Convert Hours to Minutes",
                concept: "time",
                cTest: "time-hmny-hr2mi-blw12",
                accuracy: 2,
            },
            {
                conceptName: "Convert Minutes to Hours",
                concept: "time",
                cTest: "time-hmny-mi2hr-blw1000",
                accuracy: 2,
            },
            {
                conceptName: "Convert Minutes to Seconds",
                concept: "time",
                cTest: "time-hmny-mi2se-blw60",
                accuracy: 2,
            },
            {
                conceptName: "Convert Seconds to Minutes",
                concept: "time",
                cTest: "time-hmny-se2mi-blw1000",
                accuracy: 2,
            },
            {
                conceptName: "Convert Years to Months",
                concept: "time",
                cTest: "time-hmny-yr2mn-blw10",
                accuracy: 2,
            },
            {
                conceptName: "Convert Months to Years",
                concept: "time",
                cTest: "time-hmny-mn2yr-blw100",
                accuracy: 2,
            },
            {
                conceptName: "Add Hours & Minutes",
                concept: "time",
                cTest: "time-add-hrmi-blw12-blw60-blw12-blw60",
                accuracy: 2,
            },
            {
                conceptName: "Subtract Hours & Minutes",
                concept: "time",
                cTest: "time-sub-hrmi-blw12-blw60-blw12-blw60",
                accuracy: 2,
            },
            {
                conceptName: "Add Years & Months",
                concept: "time",
                cTest: "time-add-yrmn-blw10-blw12-blw10-blw12",
                accuracy: 2,
            },
            {
                conceptName: "Subtract Years & Months",
                concept: "time",
                cTest: "time-sub-yrmn-blw10-blw12-blw10-blw12",
                accuracy: 2,
            },
            {
                conceptName: "Elapsed Time",
                concept: "time",
                cTest: "time-elpsd-blw12-blw60-blw12-blw60",
                accuracy: 4,
            },
            {
                conceptName: "Decimal Addition",
                concept: "add",
                cTest: "decadd-blw20-blw20",
                accuracy: 4,
            },
            {
                conceptName: "GCD",
                concept: "gcd",
                cTest: "gcd-blw10-blw10",
                accuracy: 4,
            },
            {
                conceptName: "LCM",
                concept: "lcm",
                cTest: "lcm-blw10-blw10",
                accuracy: 4,
            },
            {
                conceptName: "Select Fractions",
                concept: "fra",
                cTest: "fra-shpprts-crcl-blw10",
                accuracy: 4,
            },
            {
                conceptName: "Standard Form",
                concept: "stndrd",
                cTest: "stndrd-blw1000-optns",
                accuracy: 3,
            },
        ],
    },
    {
        title: "Fifth Grade",
        // price: '30',
        // subheader: 'Most popular',
        description: ["Math", "Reading"],
        buttonText: "Start Practice",
        buttonVariant: "outlined",

        numOfQuestionsPerDay: 60,
        mathCourseId: "M5",
        concepts: [
            {
                conceptName: "Exponents",
                concept: "expo",
                cTest: "expo-slct-expr-blw10-blw10",
                accuracy: 5,
            },
            {
                conceptName: "Factors",
                concept: "fctrs",
                cTest: "fctrs-slct-type-blw100",
                accuracy: 5,
            },

            {
                conceptName: "Multiplication",
                concept: "mul",
                cTest: "mul-rng1000to9999-rng10to25-mltln",
                accuracy: 2,
            },
            {
                conceptName: "Division",
                concept: "div",
                cTest: "div-blw99999-rng5to52-mltln",
                accuracy: 2,
            },
            {
                conceptName: "Standard Form",
                concept: "stndrd",
                cTest: "stndrd-blw99999999",
                accuracy: 3,
            },
            {
                conceptName: "Expand Form",
                concept: "expnd",
                cTest: "expnd-blw99999999",
                accuracy: 3,
            },
            {
                conceptName: "Roman Letters",
                concept: "rmnltrs",
                cTest: "rmnltrs-txt-nmbr-blw50",
                accuracy: 5,
            },
            {
                conceptName: "Fraction Addition",
                concept: "fra",
                cTest: "fra-num1-by-blw10-add-num1-by-blw10",
                accuracy: 3,
            },
            {
                conceptName: "Fraction Subtraction",
                concept: "fra",
                cTest: "fra-num1-by-blw10-sub-num1-by-blw10",
                accuracy: 3,
            },

            {
                conceptName: "Select Expression for Exponents",
                concept: "expo",
                cTest: "expo-slct-expr-blw10-blw10",
                accuracy: 3,
            },
            {
                conceptName: "Select Value for Exponents",
                concept: "expo",
                cTest: "expo-slct-val-blw10-blw10",
                accuracy: 3,
            },
            {
                conceptName: "Select Exponents",
                concept: "expo",
                cTest: "expo-idfy-expr-blw10-blw10",
                accuracy: 3,
            },
            {
                conceptName: "Select Exponent for Value",
                concept: "expo",
                cTest: "expo-idfy-val-blw10-blw10",
                accuracy: 3,
            },
            {
                conceptName: "Prime Factorization",
                concept: "fctrs",
                cTest: "fctrs-slct-prmfctrs-blw100",
                accuracy: 2,
            },
            {
                conceptName: "Prime / Composite",
                concept: "fctrs",
                cTest: "fctrs-slct-type-blw100",
                accuracy: 2,
            },
            {
                conceptName: "Convert Hours to Minutes",
                concept: "time",
                cTest: "time-hmny-hr2mi-blw12",
                accuracy: 2,
            },
            {
                conceptName: "Convert Minutes to Hours",
                concept: "time",
                cTest: "time-hmny-mi2hr-blw1000",
                accuracy: 2,
            },
            {
                conceptName: "Convert Minutes to Seconds",
                concept: "time",
                cTest: "time-hmny-mi2se-blw60",
                accuracy: 2,
            },
            {
                conceptName: "Convert Seconds to Minutes",
                concept: "time",
                cTest: "time-hmny-se2mi-blw1000",
                accuracy: 2,
            },
            {
                conceptName: "Convert Years to Months",
                concept: "time",
                cTest: "time-hmny-yr2mn-blw10",
                accuracy: 2,
            },
            {
                conceptName: "Convert Months to Years",
                concept: "time",
                cTest: "time-hmny-mn2yr-blw100",
                accuracy: 2,
            },
            {
                conceptName: "Add Hours & Minutes",
                concept: "time",
                cTest: "time-add-hrmi-blw12-blw60-blw12-blw60",
                accuracy: 2,
            },
            {
                conceptName: "Subtract Hours & Minutes",
                concept: "time",
                cTest: "time-sub-hrmi-blw12-blw60-blw12-blw60",
                accuracy: 2,
            },
            {
                conceptName: "Add Years & Months",
                concept: "time",
                cTest: "time-add-yrmn-blw10-blw12-blw10-blw12",
                accuracy: 2,
            },
            {
                conceptName: "Subtract Years & Months",
                concept: "time",
                cTest: "time-sub-yrmn-blw10-blw12-blw10-blw12",
                accuracy: 2,
            },
            {
                conceptName: "Elapsed Time",
                concept: "time",
                cTest: "time-elpsd-blw12-blw60-blw12-blw60",
                accuracy: 4,
            },
            {
                conceptName: "Estimate Quotient",
                concept: "div",
                cTest: "div-qtnt-blw1000-blw10-mltln",
                accuracy: 6,
            },

            // {
            //   conceptName: "Percentage",
            //   concept: "per",
            //   cTest: "per-blw20-blw100",
            //   accuracy: 6,
            // },
        ],
    },
    {
        title: "Sixth Grade",
        // price: '30',
        // subheader: 'Most popular',
        description: ["Math", "Reading"],
        buttonText: "Start Practice",
        buttonVariant: "outlined",

        numOfQuestionsPerDay: 60,
        mathCourseId: "M6",
        concepts: [
            {
                conceptName: "Decimal Addition",
                concept: "add",
                cTest: "decadd-blw100-blw100",
                accuracy: 3,
            },
            {
                conceptName: "Decimal Subtraction",
                concept: "sub",
                cTest: "decsub-blw100-blw50",
                accuracy: 3,
            },

            {
                conceptName: "Fractions Addition",
                concept: "fra",
                cTest: "fra-num1-by-blw10-add-num1-by-blw10",
                accuracy: 4,
            },
            {
                conceptName: "Fractions Subtraction",
                concept: "fra",
                cTest: "fra-num1-by-blw10-sub-num1-by-blw10",
                accuracy: 4,
            },
            {
                conceptName: "Round Off",
                concept: "rndoff",
                cTest: "rndoff-dec-add-blw1000-blw1000",
                accuracy: 5,
            },
            {
                conceptName: "Negative Numbers",
                concept: "mixopr",
                cTest: "mixopr-blw100-add-blw100-ngtv",
                accuracy: 5,
            },
            {
                conceptName: "Multiplication -Ve",
                concept: "mul",
                cTest: "mixopr-blw100-mul-blw10-ngtv",
                accuracy: 5,
            },
            {
                conceptName: "GCD",
                concept: "gcd",
                cTest: "gcd-blw10-blw10-blw10",
                accuracy: 4,
            },
            {
                conceptName: "LCM",
                concept: "lcm",
                cTest: "lcm-blw30-blw30-blw30",
                accuracy: 3,
            },
            {
                conceptName: "Mixed Operation",
                concept: "mixopr",
                cTest: "mixopr-pars-blw9999-mul-blw9999-parc-add-blw999",
                accuracy: 3,
            },
            {
                conceptName: "Area Circle",
                concept: "ara",
                cTest: "shp-ara-crcl",
                accuracy: 3,
            },
            {
                conceptName: "Perimeter Circle",
                concept: "permtr",
                cTest: "shp-permtr-crcl",
                accuracy: 3,
            },
            {
                conceptName: "Three Number Multiplication",
                concept: "mixopr",
                cTest: "mixopr-blw9999-mul-blw9999-mul-blw9999",
                accuracy: 3,
            },
        ],
    },
]