import { userInfo } from "os";
import { loginConstants } from "../constants";

const initialState = {
  isRegistrationSuccess: false,
  registrationEmail: "",
  registrationError: "",
  isSendMessageSuccess: false,
  OTPresponse: "",
  forgotPasswordResponse: "",
  forgotPasswordSuccess: "",
  forgotPasswordFailure: "",
};

export function loginReducer(state = initialState, action) {
  switch (action.type) {
    case loginConstants.ATTEMPT_LOGOUT:
      return { ...initialState };
    case loginConstants.ATTEMPT_REGISTRATION:
      return {
        ...state,
        isRegistrationSuccess: false,
        registrationEmail: "",
        registrationError: "",
        isSendMessageSuccess: false,
      };
    case loginConstants.ATTEMPT_REGISTRATION_SUCCESS:
      return {
        ...state,
        isRegistrationSuccess: true,
        registrationEmail: action.payload,
      };
    case loginConstants.ATTEMPT_REGISTRATION_FAILURE:
      return { ...state, registrationError: action.payload };

    case loginConstants.ATTEMPT_SEND_MESSAGE:
      return { ...state, isSendMessageSuccess: false, sendMessageError: "" };
    case loginConstants.ATTEMPT_SEND_MESSAGE_SUCCESS:
      console.log("sendMessageSuccess ---------?????? ", action.payload);
      return {
        ...state,
        isSendMessageSuccess: true,
        sendMessageError: "",
      };
    case loginConstants.ATTEMPT_SEND_MESSAGE_FAILURE:
      console.log("sendMessageError ---------?????? ");
      return {
        ...state,
        isSendMessageSuccess: false,
        sendMessageError: action.payload,
      };
    case loginConstants.ATTEMPT_SEND_OTP_SUCCESS:
      return {
        ...state,
        OTPresponse: action.payload,
      };
    case loginConstants.ATTEMPT_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordResponse: action.payload,
      };
    case loginConstants.ATTEMPT_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordSuccess: action.payload,
      };
    case loginConstants.ATTEMPT_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordFailure: action.payload,
      };
    default:
      return state;
  }
}