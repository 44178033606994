import {
    Button,
    Grid,
    makeStyles,
    useMediaQuery,
    useTheme,
  } from "@material-ui/core";
  import Snackbar from '@material-ui/core/Snackbar';
  import MuiAlert from '@material-ui/lab/Alert';
  import React, { useEffect, useState } from "react";
  import Speech from "../../../util/speech";
  
  const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
      fontSize: 40,
      textAlign: "center",
    },
  }));
  
  const CommonScience = (props) => {
    const theme = useTheme();
  
    const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
    const [currentQuestionId, setCurrentQuestionId] = useState(undefined);
    const [disableAnswer, setDisableAnswer] = useState(false);
    const [displayAnswer, setDisplayAnswer] = useState("");
    const [answer, setAnswer] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);
    const [attemptCount, setAttemptCount] = useState(0);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [studentAnswer, setStudentAnswer] = useState("");
    const [showHands, setShowHands] = useState(false);
    const [question, setQuestion] = useState("");
    const [open, setOpen] = useState(false);
    const [toastMessage, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
  
    useEffect(() => {
      if (
        props.currentHomeWork &&
        props.currentHomeWork.questionId &&
        props.currentHomeWork.questionId !== currentQuestionId
      ) {
        setStudentAnswer(
          props.currentHomeWork.studentAnswer
            ? props.currentHomeWork.studentAnswer
            : ""
        );
        setCurrentQuestionId(props.currentHomeWork.questionId);
        setShowHands(true);
        setQuestion(props.currentHomeWork.firstVar);
        setAttemptCount(0);
        setAnswer(
          props.currentHomeWork.finalAnswer === "1"
            ? props.currentHomeWork.studentAnswer
            : ""
        );
        setDisableAnswer(
          props.currentHomeWork.finalAnswer === "1" ? true : false
        );
        setDisplayAnswer(
          props.currentHomeWork.finalAnswer === "1"
            ? ((props.currentHomeWork.qType === "slct" || props.currentHomeWork.qType === "chse" || props.currentHomeWork.qType === "idfy"
            || props.currentHomeWork.qType === "prfrd") 
             ? props.currentHomeWork.answer : props.currentHomeWork.strAnsList)
            : ""
        );
  
        setShowSpinner(false);
        
      }
    }, [props.currentHomeWork]);
  
    const isAnswerValid = () => {
      console.log("VALIDATING ANSWER...", answer);
      if (answer){
      isAnswerCorrect();
    }
      else{
        setMessage("Invalid Answer!. Select Option.")
        setMessageType("warning")
        handleClick() }
    };
  
    const isAnswerCorrect = () => {
      var isCorrect = false;
      console.log("CHECKING ANSWER", answer);
      const answerString = answer;
      console.warn("answerString ", answerString,props.currentHomeWork.answer,answerString === props.currentHomeWork.answer);
      if(props.currentHomeWork.qType === "slct" || props.currentHomeWork.qType === "chse" || props.currentHomeWork.qType === "prfrd"){
        isCorrect =
        answerString && answerString === props.currentHomeWork.answer;
      }else{
        isCorrect =
        answerString &&
        answerString === props.currentHomeWork.strAnsList[0];
      }
      if (isCorrect) {
        console.log("IS CORRECT, GO TO NEXT QUESTION");
        props.onSubmitQuestion(
          answerString,
          attemptCount + 1,
          true,
          props.currentHomeWork.questionId
        );
        setMessage("Correct Answer!. Good job.")
        setMessageType("success")
        handleClick()
      } else {
        console.log("WRONG ANSWER ");
        setAttemptCount(attemptCount + 1);
  
        if (attemptCount >= props.currentHomeWork.numOfAttemptsAllowed - 1) {
         // props.onShowToast("Wrong Answer!. Next Question.", "danger");
         setMessage("Wrong Answer!. Try Next Question.")
         setMessageType("error")
         handleClick()
  
          props.onSubmitQuestion(
            answerString,
            attemptCount,
            false,
            props.currentHomeWork.questionId
          );
        } else {
          // props.onShowToast("Wrong Answer!. Try Again.", "warning");
          setMessage("Wrong Answer!. Try Again.")
          setMessageType("warning")
          handleClick()
        }
      }
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
  
    const handleClick = () => {
      setOpen(true);
    };
  
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
  
    const onSelection = (val) => {
      setAnswer(val);
    };
  
    const rendorOptions = () => {
      console.log(
        "props.currentHomeWork.strList === ",
        props.currentHomeWork.strList, toastMessage,messageType,props.currentHomeWork.qType 
      );
      if ( props.currentHomeWork.qType === "slct" || props.currentHomeWork.qType === "chse" || props.currentHomeWork.qType === "prfrd"
      ) {
        return (
          <div style={{ display: "inline-flex", flexDirection: "column" }}>
            {props.currentHomeWork.strList.map((item, index) => {
              return (
                <div key={`answer-${index}-${answer[index]}`}>
                  <Button
                    variant={disableAnswer ? "outlined" : "contained"}
                    color={
                      disableAnswer
                        ? item === props.currentHomeWork.answer
                          ? "default"
                          : "secondary"
                        : answer === item
                        ? "inherit"
                        : "primary"
                    }
                    style={{
                      width: 200,
                      borderWidth: 2,
                      borderRadius: 5,
                      paddingVertical: 20,
                      justifyContent: "center",
                      margin: 5,
                    }}
                    onClick={() => onSelection(item)}
                  >
                    {item}
                  </Button>
                </div>
              );
            })}
          </div>
        );
      }if (props.currentHomeWork.qType === "idfy") {
        return props.currentHomeWork.strList.map((item, index) => {
          return (
            <div style={{ display: "inline-flex", flexDirection: "row" }} key={`answer-${index}-${answer[index]}`}>
              <img
                onClick={() => onSelection(item)}
                src={props.currentHomeWork.firstVar + "/" + item.toLowerCase() + ".png"}
                border={2}
                style={{
                  width: 135,
                  height: 135,
                  marginRight: 10,
                  borderColor: disableAnswer
                    ? props.currentHomeWork.isRightAnswer
                      ? "green"
                      : "red"
                    : "#4e67c7",
                  borderWidth: answer === item ? 4 : 0,
                }}
              />
            </div>
          );
        });
      }
    };
  
    const clearAnswer = () => {
      setAnswer("");
      console.log("CLEAR ANSWER CALLED");
    };
  
    const addAnswerToState = (event) => {
      setAnswer(event.target.value);
    };
  
    const toggleHelpModal = (flag) => {
      setShowHelpModal(flag);
    };
  
    return (
      <Grid
        container
        style={{
          padding: 25,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Grid container style={{ padding: 16 }}>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: mobileDevice ? "column" : "row",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => clearAnswer()}
              style={{ marginRight: 16 }}
              disabled={disableAnswer}
            >
              CLEAR
            </Button>
            <Button variant="outlined" color="primary" onClick={isAnswerValid}>
              {disableAnswer ? "NEXT" : "SUBMIT"}
            </Button>
            <div
              style={{ marginLeft: 16, display: "flex", alignItems: "center" }}
            >
              {disableAnswer
                ? ""
                : attemptCount > 0
                ? "Attempts Remaining: " +
                  (props.currentHomeWork.numOfAttemptsAllowed - attemptCount)
                : "Attempts Remaining: " +
                  props.currentHomeWork.numOfAttemptsAllowed}
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignSelf: "flex-start",
              flexDirection: mobileDevice ? "column" : "row",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => props.showHelp()}
              style={{ marginRight: 16 }}
            >
              HELP
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                  Speech(props.currentHomeWork.voiceOver);
                }
              }}
            >
              READ OUT
            </Button>
            </Grid>
        </Grid>
        <Grid container style={{ flexDirection: "column" }}>
          <div style={{ fontSize: 30, margin: 10, alignSelf: "center" }}>
            {/* ---------------------------- QUESTION TEXT VIEW - ITEM  1 ---------------------------- */}
            {props.currentHomeWork.question}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
             
            <div style={{ fontSize: 25, display: "flex", flexDirection: "column" }}>
              {/* ---------------------------- QUESTION TEXT VIEW - ITEM  1 ---------------------------- */}
              {/* {props.currentHomeWork.varList} */}
            </div>
            {rendorOptions()}
          </div>
          <div
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {/* ------------- ANSWER IMAGE RIGHT OR WRONG */}
            {/* <div style={{ flexDirection: "row", alignSelf: "center" }}>
                  {disableAnswer && props.currentHomeWork.isRightAnswer && (
                    <CorrectImage />
                  )}
        
                  {disableAnswer && !props.currentHomeWork.isRightAnswer && (
                    <WrongImage />
                  )}
                </div> */}
            {
              // ------------------   CORRECT ANSWER
              disableAnswer && !props.currentHomeWork.isRightAnswer && (
                <div
                  style={{
                    alignSelf: "center",
                    marginTop: 20,
                    marginLeft: 50,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontSize: 24 }}>Correct Answer :{"   "}</div>
                  { (props.currentHomeWork.qType === "idfyexpr" || props.currentHomeWork.qType ==="idfyval") &&
                    <div style={{ fontSize: 48 }}>
                      <p>{displayAnswer.split("-")[0]}<sup>{displayAnswer.split("-")[1]}</sup></p>
                    </div>
                  }
                  { (props.currentHomeWork.qType !== "idfyexpr" || props.currentHomeWork.qType !=="idfyval") &&
                  <div style={{ fontSize: 48 }}>{displayAnswer}</div>
                  }
                </div>
              )
            }
          </div>
        </Grid>
        <Grid>
        <Snackbar
         open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert 
          key={`toastMessage-${toastMessage}`}
          onClose={handleClose} 
          severity={messageType}>
            {toastMessage}
          </Alert>
        </Snackbar>
        </Grid>
      </Grid>
    );
  };
  
  export default CommonScience;
  