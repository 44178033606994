import { utilsConstants } from "../constants";

const initialState = {
  showLoader: false,
};

export function utilsReducer(state = initialState, action) {
  switch (action.type) {
    case utilsConstants.SET_SHOW_LOADER:
      return { ...state, showLoader: action.payload };
    default:
      return state;
  }
}
