import React, { useEffect, useState } from 'react';
import { Grid, Tabs, Tab, Modal, Typography } from '@material-ui/core';
import SectionTitle from '../SectionTitle/sectiontitle';
import SimpleCard from '../Theme/SimpleCard';
import SelectCourse from '../SpeedTest/SelectCourse';
import image1 from '../../../images/Speedtest.jpg';
import magicsquare from '../../../images/magicsquare.png'
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

const itemInfo =
{
    name: 'Speed Test',
    image: image1,
    id: 1
}

const magicSquareInfo =
{
    name: 'Magic Square',
    image: magicsquare,
    id: 1
}



const BrainBusters = () => {
    const [value, setValue] = useState(0);
    const [showSpeedTestModal, setShowSpeedTestModal] = useState();

    const history = useHistory();

    useEffect(() => {
        setValue(0)
    }, []);

    function handleChange(event, newValue) {
        setValue(newValue);
        console.log('value..', value)
    }

    function handleSpeedTest() {
        console.log('clicked,... ')
        setShowSpeedTestModal(true);
    }

    const speedTestSubmit = (
        childId,
        hwDate,
        subjectCd,
        numOfQuestionsPerDay,
        userType,
        course
    ) => {
        console.log("COURSE ", course);
        history.push({
            pathname: "/guest/speedtest",
            state: {
                childId: childId,
                hwDate: "-",
                subjectCd: subjectCd,
                numOfQuestionsPerDay: numOfQuestionsPerDay,
                userType: userType,
                ctest: course.cTest,
                courseName: course.conceptName,
                accuracy: course.accuracy,
                pageSrc: "CoursePractice",
            },
        });
    };

    return (
        <Grid className="productArea ptb-104">
            <Modal
                open={showSpeedTestModal}
                style={{ marginTop: "25" }}
            // onClose={() => setshowSpeedTestModal(false)}    To close on Click outside modal
            >
                <SelectCourse
                    onCancel={() => setShowSpeedTestModal(false)}
                    speedTestSubmit={speedTestSubmit}
                />
            </Modal>
            <Grid container spacing={4} className="container">
                <Grid item xs={12}>
                    <SectionTitle
                        title="Brain Busters to improve thinking"
                        subtitle="Speed Test, Magic Square, Crosswords, Word Spelling"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        classes={{

                            //     root: 'productTabs',
                            //     flexContainer: 'productTabContainer',
                            //     indicator: 'productTabIndicator'
                        }}
                        centered={true}
                    >
                        <Tab style={{ minWidth: 10 }} label="Math Speed Test" />
                        <Tab style={{ minWidth: 10 }} label="Magic Square" />
                    </Tabs>
                </Grid>
                {value === 0 &&
                    <Grid item xs={3}>
                        <SimpleCard
                            disabled={false}
                            onClickFunction={handleSpeedTest}
                            itemInfo={itemInfo} />
                    </Grid>}
                    {value === 1 &&
                    <Grid item xs={3}>
                        <Typography>Coming Soon</Typography>
                    </Grid>}    

            </Grid>
        </Grid >
    );

}
export default BrainBusters;