export const loginConstants = {
  ATTEMPT_LOGIN: "ATTEMPT_LOGIN",
  ATTEMPT_LOGIN_SUCCESS: "ATTEMPT_LOGIN_SUCCESS",
  ATTEMPT_LOGIN_FAILURE: "ATTEMPT_LOGIN_FAILURE",

  ATTEMPT_LOGOUT: "ATTEMPT_LOGOUT",

  ATTEMPT_REGISTRATION: "ATTEMPT_REGISTRATION",
  ATTEMPT_REGISTRATION_SUCCESS: "ATTEMPT_REGISTRATION_SUCCESS",
  ATTEMPT_REGISTRATION_FAILURE: "ATTEMPT_REGISTRATION_FAILURE",

  ATTEMPT_SEND_MESSAGE: "ATTEMPT_SEND_MESSAGE",
  ATTEMPT_SEND_MESSAGE_SUCCESS: "ATTEMPT_SEND_MESSAGE_SUCCESS",
  ATTEMPT_SEND_MESSAGE_FAILURE: "ATTEMPT_SEND_MESSAGE_FAILURE",

  ATTEMPT_SEND_OTP: "ATTEMPT_SEND_OTP",
  ATTEMPT_SEND_OTP_SUCCESS: "ATTEMPT_SEND_OTP_SUCCESS",
  ATTEMPT_SEND_OTP_FAILURE: "ATTEMPT_SEND_OTP_FAILURE",

  ATTEMPT_FORGOT_PASSWORD: "ATTEMPT_FORGOT_PASSWORD",
  ATTEMPT_FORGOT_PASSWORD_SUCCESS: "ATTEMPT_FORGOT_PASSWORD_SUCCESS",
  ATTEMPT_FORGOT_PASSWORD_FAILURE: "ATTEMPT_FORGOT_PASSWORD_FAILURE"
};
