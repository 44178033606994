import {
  Button,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Speech from "../../../util/speech";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    fontSize: 40,
    textAlign: "center",
  },
}));

const EvenOdd = (props) => {
  const classes = useStyles({});
  const theme = useTheme();

  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentQuestionId, setCurrentQuestionId] = useState(undefined);
  const [isValid, setIsValid] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [disableAnswer, setDisableAnswer] = useState(false);
  const [displayAnswer, setDisplayAnswer] = useState("");
  const [answer, setAnswer] = useState([]);
  const [maxNumberLength, setMaxNumberLength] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [selectedList, setSelectedList] = useState([]);
  const [open, setOpen] = useState(false);
  const [toastMessage, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    if (
      props.currentHomeWork &&
      props.currentHomeWork.questionId &&
      props.currentHomeWork.questionId !== currentQuestionId
    ) {
      const l = [];
      props.currentHomeWork.varList.map((o) => {
        l.push(0);
      });
      console.log("l ---> ", props.currentHomeWork.varList, l);
      setCurrentQuestionId(props.currentHomeWork.questionId);
      setAnswer(
        props.currentHomeWork.finalAnswer === "1"
          ? props.currentHomeWork.studentAnswer
          : ""
      );
      setAttemptCount(0);
      setDisableAnswer(
        props.currentHomeWork.finalAnswer === "1" ? true : false
      );
      setDisplayAnswer(props.currentHomeWork.answer);
      setShowSpinner(false);
      setSelectedList(l);
    }
  }, [props.currentHomeWork]);

  const isAnswerValid = () => {
    var isValid = false;
    console.log("VALIDATING ANSWER", answer);

    if (disableAnswer) {
      props.goToNextQuestion();
    } else {
      const answerString = answer;
      isValid = answerString && answerString.length > 0 ? true : false;
      if (isValid){ 
        isAnswerCorrect();
      }
      else {
        setMessage("Invalid Answer!. Enter Value.")
        setMessageType("warning")
        handleClick() 
      }
    }
  };

  const isAnswerCorrect = () => {
    var isCorrect = false;
    console.log("CHECKING ANSWER", answer);
    var answerString = "";

    answerString = answer;

    console.warn("answerString ", answerString);

    isCorrect =
      answerString &&
      answerString.length > 0 &&
      answerString === props.currentHomeWork.answer;

    if (isCorrect) {
      console.log("IS CORRECT, GO TO NEXT QUESTION");
      props.onSubmitQuestion(
        answerString,
        attemptCount + 1,
        true,
        props.currentHomeWork.questionId
      );
      setMessage("Correct Answer!. Good job.")
      setMessageType("success")
      handleClick()
    } else {
      console.log("WRONG ANSWER ");
      setAttemptCount(attemptCount + 1);

      if (attemptCount >= 0) {
        //props.onShowToast("Wrong Answer!. Next Question.", "danger");
        setMessage("Wrong Answer!. Try Next Question.")
        setMessageType("error")
        handleClick()
        props.onSubmitQuestion(
          answerString,
          attemptCount,
          false,
          props.currentHomeWork.questionId
        );
      } else {
        // props.onShowToast("Wrong Answer!. Try Again.", "warning");
        setMessage("Wrong Answer!. Try Again.")
        setMessageType("warning")
        handleClick()
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const clearAnswer = () => {
    if (props.currentHomeWork.qType === "slct") {
      const l = [];
      props.currentHomeWork.varList.map((o) => {
        l.push(0);
      });
      setSelectedList(l);
    } else {
      setAnswer("");
    }
  };

  const onSelection = (val) => {
    console.log("onSelection val === ", val);
    setAnswer(val);
    console.log("selectedList 2  === ", answer);
  };

  const setStudentAnswer = (val) => {
    console.log("studentAnswer ", val);
    setAnswer(val);
    console.warn("answer ", answer);
  };

  return (
    <Grid
      container
      style={{
        padding: 25,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Grid container style={{ padding: 16 }}>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => clearAnswer()}
            style={{ marginRight: 16 }}
          >
            CLEAR
          </Button>
          <Button variant="outlined" color="primary" onClick={isAnswerValid}>
            {disableAnswer ? "NEXT" : "SUBMIT"}
          </Button>
          <div
            style={{ marginLeft: 16, display: "flex", alignItems: "center" }}
          >
            {disableAnswer
              ? ""
              : attemptCount > 0
              ? "Attempts Remaining: " + (attemptCount + 1)
              : "Attempts Remaining: " + (attemptCount + 1)}
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignSelf: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
          }}
        >
          {props.currentHomeWork && props.currentHomeWork.helpLink && <Button
            variant="outlined"
            color="primary"
            onClick={() => props.showHelp()}
            style={{ marginRight: 16 }}
          >
            HELP
          </Button>}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                Speech(props.currentHomeWork.voiceOver);
              }
            }}
          >
            READ OUT
          </Button>
          </Grid>
      </Grid>
      <div style={{ alignSelf: "center" }}>
        {/* ---------------------------- QUESTION div div - ITEM  1 ---------------------------- */}
        <div>
          <div
            style={{
              alignSelf: "center",
              marginTop: 20,
              //marginLeft: 50,
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
              fontSize: 40,
            }}
          >
            {props.currentHomeWork.question}
          </div>
          <div
            style={{
              alignSelf: "center",
              margin: 20,
              //marginLeft: 50,
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
              fontSize: 40,
            }}
          >
            {props.currentHomeWork.varList[0]}
          </div>
          <div key={`answer-${answer}`}>
            <Button
              variant={disableAnswer ? "outlined" : "contained"}
              color={
                disableAnswer
                  ? props.currentHomeWork.isRightAnswer
                    ? props.currentHomeWork.answer === "even"
                      ? "primary"
                      : "secondary"
                    : props.currentHomeWork.answer === "even"
                    ? "primary"
                    : "secondary"
                  : answer === "even"
                  ? "inherit"
                  : "primary"
              }
              style={{
                width: 55,
                borderWidth: 2,
                borderRadius: 5,
                paddingVertical: 20,
                justifyContent: "center",
                marginLeft: 10,
              }}
              onClick={() => onSelection("even")}
            >
              EVEN
            </Button>
            <Button
              variant={disableAnswer ? "outlined" : "contained"}
              color={
                disableAnswer
                  ? props.currentHomeWork.isRightAnswer
                    ? props.currentHomeWork.answer === "odd"
                      ? "primary"
                      : "secondary"
                    : props.currentHomeWork.answer === "odd"
                    ? "primary"
                    : "secondary"
                  : answer === "odd"
                  ? "inherit"
                  : "primary"
              }
              style={{
                width: 55,
                borderWidth: 2,
                borderRadius: 5,
                paddingVertical: 20,
                justifyContent: "center",
                marginLeft: 10,
              }}
              onClick={() => onSelection("odd")}
            >
              ODD
            </Button>
          </div>
        </div>
      </div>
      <div style={{ alignSelf: "center" }}>
        {/* ---------------------------- ANSWER div INPUT div ---------------------------- */}
      </div>
      <div
        style={{
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
        }}
      >
        {/* ------------- ANSWER IMAGE RIGHT OR WRONG */}
        {/* <div style={{ flexDirection: "row", alignSelf: "center" }}>
            {disableAnswer && props.currentHomeWork.isRightAnswer && (
              <CorrectImage />
            )}
  
            {disableAnswer && !props.currentHomeWork.isRightAnswer && (
              <WrongImage />
            )}
          </div> */}
        {
          // ------------------   CORRECT ANSWER
          disableAnswer && !props.currentHomeWork.isRightAnswer && (
            <div
              style={{
                alignSelf: "center",
                marginTop: 20,
                marginLeft: 50,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: 24 }}>Correct Answer :{"   "}</div>
              <div style={{ fontSize: 48 }}>{displayAnswer}</div>
            </div>
          )
        }
      </div>
      <Snackbar
       open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert 
        key={`toastMessage-${toastMessage}`}
        onClose={handleClose} 
        severity={messageType}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default EvenOdd;
