import { combineReducers } from "redux";
import counterReducer from "../features/counter/counterSlice";
import { childReducer } from "./child.reducer";
import { homeworkReducer } from "./homework.reducer";
import { parentReducer } from "./parent.reducer";
import { loginReducer } from "./login.reducer";
import { userReducer } from "./user.reducer";
import { utilsReducer } from "./utils.reducer";
import { profileReducer } from "./profile.reducer";
import { brainBustersReducer } from "./brainBusters.reducer";

const rootReducer = () =>
  combineReducers({
    counterReducer: counterReducer,
    parentReducer: parentReducer,
    childReducer: childReducer,
    homeworkReducer: homeworkReducer,
    loginReducer: loginReducer,
    userReducer: userReducer,
    utilsReducer: utilsReducer,
    profileReducer: profileReducer,
    brainBustersReducer: brainBustersReducer,
  });

export default rootReducer;
