export function getIsLoggedIn({ userReducer }) {
  if (userReducer && userReducer.isLoggedIn) return userReducer.isLoggedIn;
  else return false;
}

export function getUserInfo({ userReducer }) {
  if (userReducer && userReducer.userInfo&&userReducer.userInfo.credentials) return userReducer.userInfo.credentials;
  else return undefined;
}

export function getLoginError({ userReducer }) {
  if (userReducer && userReducer.loginError) return userReducer.loginError;
  else return "";
}

export function getUserId({ userReducer }) {
  if (userReducer && userReducer.userInfo && userReducer.userInfo.credentials)
    return userReducer.userInfo.credentials.emailId;
  else return undefined;
}
