import React, { useState, Fragment } from 'react';
import { Grid} from '@material-ui/core'
import { Helmet } from 'react-helmet';
import './style.scss'
import Breadcumb from '../Breadcumb';

const menus = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'Privacy Policies',
    },
]

const Leagal = (props) => {

    // const [title, image] = props;
    return (
        <Fragment>
            <Helmet>
                <title>Privacy Policies</title>
            </Helmet>
            <Breadcumb
                title="Privacy Policies"
                menus={menus}
            />
            <Grid className="aboutArea">
                <Grid container spacing={4} className="container">
                    <Grid item lg={12} md={12} xs={12} style={{maxHeight: '100vh', overflow: 'auto'}}>
                        <Grid className="aboutContent">
                            <h2>Edulyn <span>Privacy Policies</span></h2>
                            <p>We at Edulyn, the owner and operator of the mobile application Edulyn (the "App") and our website www.edulyn.com (the "Website"), together hereafter referred to in this Privacy Policy as "Edulyn", "us", "our" or "we",&nbsp; respect the privacy of your personal information and, as such, make every effort to ensure your information is protected and remains private. &nbsp;&nbsp;We will not rent, sell, lease or barter your information to anyone.</p>
                            <p>&nbsp;</p>
                            <p>We have provided this Privacy Policy to explain how we collect, use, share and protect information about the users of our App and Website (hereafter referred to as &ldquo;user&rdquo;, &ldquo;you&rdquo; or "your").&nbsp; We will not use or share your personal information with anyone except as described in this Privacy Policy. This Privacy Policy will inform you about the types of personal data we collect, the purposes for which we use the data, the ways in which the data is handled and your rights with regard to your personal data.</p>
                            <p>&nbsp;</p>
                            <p>Edulyn collects information about you when you contact us, purchase a subscription to our service or access other online products and services (collectively, the &ldquo;Services&rdquo;), and through other interactions and communications you have with us. The term Services includes, collectively, the content, services, technology and products available through the Edulyn App or our Website, as well as any software that Edulyn provides to you that allows you to access the Services.</p>
                            <p>&nbsp;</p>
                            <p>For purposes of this Privacy Policy, "Your Information" or "Personal Data" means information about you, which may be of a confidential or sensitive nature and may include personally identifiable information ("PII") and/or financial information. PII means individually identifiable information that would allow us to determine the actual identity of a specific living person, while sensitive data may include information, comments, content and other information that you voluntarily provide.</p>
                            <p>&nbsp;</p>
                            <p>We may change this Privacy Policy from time to time. If we decide to change this Privacy Policy, we will inform you by posting the revised Privacy Policy in the App and on the Website. If the changes to the Privacy Policy are significant, we will send an email notice to you. Changes will go into effect on the "Last updated" date shown at the end of this Privacy Policy. By continuing to use the App, Website or our Services, you consent to the revised Privacy Policy. We encourage you to periodically review the Privacy Policy for the latest information on our privacy practices.</p>
                            <p>&nbsp;</p>
                            <p>By accessing or using our services, you consent to the collection, transfer, manipulation, storage, disclosure and other uses of your information (collectively, "use of your information") as described in this privacy policy and our Terms of Service which may be accessed here http://edulyn.com/terms-of-services.html . If you do not, or no longer, agree with or consent to this privacy policy and our terms of service you may not access or use our services. Furthermore, for users of our services, we rely upon the legal grounds found in the section entitled "the legal basis and reasons for collection and processing your personal information" found hereinafter in this privacy policy.</p>
                            <p><strong>&nbsp;</strong></p>
                            <ol>
                                <li><strong>Children's Privacy</strong></li>
                            </ol>
                            <p>Edulyn does not sell our Service to children, but only to adults. Our Services are intended for users who have attained the age of majority where they reside (18 years of age or older in most jurisdictions). &nbsp;Furthermore, in compliance with the Children's Online Privacy Protection Act ("COPPA") we do not knowingly collect personally identifiable information (PII) from persons under the age of 13, the "Protected Age". If you are under the Protected Age, please do not provide us with information of any kind whatsoever. If you have reason to believe that we may have accidentally received information from a child under the Protected Age, please contact us immediately. If we become aware that we have inadvertently received Personal Information from a person under the Protected Age, we will delete such information from our records.</p>
                            <p>&nbsp;</p>
                            <ol start="2">
                                <li><strong>Information Provided Directly By You</strong></li>
                                <li>We collect information that you provide directly to us, such as when creating or modifying a personal account, requesting information, requesting Services, completing a Edulyn form, survey, questionnaire or application, contacting customer support, joining or enrolling for an event or otherwise communicating with us in any manner. This information may include, without limitation: your name, date of birth, e-mail address or any other personal information you choose to provide.</li>
                                <li>To access some features of the Service, including our subscription services plan, an adult (parent or guardian) will be required to register for an account. Parents or guardians may set up one or more child profiles associated with the parent's or guardian's personal account so that a child or children may access the Service under the parent's or guardian's direct supervision and to ensure that the parent or guardian has control of their child's use of the Service. During the child profile creation process, the parent or guardian may provide information about the child authorized to use the Service through the parent's or guardian's account. The parent or guardian should refrain from providing any PII associated with the child.</li>
                                <li>By creating a child profile associated with your parent or guardian account, and permitting your child or children to use the Edulyn Service, you expressly agree and consent to the collection, use, and disclosure of your child's personal information as described in this Privacy Policy.</li>
                            </ol>
                            <p>&nbsp;</p>
                            <ol start="3">
                                <li><strong>Information Collected Through Your Use of Our Services</strong></li>
                            </ol>
                            <p>We collect information about your activity on our services, for instance how you use them (e.g., date and time you logged in, features you&rsquo;ve been using, searches, clicks and pages that have been shown to you, referring webpage address, advertising that you click on) and how you interact with us. The following are situations in which you may provide Your Information to us:</p>
                            <ol>
                                <li>When you fill out forms or fields through our Services;</li>
                                <li>When you register for an account with our Service;</li>
                                <li>When you create a child's profile associated with your own personal parent or guardian account;</li>
                                <li>When you order a subscription to our Service or products from, or through our Service;</li>
                                <li>When you access the Services, we may gather data on content you and/or your child use, view or listen to, and similar types of usage information;</li>
                                <li>When you or your child provide responses to a survey or questionnaire;</li>
                                <li>When you join or enroll in an event through our Services;</li>
                                <li>When you sign up for any newsletters or other materials through our Services;</li>
                                <li>When you provide information to us through a third-party application, service or website;</li>
                                <li>When you communicate with us or request information about our Service or products, whether via email or other means;</li>
                                <li>When you participate in any of our marketing initiatives, including events, contests or promotions; and,</li>
                                <li>When you participate in forums, reviews or provide or post user generated content or other submissions.</li>
                            </ol>
                            <p>&nbsp;</p>
                            <ol start="4">
                                <li><strong>Information Collected From Other Sources</strong></li>
                            </ol>
                            <p>We may also receive information from other sources and combine that with information we collect through our Services. For example if you choose to link, create, or log in to your Edulyn account with a social media service, e.g. Facebook, LinkedIn or Twitter, or if you engage with a separate application or website that uses our API, or whose API we use, we may receive information about you from that third party source. This includes, without limitation, profile information, gender, user name, age, e-mail addresses, phone numbers, and other identifiers.</p>
                            <p>&nbsp;</p>
                            <ol start="5">
                                <li><strong>Information Third Parties Provide</strong></li>
                            </ol>
                            <p>We may collect information about you from sources other than you, such as from social media websites, blogs, analytics providers, business affiliates and partners and other users. This includes, without limitation, identity data, contact data, marketing and communications data, behavioral data, technical data and content data.</p>
                            <p>&nbsp;</p>
                            <ol start="6">
                                <li><strong>Device Information</strong></li>
                            </ol>
                            <p>When you use our Services through your mobile phone, tablet, computer or other device, we may collect information regarding and related to your device, such as hardware models and IDs, device type, operating system version, the request type, the content of your request and basic usage information about your use of our Services, such as date and time. In addition, we may collect information regarding application-level events and associate that with your account to provide customer service. We may also collect and store information locally on your device using mechanisms such as browser web storage and application data caches.</p>
                            <p>&nbsp;</p>
                            <ol start="7">
                                <li><strong>Location Information</strong></li>
                            </ol>
                            <p>When you use the Services we may collect your precise location data. We may also derive your approximate location from your IP address.</p>
                            <p>&nbsp;</p>
                            <ol start="8">
                                <li><strong>Aggregated Data</strong></li>
                            </ol>
                            <p>We may collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data is de-identified or anonymized and does not constitute Personal Data as this data does not directly or indirectly reveal your identity. If we ever combine Aggregated Data with your Personal Data so that it can directly or indirectly identify you, we treat the combined data as PII which will only be used in accordance with the terms of this Privacy Policy.</p>
                            <p><strong>&nbsp;</strong></p>
                            <ol start="9">
                                <li><strong>Information Collected Automatically</strong></li>
                            </ol>
                            <p>We may now, or in the future, automatically collect information via the App and the Website through the use of various technologies, including, but not limited to Cookies, Pixel Tags and Web Beacons (explained below). We may collect your IP address, browsing behavior and device IDs. This information is used by us in order to enable us to better understand how our Services are being used by visitors and allows us to administer and customize the Services to improve your overall experience.</p>
                            <p>&nbsp;</p>
                            <ol start="10">
                                <li><strong>Cookies, Log Files and Anonymous Identifiers</strong>
                                    <ol>
                                        <li>We may now, or in the future, elect to send one or more Cookies, which are small data files, to your computer or mobile device to uniquely identify you and let us help you log in faster and enhance your user experience. &ldquo;Cookies&rdquo; are text-only pieces of information that are transferred to an individual&rsquo;s storage device or other browsing devices for record keeping purposes. A Cookie may convey to us anonymous information about how you use our Services so we can provide you with a more personalized experience, but does not collect personal information about you. Cookies allow us to remember important information that will make your use of a website or App more convenient. A Cookie will typically contain the name of the domain from which the Cookie has come, the &ldquo;lifetime&rdquo; of the Cookie, and a randomly generated unique number or other value. Certain Cookies may be used on the Website regardless of whether you are logged in to your account or not.</li>
                                        <li>Session Cookies are temporary Cookies that remain in the Cookie file of your browser until you leave the Website.</li>
                                        <li>Persistent Cookies remain in the Cookie file of your browser for much longer (though how long will depend on the lifetime of the specific Cookie).</li>
                                        <li>If we use session Cookies to track the total number of visitors to our Website, this is done on an anonymous aggregate basis (as Cookies do not in themselves carry any personal data).</li>
                                        <li>We may now or in the future employ Cookies so that we remember your device when it is used to return to our Services to help customize your Edulyn experience. We may associate personal information with a Cookie file in those instances, and the information will be treated as PII under the terms of this Privacy Policy.</li>
                                        <li>We may use Cookies to help us know that you are logged on, provide you with features based on your preferences, understand when you are interacting with our Services, and compile other information regarding use of our Services.</li>
                                        <li>Edulyn may now or in the future use third-party services such as Google Analytics to help understand use of the Services. These services typically collect the information sent by your browser as part of a webpage request, including Cookies and your IP address. They receive this information and their use of it is governed by their respective privacy policies. You may opt-out of Google Analytics for Display Advertisers including AdWords and opt-out of customized Google Display Network ads by visiting the Google Ads Preferences Manager here https://adssettings.google.com/authenticated?hl=en. To provide website visitors more choice on how their data is collected by Google Analytics, Google has developed an Opt-out Browser add-on, which is available by visiting Google Analytics Opt-out Browser Add-on here https://tools.google.com/dlpage/gaoptout. For additional information on how Google uses data when you use Google partners&rsquo; sites or apps, go here: https://policies.google.com/technologies/partner-sites.</li>
                                        <li>You can control the use of Cookies at the individual browser level. Use the options in your web browser if you do not wish to receive a Cookie or if you wish to set your browser to notify you when you receive a Cookie. You can easily delete and manage any Cookies that have been installed in the Cookie folder of your browser by following the instructions provided by your particular browser manufacturer. Consult the documentation that your particular browser manufacturer provides. You may also consult your mobile device documentation for information on how to disable Cookies on your mobile device. If you reject Cookies, you may still use our Services, but your ability to use some features or areas of our Service may be limited.</li>
                                        <li>Edulyn cannot control the use of Cookies by third parties (or the resulting information), and use of third party Cookies is not covered by this policy.</li>
                                        <li>We may now or in the future automatically collect information about how you interact with our Services, preferences expressed, and settings chosen and store it in Log Files. This information may include internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or clickstream data. We may combine this automatically collected log information with other information we collect about you. We do this to improve services we offer you, to improve marketing, analytics, or website/application functionality, and to document your consent to receiving products, services or communications from us or our partners. If we link such information with personally identifiable information in a manner that identifies a particular individual, then we will treat all such information as PII for purposes of this Privacy Policy.</li>
                                        <li>When you use our Services, we may now or in the future employ Web Beacons (also known as clear GIFs or tracking pixels) to anonymously track online usage patterns. No Personally Identifiable Information from your account is collected using these Web Beacons.</li>
                                    </ol>
                                </li>
                            </ol>
                            <p><strong>&nbsp;</strong></p>
                            <ol start="11">
                                <li><strong>The Legal Basis and Reasons For Collection and Processing Your Personal Information</strong></li>
                            </ol>
                            <p>The legal basis upon which we rely for the collection and processing of your Personal Information are the following:</p>
                            <ol>
                                <li>When visiting our Website and subscribing to our App you have given Edulyn consent to provide you with our Services and to process your information in accordance with this Privacy Policy; and to the transfer of your personal data to other jurisdictions including, without limitation, the United States;</li>
                                <li>It is necessary for entering into, or performing, the obligations of a contract between you and Edulyn, including, without limitation, our Terms of Service agreement which may be accessed by you here http://edulyn.com/terms-of-services.html and a subscription agreement to provide you with the Edulyn App Services;</li>
                                <li>It is necessary when registering you as a user, managing your account and profile, and authenticating you when you log in;</li>
                                <li>It is necessary for our legitimate interests in the proper administration of our App and Website and business; analyzing the use of the App and Website and our Services; assuring the security of our App and Website and Services; maintaining back-ups of our databases; and communicating with you;</li>
                                <li>To resolve technical issues you encounter, to respond to your requests for assistance, comments and questions, to analyze crash information, to repair and improve the Services and provide other customer support;</li>
                                <li>To send communications via email and within the Services, including, for example, responding to your comments, questions and requests, providing customer support, and sending you technical notices, product updates, security alerts, and account management related messages;</li>
                                <li>To send promotional communications that you have consented to receive or have expressed a legitimate interest in receiving;</li>
                                <li>It is necessary for our legitimate interests in the protection and assertion of our legal rights, and the legal rights of others, including you; and</li>
                                <li>It is necessary for our compliance with certain legal provisions which may require us to process your personal data. By way of example, and without limitation, we may be required by law to disclose your personal data to law enforcement or a regulatory agency.</li>
                            </ol>
                            <p>&nbsp;</p>
                            <ol start="12">
                                <li><strong>How We Use Information We Collect</strong></li>
                            </ol>
                            <p>Our primary purpose in collecting, holding, using and disclosing your Information is for our legitimate business purposes and to provide you with a safe, smooth, efficient, and customized experience. &nbsp;We will not rent, sell, lease or barter your information to anyone unless you grant us explicit permission for this information to be shared in that manner.</p>
                            <p>&nbsp;</p>
                            <p>We will use this information in order to:</p>
                            <ol>
                                <li>To accomplish the specific purpose for which the information was provided;</li>
                                <li>Operate and provide our Services;</li>
                                <li>Improve our internal operations, systems, products, and Services;</li>
                                <li>Provide users with customer support including, sending you related information, including confirmations, technical notices, updates, security alerts, and administrative messages;</li>
                                <li>Understand you and your preferences to enhance your experience and enjoyment using our Services;</li>
                                <li>Analyze, improve and manage our Services and operations;</li>
                                <li>Offer you the opportunity to participate in research projects;</li>
                                <li>Resolve problems and disputes, and engage in other legal and security matters;</li>
                                <li>Protect, investigate, and deter against fraudulent, unauthorized use or illegal activity;</li>
                                <li>Enforce our Terms of Service and any terms and conditions of any agreements for our Services;</li>
                                <li>Send you communications we think will be of interest to you, including information about services, products, promotions, news, and events of Edulyn; and</li>
                                <li>Verify your identity and prevent impersonation, spam or other unauthorized or illegal activity including, without limitation, infringement of intellectual property rights of third parties.</li>
                            </ol>
                            <p>&nbsp;</p>
                            <ol start="13">
                                <li><strong>How We Share or Disclose Information We Collect</strong>
                                    <ol>
                                        <li>We share the information we collect about you as described in this Privacy Policy, or as described at the time of collection or sharing, as follows:
                                            <ul>
                                                <li>With third party Service Providers to enable them to provide the Services you request;</li>
                                                <li>With third parties with whom you choose to let us share information, for example other websites or apps that integrate with our API or Services, or those with an API or Service with which we integrate;</li>
                                                <li>With Edulyn subsidiaries and affiliated entities that provide services or conduct data processing on our behalf, or for data verification, data centralization and/or logistics purposes;</li>
                                                <li>With vendors, consultants, marketing partners, and other service providers who need access to such information to carry out work on our behalf;</li>
                                                <li>In response to a request for information by a competent authority if we believe disclosure is in accordance with, or is otherwise required by, any applicable law, regulation, court or legal process;</li>
                                                <li>With law enforcement officials, government authorities, or other third parties if we believe your actions are inconsistent with our user agreements, Terms of Service, or policies, or to protect the rights, property, or safety of Edulyn, our users or others;</li>
                                                <li>In connection with, or during negotiations of, any merger, sale of company assets, consolidation or restructuring, financing, or acquisition of all or a portion of our business by or into another company;</li>
                                                <li>If we otherwise notify you and you consent to the sharing; and</li>
                                                <li>In an aggregated and de-identified form which cannot reasonably be used to identify you. We only use such data in the aggregate form and our analytical services do not record any personal information.</li>
                                            </ul>
                                        </li>
                                        <li>We may disclose Your Information as follows:
                                            <ul>
                                                <li>To any person who, in our reasonable judgment, is authorized to receive Your Information as your agent, including as a result of your business dealings with that person (for example your attorney, etc,);</li>
                                                <li>As required by law or ordered by a court, regulatory, or administrative agency;</li>
                                                <li>As we deem necessary, in our sole discretion, if we believe that you are violating any applicable law, rule or regulation, or are otherwise interfering with another's rights or property, including, without limitation, our rights or property;</li>
                                                <li>To enforce our&nbsp;Terms of Service, this Privacy Policy, and any other applicable agreements and policies.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p>&nbsp;</p>
                            <ol start="14">
                                <li><strong>Protective Measures We Use</strong></li>
                            </ol>
                            <p>We protect your information using commercially reasonable technical and administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure and alteration. Some of the safeguards we use are firewalls and data encryption, physical access controls to our data centers, and information access authorization controls. Although we take measures to secure your information, we do not promise, and you should not expect, that your personal information, or searches, or other information will always remain secure. We cannot guarantee the security of our information storage, nor can we guarantee that the information you supply will not be intercepted while being transmitted to and from us over the Internet including, without limitation, email and text transmissions. In the event that any information under our control is compromised as a result of a breach of security, we will take reasonable steps to investigate the situation and where appropriate, notify those individuals whose information may have been compromised and take other steps, in accordance with any applicable laws and regulations.</p>
                            <p><strong>&nbsp;</strong></p>
                            <ol start="15">
                                <li><strong>User Content and Public Sharing</strong></li>
                                <li>If you share User Content, as defined in our Terms of Service Agreement, or post a comment or message that contains personally identifiable information to an area of our Services that is accessible to the public, third parties may use that information, or share it with other parties. As a result, you may receive unsolicited messages from other parties. Such User Content, comments or messages is not subject to this Privacy Policy and you share this information at your own risk. We reserve the right to remove any posting that contains information that we deem as risky or inflammatory.</li>
                                <li>You will need to contact Edulyn in the event that you wish to have this personally identifiable information removed from our Service. Please note that responding to your request may not ensure complete or comprehensive removal from the internet if the User Content has been reposted by another party. To request removal of User Content from our Services, please contact us at support@edulyn.com.</li>
                            </ol>
                            <p>&nbsp;</p>
                            <ol start="16">
                                <li><strong>Analytic Services Provided By Others</strong></li>
                            </ol>
                            <p>We may allow others to provide audience measurement and analytics services for us, to serve advertisements on our behalf across the Internet, and to track and report on the performance of those advertisements. These entities may use Cookies, Web Beacons, software development kits (SDKs), and other technologies to identify your device when you visit our Website and use our Services, as well as when you visit other online websites and services.</p>
                            <p>&nbsp;</p>
                            <ol start="17">
                                <li><strong>Links to Third-Party Websites</strong></li>
                                <li>Our Services, as well as the email messages sent with respect to our Services, may contain links or access to websites operated by third parties, including advertisers, that are beyond our control. Links or access to third parties from our Services are not an endorsement by us of such third parties, or their websites, applications, products, services, or practices. We are not responsible for the privacy policy, terms and conditions, practices or the content of such third parties. These third-parties may send their own Cookies to you and independently collect data.</li>
                                <li>If you visit or access a third-party website, application or other platform that is linked or accessed from our Services, we encourage you to read any privacy policies and terms and conditions of that third party before providing any personally identifiable information. If you have a question about the terms and conditions, privacy policy, practices or contents of a third party, please contact the third party directly.</li>
                                <li>Similarly, if you entered our Website through another website, Edulyn is not responsible for the privacy practices of that other website, and you should review the privacy policy of the originating website before providing any personal information to that website.</li>
                            </ol>
                            <p>&nbsp;</p>
                            <ol start="18">
                                <li><strong>Promotional Information Opt Out</strong></li>
                            </ol>
                            <p>You may opt out of receiving promotional messages, like newsletters, from us at any time by following the instructions in those messages sent to you including emails, or by contacting us at any time using the Contact Us information at the end of this Privacy Policy. In doing so, you elect not to receive correspondence from us or have Your Information shared with other entities for their marketing purposes. If you opt out, we may still send you non-promotional communications, such as those about your account, about Services you have requested, or our ongoing business relations.</p>
                            <p>&nbsp;</p>
                            <ol start="19">
                                <li><strong>International Privacy Practices</strong></li>
                            </ol>
                            <p>In order to provide our products and services to you, we may send and store your personal information outside of the country where you reside or are located, including to the United States. Accordingly, if you reside or are located outside of the United States, your personal information may be transferred outside of the country where you reside or are located, including countries that may not provide the same level of protection for your personal information. By accessing and using the Edulyn Services, users who reside, or are located, in countries outside of the United States, agree and consent to the transfer to, and processing of, personal information on servers located outside of the country where they reside, and assume the risk that the protection of such information may be different, and may be less protective, than those required under the laws of their residence or location.</p>
                            <p>&nbsp;</p>
                            <ol start="20">
                                <li><strong>Do Not Track Settings</strong></li>
                            </ol>
                            <p>Some web browsers may give you the ability to enable a "do not track" feature that sends signals to the websites you visit, indicating that you do not want your online activities tracked. This is different than blocking or deleting Cookies, as browsers with a "do not track" feature enabled may still accept Cookies. There currently is no accepted standard for how to respond to this signal, and we do not take any action in response to this signal.</p>
                            <p>&nbsp;</p>
                            <ol start="21">
                                <li><strong>Payment Processing</strong></li>
                            </ol>
                            <p>Edulyn offers you the opportunity to purchase access to the Service by means of a recurring subscription (&ldquo;Subscription&rdquo;) through a secure third party payment processor we provide to you, or a Third Party App Store. Payments will be processed by the secure third party payment processor, or the Third Party App Store, and not through Edulyn. Edulyn does not collect or store your payment information, and we are not responsible for the privacy policy, terms and conditions, or practices of the Third Party App Store. We encourage you to read any privacy policies and terms and conditions of that Third Party App Store before providing them with any personally identifiable information.</p>
                            <p><strong>&nbsp;</strong></p>
                            <ol start="22">
                                <li><strong>Your California Privacy Rights</strong></li>
                                <li>California Civil Code Section 1798.83 entitles California residents to request information concerning whether a business has disclosed Personal Information to any third parties for their direct marketing purposes. California residents may request and obtain from us once a year, free of charge, information about the personal information, if any, we disclosed to third parties for direct marketing purposes within the immediately preceding calendar year. If applicable, this information would include a list of the categories of personal information that was shared and the names and addresses of all third parties with which we shared information within the immediately preceding calendar year.</li>
                                <li>If you are a California resident and would like to make such a request, please submit your request in writing to: support@edulyn.com</li>
                            </ol>
                            <p>&nbsp;</p>
                            <ol start="23">
                                <li><strong>Sharing Information With Law Enforcement</strong></li>
                                <li>If Edulyn receives a request for user account information from a government agency investigating criminal activity, we will review the request to be certain that it satisfies all legal requirements before releasing information to the requesting agency.</li>
                                <li>Furthermore, under 18 U.S.C. &sect;&sect; 2702(b)(8) and 2702(c)(4) (Voluntary Disclosure Of Customer Communications or Records), Edulyn may disclose user account information to law enforcement, without a subpoena, court order, or search warrant, in response to a valid emergency when we believe that doing so is necessary to prevent death or serious physical harm to someone. Edulyn will not release more information than it prudently believes is necessary to prevent harm in an emergency situation.</li>
                            </ol>
                            <p>&nbsp;</p>
                            <ol start="24">
                                <li><strong>Account Information Correction; Account Deactivation</strong></li>
                            </ol>
                            <p>You may correct your account information at any time by logging into your online account, or contacting us. If you wish to correct your account information, or cancel your account, please contact Edulyn with an email request to support@edulyn.com.</p>
                            <p>&nbsp;</p>
                            <ol start="25">
                                <li><strong>Our Information Retention Policy</strong></li>
                            </ol>
                            <p>Unless you request that we delete certain information, we retain the information we collect for as long as your account is active or as needed to provide you services. Following termination or deactivation of your account, we will retain information for at least 1 year and may retain the information for as long as needed for our business and legal purposes. We will only retain your Personal Data for so long as we reasonably need to unless a longer retention period is required by law (for example for regulatory purposes).</p>
                            <p>&nbsp;</p>
                            <ol start="26">
                                <li><strong>Contact Us</strong></li>
                            </ol>
                            <p>If you have any questions or if you would like to contact us about our processing of your personal information, including exercising your rights as outlined above, please contact us at&nbsp;support@edulyn.com, or mail us here:</p>
                            <p>&nbsp;</p>
                            <p><strong>Edulyn</strong></p>
                            <p>Venkata Thirumalasetty&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                            <p>Venkata.t@edulyn.com</p>
                            <p>&nbsp;</p>
                            <p><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Last updated: August 27, 2020</strong></p>
                            <p>&nbsp;</p>
                        </Grid>
                    </Grid>
                    {/* <Grid item lg={6} md={6} xs={12}>
                        <Grid style={{ background: `url(${about}) no-repeat center center / cover` }} className="aboutImg">
                            <Button
                                onClick={() => setOpen(true)}>
                                <i className="fa fa-play"></i>
                            </Button>
                        </Grid>
                    </Grid> */}
                </Grid>
                
            </Grid>
            {/* <Funfact className="funfactAreaStyleTwo" /> */}
            {/* <Team /> */}
            {/* <Testmonial /> */}
        </Fragment>
    );
}
export default Leagal;