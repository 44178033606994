import { Counter } from "./features/counter/Counter";
import "./App.css";
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import {
  AppBar,
  Button,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  Grid,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import ChildList from "./view/components/ChildList";
import CoursePracticeList from "./view/components/CoursePracticeList";
import ChildHomePage from "./view/components/ChildHomePage";
import HomeworkPage from "./view/components/HomeworkPage";
import CoursePage from "./view/components/CoursePage";
import { useAuth0 } from "@auth0/auth0-react";
import Courses from "./view/components/Courses";
import Login from "./view/components/Login/Login";
import LoginButton from "./view/components/LoginButton";
import logo from "./images/logo_edulyn.png";
// import Footer from "./view/components/Common/footer";
// import Header from "./view/components/Common/header";
import Header from "./view/components/Header";
import Footer from "./view/components/Footer";

import SearchIcon from "@material-ui/icons/Search";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { useDispatch, useSelector } from "react-redux";
import { getIsLoggedIn, getUserInfo } from "./reducer/selectors/user.entity";
import { attemptLogout } from "./actions/login.actions";
import React, { useEffect, useState } from "react";
import Register from "./view/components/Register/Register";
import AddChild from "./view/components/AddChild/AddChild";
// import AboutUs from "./view/components/Common/aboutus";
import AboutContent from "./view/components/AboutUs";
import ContactUs from "./view/components/ContactUs";
import Teachers from "./view/components/Common/teachers";
import { Dialog } from "@material-ui/core";
import Loader from "./view/components/Common/Loader";
import { getShowLoader } from "./reducer/selectors/utils.entity";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SpeedTest from "./view/components/SpeedTest";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ChangePassword from "./view/components/ChangePassword";
import Profile from "./view/components/Profile/Profile";
import BrainBusters from "./view/components/BrainBusters";
import NewChildPage from "./view/components/ChildHomePage/NewChildPage";
import Leagal from "./view/components/Leagal/leagal";
import ParentProfile from "./view/components/ParentProfile/ParentProfile";
import Terms from "./view/components/Leagal/terms";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: "space-between",
    overflowX: "auto",
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  appbarColor: {
    color: "red",
  },
}));

const sections = [
  { id: 1, title: "HOME", url: "/parent" },
  { id: 2, title: "ACTIVITIES", url: "/brainbusters" },
  // { id: 2, title: "SYLLABUS", url: "/courses" },
  // { id: 3, title: "TEACHERS", url: "/teachers" },
  // { title: "Mock Tests", url: "/mocktests" },
  // { title: "Brain Busters", url: "/brainbusters" },
  // { title: "Progress", url: "/progress" },
  // { title: "Lessons", url: "/lessons" },
  // { title: "FAQs", url: "/faqs" },
  { id: 4, title: "ABOUT US", url: "/aboutus" },
  { id: 5, title: "CONTACT", url: "/contact" },
];

const guestSections = [
  { id: 1, title: "HOME", url: "/" },
  { id: 2, title: "ACTIVITIES", url: "/brainbusters" },
  // { id: 3, title: "TEACHERS", url: "/teachers" },
  // { title: "Mock Tests", url: "/mocktests" },
  // { title: "Brain Busters", url: "/brainbusters" },
  // { title: "Progress", url: "/progress" },
  // { title: "Lessons", url: "/lessons" },
  // { title: "FAQs", url: "/faqs" },
  { id: 4, title: "ABOUT US", url: "/aboutus" },
  { id: 5, title: "CONTACT", url: "/contact" },
];

const myStyle = {
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  display: "block",
};

function App() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opener = Boolean(anchorEl);

  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentPageUrl = window.location.pathname;

  const { user, isLoading } = useAuth0();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const showLoader = useSelector(getShowLoader);
  const { isAuthenticated } = true;
  const [showJoinSession, setShowJoinSession] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const userInfo = useSelector(getUserInfo);
  const [timeFlag, setTimeFlag] = useState(false);
  const [showAppBar, setShowAppBar] = useState(true);

  useEffect(() => {
    if (!isLoggedIn && history && location) {
      console.log("LOCA ", location.pathname);
      if (
        location.pathname !== "/courses" &&
        location.pathname !== "/brainbusters" &&
        location.pathname !== "/aboutus" &&
        location.pathname !== "/contact" &&
        location.pathname !== "/" &&
        location.pathname !== "/register" &&
        location.pathname !== "/login" &&
        location.pathname !== "/guest/child" &&
        location.pathname !== "/guest/child/course" &&
        location.pathname !== "/guest/child/homework" &&
        location.pathname !== "/guest/speedtest" &&
        location.pathname !== "/privacypolicies" &&
        location.pathname !== "/terms" &&
        location.pathname !== "/refundpolicy" &&
        !location.pathname.startsWith("/profile")
      ) {
        console.log("TROGGERING");
        history.push("/login");
      }
    }
    if (
      location &&
      location.pathname &&
      location.pathname.startsWith("/profile")
    ) {
      setShowAppBar(false);
    } else if (!showAppBar) {
      setShowAppBar(true);
    }
  });

  useEffect(() => {
    if (!isLoggedIn && history && location) {
      console.log("LOCB ", location.pathname);
      if (
        location.pathname !== "/courses" &&
        location.pathname !== "/brainbusters" &&
        location.pathname !== "/aboutus" &&
        location.pathname !== "/contact" &&
        location.pathname !== "/" &&
        location.pathname !== "/register" &&
        location.pathname !== "/login" &&
        location.pathname !== "/guest/child" &&
        location.pathname !== "/guest/child/course" &&
        location.pathname !== "/guest/child/homework" &&
        location.pathname !== "/guest/speedtest" &&
        location.pathname !== "/privacypolicies" &&
        location.pathname !== "/termsandconditions" &&
        location.pathname !== "/refundpolicy" &&
        !location.pathname.startsWith("/profile")
      ) {
        console.log("TROGGERING");
        history.push("/login");
      }
    }

    if (
      location &&
      location.pathname &&
      location.pathname.startsWith("/profile")
    ) {
      console.log("Location ---- ", location);
      setShowAppBar(false);
    } else if (!showAppBar) {
      setShowAppBar(true);
    }
  }, [isLoggedIn, history]);

  useEffect(() => {
    var date = new Date();
    var localTime = date.getTime();
    var localOffset = date.getTimezoneOffset() * 60000;
    var utc = localTime + localOffset;
    var newDateTime = utc + 3600000 * -4;
    var convertedDateTime = new Date(newDateTime);
    var time = convertedDateTime.toLocaleString();
    var timeEst = time.split(",")[1];
    setTimeFlag(timeEst.split(":")[0] > 9 && timeEst.split(":")[0] < 15);
    console.log(
      "Local time ======> ",
      localTime,
      localOffset,
      utc,
      " === new === ",
      Number(timeEst.split(":")[0]),
      timeEst.split(":")[0] > 6,
      timeEst,
      timeFlag
    );
    console.log("timeFlag ::::::", timeFlag ? "yes" : "no");
  });

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleShowPassword = () => {
    // history.push("/changepassword")
    setShowChangePassword(true);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {/* <BrowserRouter> */}
      <CssBaseline />
      <Container maxWidth="lg">
        <Loader open={showLoader} />
        <ChangePassword
          open={showChangePassword}
          onClose={() => setShowChangePassword(false)}
        />

        <Dialog open={showJoinSession}>
          <Grid
            container
            spacing={2}
            style={{
              // marginTop: 100,
              padding: 25,
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              backgroundColor: "white",
              minHeight: "20vh",
            }}
          >
            {/* <Typography color="primary" variant="h6">
              Mentors for online session are available from 10AM (EST) to 3PM
              (EST). Still Kids can practice Homework. Thanks
            </Typography> */}
            <Typography color="primary" variant="h6">
              No online session today. Still Kids can practice Homework. Thanks
            </Typography>

            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Grid item xs={4}>
                <Button
                  variant="outlined"
                  color="error"
                  style={{ width: "100%" }}
                  onClick={() => {
                    console.log("SERVICE CANCEL");
                    setShowJoinSession(false);
                  }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>

        <Header logo={logo} headerSections={isLoggedIn ? sections : guestSections} isLoggedIn={isLoggedIn} />
        {/* <AppBar position="static"> */}

        <div>{JSON.stringify(user)}</div>

        {/* {isAuthenticated ? ( */}
        <Switch>
          <Route path="/courses" exact={true}>
            <Courses />
          </Route>
          <Route path="/brainbusters" exact={true}>
            <BrainBusters />
          </Route>
          <Route path="/mocktests" exact={true}>
            <div>MOCKTESTS</div>
          </Route>
          <Route path="/brainbusters" exact={true}>
            <div>BRAIN BUSTERS</div>
          </Route>
          <Route path="/progress" exact={true}>
            <div>PROGRESS</div>
          </Route>
          <Route path="/lessons" exact={true}>
            <div>LESSONS</div>
          </Route>
          <Route path="/faqs" exact={true}>
            <div>FAQS</div>
          </Route>
          <Route path="/aboutus" exact={true}>
            <AboutContent />
          </Route>
          <Route path="/contact" exact={true}>
            <ContactUs />
          </Route>
          <Route path="/parent" exact={true}>
            <ChildList />
          </Route>
          <Route path="/child" exact={true}>
            {/* <ChildHomePage /> */}
            <NewChildPage />
          </Route>
          <Route path="/child/course" exact={true}>
            <CoursePage />
          </Route>
          <Route path="/child/homework">
            <HomeworkPage />
          </Route>
          <Route path="/login" exact={true}>
            <Login />
          </Route>
          <Route path="/register" exact={true}>
            <Register />
          </Route>
          <Route path="/changepassword" exact={true}>
            <ChangePassword />
          </Route>
          <Route path="/addchild" exact={true}>
            <AddChild />
          </Route>
          <Route path="/guest/child/course" exact={true}>
            <CoursePage />
          </Route>
          <Route path="/guest/child" exact={true}>
            <ChildHomePage />
          </Route>
          <Route path="/guest/child/homework">
            <HomeworkPage />
          </Route>
          <Route path="/guest/speedtest">
            <SpeedTest />
          </Route>
          <Route path="/profile/:profilename">
            <Profile />
          </Route>
          <Route path="/privacypolicies">
            <Leagal />
            </Route>
          <Route path="/parent/profile">
            <ParentProfile />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/">
            {isLoggedIn ? <ChildList /> : <CoursePracticeList />}
          </Route>
        </Switch>
        {/* ) : (
        <div>
          <LoginButton />
        </div>
      )} */}
        {/* <Footer title="Footer" description="Something here to give the footer a purpose!" /> */}
        <Footer logo={logo} />
      </Container>
      {/* </BrowserRouter> */}
    </React.Fragment>
  );
}

export default App;
