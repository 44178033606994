import { cloneDeep } from "lodash";
import React, { useEffect, useRef, useState } from "react";
// import Styles from './styles'

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const Timer = () => {
  const [time, setTime] = useState({
    seconds: 0,
    minutes: 0,
    hours: 0,
  });

  useInterval(() => {
    let { hours, minutes, seconds } = cloneDeep(time);

    if (seconds < 60) {
      seconds++;
    } else if (seconds === 60) {
      seconds = 0;
      if (minutes < 59) {
        minutes++;
      } else {
        minutes = 0;
        hours++;
      }
    }

    setTime({
      hours,
      minutes,
      seconds,
    });
  }, 1000);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div>{time.hours}</div>
      <div style={{ marginLeft: 3, marginRight: 3 }}>:</div>
      <div>{time.minutes}</div>
      <div style={{ marginLeft: 3, marginRight: 3 }}>:</div>
      <div>{time.seconds}</div>
    </div>
  );
};

export default Timer;
