import React, { useEffect, useState } from 'react';
import { Grid, Button, Link } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import './style.scss'
import PracticeList from '../PracticeList/practicelist';

const SingleSubject = (props) => {

    const [courseSyllabus, setCourseSyllabus] = useState()
    const [practiceConceptsForGrade, setPracticeConceptsForGrade] = useState();
    const [showAllPracticeList, setShowAllPracticeList] = useState(false)

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        console.log('props childId...', props.practiceChildId)
        setCourseSyllabus(props.courseConcepts)
        console.log('single subject..', props.numOfQuestionsPerDay, props.badge, props.courseConcepts)
    }, []);

    return (
        <Grid className="productWrap">

            <Grid className="productImg">
                <Link to={`/product-details/`}>
                    {/* <img src={image} alt="" /> */}
                </Link>
                <span className={
                    `badge ${props.badge === 'trending' && 'trending' ||
                    props.badge === 'featured' && 'featured' ||
                    props.badge === 'new' && 'new'
                    }`

                }>{props.badge}</span>
            </Grid>
            <Grid className="productContent">
                <div className="justLiButton">
                    <ul style={{ display: "flex", justifyContent: "space-between" }}>
                        <li style={{ display: "flex", alignItems: "center" }}><h3>{props.subjectName}</h3></li>
                        {/* <li><Button component={Link}
                            onClick={() => {
                                history.push({
                                    pathname: "/guest/child",
                                    state: {
                                        childId: props.practiceChildId,
                                        numOfQuestionsPerDay: props.numOfQuestionsPerDay,
                                        userType: "guest",
                                    },
                                });
                            }}
                        > Homework</Button></li> */}
                    </ul>
                </div>
                <span className="author borderTop"></span>
                <div className={showAllPracticeList ? "" : "showLess"}>
                    <PracticeList
                        {...props}
                        title="Click below to practice"
                        practiceList={courseSyllabus && courseSyllabus.concepts}
                        userType="guest"
                        ctest={props.cTest}
                    />
                </div>
                
                    <div className="justLiButton" style={{marginTop:5}}>
                        <ul style={{ display: "flex", justifyContent: "flex-end" }}>
                            <li><Button component={Link}
                                onClick={() => setShowAllPracticeList(!showAllPracticeList)}>{!showAllPracticeList ? 'Show All' : 'Show Less'}</Button></li>
                        </ul>
                    </div>
            </Grid>
        </Grid>
    );
}


export default SingleSubject
