export const childConstants = {
  GET_CHILD_INFO: "GET_CHILD_INFO",
  GET_CHILD_INFO_SUCCESS: "GET_CHILD_INFO_SUCCESS",

  ADD_CHILD: "ADD_CHILD",
  ADD_CHILD_SUCCESS: "ADD_CHILD_SUCCESS",
  ADD_CHILD_FAILURE: "ADD_CHILD_FAILURE",

  DELETE_CHILD: "DELETE_CHILD",
  DELETE_CHILD_SUCCESS: "DELETE_CHILD_SUCCESS",
  DELETE_CHILD_FAILURE: "DELETE_CHILD_FAILURE",

  UPLOAD_CHILD_PROFILE_PIC: "UPLOAD_CHILD_PROFILE_PIC",
  UPLOAD_CHILD_PROFILE_PIC_SUCCESS: "UPLOAD_CHILD_PROFILE_PIC_SUCCESS",
  UPLOAD_CHILD_PROFILE_PIC_FAILURE: "UPLOAD_CHILD_PROFILE_PIC_FAILURE",

  UPLOAD_CHILD_PROFILE_PIC: "UPLOAD_CHILD_PROFILE_PIC",
  UPLOAD_CHILD_PROFILE_PIC_SUCCESS: "UPLOAD_CHILD_PROFILE_PIC_SUCCESS",
  UPLOAD_CHILD_PROFILE_PIC_FAILURE: "UPLOAD_CHILD_PROFILE_PIC_FAILURE",
};
