import * as React from "react";
import Styles from "./styles";
import AnalogClockFaceImage from "../assets/images/analogue-clock-face.png";

const Clock = (props) => {
  return (
    <div style={Styles.clock}>
      <div
        style={{
          backgroundImage: `url(${AnalogClockFaceImage})`,
          ...Styles.analogClock,
        }}
      >
        {/* <ImageBackground source={require('../../../assets/images/analogue-clock-face.png')} style={{ width: 150, height: 150 }}> */}
        {props.showHands && (
          <div>
            <div
              style={{
                position: "absolute",
                transform: `rotate(${
                  props.hours * 30 + props.minutes * 0.5
                }deg)`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                width: 150,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: 150,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={{ width: 75, height: 35 }}></div>
                  <div
                    style={{
                      width: 73,
                      height: 40,
                      borderRightWidth: 2,
                      borderRightColor: "green",
                      borderRightStyle: "solid",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={{ width: 75, height: 35 }}></div>
                  <div
                    style={{
                      width: 73,
                      height: 40,
                      borderLeftWidth: 2,
                      borderLeftColor: "green",
                      borderLeftStyle: "solid",
                    }}
                  ></div>
                </div>
              </div>
              <div style={{ width: 150, height: 75 }}></div>
            </div>
            {/* ---- HAND  ----- */}
            <div
              style={{
                position: "absolute",
                transform: `rotate(${props.minutes * 6}deg)`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                width: 150,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: 150,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={{ width: 75, height: 25 }}></div>
                  <div
                    style={{
                      width: 74,
                      height: 50,
                      borderRightWidth: 1,
                      borderRightColor: "red",
                      borderRightStyle: "solid",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={{ width: 75, height: 25 }}></div>
                  <div
                    style={{
                      width: 74,
                      height: 50,
                      borderLeftWidth: 1,
                      borderLeftColor: "red",
                      borderLeftStyle: "solid",
                    }}
                  ></div>
                </div>
              </div>
              <div style={{ width: 150, height: 75 }}></div>
            </div>
          </div>
        )}
        {/* </ImageBackground> */}
      </div>
    </div>
  );
};

export default Clock;
