import {
  Button,
  Card,
  Container,
  Grid,
  Snackbar,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  attemptRegistration,
  attemptSendOtp,
} from "../../../actions/login.actions";
import RabbitBoardLogo from "../../../images/logo_edulyn.png";
import {
  getIsRegistrationSuccess,
  getRegistrationError,
} from "../../../reducer/selectors/login.entity";
import { getIsLoggedIn } from "../../../reducer/selectors/user.entity";
import OTP from "../Otp/Otp";
import { cloneDeep } from "lodash";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export function Register() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [isOtpValidationSuccess, setIsOtpValidationSuccess] = useState(null);
  const [confirmpassword, setConfirmPassword] = useState(true);
  const [checkedterms, setCheckedterms] = useState("");

  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    mobile: "",
    email: "",
    password: "",
    confirmPassword: "",
    checkedTerms: "",
  });

  const isLoggedIn = useSelector(getIsLoggedIn);
  const isRegistrationSuccess = useSelector(getIsRegistrationSuccess);
  const registrationError = useSelector(getRegistrationError);

  useEffect(() => {
    if (isLoggedIn && history) {
      history.push("/parent");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isRegistrationSuccess) {
      history.push("/login");
    }
  }, [isRegistrationSuccess]);

  useEffect(() => {
    if (registrationError) {
      setShowSnackbar(true);
    }
  }, [registrationError]);

  useEffect(() => {
    if (isOtpValidationSuccess === true) {
      dispatch(
        attemptRegistration({ firstname, lastname, email, mobile, password })
      );
    } else if (isOtpValidationSuccess === false) {
      setShowSnackbar(true);
    }
  }, [isOtpValidationSuccess]);

  const validate = () => {
    let isCurrentValid = true;
    const currentErrors = cloneDeep(errors);
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mobileRegex = /^\d{10}$/;
    const passwordRegex =
      /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/;
    if (firstname && firstname.length > 0) {
      isCurrentValid = isCurrentValid && true;
      currentErrors.firstname = "";
    } else {
      console.log("FRST ", firstname);
      isCurrentValid = isCurrentValid && false;
      currentErrors.firstname = "First Name is not valid";
    }
    if (lastname && lastname.length > 0) {
      isCurrentValid = isCurrentValid && true;
      currentErrors.lastname = "";
    } else {
      isCurrentValid = isCurrentValid && false;
      currentErrors.lastname = "Last Name is not valid";
    }
    if (mobile && mobileRegex.test(mobile)) {
      isCurrentValid = isCurrentValid && true;
      currentErrors.mobile = "";
    } else {
      isCurrentValid = isCurrentValid && false;
      currentErrors.mobile = "Mobile Number is not valid";
    }
    if (email && emailRegex.test(email)) {
      isCurrentValid = isCurrentValid && true;
      currentErrors.email = "";
    } else {
      isCurrentValid = isCurrentValid && false;
      currentErrors.email = "Email is not valid";
    }
    if (password && passwordRegex.test(password)) {
      isCurrentValid = isCurrentValid && true;
      currentErrors.password = "";
    } else {
      console.log("PASSWORD ", password);
      isCurrentValid = isCurrentValid && false;
      const passwordCharCheck = /^(?=.*[A-Z])(?=.*[a-z])[\w!@#$%^&*]{2,}$/;
      const passwordNumCheck = /^(?=.*[\d])[\w!@#$%^&*]{1,}$/;
      const passwordSpclCharCheck = /^(?=.*[!@#$%^&*])[\w!@#$%^&*]{1,}$/;
      currentErrors.password = `Password must contain ${!passwordSpclCharCheck.test(password) ? "a special character, " : ""
        }${!passwordNumCheck.test(password) ? "a number, " : ""}${!passwordCharCheck.test(password) ? "upper and lower case, " : ""
        }${password.length < 8 ? "minimum 8 characters" : ""}`;
    }
    if (password && confirmpassword && password === confirmpassword) {
      isCurrentValid = isCurrentValid && true;
      currentErrors.confirmPassword = "";
    } else {
      isCurrentValid = isCurrentValid && false;
      currentErrors.confirmPassword = "Password is not matching";
    }
    if (checkedterms) {
      isCurrentValid = isCurrentValid && true;
      currentErrors.checkedTerms = "";
    } else {
      isCurrentValid = isCurrentValid && false;
      currentErrors.checkedTerms = "Accept Terms and Conditions and Privacy Policy";
    }
    setIsValid(isCurrentValid);
    setErrors(currentErrors);
    return isCurrentValid;
  };

  const onSubmit = () => {
    const valid = validate();
    if (valid) {
      // dispatch(
      //   attemptRegistration({ firstname, lastname, email, mobile, password })
      // );
      dispatch(attemptSendOtp(mobile));
      setShowOTP(true);
    }
  };

  return (
    <Container maxWidth="sm">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert
          elevation={6}
          onClose={() => setShowSnackbar(false)}
          severity="error"
        >
          {registrationError
            ? registrationError
            : isOtpValidationSuccess === false
              ? "OTP Validation Failed"
              : "Error. Please try again."}
        </Alert>
      </Snackbar>
      <Card style={{ marginTop: "10vh" }}>
        {showOTP ? (
          <Grid
            container
            spacing={2}
            style={{ padding: 25, display: "flex", justifyContent: "center" }}
          >
            <OTP OTPSuccess={(bool) => setIsOtpValidationSuccess(bool)} />
          </Grid>
        ) : (
          <Grid
            container
            spacing={2}
            style={{ padding: 25, display: "flex", justifyContent: "center" }}
          >
            <img
              alt="rabbit board logo"
              src={RabbitBoardLogo}
              style={{ width: "60%", marginTop: "5vh", marginBottom: "5vh" }}
            />
            <Grid item xs={12} lg={6}>
              <TextField
                error={!isValid && Boolean(errors.firstname)}
                style={{ width: "100%" }}
                id="firstname"
                type="firstname"
                label="First name"
                variant="outlined"
                onChange={(e) => {
                  setFirstname(e.target.value);
                }}
                helperText={!isValid && errors.firstname}
                size={"small"}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                error={!isValid && Boolean(errors.lastname)}
                style={{ width: "100%" }}
                id="lastname"
                type="lastname"
                label="Last name"
                variant="outlined"
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
                helperText={!isValid && errors.lastname}
                size={"small"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={!isValid && Boolean(errors.mobile)}
                style={{ width: "100%" }}
                id="mobile"
                type="mobile"
                label="Mobile"
                variant="outlined"
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
                helperText={!isValid && errors.mobile}
                size={"small"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={!isValid && Boolean(errors.email)}
                style={{ width: "100%" }}
                id="email"
                type="email"
                label="Email"
                variant="outlined"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                helperText={!isValid && errors.email}
                size={"small"}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                error={!isValid && Boolean(errors.password)}
                style={{ width: "100%" }}
                id="outlined-password-input"
                label="Password"
                type="password"
                // autoComplete="current-password"
                variant="outlined"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                helperText={!isValid && errors.password}
                size={"small"}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                error={!isValid && Boolean(errors.confirmPassword)}
                style={{ width: "100%" }}
                id="confirmpassword"
                label="Confirm Password"
                type="password"
                // autoComplete="current-password"
                variant="outlined"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                helperText={!isValid && errors.confirmPassword}
                size={"small"}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    error={!checkedterms}
                    checked={checkedterms}
                    onClick={(e) => { setCheckedterms(checkedterms ? false : true) }}
                    helperText={!isValid && errors.checkedTerms}
                    color="primary"
                    size="small"
                  />
                }
                label="Accept Terms & Conditions, Privacy Policy"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                style={{ width: "100%" }}
                onClick={() => {
                  console.log("SERVICE NOIW");
                  onSubmit();
                }}
              >
                Register
              </Button>
            </Grid>
          </Grid>
        )}
      </Card>
    </Container>
  );
}
export default Register;
