export default {
  clock: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // border: "solid 1px #ccc",
    borderRadius: 5,
    margin: 5,
  },
  analogClock: {
    width: 150,
    height: 150,
    // border: "solid 1px #000",
    borderRadius: 75,
    position: "relative",
    backgroundSize: "contain",
  },
};
