export const parentConstants = {
    GET_PARENT_INFO: "GET_PARENT_INFO",
    GET_PARENT_INFO_SUCCESS: "GET_PARENT_INFO_SUCCESS",

    ADD_NEW_PAYMENT_METHOD: "ADD_NEW_PAYMENT_METHOD",
    ADD_NEW_PAYMENT_METHOD_SUCCESS: "ADD_NEW_PAYMENT_METHOD_SUCCESS",
    ADD_NEW_PAYMENT_METHOD_FAILURE: "ADD_NEW_PAYMENT_METHOD_FAILURE",

    DELETE_PAYMENT_METHOD: "DELETE_PAYMENT_METHOD",
    DELETE_PAYMENT_METHOD_SUCCESS: "DELETE_PAYMENT_METHOD_SUCCESS",
    DELETE_PAYMENT_METHOD_FAILURE: "DELETE_PAYMENT_METHOD_FAILURE",

    CREATE_SUBSCRIPTION: "CREATE_SUBSCRIPTION",
    CREATE_SUBSCRIPTION_SUCCESS: "CREATE_SUBSCRIPTION_SUCCESS",
    CREATE_SUBSCRIPTION_FAILURE: "CREATE_SUBSCRIPTION_FAILURE",
}