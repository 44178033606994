export function getIsRegistrationSuccess({ loginReducer }) {
  if (loginReducer && loginReducer.isRegistrationSuccess) return true;
  else return false;
}

export function getRegistrationError({ loginReducer }) {
  if (loginReducer && loginReducer.registrationError) {
    return loginReducer.registrationError;
  } else return "";
}

export function getIsSendMessageSuccess({ loginReducer }) {
  if (loginReducer && loginReducer.isSendMessageSuccess) return true;
  else return false;
}

export function getSendMessageError({ loginReducer }) {
  if (loginReducer && loginReducer.sendMessageError)
    return loginReducer.sendMessageError;
  else return "";
}

export function getOTPResponse({ loginReducer }) {
  if (loginReducer && loginReducer.OTPresponse) return loginReducer.OTPresponse;
  else return "";
}

export function getForgotPasswordResponse({ loginReducer }) {
  if (loginReducer && loginReducer.forgotPasswordResponse) return loginReducer.forgotPasswordResponse;
  else return "";
}

export function getForgotPasswordSuccess({ loginReducer }) {
  if (loginReducer && loginReducer.forgotPasswordSuccess) return true;
  else return false;
}

export function getForgotPasswordFailure({ loginReducer }) {
  if (loginReducer && loginReducer.forgotPasswordFailure) return true;
  else return false;
}
