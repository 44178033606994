import { brainBustersConstants } from "../constants";

const initialState = {
    brainBusters: [],
    newBrainBuster: {},
    gettingNewBrainBuster: false
}

export function brainBustersReducer(state = initialState, action) {
    switch (action.type) {
        case brainBustersConstants.GET_BRAIN_BUSTERS_LIST_REQUEST:
            return {
                ...state,
                brainBusters: []
            }
        case brainBustersConstants.GET_BRAIN_BUSTERS_LIST_SUCCESS:
            return {
                ...state,
                brainBusters: action.response.brainBusters && action.response.brainBusters.info ?
                    action.response.brainBusters.info : []
            };
        case brainBustersConstants.GET_BRAIN_BUSTERS_LIST_FAILURE:
            return {
                ...state,
                brainBusters: action.response || []
            };
        case brainBustersConstants.GET_NEW_BRAIN_BUSTER_REQUEST:
            return {
                ...state,
                newBrainBuster: {},
                gettingNewBrainBuster: true
            }
        case brainBustersConstants.GET_NEW_BRAIN_BUSTER_SUCCESS:
            return {
                ...state,
                newBrainBuster: action.response.data,
                gettingNewBrainBuster: false
            };
        case brainBustersConstants.GET_NEW_BRAIN_BUSTER_FAILURE:
            return {
                ...state,
                newBrainBuster: action.response || {},
                gettingNewBrainBuster: false
            };

        case brainBustersConstants.SET_BB_ANSWER:
            const { question } = action
            const currentBBs = state.brainBusters
            if (currentBBs.length > 0) {
                const currentBB = currentBBs[0]
                const newlySubmittedQ = {
                    magicSquareId: (currentBB.solved.length + 1).toString(),
                    question: question
                }
                currentBB.solved.push(newlySubmittedQ)
                currentBBs[0] = currentBB
            } else {
                const newlySubmittedQ = {
                    magicSquareId: "1",
                    question: question
                }
                const newBB = {
                    solved: [newlySubmittedQ]
                }
                currentBBs.push(newBB)
            }

            return {
                ...state,
                brainBusters: currentBBs,
                newBrainBuster: {}
            }
        default:
            return state
    }
}
export default brainBustersReducer;