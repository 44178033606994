import {
	Button,
	Card,
	Container,
	Grid,
	makeStyles,
	Snackbar,
	TextField,
	Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { addChild } from "../../../actions/child.actions";
import {
	getIsLoggedIn,
	getUserId,
} from "../../../reducer/selectors/user.entity";
import { getIsRegistrationSuccess } from "../../../reducer/selectors/login.entity";
import { cloneDeep } from "lodash";
import {
	getChildUserId,
	getAddChildError,
} from "../../../reducer/selectors/child.entity";
import { getAddNewPaymentSuccess, getParentPaymentInfo, getSubscriptionInfo } from "../../../reducer/selectors/parent.entity";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AddPaymentMethod } from "../ParentProfile/ParentProfile"
import { getCardLogo } from "../../../util/paymentUtil"
import { addNewPaymentMethod, } from "../../../actions";
import { serviceConstants } from "../../../constants"

const stripePromise = loadStripe(serviceConstants.stripePublishKey);


const useStyles = makeStyles(() => ({
	menuItem: {
		borderWidth: 1,
		borderStyle: "solid",
		borderColor: "grey"
	}
}))

export function AddChild(props) {
	const dispatch = useDispatch();
	const classes = useStyles()

	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [gender, setGender] = useState("");
	const [grade, setGrade] = useState("");
	const [isValid, setIsValid] = useState(true);
	const [showSnackbar, setShowSnackbar] = useState(false);


	const [errors, setErrors] = useState({
		firstname: "",
		lastname: "",
		gender: "",
		grade: "",
	});

	const [subscriptionSelection, setSubscriptionSelection] = useState(undefined)
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(undefined)
	const [newCardDetails, setNewCardDetails] = useState(undefined)


	const addChildError = useSelector(getAddChildError);
    const userId = useSelector(getUserId);
	const parentPaymentInfo = useSelector(getParentPaymentInfo)
    const subscriptionInfo = useSelector(getSubscriptionInfo)

	useEffect(() => {
		if (parentPaymentInfo && parentPaymentInfo.cards && parentPaymentInfo.cards.length > 0) {
			console.log("parentPaymentInfo.cards[parentPaymentInfo.cards.length - 1].cardId---", parentPaymentInfo.cards[parentPaymentInfo.cards.length - 1])
			setSelectedPaymentMethod(parentPaymentInfo.cards[parentPaymentInfo.cards.length - 1].cardId)
		}
	}, [parentPaymentInfo && parentPaymentInfo.cards])

	// useEffect(() => {
	//   if (isLoggedIn && history) {
	//     console.log("userId useeffect add child ", userId, userId);
	//     history.push("/parent");
	//   }
	// }, [isLoggedIn]);

	// useEffect(() => {
	//   if (isRegistrationSuccess) {
	//     history.push("/login");
	//   }
	// }, [isRegistrationSuccess]);

	// useEffect(() => {
	//   if (addChildError) {
	//     setShowSnackbar(true);
	//   }
	// }, [addChildError]);

	const validate = () => {
		let isCurrentValid = true;
		const currentErrors = cloneDeep(errors);
		if (firstname && firstname.length > 0) {
			isCurrentValid = isCurrentValid && true;
			currentErrors.firstname = "";
		} else {
			console.log("FRST ", firstname);
			isCurrentValid = isCurrentValid && false;
			currentErrors.firstname = "First Name is not valid";
		}
		if (lastname && lastname.length > 0) {
			isCurrentValid = isCurrentValid && true;
			currentErrors.lastname = "";
		} else {
			isCurrentValid = isCurrentValid && false;
			currentErrors.lastname = "Last Name is not valid";
		}
		if (gender && gender.length > 0) {
			isCurrentValid = isCurrentValid && true;
			currentErrors.gender = "";
		} else {
			isCurrentValid = isCurrentValid && false;
			currentErrors.gender = "Select Gender";
		}
		if (grade && grade.length > 0) {
			isCurrentValid = isCurrentValid && true;
			currentErrors.grade = "";
		} else {
			isCurrentValid = isCurrentValid && false;
			currentErrors.grade = "Select Grade";
		}
		setIsValid(isCurrentValid);
		setErrors(currentErrors);
		return isCurrentValid;
	};

	// ON SUBMIT
	//    Step 1: Add New Card    
	//    Step 2: On Add New Card Success, Create Subscription  
	//    Step 3: On Create Subscription Success, Add New Child
	const onSubmit = () => {
		const valid = validate();
		var courseList = ["M" + grade, "R" + grade, "S" + grade, "H" + grade];
		console.log("onSubmit === ", courseList, userId);
		if (valid) {
			dispatch(
				addChild({ userId, firstname, lastname, gender, grade, courseList, cardId: selectedPaymentMethod, planName: subscriptionSelection })
			);
		}
		if (addChildError) {
			setShowSnackbar(true);
		}
	};

	const showSubscriptionDetails = () => {
		return (
			<div>
				<Typography variant="h6" style={{ marginTop: 16, marginBottom: 8 }}>
					Subscription
				</Typography>
				<Grid item xs={12}>
					<FormControl
						error={!isValid && Boolean(errors.gender)}
						style={{ width: "100%" }}
					>
						<InputLabel
							id="demo-simple-select-required-label"
							style={{ width: "100%" }}
						>
							Select Subscription
						</InputLabel>
						<Select
							style={{ width: "100%" }}
							labelId="childSubscription"
							id="childSubscription"
							name="childSubscription"
							label="Subscription"
							value={subscriptionSelection} //{data.childSecondgrade}
							onChange={(e) => {
								setSubscriptionSelection(e.target.value);
							}}
						// onBlur={(e)=>this.onBlur(e,"two")}
						>
							{serviceConstants.subscriptions.map((sub) => <MenuItem classes={{ root: classes.menuItem }} value={sub.stripeId}>
								<div
									style={{
										display: "flex", flexDirection: "column"
									}}>
									<div style={{ display: "flex", flexDirection: "row" }}>
										<Typography style={{ fontWeight: "bold" }} color="primary">{sub.name}</Typography>
										{sub.promoPrice ? (
											<div>
												<span style={{
													textDecoration: "line-through",
													marginLeft: 8, marginRight: 4,
													textDecorationColor: "red",
													textDecorationWidth: 6,
													textDecorationStyle: "solid"
												}}>
													{sub.price}
												</span>
												<span>{sub.promoPrice}</span>
											</div>
										) : (
											<div style={{ marginLeft: 8, marginRight: 4 }}>{sub.price}</div>)}
									</div>
									<Typography variant="subtitle2">{sub.description}</Typography>
								</div>
							</MenuItem>)}
						</Select>
					</FormControl>
				</Grid>
			</div>
		)
	}

	const showPaymentDetails = () => {
		return (<div>
			<Typography style={{ marginTop: 16, marginBottom: 8 }}>Payment Card Details</Typography>
			{parentPaymentInfo && parentPaymentInfo.cards && parentPaymentInfo.cards.length > 0 && (
				<Grid item xs={12} style={{ marginBottom: 20 }}>
					<FormControl
						error={!isValid && Boolean(errors.gender)}
						style={{ width: "100%" }}
					>
						<InputLabel
							id="demo-simple-select-required-label"
							style={{ width: "100%" }}
						>
							Select Payment Method
						</InputLabel>
						<Select
							style={{ width: "100%" }}
							labelId="selectPaymentMethod"
							id="selectPaymentMethod"
							name="selectPaymentMethod"
							label="Subscription"
							value={selectedPaymentMethod} //{data.childSecondgrade}
							onChange={(e) => {
								setSelectedPaymentMethod(e.target.value);
							}}
							key={"key_payment_method_" + selectedPaymentMethod}
						// onBlur={(e)=>this.onBlur(e,"two")}
						>
							{parentPaymentInfo.cards.map((paymentMethod) => <MenuItem classes={{ root: classes.menuItem }} value={paymentMethod.cardId}>
								<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
									<div style={{ alignSelf: "center", flexDirection: "row", display: "flex", alignItems: "center" }}>
										<img style={{ width: 50, height: 30, marginRight: 16 }} src={getCardLogo(paymentMethod.brand)} />
										<Typography>**** **** **** {paymentMethod.lastFourDigits}</Typography>
									</div>
									<div>
										<Typography>Expiry: {paymentMethod.expDate}</Typography>
									</div>
								</div>
							</MenuItem>)}
							<MenuItem value="NEW">Add New Payment Method</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			)}
			{(selectedPaymentMethod === "NEW" || ((!parentPaymentInfo) && selectedPaymentMethod === undefined)) && <Grid item={true} style={{ display: "flex", flexDirection: "row" }}>
				<AddPaymentMethod setNewCardDetails={setNewCardDetails} />{newCardDetails && newCardDetails.token && newCardDetails.token.id && (
					<Button
						variant="outlined"
						onClick={() => {
							console.log("NEW CARD DEtAILS, MAKE API CALLS HERE")
							dispatch(addNewPaymentMethod({
								userId,
								tokenId: newCardDetails.token.id
							}))
						}}
					>
						ADD
					</Button>
				)}
			</Grid>
			}
		</div>)
	}

	const renderSubscriptionInfo = () => {
		const selectedSubscription = serviceConstants.subscriptions.find((sub) => sub.stripeId === subscriptionInfo.subscriptionPlanId)
		return <>
			<Grid
				item
				xs={12}
				style={{
					display: "flex", flexDirection: "column",
					border: "1px solid green", borderRadius: 8,
					margin: 8, padding: 10
				}}
			>
				<Typography variant="h6" style={{ marginBottom: 16 }}>Current Subscription</Typography>
				{selectedSubscription && <div>
					<h5>
						{selectedSubscription.name}
					</h5>
					<span className="author">{selectedSubscription.description}</span>
					<div className="productAction">
						<span style={{ display: "flex", flexDirection: "row" }}>
							{selectedSubscription.introductoryPrice ? <h6>
								<span style={{ textDecoration: "line-through", color: "red", marginRight: 5 }}>
									{selectedSubscription.price}
								</span>
								<span>{selectedSubscription.introductoryPrice}</span>
							</h6> : <h6>
								{selectedSubscription.price}</h6>}<h6>/</h6><h6>{selectedSubscription.duration}</h6></span>
					</div>
				</div>}
			</Grid>
		</>
	}

	return (
		<Container maxWidth="sm"
		// style={{
		// 	backgroundColor: "white",
		// 	display: "flex",
		// 	width: "50vw",
		// 	marginTop: "25vh",
		// 	justifyContent: "center",
		// 	flexDirection: "column",
		// 	padding: "30px",
		// 	marginLeft: "25vw",
		// 	borderRadius: 3
		// }}
		>
			<Elements stripe={stripePromise}>
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
					open={showSnackbar}
					autoHideDuration={6000}
					onClose={() => setShowSnackbar(false)}
				>
					<Alert
						elevation={6}
						onClose={() => setShowSnackbar(false)}
						severity="error"
					>
						{addChildError}
					</Alert>
				</Snackbar>
				<Card style={{
					marginTop: "10vh",
					overflowY: "auto",
					overflowX: "hidden",
					maxHeight: "70vh"
				}}>
					<Grid
						container
						spacing={2}
						style={{
							padding: 25, display: "flex", justifyContent: "center",
							margin: 0, width: "100%"
						}}
					>
						<Grid
							item={true}
							xs={12}
							style={{
								marginBottom: 15,
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Typography variant="h6">ADD NEW CHILD</Typography>
						</Grid>
						{props.allowAddChild && <><Grid item xs={12}>
							<TextField
								error={!isValid && Boolean(errors.firstname)}
								style={{ width: "100%" }}
								id="firstname"
								type="firstname"
								label="First name"
								variant="outlined"
								onChange={(e) => {
									setFirstname(e.target.value);
								}}
								helperText={!isValid && errors.firstname}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								error={!isValid && Boolean(errors.lastname)}
								style={{ width: "100%" }}
								id="lastname"
								type="lastname"
								label="Last name"
								variant="outlined"
								onChange={(e) => {
									setLastname(e.target.value);
								}}
								helperText={!isValid && errors.lastname}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl
								error={!isValid && Boolean(errors.gender)}
								style={{ width: "100%" }}
							>
								<InputLabel
									id="demo-simple-select-required-label"
									style={{ width: "100%" }}
								>
									Select Gender
								</InputLabel>
								<Select
									style={{ width: "100%" }}
									labelId="childGender"
									id="childGender"
									name="childGender"
									label="Grade"
									value={gender} //{data.childSecondgrade}
									onChange={(e) => {
										setGender(e.target.value);
									}}
								// onBlur={(e)=>this.onBlur(e,"two")}
								>
									<MenuItem value="Female">Girl</MenuItem>
									<MenuItem value="Male">Boy</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControl
								error={!isValid && Boolean(errors.grade)}
								style={{ width: "100%" }}
							>
								<InputLabel
									id="demo-simple-select-required-label"
									style={{ width: "100%" }}
								>
									Select Grade
								</InputLabel>
								<Select
									style={{ width: "100%" }}
									labelId="childGrade"
									id="childGrade"
									name="childGrade"
									label="Grade"
									value={grade} //{data.childSecondgrade}
									onChange={(e) => {
										setGrade(e.target.value);
									}}
								// onBlur={(e)=>this.onBlur(e,"two")}
								>
									<MenuItem value="P">Pre-K</MenuItem>
									<MenuItem value="K">Kindergarten</MenuItem>
									<MenuItem value="1">First Grade</MenuItem>
									<MenuItem value="2">Second Grade</MenuItem>
									<MenuItem value="3">Third Grade</MenuItem>
									<MenuItem value="4">Fourth Grade</MenuItem>
									<MenuItem value="5">Fifth Grade</MenuItem>
									<MenuItem value="6">Sixth Grade</MenuItem>
								</Select>
							</FormControl>
						</Grid></>}
						{!props.allowAddChild && <Grid
							item
							xs={12}
							style={{ display: "flex", justifyContent: "space-between", marginTop: 16 }}
						>
							<Grid item xs={12}>
								<Typography>You reached max profiles. Please upgrade to add more profiles.</Typography>
							</Grid>
							
						</Grid>}
						{(subscriptionInfo && subscriptionInfo.status === "1" && subscriptionInfo.subscriptionPlanId) ? (
							renderSubscriptionInfo()
						) : (<>
							<Grid item xs={12}>
								{showSubscriptionDetails()}
							</Grid>
							<Grid item xs={12}>
								{showPaymentDetails()}
							</Grid>
						</>)}
						<Grid
							item
							xs={12}
							style={{ display: "flex", justifyContent: "space-between", marginTop: 16 }}
						>
							<Grid item xs={4}>
								<Button
									variant="outlined"
									color="error"
									style={{ width: "100%" }}
									onClick={() => {
										console.log("SERVICE NOIW");
										props.onCancel();
									}}
								>
									Cancel
								</Button>
							</Grid>
							{props.allowAddChild && <Grid item={true} xs={4}>
								<Button
									variant="outlined"
									color="primary"
									style={{ width: "100%" }}
									onClick={() => {
										console.log("SERVICE NOIW");
										onSubmit();
									}}
									disabled={!selectedPaymentMethod || selectedPaymentMethod === "NEW"}
								>
									Add Child
								</Button>
							</Grid>}
						</Grid>
					</Grid>
				</Card>
			</Elements>
		</Container>
	);
}
export default AddChild;
