export const getQuestionType = (concept,qType,originalConcept) => {
  switch (concept) {
    case "add":
      if(qType==="sntns"){
        return "Word Problems";
      }if(qType==="soln"){
        return "Select Operation";
      }else{
        return "Addition"; }
    case "sub":
      if(qType==="sntns"){
        return "Word Problems";
      }if(qType==="soln"){
        return "Select Operation";
      }else{
      return "Subtraction"; }
    case "mul":
      if(qType==="sntns"){
        return "Word Problems";
      }if(qType==="soln"){
        return "Select Operation";
      }else{
      return "Multiplication";}
    case "div":
      if(qType==="sntns"){
        return "Word Problems";
      }if(qType==="soln"){
        return "Select Operation";
      }else{
      return "Division";}
    case "nmbr":
      return "Numbers";
    case "wrtnum":
      return "Write Numbers";
    case "mixopr":
      return "Mixed Operation";
    case "evenodd":
      return "Even/Odd";
    case "rndoff":
      return "Round Off";
    case "eqn":
      return "Equation";
    case "fct":
      return "Factorial";
    case "plc":
      return "Placement";
    case "expnd":
      return "Expand";
    case "wrtwrd":
      return "Write Words";
    case "stndrd":
      return "Standard Form";
    case "sntns":
      return "Word Problems";
    case "cmnidfyslct":
      if(originalConcept==="expo"){
      return "Exponent";
      }else{
      return "Select Options";
      }
    case "mny":
      return "Money";
    case "blck":
      return "Blocks";
    case "gcd":
      return "GCD";
    case "lcm":
      return "LCM";
    case "rule":
      return "Rule";
    case "clk":
      return "Clock";
    case "multbl":
      return "Multiplication Table";
    case "syn":
      return "Synonyms";
    case "ant":
      return "Antonyms";
    case "nou":
        return "Noun";
    case "prep":
      return "Preposition";
    case "verb":
        return "Verb";
    case "ltrs":
      return "Letters";
    case "chse":
      if(qType==="prfx"){
        return "Prefix";
      }if(qType==="sfx"){
        return "Suffix"
      }if(qType==="uppr" || qType=="lowr"){
        return "Select Letters"
      } else{
        return "Select Options"; 
      }  
    case "sylb":
      return "Syllable";
    case "abre":
        return "Abbreviation";
    case "conjun":
        return "Conjunction";
    case "pron":
      return "Pronoun";
    case "hompne":
        return "Homophone";
    case "homnym":
        return "Homonym";
    case "plrl":
      return "Singular Plural";
    case "cmp":
      return "Compare";
    case "shp":
      return "Shapes";
    case "fra":
      return "Fraction";
    case "decdiv":
      return "Decimal Division";
    case "decmul":
      return "Decimal Multiplication";
    case "decadd":
      return "Decimal Addition";
    case "decsub":
      return "Decimal Subtraction";  
    case "lisn":
      return "Listen";  
    case "addsub":
      return "Addition/Subtraction"; 
    case "time":
      return "Time"; 
    case "pro":
      return "Property"; 
    case "mtr":
      return "State of Matter";
    case "chng":
      return "Physical/Chemical Change";
    case "mxtr":
      return "Mixture";
    case "plnts":
      return "Plants";
    case "itms":
      return "Items";
    case "mtrls":
      return "Materials";
    case "anmlsclsf":
      return "Animals";
    case "anmlstyp":
      return "Animals";
    case "anmlsfd":
      return "Animals";
    case "thngs":
      return "Living & Nonliving Things";
    case "gnrl":
      return "Select Options";
    case "mnrls":
      return "Minerals";
    case "ntrlrsrc":
      return "Natural Resources";
    case "mgnt":
      return "Magnetic Substance";
    case "astrnmy":
      return "Astronomy";
    case "skp":
      return "Skip Numbers";
    case "smplstry":
      return "Story";
    case "spel":
      return "Spelling";
    case "cmpl":
        return "Missing Letters";
    case "ratio":
        return "Ratio";
    case "per":
        return "Percentage";
    case "atms":
        return "Atoms/Molecules";
    case "enrgy":
        return "Energy";
    case "cel":
        return "Cell";
    case "sts":
        return "Statistics";
    case "geo":
        return "Geography";
    case "lndmrks":
        return "Landmarks";
    case "cptls":
        return "Capitals";
    case "amrcgeo":
        return "American Geography";
    case "ocngeo":
        return "Oceania Geography";
    case "asiageo":
        return "Asian Geography";
    case "stts":
        return "State";    
    default:
      return "";
  }
};
