import {
    Button,
    Card,
    CardActionArea,
    Grid,
    Typography,
    Tabs,
    Tab
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import * as actions from "../../../actions";
import {
    getChildInfo,
    getChildUserId,
    getCoursesList,
    getDeleteChildError,
    getDeleteChildSuccess,
    getUploadChildProfilePicSuccess,
    getHomeworksList,
} from "../../../reducer/selectors/child.entity";
import { getRankings } from "../../../reducer/selectors/homework.entity";
import { useHistory, useLocation } from "react-router-dom";

import {
    getUserId,
    getIsLoggedIn,
} from "../../../reducer/selectors/user.entity";
import logo from "../../../images/logo.png";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { ProgressBar } from "react-bootstrap";
import Divider from "@material-ui/core/Divider";
import PublishIcon from "@material-ui/icons/Publish";
import "./styles.css";
import { mathPracticeConcepts } from "../../../mock/mathPracticeConcepts";
import { sciencePracticeConcepts } from "../../../mock/sciencePracticeConcepts";
import { socialPracticeConcepts } from "../../../mock/socialPracticeConcepts";
import { languagePracticeConcepts } from "../../../mock/languagePracticeConcepts";
import CardContent from "@material-ui/core/CardContent";
import './style.scss'
// images 
import SectionTitle from "../SectionTitle/sectiontitle";


const useStyles = makeStyles((theme) => ({
    "@global": {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: "none",
        },
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[700],
    },
    dividerFullWidth: {
        margin: `10px 0 0 ${theme.spacing(0)}px`,
    },
}));

const NewChildPage = () => {
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    //   const homeWorksList = useSelector(getHomeworksList);
    const [showDeleteChildModal, setShowDeleteChildModal] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [courseId, setCourseId] = useState();

    const childuserId = useSelector(getChildUserId);
    const userId = useSelector(getUserId);
    const childInfo = useSelector(getChildInfo);
    const childRankings = useSelector(getRankings);
    const deleteChildSuccess = useSelector(getDeleteChildSuccess);
    const uploadChildProfilePicSuccess = useSelector(
        getUploadChildProfilePicSuccess
    );
    const coursesList = useSelector(getCoursesList);
    const deleteChildError = useSelector(getDeleteChildError);
    const isLoggedIn = useSelector(getIsLoggedIn);
    const classes = useStyles();
    const homeWorksList = useSelector(getHomeworksList);

    const speedTestSubmit = (
        childId,
        hwDate,
        subjectCd,
        numOfQuestionsPerDay,
        userType,
        course
    ) => {
        history.push({
            pathname: "/guest/speedtest",
            state: {
                childId: childId,
                hwDate: "-",
                subjectCd: subjectCd,
                numOfQuestionsPerDay: numOfQuestionsPerDay,
                userType: userType,
                ctest: course.cTest,
                courseName: course.conceptName,
                accuracy: course.accuracy,
                pageSrc: "ChildHomePage",
                srcpage: "ChildHomePage",
            },
        });
    };

    useEffect(() => {
        if (uploadChildProfilePicSuccess) {
            dispatch(actions.getChildInfo(location.state.childId));
        }
    }, [uploadChildProfilePicSuccess]);

    useEffect(() => {
        console.log('child home page...', location.state)
        if (location && location.state && location.state.childId) {
            dispatch(actions.setShowLoader(true));
            dispatch(actions.getChildInfo(location.state.childId));
            dispatch(actions.setShowLoader(true));
            dispatch(
                actions.getRankings({ childId: location.state.childId, isLoggedIn })
            );
            // setValue(0);
            changeTab(value);
        }
        setShowDeleteChildModal(false);
    }, []);

    useEffect(() => {
        console.log('child home page...', location.state)
        if (childInfo && childInfo.grade) {
            changeTab(value);
        }
        setShowDeleteChildModal(false);
    }, [childInfo, childInfo.grade]);

    useEffect(() => {
        if (deleteChildSuccess) {
            setShowDeleteChildModal(false);
            history.push("/parent");
        }
    }, [deleteChildSuccess]);

    useEffect(() => {
        if (deleteChildError) {
            setShowSnackbar(true);
        }
    }, [deleteChildError]);

    useEffect(() => {
        if (selectedFile && selectedFile.name) {
            onFileUpload();
        }
    }, [selectedFile]);

    const onSubmit = () => {
        if (location && location.state && location.state.childId && userId) {
            dispatch(actions.setShowLoader(true));
            dispatch(
                actions.deleteChild({ childId: location.state.childId, userId: userId })
            );
        }
    };

    const getConceptName = (concept) => {
        if (concept === "Multiplication" || concept === "Multiplication -Ve") {
            return "Multiplication";
        }
        if (concept === "Select Numbers" || concept === "Count Numbers") {
            return "Numbers";
        }
        if (concept === "Shapes") {
            return "Shapes";
        }
        if (
            concept === "Addition" ||
            concept === "Addition with Alphabets" ||
            concept === "Adding 3 numbers" ||
            concept === "Adding 4 numbers" ||
            concept === "Select correct Operation" ||
            concept === "Adding Money" ||
            concept === "Decimal Addition" ||
            concept === ""
        ) {
            return "Addition";
        }
        if (
            concept === "Subtraction" ||
            concept === "Subtraction with Alphabets" ||
            concept === "Addition with Alphabets" ||
            concept === "Addition with Alphabets" ||
            concept === "Addition with Alphabets" ||
            concept === "Addition with Alphabets"
        ) {
            return "Subtraction";
        }
        if (
            concept === "Elapsed Time" ||
            concept === "Convert Hours to Minutes" ||
            concept === "Convert Minutes to Hours" ||
            concept === "Convert Minutes to Seconds" ||
            concept === "Convert Seconds to Minutes" ||
            concept === "Convert Years to Months" ||
            concept === "Convert Months to Years" ||
            concept === "Add Hours & Minutes" ||
            concept === "Subtract Hours & Minutes" ||
            concept === "Add Years & Months" ||
            concept === "Subtract Years & Months" ||
            concept === "Elapsed Time"
        ) {
            return "Time";
        }
        if (
            concept === "Select Fractions" ||
            concept === "Fraction Addition" ||
            concept === "Fraction Subtraction"
        ) {
            return "Fraction";
        }
        if (
            concept === "Exponents" ||
            concept === "Select Expression for Exponents" ||
            concept === "Select Value for Exponents" ||
            concept === "Select Exponents" ||
            concept === "Select Exponent for Value"
        ) {
            return "Exponents";
        }
        if (
            concept === "Factors" ||
            concept === "Prime Factorization" ||
            concept === "Prime / Composite"
        ) {
            return "Factors";
        }
        if (concept === "Roman Numeral" || concept === "Roman Letters") {
            return "Roman Numeral";
        }
        if (concept === "Division" || concept === "Estimate Quotient") {
            return "Division";
        }
        if (
            concept === "Mixed Operation" ||
            concept === "Negative Numbers" ||
            concept === "Three Number Multiplication"
        ) {
            return "Mixed Operation";
        }
        if (concept === "Upper case letter" || concept === "Lower case letter") {
            return "Letters";
        }
        if (concept === "Sounding words" || concept === "Sounding end words") {
            return "Sounding Words";
        }
        if (
            concept === "Spell Words" ||
            concept === "Complete word" ||
            concept === "Silent e word" ||
            concept === "Complete silent e word" ||
            concept === "Complete vowel word" ||
            concept === "Listen Words"
        ) {
            return "Words";
        }
        if (concept === "Homophones" || concept === "Select Homophones") {
            return "Homophones";
        }
        if (concept === "Prepositions" || concept === "Select Prepositions") {
            return "Prepositions";
        }
        if (concept === "Pronouns in Sentence" || concept === "Select Pronouns") {
            return "Pronouns";
        }

        if (
            concept === "Select Property of Materials" ||
            concept === "Choose Property of Materials"
        ) {
            return "Property";
        }
        if (
            concept === "Select States Of Matter" ||
            concept === "Choose States Of Matter"
        ) {
            return "StatesOfMatter";
        }


        if (
            concept === "Select Physical/Chemical change" ||
            concept === "Choose Physical/Chemical change"
        ) {
            return "PhysicalAndChemicalChange";
        }
        if (
            concept === "Select Mixtures" ||
            concept === "Choose Mixtures"
        ) {
            return "Mixtures";
        }
        if (
            concept === "Select Classification Of Animals" ||
            concept === "Choose Classification Of Animals"
        ) {
            return "ClassificationOfAnimals";
        }
        if (
            concept === "Select Types Of Animals" ||
            concept === "Choose Types Of Animals"
        ) {
            return "TypesOfAnimals";
        }
        if (
            concept === "Select Food habits" ||
            concept === "Choose Food habits" ||
            concept === "Food habits"
        ) {
            return "FoodHabbitsOfAnimals";
        }
        if (
            concept === "Select Living/Non living things" ||
            concept === "Choose Living/Non living things"
        ) {
            return "LivingAndNonLivingThings";
        }
        if (
            concept === "General questions"
        ) {
            return "GeneralQuestions";
        }
        if (
            concept === "Select Plants" ||
            concept === "Choose Plants"
        ) {
            return "Plants";
        }
        if (
            concept === "Select Minerals" ||
            concept === "Choose Minerals"
        ) {
            return "Minerals";
        }
        if (
            concept === "Select Items" ||
            concept === "Choose Items"
        ) {
            return "Items";
        }
        if (
            concept === "Select Materials" ||
            concept === "Choose Materials"
        ) {
            return "Materials";
        }
        if (
            concept === "Select Cells" ||
            concept === "Choose Cells"
        ) {
            return "Cells";
        }
        if (
            concept === "Select Natural Resource" ||
            concept === "Choose Natural Resource"
        ) {
            return "NaturalResource";
        }
        if (
            concept === "Select Mixture Type" ||
            concept === "Choose Mixture Type"
        ) {
            return "MixtureType";
        }
        if (
            concept === "Select Magnets" ||
            concept === "Choose Magnets"
        ) {
            return "Magnets";
        }
        if (
            concept === "Select Direction Of Force" ||
            concept === "Choose Direction Of Force"
        ) {
            return "DirectionOfForce";
        }
        if (
            concept === "Select Astronomy" ||
            concept === "Choose Astronomy"
        ) {
            return "Astronomy";
        }
        if (
            concept === "Select Disaster" ||
            concept === "Choose Disaster"
        ) {
            return "Disaster";
        }
        if (
            concept === "Select Ecosystem" ||
            concept === "Choose Ecosystem"
        ) {
            return "Ecosystem";
        }

        if (
            concept === "Select Ecosystem" ||
            concept === "Choose Ecosystem"
        ) {
            return "Ecosystem";
        }
        if (
            concept === "Select Types Of Rocks" ||
            concept === "Choose Types Of Rocks"
        ) {
            return "TypesOfRocks";
        }
        if (
            concept === "Select Vegetables" ||
            concept === "Choose Vegetables"
        ) {
            return "Vegetables";
        }
        if (
            concept === "Select Chamber in Heart" ||
            concept === "Choose Chamber in Heart"
        ) {
            return "NoOfChamberHearts";
        }
        if (
            concept === "Select Vitamins" ||
            concept === "Choose Vitamins"
        ) {
            return "Vitamins";
        }
        if (
            concept === "Select Vitamins Defeciency" ||
            concept === "Choose Vitamins Defeciency"
        ) {
            return "VitaminsDefeciency";
        }
        if (
            concept === "Select Vitamins Importance" ||
            concept === "Choose Vitamins Importance"
        ) {
            return "VitaminsImportance";
        }
        if (
            concept === "Select Sources of Vitamins" ||
            concept === "Choose Sources of Vitamins"
        ) {
            return "VitaminsOfSource";
        }
        if (
            concept === "Select Endrocrine Gland" ||
            concept === "Choose Endrocrine Gland"
        ) {
            return "EndrocrineGland";
        }
        if (
            concept === "Select Human Body System"
        ) {
            return "PurposeOfHumanBodySystem";
        }
        if (
            concept === "Select Types Of Muscle Cells" ||
            concept === "Choose Types Of Muscle Cells"
        ) {
            return "TypesOfMuscleCells";
        }
        if (
            concept === "Select Definitions"
        ) {
            return "Definitions";
        }
        if (
            concept === "Newton's law"
        ) {
            return "Newton'slaw";
        }



        if (
            concept === "Select Biochemistry" ||
            concept === "Choose Biochemistry" ||
            concept === "Identify Biochemistry" ||
            concept === "Biochemistry"
        ) {
            return "BioChemistry";
        }
        if (
            concept === "Select Kinetic/Potential Energy" ||
            concept === "Choose Kinetic/Potential Energy"
        ) {
            return "kineticAndPotentialEnergy";
        }
        if (
            concept === "Select Atoms and molecules" ||
            concept === "Choose Atoms and molecules"
        ) {
            return "AtomsAndMolecules";
        }
        if (
            concept === "Select life cycle of animals"
        ) {
            return "LifeCycleOfAnimals";
        }
        if (
            concept === "Select Human Organ Functions" ||
            concept === "Choose Human Organ Functions"
        ) {
            return "HumanOrganFunctions";
        }
        if (
            concept === "Select Fossil Traits" ||
            concept === "Choose Fossil Traits"
        ) {
            return "FossilTraits";
        }
        if (
            concept === "Select Property of Materials" ||
            concept === "Choose Property of Materials"
        ) {
            return "PropertyofMaterials";
        }
        if (
            concept === "Select Plate boundaries" ||
            concept === "Choose Plate boundaries"
        ) {
            return "PlateBoundaries";
        }
        if (
            concept === "Select Hazards" ||
            concept === "Choose Hazards"
        ) {
            return "Hazards";
        }
        if (
            concept === "Select Photosynthsis" ||
            concept === "Choose Photosynthsis"
        ) {
            return "PhotoSynthesis";
        }
        if (
            concept === "Select Geography" ||
            concept === "Choose Geography"
        ) {
            return "Geography";
        }
        if (
            concept === "Historical Persons"
        ) {
            return "HistoricalPersons";
        }
        if (
            concept === "American Symbols,Landmarks & Monuments"
        ) {
            return "AmericanSymbolsLandmarksAndMonuments";
        }
        if (
            concept === "Cultural Celebrations"
        ) {
            return "CulturalCelebrations";
        }
        if (
            concept === "Economics"
        ) {
            return "Economics";
        }
        if (
            concept === "Select States" ||
            concept === "Choose States"
        ) {
            return "States";
        }
        if (
            concept === "Select Capitals" ||
            concept === "Choose Capitals"
        ) {
            return "Capitals";
        }
        if (
            concept === "Select Geography" ||
            concept === "Choose Geography"
        ) {
            return "Geography";
        }
        if (
            concept === "Social Studies Skills"
        ) {
            return "SocialStudiesSkills";
        }
        if (
            concept === "Basic Economic Principles"
        ) {
            return "BasicEconomicPrinciples";
        }
        if (
            concept === "Supply And Demand"
        ) {
            return "SupplyAndDemand";
        }
        if (
            concept === "English Colonies In North America"
            || concept === "Select English Colonies of North America"
        ) {
            return "EnglishColoniesInNorthAmerica";
        }
        if (
            concept === "The American Revolution"
        ) {
            return "TheAmericanRevolution";
        }
        if (
            concept === "Early 19th Century American History"
        ) {
            return "Early19thCenturyAmericanHistory";
        }
        if (
            concept === "The Civil War And Reconstruction"
        ) {
            return "TheCivilWarAndReconstruction";
        }
        if (
            concept === "20th Century American History"
        ) {
            return "20thCenturyAmericanHistory";
        }
        if (
            concept === "Maps"
        ) {
            return "Maps";
        }
        if (
            concept === "American National Holidays"
        ) {
            return "AmericanNationalHolidays";
        }
        if (
            concept === "Banking And Finance"
        ) {
            return "BankingAndFinance";
        }
        // if (
        //   concept === "Population Of Cities" 
        // ) {
        //   return "";
        // }
        if (
            concept === "New England Colonies"
        ) {
            return "EnglishColoniesNorthAmerica";
        }
        if (
            concept === "Select Plymouth"
        ) {
            return "Plymouth";
        }
        if (
            concept === "Middle Colonies"
        ) {
            return "MiddleColonies";
        }
        if (
            concept === "Southern Colonies"
        ) {
            return "SouthernColonies";
        }
        if (
            concept === "Thirteen Colonies/Non ThirteenColonies"
        ) {
            return "ThirteenColonies";
        }
        if (
            concept === "The American Revolution" ||
            concept === "The American Revolution- The Thirteen Colonies Under BritishRule"
        ) {
            return "TheAmericanRevolution";
        }
        if (
            concept === "New British Taxes"
        ) {
            return "NewBritishTaxes";
        }
        if (
            concept === "The Boston Party"
        ) {
            return "TheBostonParty";
        }
        if (
            concept === "Preparing For War"
        ) {
            return "PreparingForWar";
        }
        if (
            concept === "The Rebellion Begins"
        ) {
            return "TheRebellionBegins";
        }
        if (
            concept === "Struggle For Independence"
        ) {
            return "StruggleForIndependence";
        }
        if (
            concept === "Conclusion And Aftermath"
        ) {
            return "ConclusionAndAfterMath";
        }
        if (
            concept === "Early Nineteenth Century American History"
        ) {
            return "EarlyNineteenththCenturyAmericanHistory";
        }
        if (
            concept === "The Lewis And Clark Expedition"
        ) {
            return "TheLewisAndClarkExpedition";
        }
        if (
            concept === "The War Of 1812 Causes"
        ) {
            return "TheWarOf1812Causes";
        }
        if (
            concept === "Ante Bellam Period"
        ) {
            return "AnteBellamPeriod";
        }
        if (
            concept === "Civil War And Reconstruction"
        ) {
            return "CivilWarAndReconstruction";
        }
        if (
            concept === "American History Of Twentieth Century"
        ) {
            return "AmericanHistoryOfTwentiethCentury";
        }
        if (
            concept === "The Great Depression"
        ) {
            return "TheGreatDepression";
        }
        if (
            concept === "World War II"
        ) {
            return "WorldWarII";
        }
        if (
            concept === "American Government"
        ) {
            return "AmericanGovernment";
        }
        if (
            concept === "Longitude And Latitude Lines"
        ) {
            return "LongitudeAndLatitudeLines";
        }
        else {
            return concept;
        }

    };

    const onFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            console.log("ON FILE CHANGE ", e.target.files);
            setSelectedFile(e.target.files[0]);
        } else {
            setSelectedFile(undefined);
        }
    };

    const fileEncoder = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(file);
        });
    };
    const onFileUpload = () => {
        console.log("ON FILE UPDLAO ");
        const formData = new FormData();
        // const encodedFile = fileEncoder(selectedFile)
        const body = {
            // file: selectedFile,
            userId,
            childId: location.state.childId,
        };
        formData.append("file", selectedFile, selectedFile.name);

        dispatch(
            actions.uploadChildProfilePic({
                body,
                formData,
            })
        );
    };

    const getCurrentlevel = (conceptName) => {
        let currentLevel = 1;
        childRankings && childRankings.map((rank) => {
            if (conceptName === rank.grade) {
                currentLevel = rank.rankings && rank.rankings.currentBadge
                    ? Number(rank.rankings.currentBadge
                        .badgeNumber) * Number(rank.rankings.currentBadge
                            .currentLevel)
                    : "";
            }
        });
        return currentLevel;
    };

    const fileData = () => {
        if (this.state.selectedFile) {
            return (
                <div>
                    <h2>File Details:</h2>

                    <p>File Name: {this.state.selectedFile.name}</p>

                    <p>File Type: {this.state.selectedFile.type}</p>

                    <p>
                        Last Modified:{" "}
                        {this.state.selectedFile.lastModifiedDate.toDateString()}
                    </p>
                </div>
            );
        } else {
            return (
                <div>
                    <br />
                    <h4>Choose before Pressing the Upload button</h4>
                </div>
            );
        }
    };

    function handleChange(event, newValue) {
        setValue(newValue);
        changeTab(newValue);
    }
    function changeTab(newValue) {
        console.log('handle change...', newValue)
        if (newValue === 0) {
            setCourseId('M' + childInfo.grade);
        } else if (newValue === 1) {
            setCourseId('R' + childInfo.grade);
        } else if (newValue === 2) {
            setCourseId('S' + childInfo.grade);
        } else if (newValue === 3) {
            setCourseId('H' + childInfo.grade);
        }
    }
    return (
        <Grid className="authorInfoArea">
            <Grid
                container
                spacing={4}
                className="container">
                <Grid item lg={4} md={4} xs={12}>
                    <Grid className="authorImage">
                        {childInfo && childInfo.childProfilePic ? (
                            <img
                                src={`${childInfo.childProfilePic}?${childInfo.timeStamp}`}
                                alt="Avatar"
                                class="image"
                            />
                        ) : (
                            <img src={logo} alt="Avatar" class="image" />
                        )}
                        <input
                            id="select-profile-pic"
                            type="file"
                            onChange={onFileChange}
                            style={{ display: "none" }}
                        />
                        <Button style={{ textTransform: "none" }} variant="text" size="small" color="inherit" style={{
                            justifyContent: "flex-start",
                            display: "flex",
                            marginTop: 5
                        }}>
                            <PublishIcon /><label htmlFor="select-profile-pic">Click to Upload<br />
                                <span style={{ color: "red", fontSize: 10 }}>(Image size should not exceed 1MB)</span></label>
                        </Button>
                    </Grid>
                </Grid>
                <Grid item lg={8} md={8} xs={12}>
                    <Grid className="authorInfoWrap">
                        <Grid className="authorInfo">
                            <h3>{childInfo && childInfo.firstName + ' ' + childInfo.lastName}</h3>
                            <p>Grade : {childInfo && childInfo.grade}</p>
                            {childInfo && childInfo.firstName ? (
                                <ul className="flowBtn">
                                    <li><span className="flow"><Button
                                        onClick={() => history.push("/profile/" + childInfo.profileName)}
                                        path
                                    >{childInfo && childInfo.firstName
                                        ? childInfo.firstName + "'s Page"
                                        : "Guest Child"}

                                    </Button></span></li>
                                </ul>
                            ) : (
                                <ul className="flowBtn">
                                    <li><span className="flow">Guest</span></li>
                                </ul>
                            )}

                        </Grid>

                        <ul className="infoActionWrap">
                            <li>
                                <h4>4</h4>
                                <p>Expert Badges</p>
                            </li>
                            <li>
                                <h4>53233</h4>
                                <p>Total problems solved</p>
                            </li>
                            <li>
                                {/* <Rating
                                    className="ratingIcon"
                                    emptySymbol="fa fa-star-o"
                                    fullSymbol="fa fa-star"
                                    initialRating={4.3}
                                    readonly
                                />
                                <span className="review">(08)</span>
                                <p>Author Ratings</p> */}
                            </li>
                        </ul>
                    </Grid>
                    <Grid container item>
                        <Grid item xs={12}>
                            <SectionTitle
                                title='Daily Homeworks'
                            />
                        </Grid>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            classes={{
                                root: 'tabWrap',
                                flexContainer: 'tabContainer',
                                indicator: 'tabIndicator'
                            }}
                        >
                            <Tab label="Mathematics" />
                            <Tab label="Reading" />
                            <Tab label="Science" />
                            <Tab label="Social" />
                        </Tabs>
                        {value >= 0 && <Grid container spacing={2}>
                            {homeWorksList &&
                                courseId &&
                                homeWorksList
                                    .filter((hw) => hw.courseId === courseId)
                                    .map((hWork) => (
                                        <Grid item md={4}>
                                            <Card>
                                                <CardActionArea
                                                    style={{
                                                        padding: 10,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "flex-start",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        console.log("CLICKED", hWork.courseId);
                                                        history.push({
                                                            pathname: (location.state.userType && location.state.userType === 'guest') ? '/guest/child/homework' : '/child/homework',
                                                            state: {
                                                                childId: childInfo.childId,
                                                                hwDate: hWork.homeWorkDate,
                                                                subjectCd: hWork.courseId,
                                                                numOfQuestionsPerDay: (location.state.numOfQuestionsPerDay ? location.state.numOfQuestionsPerDay : 20),
                                                                userType: location.state.userType,
                                                                ctest: '',
                                                                pageFlag: "childCourse",
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <Typography variant="h4">{hWork.courseId}</Typography>
                                                    <Typography
                                                        variant={hWork.status === "New" ? "h5" : "h6"}
                                                        style={{ color: hWork.status === "New" ? "green" : "" }}
                                                    >
                                                        {hWork.status}
                                                        <div>{hWork.homeWorkDate.split("T")[0]}</div>
                                                    </Typography>
                                                    {(hWork.status === "Started" && location.state.userType === 'user') &&
                                                        <Typography variant="h6">Q:{hWork.numOfQuestions} A:{hWork.numOfcompleted} P:{hWork.numOfPending}</Typography>
                                                    }

                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    ))}
                        </Grid>}

                        {/* practice concepts */}
                        <Grid item xs={12} style={{ marginTop: 30 }}>
                            <SectionTitle
                                title={(courseId && courseId.charAt(0) === "M" ? "Math" : (courseId && courseId.charAt(0) === "R" ? "Reading" : (courseId && courseId.charAt(0) === "S" ? "Sceince" : "Social"))) + ' Additional Practice'}
                            />
                        </Grid>
                        {(value === 0 || courseId === "M" + childInfo.grade) && <Grid item style={{ marginTop: 15 }}>
                            {isLoggedIn && location && location.state && location.state.childId &&
                                mathPracticeConcepts.map((tier) => (
                                    <Grid>
                                        {tier.mathCourseId === "M" + childInfo.grade && (
                                            <Grid>
                                                <Card style={{ width: "150%", height: "100%" }}>
                                                    {/* <CardHeader
                                                        subheader="Practice Math Concepts"
                                                        titleTypographyProps={{ align: "center" }}
                                                        subheaderTypographyProps={{ align: "center" }}
                                                        // action={tier.title === 'Pro' ? <StarIcon /> : null}
                                                        className={classes.cardHeader}
                                                    /> */}
                                                    <CardContent>
                                                        <ul style={{ listStyle: "none" }}>
                                                            {tier.concepts.map((concept) => (
                                                                <>
                                                                    <Link
                                                                        variant="button"
                                                                        display="block"
                                                                        component="li"
                                                                        align="left"
                                                                        onClick={() => {
                                                                            history.push({
                                                                                pathname: "/guest/child/homework",
                                                                                state: {
                                                                                    childId: location && location.state && location.state.childId,
                                                                                    hwDate: "2021-08-08",
                                                                                    subjectCd: tier.mathCourseId,
                                                                                    numOfQuestionsPerDay: 60,
                                                                                    userType: "guest",
                                                                                    ctest: concept.cTest,
                                                                                    pageFlag: "practice",
                                                                                    concept: concept.concept,
                                                                                    practiceConcept: concept.concept.split('-')[0],
                                                                                    currentLevel: getCurrentlevel(getConceptName(concept.conceptName)),
                                                                                },
                                                                            });
                                                                        }}
                                                                    >
                                                                        {concept.conceptName}
                                                                    </Link>
                                                                    {childRankings &&
                                                                        childRankings.map((rank) => {
                                                                            const cName = getConceptName(
                                                                                concept.conceptName
                                                                            );
                                                                            if (cName === rank.grade) {
                                                                                return (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                            }}
                                                                                        >
                                                                                            <Typography
                                                                                                color="green"
                                                                                                variant="caption"
                                                                                                display="block"
                                                                                            >
                                                                                                {rank.rankings
                                                                                                    ? rank.rankings.currentBadge.name
                                                                                                    : ""}
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                align="left"
                                                                                                color="green"
                                                                                                variant="caption"
                                                                                                display="block"
                                                                                            >
                                                                                                Level{" "}
                                                                                                {rank.rankings && rank.rankings.currentBadge
                                                                                                    ? rank.rankings.currentBadge
                                                                                                        .currentLevel
                                                                                                    : ""}
                                                                                                {rank.rankings && rank.rankings.currentBadge
                                                                                                    && ' (' + (rank.rankings.currentBadge
                                                                                                        .pointsAchieved % 500) + '/500)'}
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                variant="caption"
                                                                                                display="block"
                                                                                            >
                                                                                                {rank.rankings && rank.rankings.currentBadge
                                                                                                    && (rank.rankings.currentBadge
                                                                                                        .pointsAchieved >= 10000 ? 'Completed' : "")}
                                                                                                {rank.rankings && rank.rankings.currentBadge && '(' + rank.rankings.currentBadge
                                                                                                    .pointsAchieved + '/10000)'}
                                                                                            </Typography>
                                                                                        </div>
                                                                                        <ProgressBar
                                                                                            style={{
                                                                                                height: "0.75rem",
                                                                                                fontSize: "1rem",
                                                                                            }}
                                                                                        >
                                                                                            <ProgressBar
                                                                                                animated
                                                                                                striped
                                                                                                variant="success"
                                                                                                now={
                                                                                                    rank.rankings &&
                                                                                                        rank.rankings.currentBadge
                                                                                                        ? (100 *
                                                                                                            rank.rankings.currentBadge
                                                                                                                .pointsAchieved) /
                                                                                                        2000
                                                                                                        : 0
                                                                                                }
                                                                                                key={1}
                                                                                                label={
                                                                                                    rank.rankings &&
                                                                                                        rank.rankings.currentBadge
                                                                                                        ? (100 *
                                                                                                            rank.rankings.currentBadge
                                                                                                                .pointsAchieved) /
                                                                                                        2000
                                                                                                        : 0
                                                                                                }
                                                                                            />
                                                                                        </ProgressBar>
                                                                                        <Divider
                                                                                            className={classes.dividerFullWidth}
                                                                                            variant="inset"
                                                                                            component="li"
                                                                                        />
                                                                                    </>
                                                                                );
                                                                            }
                                                                        })}
                                                                </>
                                                            ))}
                                                        </ul>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        )}
                                    </Grid>
                                ))}
                        </Grid>}
                        {(value === 1 || courseId === "R" + childInfo.grade) && <Grid item style={{ marginTop: 30 }}>
                            {isLoggedIn &&
                                languagePracticeConcepts.map((tier) => (
                                    <Grid>
                                        {tier.courseId === "R" + childInfo.grade && (
                                            <Grid>
                                                <Card style={{ width: "150%", height: "100%" }}>
                                                    {/* <CardHeader
                                                        subheader="Practice Language Concepts"
                                                        titleTypographyProps={{ align: "center" }}
                                                        subheaderTypographyProps={{ align: "center" }}
                                                        // action={tier.title === 'Pro' ? <StarIcon /> : null}
                                                        className={classes.cardHeader}
                                                    /> */}
                                                    <CardContent>
                                                        <ul style={{ listStyle: "none" }}>
                                                            {tier.concepts.map((concept) => (
                                                                <>
                                                                    <Link
                                                                        variant="button"
                                                                        display="block"
                                                                        component="li"
                                                                        align="left"
                                                                        onClick={() => {
                                                                            console.log(
                                                                                "SERVICE language",
                                                                                tier,
                                                                                concept.cTest,
                                                                                concept,
                                                                                " ==== ",
                                                                                concept.concept
                                                                            );
                                                                            history.push({
                                                                                pathname: "/guest/child/homework",
                                                                                state: {
                                                                                    childId: location && location.state && location.state.childId,
                                                                                    hwDate: "2021-08-08",
                                                                                    subjectCd: tier.courseId,
                                                                                    numOfQuestionsPerDay: 60,
                                                                                    userType: "guest",
                                                                                    ctest: concept.cTest,
                                                                                    pageFlag: "practice",
                                                                                    concept: concept.concept,
                                                                                },
                                                                            });
                                                                        }}
                                                                    >
                                                                        {concept.conceptName}
                                                                    </Link>
                                                                    {childRankings &&
                                                                        childRankings.map((rank) => {
                                                                            const cName = getConceptName(
                                                                                concept.conceptName
                                                                            );
                                                                            console.log(
                                                                                "@@@@@@@@@@@@@@@@@@@@@@@@ ",
                                                                                cName
                                                                            );
                                                                            if (cName === rank.grade) {
                                                                                return (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                            }}
                                                                                        >
                                                                                            <Typography
                                                                                                color="green"
                                                                                                variant="caption"
                                                                                                display="block"
                                                                                                style={{ fontSize: 'xx-small' }}
                                                                                            >
                                                                                                {rank.rankings
                                                                                                    ? rank.rankings.currentBadge.name
                                                                                                    : ""}
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                align="left"
                                                                                                color="green"
                                                                                                variant="caption"
                                                                                                display="block"
                                                                                                style={{ fontSize: 'xx-small' }}
                                                                                            >
                                                                                                Level{" "}
                                                                                                {rank.rankings
                                                                                                    ? rank.rankings.currentBadge
                                                                                                        .currentLevel
                                                                                                    : ""}
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                variant="caption"
                                                                                                display="block"
                                                                                                style={{ fontSize: 'xx-small' }}
                                                                                            >
                                                                                                {rank.rankings
                                                                                                    ? rank.rankings.currentBadge
                                                                                                        .pointsAchieved
                                                                                                    : ""}
                                                                                                /500
                                                                                            </Typography>
                                                                                        </div>
                                                                                        <ProgressBar
                                                                                            style={{
                                                                                                height: "0.75rem",
                                                                                                fontSize: "1rem",
                                                                                            }}
                                                                                        >
                                                                                            <ProgressBar
                                                                                                animated
                                                                                                striped
                                                                                                variant="success"
                                                                                                now={
                                                                                                    rank.rankings &&
                                                                                                        rank.rankings.currentBadge
                                                                                                        ? (100 *
                                                                                                            rank.rankings.currentBadge
                                                                                                                .pointsAchieved) /
                                                                                                        2000
                                                                                                        : 0
                                                                                                }
                                                                                                key={1}
                                                                                                label={
                                                                                                    rank.rankings &&
                                                                                                        rank.rankings.currentBadge
                                                                                                        ? (100 *
                                                                                                            rank.rankings.currentBadge
                                                                                                                .pointsAchieved) /
                                                                                                        2000
                                                                                                        : 0
                                                                                                }
                                                                                            />
                                                                                        </ProgressBar>
                                                                                        <Divider
                                                                                            className={classes.dividerFullWidth}
                                                                                            variant="inset"
                                                                                            component="li"
                                                                                        />
                                                                                    </>
                                                                                );
                                                                            }
                                                                        })}
                                                                </>
                                                            ))}
                                                        </ul>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        )}
                                    </Grid>
                                ))}
                        </Grid>
                        }
                        {(value === 2 || courseId === "S" + childInfo.grade) && <Grid item style={{ marginTop: 30 }}>
                            {isLoggedIn &&
                                sciencePracticeConcepts.map((tier) => (
                                    <Grid>
                                        {tier.courseId === "S" + childInfo.grade && (
                                            <Grid>
                                                <Card style={{ width: "150%", height: "100%" }}>
                                                    {/* <CardHeader
                                                        subheader="Practice Science Concepts"
                                                        titleTypographyProps={{ align: "center" }}
                                                        subheaderTypographyProps={{ align: "center" }}
                                                        // action={tier.title === 'Pro' ? <StarIcon /> : null}
                                                        className={classes.cardHeader}
                                                    /> */}
                                                    <CardContent>
                                                        <ul style={{ listStyle: "none" }}>
                                                            {tier.concepts.map((concept) => (
                                                                <>
                                                                    <Link
                                                                        variant="button"
                                                                        display="block"
                                                                        component="li"
                                                                        align="left"
                                                                        onClick={() => {
                                                                            console.log(
                                                                                "SERVICE language",
                                                                                tier,
                                                                                concept.cTest,
                                                                                concept,
                                                                                " ==== ",
                                                                                concept.concept
                                                                            );
                                                                            history.push({
                                                                                pathname: "/guest/child/homework",
                                                                                state: {
                                                                                    childId: location && location.state && location.state.childId,
                                                                                    hwDate: "2021-08-08",
                                                                                    subjectCd: tier.courseId,
                                                                                    numOfQuestionsPerDay: 60,
                                                                                    userType: "guest",
                                                                                    ctest: concept.cTest,
                                                                                    pageFlag: "practice",
                                                                                    concept: concept.concept,
                                                                                },
                                                                            });
                                                                        }}
                                                                    >
                                                                        {concept.conceptName}
                                                                    </Link>
                                                                    {childRankings &&
                                                                        childRankings.map((rank) => {
                                                                            const cName = getConceptName(
                                                                                concept.conceptName
                                                                            );
                                                                            console.log(
                                                                                "@@@@@@@@@@@@@@@@@@@@@@@@ ",
                                                                                cName
                                                                            );
                                                                            if (cName === rank.grade) {
                                                                                return (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                            }}
                                                                                        >
                                                                                            <Typography
                                                                                                color="green"
                                                                                                variant="caption"
                                                                                                display="block"
                                                                                            >
                                                                                                {rank.rankings
                                                                                                    ? rank.rankings.currentBadge.name
                                                                                                    : ""}
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                align="left"
                                                                                                color="green"
                                                                                                variant="caption"
                                                                                                display="block"
                                                                                            >
                                                                                                Level{" "}
                                                                                                {rank.rankings
                                                                                                    ? rank.rankings.currentBadge
                                                                                                        .currentLevel
                                                                                                    : ""}
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                variant="caption"
                                                                                                display="block"
                                                                                            >
                                                                                                {rank.rankings
                                                                                                    ? rank.rankings.currentBadge
                                                                                                        .pointsAchieved
                                                                                                    : ""}
                                                                                                /500
                                                                                            </Typography>
                                                                                        </div>
                                                                                        <ProgressBar
                                                                                            style={{
                                                                                                height: "0.75rem",
                                                                                                fontSize: "1rem",
                                                                                            }}
                                                                                        >
                                                                                            <ProgressBar
                                                                                                animated
                                                                                                striped
                                                                                                variant="success"
                                                                                                now={
                                                                                                    rank.rankings &&
                                                                                                        rank.rankings.currentBadge
                                                                                                        ? (100 *
                                                                                                            rank.rankings.currentBadge
                                                                                                                .pointsAchieved) /
                                                                                                        2000
                                                                                                        : 0
                                                                                                }
                                                                                                key={1}
                                                                                                label={
                                                                                                    rank.rankings &&
                                                                                                        rank.rankings.currentBadge
                                                                                                        ? (100 *
                                                                                                            rank.rankings.currentBadge
                                                                                                                .pointsAchieved) /
                                                                                                        2000
                                                                                                        : 0
                                                                                                }
                                                                                            />
                                                                                        </ProgressBar>
                                                                                        <Divider
                                                                                            className={classes.dividerFullWidth}
                                                                                            variant="inset"
                                                                                            component="li"
                                                                                        />
                                                                                    </>
                                                                                );
                                                                            }
                                                                        })}
                                                                </>
                                                            ))}
                                                        </ul>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        )}
                                    </Grid>
                                ))}
                        </Grid>
                        }
                        {(value === 3 || courseId === "H" + childInfo.grade) && <Grid item style={{ marginTop: 30 }}>
                            {isLoggedIn &&
                                socialPracticeConcepts.map((tier) => (
                                    <Grid>
                                        {tier.courseId === "H" + childInfo.grade && (
                                            <Grid>
                                                <Card style={{ width: "150%", height: "100%" }}>
                                                    {/* <CardHeader
                                                        subheader="Practice Social Concepts"
                                                        titleTypographyProps={{ align: "center" }}
                                                        subheaderTypographyProps={{ align: "center" }}
                                                        // action={tier.title === 'Pro' ? <StarIcon /> : null}
                                                        className={classes.cardHeader}
                                                    /> */}
                                                    <CardContent>
                                                        <ul style={{ listStyle: "none" }}>
                                                            {tier.concepts.map((concept) => (
                                                                <>
                                                                    <Link
                                                                        variant="button"
                                                                        display="block"
                                                                        component="li"
                                                                        align="left"
                                                                        onClick={() => {
                                                                            console.log(
                                                                                "SERVICE language",
                                                                                tier,
                                                                                concept.cTest,
                                                                                concept,
                                                                                " ==== ",
                                                                                concept.concept
                                                                            );
                                                                            history.push({
                                                                                pathname: "/guest/child/homework",
                                                                                state: {
                                                                                    childId: location && location.state && location.state.childId,
                                                                                    hwDate: "2021-08-08",
                                                                                    subjectCd: tier.courseId,
                                                                                    numOfQuestionsPerDay: 60,
                                                                                    userType: "guest",
                                                                                    ctest: concept.cTest,
                                                                                    pageFlag: "practice",
                                                                                    concept: concept.concept,
                                                                                },
                                                                            });
                                                                        }}
                                                                    >
                                                                        {concept.conceptName}
                                                                    </Link>
                                                                    {childRankings &&
                                                                        childRankings.map((rank) => {
                                                                            const cName = getConceptName(
                                                                                concept.conceptName
                                                                            );
                                                                            console.log(
                                                                                "@@@@@@@@@@@@@@@@@@@@@@@@ ",
                                                                                cName
                                                                            );
                                                                            if (cName === rank.grade) {
                                                                                return (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                            }}
                                                                                        >
                                                                                            <Typography
                                                                                                color="green"
                                                                                                variant="caption"
                                                                                                display="block"
                                                                                            >
                                                                                                {rank.rankings
                                                                                                    ? rank.rankings.currentBadge.name
                                                                                                    : ""}
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                align="left"
                                                                                                color="green"
                                                                                                variant="caption"
                                                                                                display="block"
                                                                                            >
                                                                                                Level{" "}
                                                                                                {rank.rankings
                                                                                                    ? rank.rankings.currentBadge
                                                                                                        .currentLevel
                                                                                                    : ""}
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                variant="caption"
                                                                                                display="block"
                                                                                            >
                                                                                                {rank.rankings
                                                                                                    ? rank.rankings.currentBadge
                                                                                                        .pointsAchieved
                                                                                                    : ""}
                                                                                                /500
                                                                                            </Typography>
                                                                                        </div>
                                                                                        <ProgressBar
                                                                                            style={{
                                                                                                height: "0.75rem",
                                                                                                fontSize: "1rem",
                                                                                            }}
                                                                                        >
                                                                                            <ProgressBar
                                                                                                animated
                                                                                                striped
                                                                                                variant="success"
                                                                                                now={
                                                                                                    rank.rankings &&
                                                                                                        rank.rankings.currentBadge
                                                                                                        ? (100 *
                                                                                                            rank.rankings.currentBadge
                                                                                                                .pointsAchieved) /
                                                                                                        2000
                                                                                                        : 0
                                                                                                }
                                                                                                key={1}
                                                                                                label={
                                                                                                    rank.rankings &&
                                                                                                        rank.rankings.currentBadge
                                                                                                        ? (100 *
                                                                                                            rank.rankings.currentBadge
                                                                                                                .pointsAchieved) /
                                                                                                        2000
                                                                                                        : 0
                                                                                                }
                                                                                            />
                                                                                        </ProgressBar>
                                                                                        <Divider
                                                                                            className={classes.dividerFullWidth}
                                                                                            variant="inset"
                                                                                            component="li"
                                                                                        />
                                                                                    </>
                                                                                );
                                                                            }
                                                                        })}
                                                                </>
                                                            ))}
                                                        </ul>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        )}
                                    </Grid>
                                ))}
                        </Grid>
                        }
                    </Grid>

                </Grid>
            </Grid>
        </Grid >
    )
}


export default NewChildPage;
