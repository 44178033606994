import { createAction } from "@reduxjs/toolkit";
import { childConstants } from "../constants";

export const getChildInfo = createAction(
  childConstants.GET_CHILD_INFO,
  (payload) => ({ payload })
);
export const getChildInfoSuccess = createAction(
  childConstants.GET_CHILD_INFO_SUCCESS,
  (payload) => ({ payload })
);

export const addChild = createAction(childConstants.ADD_CHILD, (payload) => ({
  payload,
}));
export const addChildSuccess = createAction(
  childConstants.ADD_CHILD_SUCCESS,
  (payload) => ({ payload })
);

export const addChildFailure = createAction(
  childConstants.ADD_CHILD_FAILURE,
  (payload) => ({ payload })
);

export const deleteChild = createAction(
  childConstants.DELETE_CHILD,
  (payload) => ({ payload })
);
export const deleteChildSuccess = createAction(
  childConstants.DELETE_CHILD_SUCCESS,
  (payload) => ({ payload })
);

export const deleteChildFailure = createAction(
  childConstants.DELETE_CHILD_FAILURE,
  (payload) => ({ payload })
);

export const uploadChildProfilePic = createAction(
  childConstants.UPLOAD_CHILD_PROFILE_PIC,
  (payload) => ({ payload })
);

export const uploadChildProfilePicSuccess = createAction(
  childConstants.UPLOAD_CHILD_PROFILE_PIC_SUCCESS,
  (payload) => ({ payload })
);

export const uploadChildProfilePicFailure = createAction(
  childConstants.UPLOAD_CHILD_PROFILE_PIC_FAILURE,
  (payload) => ({ payload })
);
