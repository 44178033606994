export const socialPracticeConcepts = [

    {
        title: "Second Grade",

        numOfQuestionsPerDay: 40,
        courseId: "H2",
        concepts: [
            {
                conceptName: "Select Geography",
                concept: "geo",
                cTest: "geo-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Geography",
                concept: "geo",
                cTest: "geo-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Historical Persons",
                concept: "smplstry-hstprsns",
                cTest: "smplstry-hstprsns-qstns",
                accuracy: 5,
            },
            {
                conceptName: "Government",
                concept: "smplstry-gvrnmnt",
                cTest: "smplstry-gvrnmnt-qstns",
                accuracy: 5,
            },
            // {
            //   conceptName: "American Symbols,Landmarks & Monuments",
            //   concept: "lndmrks",
            //   cTest: "lndmrks-idfy-any",
            //   accuracy: 5,
            // },
            {
                conceptName: "Cultural Celebrations",
                concept: "smplstry-cltrlclbr",
                cTest: "smplstry-cltrlclbr-qstns",
                accuracy: 5,
            },
            // {
            //   conceptName: "Economics",
            //   concept: "smplstry-economics",
            //   cTest: "smplstry-economics-qstns",
            //   accuracy: 5,
            // },     
        ],
    },
    {
        title: "Third Grade",

        numOfQuestionsPerDay: 40,
        courseId: "H3",
        concepts: [
            {
                conceptName: "Select Geography",
                concept: "geo",
                cTest: "geo-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Geography",
                concept: "geo",
                cTest: "geo-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select States",
                concept: "stts",
                cTest: "stts-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose States",
                concept: "stts",
                cTest: "stts-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Capitals",
                concept: "cptls",
                cTest: "cptls-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Capitals",
                concept: "cptls",
                cTest: "cptls-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Historical Persons",
                concept: "smplstry-hstprsns",
                cTest: "smplstry-hstprsns-qstns",
                accuracy: 5,
            },
            {
                conceptName: "Government",
                concept: "smplstry-gvrnmnt",
                cTest: "smplstry-gvrnmnt-qstns",
                accuracy: 5,
            },
            // {
            //   conceptName: "American Symbols,Landmarks & Monuments",
            //   concept: "lndmrks",
            //   cTest: "lndmrks-idfy-any",
            //   accuracy: 5,
            // },
            {
                conceptName: "Cultural Celebrations",
                concept: "smplstry-cltrlclbr",
                cTest: "smplstry-cltrlclbr-qstns",
                accuracy: 5,
            },
            // {
            //   conceptName: "Economics",
            //   concept: "smplstry-economics",
            //   cTest: "smplstry-economics-qstns",
            //   accuracy: 5,
            // },
            {
                conceptName: "Social Studies Skills",
                concept: "smplstry-skls",
                cTest: "smplstry-skls-qstns",
                accuracy: 5,
            },
            {
                conceptName: "Basic Economic Principles",
                concept: "smplstry-prncpls",
                cTest: "smplstry-prncpls-qstns",
                accuracy: 5,
            },
            {
                conceptName: "Supply And Demand",
                concept: "smplstry-spply",
                cTest: "smplstry-spply-qstns",
                accuracy: 5,
            },
        ],
    },
    {
        title: "Fourth Grade",

        numOfQuestionsPerDay: 40,
        courseId: "H4",
        concepts: [
            {
                conceptName: "English Colonies In North America",
                concept: "smplstry-colnys",
                cTest: "smplstry-colnys-qstns",
                accuracy: 5,
            },
            {
                conceptName: "The American Revolution",
                concept: "smplstry-rvltn",
                cTest: "smplstry-rvltn-qstns",
                accuracy: 5,
            },
            {
                conceptName: "Early 19th Century American History",
                concept: "smplstry-19thcen",
                cTest: "smplstry-19thcen-qstns",
                accuracy: 5,
            },
            {
                conceptName: "The Civil War And Reconstruction",
                concept: "smplstry-recon",
                cTest: "smplstry-recon-qstns",
                accuracy: 5,
            },
            {
                conceptName: "20th Century American History",
                concept: "smplstry-20thcen",
                cTest: "smplstry-20thcen-qstns",
                accuracy: 5,
            },
            {
                conceptName: "Maps",
                concept: "smplstry-maps",
                cTest: "smplstry-maps-qstns",
                accuracy: 5,
            },
            {
                conceptName: "Select Geography",
                concept: "geo",
                cTest: "geo-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Geography",
                concept: "geo",
                cTest: "geo-chse-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Select States",
            //   concept: "stts",
            //   cTest: "stts-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose States",
            //   concept: "stts",
            //   cTest: "stts-chse-any",
            //   accuracy: 5,
            // },
            {
                conceptName: "Select Capitals",
                concept: "cptls",
                cTest: "cptls-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Capitals",
                concept: "cptls",
                cTest: "cptls-chse-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Historical Persons",
            //   concept: "smplstry-hstprsns",
            //   cTest: "smplstry-hstprsns-qstns",
            //   accuracy: 5,
            // },
            {
                conceptName: "Government",
                concept: "smplstry-gvrnmnt",
                cTest: "smplstry-gvrnmnt-qstns",
                accuracy: 5,
            },
            // {
            //   conceptName: "American Symbols,Landmarks & Monuments",
            //   concept: "lndmrks",
            //   cTest: "lndmrks-idfy-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Cultural Celebrations",
            //   concept: "smplstry-cltrlclbr",
            //   cTest: "smplstry-cltrlclbr-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Economics",
            //   concept: "smplstry-economics",
            //   cTest: "smplstry-economics-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Social Studies Skills",
            //   concept: "smplstry-skls",
            //   cTest: "smplstry-skls-qstns",
            //   accuracy: 5,
            // },
            {
                conceptName: "Basic Economic Principles",
                concept: "smplstry-prncpls",
                cTest: "smplstry-prncpls-qstns",
                accuracy: 5,
            },
            {
                conceptName: "Supply And Demand",
                concept: "smplstry-spply",
                cTest: "smplstry-spply-qstns",
                accuracy: 5,
            },
            {
                conceptName: "Banking And Finance",
                concept: "smplstry-bnkng",
                cTest: "smplstry-bnkng-qstns",
                accuracy: 5,
            },
            {
                conceptName: "Population Of Cities",
                concept: "ppltn",
                cTest: "ppltn-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "American National Holidays",
                concept: "smplstry-hldys",
                cTest: "smplstry-hldys-qstns",
                accuracy: 5,
            },
        ],
    },
    {
        title: "Fifth Grade",

        numOfQuestionsPerDay: 40,
        courseId: "H5",
        concepts: [
            // {
            //   conceptName: "English Colonies In North America",
            //   concept: "smplstry-colnys",
            //   cTest: "smplstry-colnys-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select English Colonies of North America",
            //   concept: "gnrl-colnys",
            //   cTest: "gnrl-colnys-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "The American Revolution",
            //   concept: "smplstry-rvltn",
            //   cTest: "smplstry-rvltn-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Early 19th Century American History",
            //   concept: "smplstry-19thcen",
            //   cTest: "smplstry-19thcen-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "The Civil War And Reconstruction",
            //   concept: "smplstry-recon",
            //   cTest: "smplstry-recon-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "20th Century American History",
            //   concept: "smplstry-20thcen",
            //   cTest: "smplstry-20thcen-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Maps",
            //   concept: "smplstry-maps",
            //   cTest: "smplstry-maps-qstns",
            //   accuracy: 5,
            // },
            {
                conceptName: "Select Geography",
                concept: "geo",
                cTest: "geo-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Geography",
                concept: "geo",
                cTest: "geo-chse-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Select States",
            //   concept: "stts",
            //   cTest: "stts-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose States",
            //   concept: "stts",
            //   cTest: "stts-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Capitals",
            //   concept: "cptls",
            //   cTest: "cptls-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Capitals",
            //   concept: "cptls",
            //   cTest: "cptls-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Historical Persons",
            //   concept: "smplstry-hstprsns",
            //   cTest: "smplstry-hstprsns-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Government",
            //   concept: "smplstry-gvrnmnt",
            //   cTest: "smplstry-gvrnmnt-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "American Symbols,Landmarks & Monuments",
            //   concept: "lndmrks",
            //   cTest: "lndmrks-idfy-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Cultural Celebrations",
            //   concept: "smplstry-cltrlclbr",
            //   cTest: "smplstry-cltrlclbr-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Economics",
            //   concept: "smplstry-economics",
            //   cTest: "smplstry-economics-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Social Studies Skills",
            //   concept: "smplstry-skls",
            //   cTest: "smplstry-skls-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Basic Economic Principles",
            //   concept: "smplstry-prncpls",
            //   cTest: "smplstry-prncpls-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Supply And Demand",
            //   concept: "smplstry-spply",
            //   cTest: "smplstry-spply-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Banking And Finance",
            //   concept: "smplstry-bnkng",
            //   cTest: "smplstry-bnkng-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Population Of Cities",
            //   concept: "ppltn",
            //   cTest: "ppltn-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "American National Holidays",
            //   concept: "smplstry-hldys",
            //   cTest: "smplstry-hldys-qstns",
            //   accuracy: 5,
            // },
            {
                conceptName: "Select Plymouth",
                concept: "gnrl-plmth",
                cTest: "gnrl-plmth-slct",
                accuracy: 5,
            },
            {
                conceptName: "New England Colonies",
                concept: "gnrl-engcolnys",
                cTest: "gnrl-engcolnys-slct",
                accuracy: 5,
            },
            {
                conceptName: "Middle Colonies",
                concept: "gnrl-mdlcolnys",
                cTest: "gnrl-mdlcolnys-slct",
                accuracy: 5,
            },
            {
                conceptName: "Southern Colonies",
                concept: "gnrl-sthrncolnys",
                cTest: "gnrl-sthrncolnys-slct",
                accuracy: 5,
            },
            {
                conceptName: "Thirteen Colonies/Non ThirteenColonies",
                concept: "13clnys",
                cTest: "13clnys-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "The American Revolution- The Thirteen Colonies Under BritishRule",
                concept: "gnrl-rvltn",
                cTest: "gnrl-rvltn-slct",
                accuracy: 5,
            },
            {
                conceptName: "New British Taxes",
                concept: "gnrl-tax",
                cTest: "gnrl-tax-slct",
                accuracy: 5,
            },
            {
                conceptName: "The Boston Party",
                concept: "gnrl-bstnprty",
                cTest: "gnrl-bstnprty-slct",
                accuracy: 5,
            },
            {
                conceptName: "Preparing For War",
                concept: "gnrl-war",
                cTest: "gnrl-war-slct",
                accuracy: 5,
            },
            {
                conceptName: "The Rebellion Begins",
                concept: "gnrl-bgns",
                cTest: "gnrl-bgns-slct",
                accuracy: 5,
            },
            {
                conceptName: "Struggle For Independence",
                concept: "gnrl-indpn",
                cTest: "gnrl-indpn-slct",
                accuracy: 5,
            },
            {
                conceptName: "Conclusion And Aftermath",
                concept: "gnrl-cnclsn",
                cTest: "gnrl-cnclsn-slct",
                accuracy: 5,
            },
            {
                conceptName: "Early Nineteenth Century American History",
                concept: "gnrl-19thcen",
                cTest: "gnrl-19thcen-slct",
                accuracy: 5,
            },
            {
                conceptName: "The Lewis And Clark Expedition",
                concept: "gnrl-expdtn",
                cTest: "gnrl-expdtn-slct",
                accuracy: 5,
            },
            {
                conceptName: "The War Of 1812 Causes",
                concept: "gnrl-1812war",
                cTest: "gnrl-1812war-slct",
                accuracy: 5,
            },
            {
                conceptName: "Ante Bellam Period",
                concept: "gnrl-blmprd",
                cTest: "gnrl-blmprd-slct",
                accuracy: 5,
            },
            {
                conceptName: "Civil War And Reconstruction",
                concept: "gnrl-recon",
                cTest: "gnrl-recon-slct",
                accuracy: 5,
            },
            {
                conceptName: "American History Of Twentieth Century",
                concept: "gnrl-20thcen",
                cTest: "gnrl-20thcen-slct",
                accuracy: 5,
            },
            {
                conceptName: "The Great Depression",
                concept: "gnrl-dprsn",
                cTest: "gnrl-dprsn-slct",
                accuracy: 5,
            },
            {
                conceptName: "World War II",
                concept: "gnrl-wrlwar2",
                cTest: "gnrl-wrlwar2-slct",
                accuracy: 5,
            },
            {
                conceptName: "American Government",
                concept: "gnrl-gvrnmnt",
                cTest: "gnrl-gvrnmnt-slct",
                accuracy: 5,
            },
            {
                conceptName: "American Symbols And Monuments",
                concept: "gnrl-symbls",
                cTest: "gnrl-symbls-slct",
                accuracy: 5,
            },
            {
                conceptName: "Basic Principles Of Economics",
                concept: "gnrl-prncpls",
                cTest: "gnrl-prncpls-slct",
                accuracy: 5,
            },
            {
                conceptName: "Finance And Banking",
                concept: "gnrl-bnkng",
                cTest: "gnrl-bnkng-slct",
                accuracy: 5,
            },
            {
                conceptName: "Demand And Supply",
                concept: "gnrl-spply",
                cTest: "gnrl-spply-slct",
                accuracy: 5,
            },
            {
                conceptName: "Skills Of Social Studies",
                concept: "gnrl-skls",
                cTest: "gnrl-skls-slct",
                accuracy: 5,
            },
        ],
    },
    {
        title: "Sixth Grade",

        numOfQuestionsPerDay: 40,
        courseId: "H6",
        concepts: [
            // {
            //   conceptName: "English Colonies In North America",
            //   concept: "smplstry-colnys",
            //   cTest: "smplstry-colnys-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select English Colonies of North America",
            //   concept: "gnrl-colnys",
            //   cTest: "gnrl-colnys-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "The American Revolution",
            //   concept: "smplstry-rvltn",
            //   cTest: "smplstry-rvltn-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Early 19th Century American History",
            //   concept: "smplstry-19thcen",
            //   cTest: "smplstry-19thcen-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "The Civil War And Reconstruction",
            //   concept: "smplstry-recon",
            //   cTest: "smplstry-recon-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "20th Century American History",
            //   concept: "smplstry-20thcen",
            //   cTest: "smplstry-20thcen-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Maps",
            //   concept: "smplstry-maps",
            //   cTest: "smplstry-maps-qstns",
            //   accuracy: 5,
            // },
            {
                conceptName: "Select Geography",
                concept: "geo",
                cTest: "geo-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Geography",
                concept: "geo",
                cTest: "geo-chse-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Select States",
            //   concept: "stts",
            //   cTest: "stts-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose States",
            //   concept: "stts",
            //   cTest: "stts-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Capitals",
            //   concept: "cptls",
            //   cTest: "cptls-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Capitals",
            //   concept: "cptls",
            //   cTest: "cptls-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Historical Persons",
            //   concept: "smplstry-hstprsns",
            //   cTest: "smplstry-hstprsns-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Government",
            //   concept: "smplstry-gvrnmnt",
            //   cTest: "smplstry-gvrnmnt-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "American Symbols,Landmarks & Monuments",
            //   concept: "lndmrks",
            //   cTest: "lndmrks-idfy-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Cultural Celebrations",
            //   concept: "smplstry-cltrlclbr",
            //   cTest: "smplstry-cltrlclbr-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Economics",
            //   concept: "smplstry-economics",
            //   cTest: "smplstry-economics-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Social Studies Skills",
            //   concept: "smplstry-skls",
            //   cTest: "smplstry-skls-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Basic Economic Principles",
            //   concept: "smplstry-prncpls",
            //   cTest: "smplstry-prncpls-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Supply And Demand",
            //   concept: "smplstry-spply",
            //   cTest: "smplstry-spply-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Banking And Finance",
            //   concept: "smplstry-bnkng",
            //   cTest: "smplstry-bnkng-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Population Of Cities",
            //   concept: "ppltn",
            //   cTest: "ppltn-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "American National Holidays",
            //   concept: "smplstry-hldys",
            //   cTest: "smplstry-hldys-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Plymouth",
            //   concept: "gnrl-plmth",
            //   cTest: "gnrl-plmth-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "New England Colonies",
            //   concept: "gnrl-engcolnys",
            //   cTest: "gnrl-engcolnys-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Middle Colonies",
            //   concept: "gnrl-mdlcolnys",
            //   cTest: "gnrl-mdlcolnys-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Southern Colonies",
            //   concept: "gnrl-sthrncolnys",
            //   cTest: "gnrl-sthrncolnys-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Thirteen Colonies/Non ThirteenColonies",
            //   concept: "13clnys",
            //   cTest: "13clnys-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "The American Revolution- The Thirteen Colonies Under BritishRule",
            //   concept: "gnrl-rvltn",
            //   cTest: "gnrl-rvltn-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "New British Taxes",
            //   concept: "gnrl-tax",
            //   cTest: "gnrl-tax-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "The Boston Party",
            //   concept: "gnrl-bstnprty",
            //   cTest: "gnrl-bstnprty-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Preparing For War",
            //   concept: "gnrl-war",
            //   cTest: "gnrl-war-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "The Rebellion Begins",
            //   concept: "gnrl-bgns",
            //   cTest: "gnrl-bgns-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Struggle For Independence",
            //   concept: "gnrl-indpn",
            //   cTest: "gnrl-indpn-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Conclusion And Aftermath",
            //   concept: "gnrl-cnclsn",
            //   cTest: "gnrl-cnclsn-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Early Nineteenth Century American History",
            //   concept: "gnrl-19thcen",
            //   cTest: "gnrl-19thcen-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "The Lewis And Clark Expedition",
            //   concept: "gnrl-expdtn",
            //   cTest: "gnrl-expdtn-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "The War Of 1812 Causes",
            //   concept: "gnrl-1812war",
            //   cTest: "gnrl-1812war-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Ante Bellam Period",
            //   concept: "gnrl-blmprd",
            //   cTest: "gnrl-blmprd-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Civil War And Reconstruction",
            //   concept: "gnrl-recon",
            //   cTest: "gnrl-recon-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "American History Of Twentieth Century",
            //   concept: "gnrl-20thcen",
            //   cTest: "gnrl-20thcen-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "The Great Depression",
            //   concept: "gnrl-dprsn",
            //   cTest: "gnrl-dprsn-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "World War II",
            //   concept: "gnrl-wrlwar2",
            //   cTest: "gnrl-wrlwar2-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "American Government",
            //   concept: "gnrl-gvrnmnt",
            //   cTest: "gnrl-gvrnmnt-slct",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "American Symbols And Monuments",
            //   concept: "gnrl-symbls",
            //   cTest: "gnrl-symbls-slct",
            //   accuracy: 5,
            // },
            {
                conceptName: "Basic Principles Of Economics",
                concept: "gnrl-prncpls",
                cTest: "gnrl-prncpls-slct",
                accuracy: 5,
            },
            {
                conceptName: "Finance And Banking",
                concept: "gnrl-bnkng",
                cTest: "gnrl-bnkng-slct",
                accuracy: 5,
            },
            {
                conceptName: "Demand And Supply",
                concept: "gnrl-spply",
                cTest: "gnrl-spply-slct",
                accuracy: 5,
            },
            {
                conceptName: "Skills Of Social Studies",
                concept: "gnrl-skls",
                cTest: "gnrl-skls-slct",
                accuracy: 5,
            },
            {
                conceptName: "Pre History Of The Neolithic Period",
                concept: "gnrl-hstryneo",
                cTest: "gnrl-hstryneo-slct",
                accuracy: 5,
            },
            {
                conceptName: "Ancient Mesopotamia",
                concept: "gnrl-ancntmsptm",
                cTest: "gnrl-ancntmsptm-slct",
                accuracy: 5,
            },
            {
                conceptName: "Ancient Egypt And Kush",
                concept: "gnrl-ancntegpt",
                cTest: "gnrl-ancntegpt-slct",
                accuracy: 5,
            },
            {
                conceptName: "Early China",
                concept: "gnrl-china",
                cTest: "gnrl-china-slct",
                accuracy: 5,
            },
            {
                conceptName: "Ancient South Asia",
                concept: "gnrl-ancntsa",
                cTest: "gnrl-ancntsa-slct",
                accuracy: 5,
            },
            {
                conceptName: "Comparing Ancient River Civilizations",
                concept: "gnrl-ancntrvr",
                cTest: "gnrl-ancntrvr-slct",
                accuracy: 5,
            },
            {
                conceptName: "The Silk Road",
                concept: "gnrl-slkrd",
                cTest: "gnrl-slkrd-slct",
                accuracy: 5,
            },
            {
                conceptName: "World Religions",
                concept: "gnrl-wrldrlgns",
                cTest: "gnrl-wrldrlgns-slct",
                accuracy: 5,
            },
            {
                conceptName: "Greece",
                concept: "gnrl-grc",
                cTest: "gnrl-grc-slct",
                accuracy: 5,
            },
            {
                conceptName: "Rome And The Byzantine Empire",
                concept: "gnrl-rome",
                cTest: "gnrl-rome-slct",
                accuracy: 5,
            },
            {
                conceptName: "Islamic Empires",
                concept: "gnrl-islmc",
                cTest: "gnrl-islmc-slct",
                accuracy: 5,
            },
            {
                conceptName: "African Empires",
                concept: "gnrl-afrcn",
                cTest: "gnrl-afrcn-slct",
                accuracy: 5,
            },
            {
                conceptName: "Medieval Asia",
                concept: "gnrl-asia",
                cTest: "gnrl-asia-slct",
                accuracy: 5,
            },
            {
                conceptName: "Medieval Europe",
                concept: "gnrl-erp",
                cTest: "gnrl-erp-slct",
                accuracy: 5,
            },
            {
                conceptName: "Early Modern Europe",
                concept: "gnrl-mdrnrerp",
                cTest: "gnrl-mdrnrerp-slct",
                accuracy: 5,
            },
            {
                conceptName: "Early Americas",
                concept: "gnrl-erlamrc",
                cTest: "gnrl-erlamrc-slct",
                accuracy: 5,
            },
            {
                conceptName: "Age Of Exploration",
                concept: "gnrl-explrtn",
                cTest: "gnrl-explrtn-slct",
                accuracy: 5,
            },
            {
                conceptName: "Select American Geography",
                concept: "amrcgeo",
                cTest: "amrcgeo-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose American Geography",
                concept: "amrcgeo",
                cTest: "amrcgeo-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Asian Geography",
                concept: "asiageo",
                cTest: "asiageo-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Asian Geography",
                concept: "asiageo",
                cTest: "asiageo-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Oceania Geography",
                concept: "ocngeo",
                cTest: "ocngeo-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Oceania Geography",
                concept: "ocngeo",
                cTest: "ocngeo-chse-any",
                accuracy: 5,
            },
        ],
    },
]