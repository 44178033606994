import {
  Button,
  Grid,
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import Speech from "../../../util/speech";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    fontSize: 40,
    textAlign: "center",
  },
}));

const MultiLine = (props) => {
  const classes = useStyles({});
  const theme = useTheme();

  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentQuestionId, setCurrentQuestionId] = useState(undefined);
  const [isValid, setIsValid] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [disableAnswer, setDisableAnswer] = useState(false);
  const [displayAnswer, setDisplayAnswer] = useState("");
  const [answer, setAnswer] = useState([]);
  const [maxNumberLength, setMaxNumberLength] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [toastMessage, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    if (
      props.currentHomeWork &&
      props.currentHomeWork.questionId &&
      props.currentHomeWork.questionId !== currentQuestionId
    ) {
      setCurrentQuestionId(props.currentHomeWork.questionId);
      const maxNumberLength = Math.max
        .apply(null, props.currentHomeWork.varList)
        .toString().length;
      const data = [];
      const varListLength =
        props.currentHomeWork && props.currentHomeWork.varList
          ? props.currentHomeWork.varList.length
          : 0;
      props.currentHomeWork.varList.forEach((numeral, index) => {
        const digits = numeral.toString().split("");
        digits.reverse();
        if (digits.length !== maxNumberLength) {
          for (var i = 0; i < maxNumberLength - numeral.toString().length; i++)
            digits.push(null);
        }
        if (index === varListLength - 1)
          digits.push(getConceptSymbol(props.currentHomeWork.concept));
        else digits.push(null);
        digits.reverse();
        data.push(digits);
      });
      const answer = [];
      if (props.currentHomeWork.finalAnswer === "1") {
        const output = props.currentHomeWork.studentAnswer.split("");
        output.forEach((outputDigit) => answer.push(outputDigit));
      } else {
        const output = props.currentHomeWork.answer.split("");
        output.forEach((outputDigit) => answer.push(null));
      }

      setRowData(data);
      setMaxNumberLength(maxNumberLength);
      setAnswer(answer);
      setAttemptCount(0);
      setDisableAnswer(
        props.currentHomeWork.finalAnswer === "1" ? true : false
      );
      setDisplayAnswer(props.currentHomeWork.answer);
      setShowSpinner(false);
    }
  }, [props.currentHomeWork]);

  const isAnswerValid = () => {
    var isValid = false;
    console.log("VALIDATING ANSWER");

    if (disableAnswer) {
      props.goToNextQuestion();
    } else {
      const answerString = answer.join("");
      isValid = answerString && answerString.length > 0 ? true : false;
      if (isValid) {
        isAnswerCorrect();
      }
      else {
        setMessage("Invalid Answer!. Enter Answer.")
        setMessageType("warning")
        handleClick() 
      }
    }
  };

  const isAnswerCorrect = () => {
    var isCorrect = false;
    console.log("CHECKING ANSWER");
    const answerString = answer.join("");
    console.warn("answerString ", answerString);
    isCorrect =
      answerString &&
      answerString.length > 0 &&
      answerString === props.currentHomeWork.answer;
    if (isCorrect) {
      console.log("IS CORRECT, GO TO NEXT QUESTION");
      props.onSubmitQuestion(
        answerString,
        attemptCount + 1,
        true,
        props.currentHomeWork.questionId
      );
      setMessage("Correct Answer!. Good job.")
      setMessageType("success")
      handleClick()
    } else {
      console.log("WRONG ANSWER ");
      setAttemptCount(attemptCount + 1);

      if (attemptCount >= 2) {
        //props.onShowToast("Wrong Answer!. Next Question.", "danger");
        setMessage("Wrong Answer!. Try Next Question.")
        setMessageType("error")
        handleClick()
        props.onSubmitQuestion(
          answerString,
          attemptCount,
          false,
          props.currentHomeWork.questionId
        );
      } else {
        //props.onShowToast("Wrong Answer!. Try Again.", "warning");
        setMessage("Wrong Answer!. Try Again.")
        setMessageType("warning")
        handleClick()
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const clearAnswer = () => {
    const output = props.currentHomeWork.answer.split("");
    const blankAnswer = [];
    output.forEach((outputDigit) => blankAnswer.push(null));

    setAnswer(blankAnswer);
  };

  const getConceptSymbol = (concept) => {
    console.log("CAME HERE");
    switch (concept) {
      case "add":
        return "+";
      case "sub":
        return "-";
      case "mul":
        return "x";
      case "div":
        return "÷";
      default:
        return "+";
    }
  };

  const renderNumberLines = (var1, var2, type) => {
    const left = type === "add" ? var1 - 5 : var1 - var2 - 5;
    const leftRange = left > 0 ? left : 0;
    const rightRange = type === "add" ? var1 + var2 + 5 : var1 + 5;
    const numberLinesSize = rightRange - leftRange;
    const arr = new Array(numberLinesSize);
    const size = 30;
    const fontSize = 18;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: 50,
        }}
      >
        <div>
          <div
            style={{
              borderLeftWidth: 0,
              borderBottomWidth: 2,
              width: size,
              height: size,
            }}
          ></div>
          <div style={{ borderLeftWidth: 0, width: size, height: size }}></div>
          <div
            style={{ marginLeft: -8, fontSize: fontSize, fontWeight: "bold" }}
          ></div>
        </div>
        {Array.from(Array(numberLinesSize)).map((value, index) => {
          return (
            <div key={`number-line-${index}`}>
              <div
                style={{
                  borderLeftWidth: 2,
                  borderBottomWidth: 2,
                  width: size,
                  height: size,
                }}
              ></div>
              <div
                style={{ borderLeftWidth: 2, width: size, height: size }}
              ></div>
              <div
                style={{
                  marginLeft: -8,
                  fontSize: fontSize,
                  fontWeight: "bold",
                }}
              >
                {leftRange + index}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderRow = (rowData, index) => {
    console.log("PROPS ", props.currentHomeWork, "===", rowData);

    return (
      <div
        style={{ textAlign: "center", display: "flex" }}
        key={`row-${index}`}
      >
        {rowData.map((digit, i) => (
          <div
            style={{ fontSize: 60, width: 70, textAlign: "center" }}
            key={`row-${index}-item-${i}`}
          >
            {digit}
          </div>
        ))}
      </div>
    );
  };

  const onAnswerChange = (value, index, output) => {
    const currentAnswer = cloneDeep(answer);
    if (value && value.length <= 1) {
      currentAnswer[index] = value;

      if (index < maxNumberLength) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=input-${index + 1}]`
        );

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    } else currentAnswer[index] = "";
    setAnswer(currentAnswer);

    console.log("CUR STATE ", answer, currentAnswer, value, index, output);
  };

  const renderFooter = () => {
    const output = props.currentHomeWork.answer.split("");
    const answerLength = output ? output.length : 0;
    const displaySpace = (maxNumberLength + 1 - answerLength) * 70;
    // const Leftpadding = (output.length - maxNumberLength) * 70;
    console.log("displaySpace ", displaySpace);
    return (
      <div
        style={{
          paddingLeft: displaySpace,
          height: 70,
          display: "flex",
          flexDirection: "row",
        }}
      >
        {output.map((item, index) => (
          <div
            style={{
              //   borderStyle: "solid",
              //   borderColor: "black",
              //   borderWidth: 1,
              width: 70,
            }}
            key={`answer-input-${index}-${answer[index]}`}
          >
            <TextField
              name={`input-${index}`}
              value={answer && answer[index] ? answer[index] : ""}
              variant="outlined"
              //   value={answer[index] ? answer[index] : ""}
              style={{
                // borderStyle: "solid",
                // borderColor: "black",
                // borderWidth: 1,
                textAlign: "center",
                // fontSize: 50,
              }}
              inputProps={{
                maxLength: 1,
              }}
              InputProps={{
                classes: {
                  input: classes.input,
                },
              }}
              multiline={false}
              disabled={disableAnswer}
              onChange={(e) => {
                console.log("ON CHANGE ", e.target.value, "---", e);
                onAnswerChange(
                  e.target.value.replace(/[^0-9]/g, ""),
                  index,
                  output
                );
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <Grid
      container
      style={{
        padding: 25,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid container style={{ padding: 16 }}>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => clearAnswer()}
            style={{ marginRight: 16 }}
          >
            CLEAR
          </Button>
          <Button variant="outlined" color="primary" onClick={isAnswerValid}>
            {disableAnswer ? "NEXT" : "SUBMIT"}
          </Button>
          <div
            style={{ marginLeft: 16, display: "flex", alignItems: "center" }}
          >
            {disableAnswer
              ? ""
              : attemptCount > 0
              ? "Attempts Remaining: " +
                (props.currentHomeWork.numOfAttemptsAllowed - attemptCount)
              : "Attempts Remaining: " +
                props.currentHomeWork.numOfAttemptsAllowed}
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignSelf: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
          }}
        >
          {props.currentHomeWork && props.currentHomeWork.helpLink && <Button
            variant="outlined"
            color="primary"
            onClick={() => props.showHelp()}
            style={{ marginRight: 16 }}
          >
            HELP
          </Button>}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                Speech(props.currentHomeWork.voiceOver);
              }
            }}
          >
            READ OUT
          </Button>
        </Grid>
      </Grid>
      <div style={{ alignSelf: "center" }}>
        {/* ---------------------------- QUESTION div div - ITEM  1 ---------------------------- */}
        {/* <div>
          {
            // RENDERING NUMBER LINES
            (props.currentHomeWork.concept === "add" ||
              props.currentHomeWork.concept === "sub") &&
            props.currentHomeWork.varList[1] < 20 &&
            props.currentHomeWork.varList[0] +
              props.currentHomeWork.varList[1] <
              95
              ? renderNumberLines(
                  props.currentHomeWork.varList[0],
                  props.currentHomeWork.varList[1],
                  props.currentHomeWork.concept
                )
              : null
          }
        </div> */}
        <div>
          {rowData && rowData.map((row, index) => renderRow(row, index))}
        </div>
      </div>
      <div style={{ alignSelf: "center" }}>
        {/* ---------------------------- ANSWER div INPUT div ---------------------------- */}
        {renderFooter()}
      </div>
      <div
        style={{
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
        }}
      >
        {/* ------------- ANSWER IMAGE RIGHT OR WRONG */}
        {/* <div style={{ flexDirection: "row", alignSelf: "center" }}>
          {disableAnswer && props.currentHomeWork.isRightAnswer && (
            <CorrectImage />
          )}

          {disableAnswer && !props.currentHomeWork.isRightAnswer && (
            <WrongImage />
          )}
        </div> */}
        {
          // ------------------   CORRECT ANSWER
          disableAnswer && !props.currentHomeWork.isRightAnswer && (
            <div
              style={{
                alignSelf: "center",
                marginTop: 20,
                marginLeft: 50,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: 24 }}>Correct Answer :{"   "}</div>
              <div style={{ fontSize: 48 }}>{displayAnswer}</div>
            </div>
          )
        }
      </div>
      <Snackbar
       open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert 
        key={`toastMessage-${toastMessage}`}
        onClose={handleClose} 
        severity={messageType}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default MultiLine;
