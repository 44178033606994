import { configureStore } from "@reduxjs/toolkit";
import { sagaMiddleware } from "../sagas";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from ".";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["userReducer"],
};

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(persistConfig, rootReducer());

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: [sagaMiddleware],
});

export const persistor = persistStore(store);
