import {
  Button,
  Grid,
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CorrectImage from "../../../assets/images/green-tick.png";
import WrongImage from "../../../assets/images/red-cross.png";
import Speech from "../../../util/speech";
import Clock from "../../../util/Clock";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 0,
  },
  input: {
    padding: 6,
    fontSize: 24,
    textAlign: "center",
  },
}));

const Clk = (props) => {
  const classes = useStyles({});
  const theme = useTheme();

  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentQuestionId, setCurrentQuestionId] = useState(undefined);
  const [disableAnswer, setDisableAnswer] = useState(false);
  const [answer] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [open, setOpen] = useState(false);
  const [toastMessage, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    if (
      props.currentHomeWork &&
      props.currentHomeWork.questionId &&
      props.currentHomeWork.questionId !== currentQuestionId
    ) {
      setCurrentQuestionId(props.currentHomeWork.questionId);
      setAttemptCount(0);
      setDisableAnswer(
        props.currentHomeWork.finalAnswer === "1" ? true : false
      );
      setShowSpinner(false);
      if (
        props.currentHomeWork.finalAnswer === "1" &&
        props.currentHomeWork.studentAnswer
      ) {
        const ans = props.currentHomeWork.studentAnswer.split(":");
        setHours(ans[0]);
        setMinutes(ans[1]);
      } else {
        setHours("");
        setMinutes("");
      }
    }
  }, [props.currentHomeWork, currentQuestionId]);

  const isAnswerValid = () => {
    console.log("VALIDATING ANSWER");

    if (disableAnswer) {
      props.goToNextQuestion();
    } else {
      if (hours && minutes) isAnswerCorrect();
      else {
        //props.onShowToast("Enter Value", "warning");
        setMessage("Invalid Answer!. Enter Value.")
        setMessageType("warning")
        handleClick() 
      }
    }
  };

  const isAnswerCorrect = () => {
    var isCorrect = true;
    console.log("CHECKING ANSWER");
    var answerString = "";
    var studentAnswer = "";
    if (
      minutes === "00" ||
      minutes === "0" ||
      (props.currentHomeWork.varList && props.currentHomeWork.varList[1] === "0")
    ) {
      console.log("its 00 ");
      answerString = Number(hours).toString() + "00";
      studentAnswer = Number(hours) + ":" + "00";
    } else {
      answerString = Number(hours).toString() + Number(minutes).toString();
      studentAnswer = Number(hours) + ":" + Number(minutes);
    }
    console.log("STU ", answer, "  COR ");

    isCorrect =
      answerString &&
      answerString.length > 0 &&
      answerString === props.currentHomeWork.answer;

    if (isCorrect) {
      console.log("IS CORRECT, GO TO NEXT QUESTION");
      props.onSubmitQuestion(
        studentAnswer,
        attemptCount + 1,
        true,
        props.currentHomeWork.questionId
      );
      setMessage("Correct Answer!. Good job.")
      setMessageType("success")
      handleClick()
    } else {
      console.log("WRONG ANSWER ");
      setAttemptCount(attemptCount + 1);

      if (attemptCount >= 2) {
        //props.onShowToast("Wrong Answer!. Next Question.", "danger");
        setMessage("Wrong Answer!. Try Next Question.")
        setMessageType("error")
        handleClick()
        props.onSubmitQuestion(
          studentAnswer,
          attemptCount,
          false,
          props.currentHomeWork.questionId
        );
      } else {
        //props.onShowToast("Wrong Answer!. Try Again.", "warning");
        setMessage("Wrong Answer!. Try Again.")
        setMessageType("warning")
        handleClick()
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const clearAnswer = () => {
    setHours("");
    setMinutes("");
  };

  return (
    <Grid
      container
      style={{
        padding: 25,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid container style={{ padding: 16 }}>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => clearAnswer()}
            style={{ marginRight: 16 }}
            disabled={disableAnswer}
          >
            CLEAR
          </Button>
          <Button variant="outlined" color="primary" onClick={isAnswerValid}>
            {disableAnswer ? "NEXT" : "SUBMIT"}
          </Button>
          <div
            style={{ marginLeft: 16, display: "flex", alignItems: "center" }}
          >
            {attemptCount > 0 ? "Wrong Attempts: " + attemptCount : ""}
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignSelf: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
          }}
        >
          {props.currentHomeWork && props.currentHomeWork.helpLink && <Button
            variant="outlined"
            color="primary"
            onClick={() => props.showHelp()}
            style={{ marginRight: 16 }}
          >
            HELP
          </Button>}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                Speech(props.currentHomeWork.voiceOver);
              }
            }}
          >
            READ OUT
          </Button>
          </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ marginBottom: 15 }}>
          <div style={{ fontSize: 24, textAlign: "center" }}>
            {props.currentHomeWork.question}
          </div>
        </div>
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          {/* ---------------------------- QUESTION TEXT VIEW - ITEM  1 ---------------------------- */}

          <Clock
            title="Tokyo"
            datediff={9}
            minutes={props.currentHomeWork.varList[1]}
            hours={props.currentHomeWork.varList[0]}
            showHands={true}
          />
        </div>
        <div
          style={{
            flexDirection: "row",
            alignSelf: "center",
            display: "flex",
            alignItems: "center",
            marginTop: 24,
          }}
        >
          <TextField
            name={`hours-${props.currentHomeWork.questionId}`}
            value={hours}
            variant="outlined"
            //   value={answer[index] ? answer[index] : ""}
            style={{
              textAlign: "center",
            }}
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            multiline={false}
            disabled={disableAnswer}
            onChange={(e) => {
              console.log("ON hours CHANGE ", e.target.value, "---", e);
              setHours(e.target.value);
            }}
            placeholder="hrs"
          />
          <div
            style={{
              marginLeft: 16,
              marginRight: 16,
              fontSize: 24,
              fontWeight: "bolder",
            }}
          >
            :
          </div>
          <TextField
            name={`minutes-${props.currentHomeWork.questionId}`}
            value={minutes}
            variant="outlined"
            //   value={answer[index] ? answer[index] : ""}
            style={{
              textAlign: "center",
            }}
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            multiline={false}
            disabled={disableAnswer}
            onChange={(e) => {
              console.log("ON minutes CHANGE ", e.target.value, "---", e);
              setMinutes(e.target.value);
            }}
            placeholder="min"
          />
        </div>
        <div
          style={{
            flexDirection: "row",
            justifyContent: "center",
            display: "flex",
            marginTop: 20,
          }}
        >
          {/* ------------- ANSWER IMAGE RIGHT OR WRONG */}
          <div style={{ marginLeft: 20 }}>
            {disableAnswer && props.currentHomeWork.isRightAnswer && (
              <img alt="correct-answer" src={CorrectImage} />
            )}

            {disableAnswer && !props.currentHomeWork.isRightAnswer && (
              <img alt="wrong-answer" src={WrongImage} />
            )}
          </div>
          {
            // ------------------   CORRECT ANSWER
            disableAnswer && !props.currentHomeWork.isRightAnswer && (
              <div
                style={{
                  alignSelf: "center",
                  marginLeft: 50,
                  flexDirection: "row",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ fontSize: 18 }}>Correct Answer</div>
                <div style={{ fontSize: 32, marginLeft: 16 }}>
                  {`${props.currentHomeWork.varList[0]}  :  ${props.currentHomeWork.varList[1]}`}
                </div>
              </div>
            )
          }
        </div>
      </Grid>
      <Grid>
      <Snackbar
       open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert 
        key={`toastMessage-${toastMessage}`}
        onClose={handleClose} 
        severity={messageType}>
          {toastMessage}
        </Alert>
      </Snackbar>
      </Grid>
    </Grid>
  );
};

export default Clk;
