import {
    Button,
    Grid,
    makeStyles,
    useMediaQuery,
    useTheme,
  } from "@material-ui/core";
  import Snackbar from '@material-ui/core/Snackbar';
  import MuiAlert from '@material-ui/lab/Alert';
  import React, { useEffect, useState } from "react";
  import Speech from "../../../util/speech";

  const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
      fontSize: 40,
      textAlign: "center",
    },
  }));

  
  
  const Story = (props) => {
    const theme = useTheme();
  
    const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
    const [currentQuestionId, setCurrentQuestionId] = useState(undefined);
    const [disableAnswer, setDisableAnswer] = useState(false);
    const [displayAnswer, setDisplayAnswer] = useState("");
    const [answer, setAnswer] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const [attemptCount, setAttemptCount] = useState(0);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [studentAnswer, setStudentAnswer] = useState("");
    const [showHands, setShowHands] = useState(false);
    const [question, setQuestion] = useState("");
    const [open, setOpen] = useState(false);
    const [toastMessage, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const [actualAnswer, setActualAnswer] = useState([]);
    
    useEffect(() => {
      if (
        props.currentHomeWork &&
        props.currentHomeWork.questionId &&
        props.currentHomeWork.questionId !== currentQuestionId
        ) {
        setStudentAnswer(
          props.currentHomeWork.studentAnswer
            ? props.currentHomeWork.studentAnswer
            : ""
        );
        setCurrentQuestionId(props.currentHomeWork.questionId);
        setShowHands(true);
        setQuestion(props.currentHomeWork.firstVar);
        setAttemptCount(0);
        setAnswer(
          props.currentHomeWork.finalAnswer === "1"
            ? props.currentHomeWork.studentAnswer
            : ""
        );

        let studentAnswer = [];
      const currentHomeWorkQuestion = props.currentHomeWork.strAnsList;
      if (props.currentHomeWork.finalAnswer === "1") {
        const submittedAnswer = props.currentHomeWork.studentAnswer;
        studentAnswer = submittedAnswer;
        setAnswer(studentAnswer)
      } else {
        studentAnswer = currentHomeWorkQuestion.map((index) => {
          return "";
        });
        setAnswer(studentAnswer)
      }

        setStudentAnswer(studentAnswer);
      

        setDisableAnswer(
          props.currentHomeWork.finalAnswer === "1" ? true : false
        );
        setDisplayAnswer(
          props.currentHomeWork.finalAnswer === "1"
            ? props.currentHomeWork.strAnsList
            : ""
        );
        setActualAnswer(props.currentHomeWork.finalAnswer === "0" ? [] : props.currentHomeWork.strAnsList)
  
        setShowSpinner(false);
      }
    }, [props.currentHomeWork]);
  
    const isAnswerValid = () => {
      console.log("VALIDATING ANSWER...", answer);
      if (answer){
      isAnswerCorrect();
    }
      else{
        setMessage("Invalid Answer!. Select Option.")
        setMessageType("warning")
        handleClick() }
    };
  
    const isAnswerCorrect = () => {
      var isCorrect = false;
      console.log("CHECKING ANSWER", actualAnswer);
      const answerString = actualAnswer;
      console.log("CHECKING ANSWER", answerString.toString());
      console.log("answerString >>> ", (answerString.toString().replace(/[ ,.?]/g, "")).toLowerCase() === (props.currentHomeWork.answer.toString().replace(/[ ,.]/g, "")).toLowerCase(), " ***** ", (answerString.toString().replace(/[ ,.?]/g, "")).toLowerCase(), " @@@@@ ", (props.currentHomeWork.answer.toString().replace(/[ ,.]/g, "")).toLowerCase(), "###")
      console.warn("answerString ", answerString,props.currentHomeWork.answer,answerString === props.currentHomeWork.answer);
      
        isCorrect =
        answerString &&
        (answerString.toString().replace(/[ ,.?]/g, "")).toLowerCase() === (props.currentHomeWork.answer.toString().replace(/[ ,.]/g, "")).toLowerCase();
      
      if (isCorrect) {
        console.log("IS CORRECT, GO TO NEXT QUESTION");
        props.onSubmitQuestion(
          answerString.toString(),
          attemptCount + 1,
          true,
          props.currentHomeWork.questionId
        );
        setMessage("Correct Answer!. Good job.")
        setMessageType("success")
        handleClick()
      } else {
        console.log("WRONG ANSWER ");
        setAttemptCount(attemptCount + 1);
  
        if (attemptCount >= props.currentHomeWork.numOfAttemptsAllowed - 1) {
         // props.onShowToast("Wrong Answer!. Next Question.", "danger");
         setMessage("Wrong Answer!. Try Next Question.")
         setMessageType("error")
         handleClick()
  
          props.onSubmitQuestion(
            answerString,
            attemptCount,
            false,
            props.currentHomeWork.questionId
          );
        } else {
          // props.onShowToast("Wrong Answer!. Try Again.", "warning");
          setMessage("Wrong Answer!. Try Again.")
          setMessageType("warning")
          handleClick()
        }
      }
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
  
    const handleClick = () => {
      setOpen(true);
    };
  
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const onIdfyClick = (item, index) => {
        console.log("item .... ", item," index === ",index,actualAnswer)
        actualAnswer[index] = item
        answer[index] = item
        setAnswer({actualAnswer})
        console.log("actualAnswer   ----> ", actualAnswer,answer)
        
    };
  
    const rendorOptions = () => {
        return<div
        style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column" ,
        }}>
         {props.currentHomeWork.simpleStory.questions.map((quest, index) => {
            console.log(" %%%%%%%%% @@@@@@@@@ >>>> ",index)
            {
                console.log("Question ------- ", quest.question, index)
                console.log("Num of options ------- ", quest.question)
            }
            return <div style={{ marginLeft: 20, marginTop: 10, marginBottom: 10 }}>
                <div style={{ flexDirection: "column", fontSize: 25, textAlign: "left" }}>
                    {quest.question}
                </div>
                 <div style={{ marginTop: 20, marginBottom: 20, flexDirection: "column", alignSelf: "center" }}>
                    {quest.options.map((item,i) => {
                        console.log(" ############## =====> ",disableAnswer,item ,  props.currentHomeWork.strAnsList[index],item ===  props.currentHomeWork.strAnsList[index])
                        return  <div >
                              
                            <Button disabled={disableAnswer}
                                variant={disableAnswer ? "outlined" : "contained"}
                                color={
                                    disableAnswer
                                      ? (item ===  props.currentHomeWork.strAnsList[index]
                                        ? "default"
                                        : "secondary")
                                      : ( item === actualAnswer[index]
                                        ? "inherit"
                                        : "primary")
                                  }
                                    style={{
                                        marginTop: 10,
                                        width: 400,
                                        borderWidth: 2,
                                        borderRadius: 5,
                                        paddingVertical: 20,
                                        justifyContent: "center",
                                        marginLeft: 15,
                                        borderColor: "#F3BA1D",
                                    }} 
                                    
                                    onClick={() => {
                                        console.log(" ~~~~~~~~ on click ~~~~~~~~ ",item,index,i)
                                        onIdfyClick(item, index)}}
                                    >
                                    <div>{item}</div>
                                </Button>
                                </div>
                    })
                    }
                </div>


            </div>

        })
      }
        </div>
    };


   
  
    const clearAnswer = () => {
      setAnswer("");
      console.log("CLEAR ANSWER CALLED");
    };
  
    const addAnswerToState = (event) => {
      setAnswer(event.target.value);
    };
  
    const toggleHelpModal = (flag) => {
      setShowHelpModal(flag);
    };
  
    return (
      <Grid
        container
        style={{
          padding: 25,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Grid container style={{ padding: 16 }}>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: mobileDevice ? "column" : "row",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => clearAnswer()}
              style={{ marginRight: 16 }}
              disabled={disableAnswer}
            >
              CLEAR
            </Button>
            <Button variant="outlined" color="primary" onClick={isAnswerValid}>
              {disableAnswer ? "NEXT" : "SUBMIT"}
            </Button>
            <div
              style={{ marginLeft: 16, display: "flex", alignItems: "center" }}
            >
              {disableAnswer
                ? ""
                : attemptCount > 0
                ? "Attempts Remaining: " +
                  (props.currentHomeWork.numOfAttemptsAllowed - attemptCount)
                : "Attempts Remaining: " +
                  props.currentHomeWork.numOfAttemptsAllowed}
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignSelf: "flex-start",
              flexDirection: mobileDevice ? "column" : "row",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => props.showHelp()}
              style={{ marginRight: 16 }}
            >
              HELP
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                  Speech(props.currentHomeWork.voiceOver);
                }
              }}
            >
              READ OUT
            </Button>
            </Grid>
        </Grid>
        <Grid container style={{ flexDirection: "column" }}>
          <div style={{ fontSize: 30, margin: 10, alignSelf: "center" }}>
            {/* ---------------------------- QUESTION TEXT div - ITEM  1 ---------------------------- */}
            {props.currentHomeWork.question}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
              
            { props.currentHomeWork.secondVar &&
                <div style={{ width: 200, height: 200 ,alignSelf:'center'}} >
                    <img
                        /*source={{
                            uri: urlStringSample + item.toLowerCase() + '.png'
                        }}
                        */
                        src={props.currentHomeWork.secondVar.toLowerCase()}
                        style={{
                            flex: 1,
                            width: '70%',
                            height: '70%',
                            resizeMode: 'contain',
                            
                            //marginLeft: 280,
                        }}
                    />
                     
                </div>
            }        
            <div style={{ fontSize: 20, textAlign: "left" }}>{props.currentHomeWork.simpleStory.story}</div>
            <div>
            {rendorOptions()}
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {/* ------------- ANSWER IMAGE RIGHT OR WRONG */}
            {/* <div style={{ flexDirection: "row", alignSelf: "center" }}>
                  {disableAnswer && props.currentHomeWork.isRightAnswer && (
                    <CorrectImage />
                  )}
        
                  {disableAnswer && !props.currentHomeWork.isRightAnswer && (
                    <WrongImage />
                  )}
                </div> */}
            {
              // ------------------   CORRECT ANSWER
              disableAnswer &&(
                <div
                  style={{
                    alignSelf: "center",
                    marginTop: 20,
                    marginLeft: 50,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {disableAnswer && !props.currentHomeWork.isRightAnswer && 
                  <div>
                  <div style={{ fontSize: 24 }}>Correct Answer :{"   "}</div>
                  <div style={{ fontSize: 48 }}>{displayAnswer.toString()}</div>
                  </div>
                  }
                 <div style={{ fontSize: 24 }}>Your Answer :{"   "}</div>
                 <div style={{ fontSize: 48 }}>{props.currentHomeWork.studentAnswer.toString()}</div>
                 
                </div>
              )
            }
          </div>
        </Grid>
        <Grid>
        <Snackbar
         open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert 
          key={`toastMessage-${toastMessage}`}
          onClose={handleClose} 
          severity={messageType}>
            {toastMessage}
          </Alert>
        </Snackbar>
        </Grid>
      </Grid>
    );
  };
  
  export default Story;
  