import {
    Button,
    Grid,
    makeStyles,
    TextField,
    useMediaQuery,
    useTheme,
  } from "@material-ui/core";
  import React, { useEffect, useState } from "react";
  import { cloneDeep } from "lodash";
  import Speech from "../../../util/speech";
  import Snackbar from "@material-ui/core/Snackbar";
  import MuiAlert from "@material-ui/lab/Alert";
  
  const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
      fontSize: 40,
      textAlign: "center",
    },
  }));
  
  const Complete = (props) => {
    const classes = useStyles({});
    const theme = useTheme();
  
    const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
    const [currentQuestionId, setCurrentQuestionId] = useState(undefined);
    const [disableAnswer, setDisableAnswer] = useState(false);
    const [displayAnswer, setDisplayAnswer] = useState("");
    const [answer, setAnswer] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const [attemptCount, setAttemptCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [toastMessage, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const [studentAnswer] = useState([]);
   
    useEffect(() => {
      if (
        props.currentHomeWork &&
        props.currentHomeWork.questionId &&
        props.currentHomeWork.questionId !== currentQuestionId
      ) {
        setCurrentQuestionId(props.currentHomeWork.questionId);
        setAnswer(
          props.currentHomeWork.finalAnswer === "1"
            ? props.currentHomeWork.studentAnswer
            : []
        );
        setAttemptCount(0);
        setDisableAnswer(
          props.currentHomeWork.finalAnswer === "1" ? true : false
        );
        setDisplayAnswer(props.currentHomeWork.answer);
        setShowSpinner(false);
      }
    }, [props.currentHomeWork]);
  
    const isAnswerValid = () => {
      var isValid = false;
      console.log("VALIDATING ANSWER", answer);
  
      if (disableAnswer) {
        props.goToNextQuestion();
      } else {
        const answerString = answer;
        isValid = answerString && answerString.length > 0 ? true : false;
        if (isValid) {
          isAnswerCorrect();
        } else {
          setMessage("Invalid Answer!. Enter Value.");
          setMessageType("warning");
          handleClick();
        }
      }
    };
  
    const isAnswerCorrect = () => {
      var isCorrect = false;
      console.log("CHECKING ANSWER",answer.toString().split(",").join(""),props.currentHomeWork.answer);
      var answerString = answer.toString().split(",").join("");
     
        isCorrect =
          answerString &&
          answerString.length > 0 &&
          answerString === props.currentHomeWork.answer;
      
  
      if (isCorrect) {
        console.log("IS CORRECT, GO TO NEXT QUESTION");
        props.onSubmitQuestion(
          answerString,
          attemptCount + 1,
          true,
          props.currentHomeWork.questionId
        );
        setMessage("Correct Answer!. Good job.");
        setMessageType("success");
        handleClick();
      } else {
        console.log("WRONG ANSWER ");
        setAttemptCount(attemptCount + 1);
  
        if (attemptCount >= 2) {
          // props.onShowToast("Wrong Answer!. Next Question.", "danger");
          setMessage("Wrong Answer!. Try Next Question.");
          setMessageType("error");
          handleClick();
          props.onSubmitQuestion(
            answerString,
            attemptCount,
            false,
            props.currentHomeWork.questionId
          );
        } else {
          // props.onShowToast("Wrong Answer!. Try Again.", "warning");
          setMessage("Wrong Answer!. Try Again.");
          setMessageType("warning");
          handleClick();
        }
      }
    };
  
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setOpen(false);
    };
  
    const handleClick = () => {
      setOpen(true);
    };
  
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
  
    const clearAnswer = () => {
      
        setAnswer("");
      
    };

    const onAnswerChange = (value,index) => {
      console.log("onAnswerChange ===> ", value,index);
      const studentAnswer = cloneDeep(props.currentHomeWork.strList);
      // setStudentAnswer(cloneDeep(props.currentHomeWork.strList))
      studentAnswer[index] = (value);
      setAnswer(studentAnswer);
      console.log("answer === ",answer,studentAnswer)

    }

    const renderRow = () => {
      console.log("answer.slice ===> ",answer)
       
        return <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: mobileDevice ? "column" : "row",
                }}>
          { props.currentHomeWork.strAnsList && props.currentHomeWork.strAnsList.map((letter, index) => {
            console.log("answer.slice ===> ",answer[index],letter,studentAnswer[index],letter[index] === answer[index])
            if ( letter !== "_") 
            return <div key={answer[index]} style={{ width: 90 }} >
                    <div  style={{ fontSize: 40, textAlign: 'center',margin: 5 }}>{letter}</div>
                </div>
            else return <TextField
            value= {answer[index]}
            variant="standard"
            style={{
              width: 90,
              textAlign: "center",
              margin: 5
            }}
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            //multiline={false}
            disabled={disableAnswer}
            onChange={(e) => {
              console.log("ON CHANGE ", e.target.value, "---", index);
              onAnswerChange(
                e.target.value,
                index
              );
            }}
          /> 
        })}</div>
    }
  
  
    return (
      <Grid
        container
        style={{
          padding: 25,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Grid container style={{ padding: 16 }}>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: mobileDevice ? "column" : "row",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => clearAnswer()}
              style={{ marginRight: 16 }}
            >
              CLEAR
            </Button>
            <Button variant="outlined" color="primary" onClick={isAnswerValid}>
              {disableAnswer ? "NEXT" : "SUBMIT"}
            </Button>
            <div
              style={{ marginLeft: 16, display: "flex", alignItems: "center" }}
            >
              {disableAnswer
                ? ""
                : attemptCount > 0
                ? "Attempts Remaining: " +
                  (props.currentHomeWork.numOfAttemptsAllowed - attemptCount)
                : "Attempts Remaining: " +
                  props.currentHomeWork.numOfAttemptsAllowed}
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignSelf: "flex-start",
              flexDirection: mobileDevice ? "column" : "row",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => props.showHelp()}
              style={{ marginRight: 16 }}
            >
              HELP
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                  Speech(props.currentHomeWork.voiceOver);
                }
              }}
            >
              READ OUT
            </Button>
          </Grid>
        </Grid>
        <Grid container style={{ flexDirection: "column" }}>
          <div 
          style={{ marginLeft: 40, alignSelf: "center" }}>
            {/* ---------------------------- QUESTION div div - ITEM  1 ---------------------------- */}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                  Speech(props.currentHomeWork.voiceOver);
                }
              }}
            >
              Listen
            </Button>
            <div>
            
              <div
                style={{
                  //alignSelf: "center",
                  //marginTop: 20,
                  //marginLeft: 50,
                  flexDirection: "row",
                  // alignItems: "center",
                  alignContent: "center",
                  fontSize: 40,
                  marginBottom: 20,
                }}
              >
                { props.currentHomeWork.question }
                
              </div>
              {renderRow()}
              
          </div>
          </div>
          <div style={{ alignSelf: "center" }}>
            {/* ---------------------------- ANSWER div INPUT div ---------------------------- */}
          </div>
          <div
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {/* ------------- ANSWER IMAGE RIGHT OR WRONG */}
            {/* <div style={{ flexDirection: "row", alignSelf: "center" }}>
            {disableAnswer && props.currentHomeWork.isRightAnswer && (
              <CorrectImage />
            )}
  
            {disableAnswer && !props.currentHomeWork.isRightAnswer && (
              <WrongImage />
            )}
          </div> */}
            {
              // ------------------   CORRECT ANSWER
              disableAnswer && !props.currentHomeWork.isRightAnswer && (
                <div
                  style={{
                    alignSelf: "center",
                    marginTop: 20,
                    marginLeft: 50,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontSize: 24 }}>Correct Answer :{"   "}</div>
                  <div style={{ fontSize: 48 }}>{displayAnswer}</div>
                </div>
              )
            }
          </div>
        </Grid>
        <Grid>
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert
              key={`toastMessage-${toastMessage}`}
              onClose={handleClose}
              severity={messageType}
            >
              {toastMessage}
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    );
  };
  
  export default Complete;
  