import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Dialog,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import Speech from "../../../util/speech";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    fontSize: 40,
    textAlign: "center",
  },
}));

const SelectSentence = (props) => {
  const classes = useStyles({});
  const theme = useTheme();

  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentQuestionId, setCurrentQuestionId] = useState(undefined);
  const [disableAnswer, setDisableAnswer] = useState(false);
  const [displayAnswer, setDisplayAnswer] = useState("");
  const [answer, setAnswer] = useState("");
  const [maxNumberLength, setMaxNumberLength] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [studentAnswer, setStudentAnswer] = useState("");
  const [showHands, setShowHands] = useState(false);
  const [question, setQuestion] = useState("");
  const [selectedList, setSelectedList] = useState([]);
  const [showHelp, setShowHelp] = useState(false);
  const [open, setOpen] = useState(false);
  const [toastMessage, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    if (
      props.currentHomeWork &&
      props.currentHomeWork.questionId &&
      props.currentHomeWork.questionId !== currentQuestionId
    ) {
      setStudentAnswer(
        props.currentHomeWork.studentAnswer
          ? props.currentHomeWork.studentAnswer
          : ""
      );
      setCurrentQuestionId(props.currentHomeWork.questionId);
      setShowHands(true);
      setQuestion(props.currentHomeWork.firstVar);
      setAttemptCount(0);
      setAnswer(
        props.currentHomeWork.finalAnswer === "1"
          ? props.currentHomeWork.studentAnswer
          : ""
      );
      setDisableAnswer(
        props.currentHomeWork.finalAnswer === "1" ? true : false
      );
      setDisplayAnswer(
        props.currentHomeWork.finalAnswer === "1"
          ? props.currentHomeWork.answer
          : ""
      );

      setShowSpinner(false);
      const l = [];
      props.currentHomeWork.strList &&
        props.currentHomeWork.strList
          .toString()
          .split(" ")
          .map((o) => {
            l.push(0);
          });
      setSelectedList(l);
      console.log(" ################### ", selectedList, l);
    }
  }, [props.currentHomeWork]);

  const isAnswerValid = () => {
    var isValid = false;
    console.log("VALIDATING ANSWER...", answer);
    if (answer){
       isAnswerCorrect();
      }
    else{
        setMessage("Invalid Answer!. Enter Value.")
        setMessageType("warning")
        handleClick() 
    }
  };

  const isAnswerCorrect = () => {
    var isCorrect = false;
    console.log("CHECKING ANSWER", answer, props.currentHomeWork.answer);
    var answerString = answer.filter((item) => {
      return item !== 0;
    });
    console.warn("answerString ", answerString);
    var ans = answerString.toString().replace(/[ ,.?]/g, "");
    console.log(
      "answerString >>> ",
      ans.toLowerCase() ===
        props.currentHomeWork.answer
          .toString()
          .replace(/[ ,.]/g, "")
          .toLowerCase(),
      ans,
      props.currentHomeWork.answer
        .toString()
        .replace(/[ ,.]/g, "")
        .toLowerCase()
    );

    isCorrect =
      answerString &&
      answerString.length > 0 &&
      ans.toLowerCase() ===
        props.currentHomeWork.answer
          .toString()
          .replace(/[ ,.]/g, "")
          .toLowerCase();
    if (isCorrect) {
      console.log("IS CORRECT, GO TO NEXT QUESTION");
      props.onSubmitQuestion(
        answerString.toString(),
        attemptCount + 1,
        true,
        props.currentHomeWork.questionId
      );
      setMessage("Correct Answer!. Good job.")
      setMessageType("success")
      handleClick()
    } else {
      console.log("WRONG ANSWER ", answerString);
      setAttemptCount(attemptCount + 1);

      if (attemptCount >= props.currentHomeWork.numOfAttemptsAllowed - 1) {
        // props.onShowToast("Wrong Answer!. Next Question.", "danger");
        setMessage("Wrong Answer!. Try Next Question.")
        setMessageType("error")
        handleClick()
        props.onSubmitQuestion(
          answerString.toString(),
          attemptCount,
          false,
          props.currentHomeWork.questionId
        );
      } else {
        // props.onShowToast("Wrong Answer!. Try Again.", "warning");
        setMessage("Wrong Answer!. Try Again.")
        setMessageType("warning")
        handleClick()
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const onSelection = (val, index) => {
    var selList = cloneDeep(selectedList);
    console.log(
      "onSelection ===> ",
      val,
      index,
      " =*=*=*= ",
      selList,
      selectedList
    );
    if (selList[index] === 0) {
      selList[index] = val;
      setSelectedList(selList);
    } else {
      selList[index] = 0;
      setSelectedList(selList);
    }

    setAnswer(selList);
    console.log(
      "this.state.selectedList *******>> ",
      selList,
      selectedList,
      " --- answer ---- ",
      answer
    );
  };

  const renderItem = () => {
    console.log(
      "props.currentHomeWork.strList === ",
      props.currentHomeWork.strList
    );

    return (
      <div style={{ display: "inline-flex", flexDirection: "row" }}>
        {props.currentHomeWork.strList
          .toString()
          .split(" ")
          .map((item, index) => {
            return (
              <div key={`selectedList-${index}-${selectedList[index]}`}>
                <Button
                  //variant={disableAnswer ? "outlined" : ""}
                  disabled={disableAnswer}
                  color={
                    disableAnswer
                      ? item === props.currentHomeWork.answer
                        ? "default"
                        : "grey"
                      : selectedList[index] !== 0
                      ? "inherit"
                      : "primary"
                  }
                  style={{
                    width:
                      props.currentHomeWork.concept === "ltrs" ||
                      props.currentHomeWork.concept === "chse"
                        ? 60
                        : "",
                    borderWidth: 2,
                    borderRadius: 5,
                    paddingVertical: 20,
                    justifyContent: "center",
                    //margin: 5,
                    textTransform: "none",
                    fontSize: 20,
                  }}
                  onClick={() => onSelection(item, index)}
                >
                  {item}
                </Button>
              </div>
            );
          })}
      </div>
    );
  };

  const clearAnswer = () => {
    setAnswer("");
    console.log("CLEAR ANSWER CALLED");
  };

  const addAnswerToState = (event) => {
    setAnswer(event.target.value);
  };

  const toggleHelpModal = (flag) => {
    setShowHelpModal(flag);
  };

  return (
    <Grid
      container
      style={{
        padding: 25,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid container style={{ padding: 16 }}>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => clearAnswer()}
            style={{ marginRight: 16 }}
            disabled={disableAnswer}
          >
            CLEAR
          </Button>
          <Button variant="outlined" color="primary" onClick={isAnswerValid}>
            {disableAnswer ? "NEXT" : "SUBMIT"}
          </Button>
          <div
            style={{ marginLeft: 16, display: "flex", alignItems: "center" }}
          >
            {disableAnswer
              ? ""
              : attemptCount > 0
              ? "Attempts Remaining: " +
                (props.currentHomeWork.numOfAttemptsAllowed - attemptCount)
              : "Attempts Remaining: " +
                props.currentHomeWork.numOfAttemptsAllowed}
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignSelf: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowHelp(true)}
            style={{ marginRight: 16 }}
          >
            HELP
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                Speech(props.currentHomeWork.voiceOver);
              }
            }}
          >
            READ OUT
          </Button>
          </Grid>
      </Grid>
      <Grid container style={{ flexDirection: "column" }}>
        {props.currentHomeWork.concept === "ltrs" ? (
          <div style={{ fontSize: 30, margin: 10, alignSelf: "center" }}>
            <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                Speech(props.currentHomeWork.voiceOver);
              }
            }}
          >
            READ OUT
          </Button>
          </div>
        ) : (
          <div style={{ fontSize: 30, margin: 10, alignSelf: "center" }}>
            {/* ---------------------------- QUESTION TEXT VIEW - ITEM  1 ---------------------------- */}
            {props.currentHomeWork.question}
          </div>
        )}

                                    { props.currentHomeWork.secondVar &&
                                        <div style={{ width: 200, height: 200 ,alignSelf:'center'}} >
                                            <img
                                                /*source={{
                                                    uri: urlStringSample + item.toLowerCase() + '.png'
                                                }}
                                                */
                                                src={props.currentHomeWork.secondVar}
                                                style={{
                                                    flex: 1,
                                                    width: '100%',
                                                    height: '100%',
                                                    resizeMode: 'contain',
                                                    
                                                    //marginLeft: 280,
                                                }}
                                            />
                                        </div>
                                    }
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 25, display: "flex", flexDirection: "row" }}>
            {/* ---------------------------- QUESTION TEXT VIEW - ITEM  1 ---------------------------- */}
            {/* {props.currentHomeWork.varList} */}
          </div>
          {props.currentHomeWork.qType !== "hmny" ? (
            renderItem()
          ) : (
            <TextField
              variant="outlined"
              style={{
                fontSize: 30,
                width: 250,
                textAlign: "center",
                borderColor: "green",
                borderWidth: 2,
                color: "black",
                marginTop: 20,
              }}
              value={answer}
              InputProps={{
                classes: {
                  input: classes.input,
                },
              }}
              multiline={false}
              disabled={disableAnswer}
              onChange={addAnswerToState}
            ></TextField>
          )}
        </div>
        <div
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {/* ------------- ANSWER IMAGE RIGHT OR WRONG */}
          {/* <div style={{ flexDirection: "row", alignSelf: "center" }}>
                  {disableAnswer && props.currentHomeWork.isRightAnswer && (
                    <CorrectImage />
                  )}
        
                  {disableAnswer && !props.currentHomeWork.isRightAnswer && (
                    <WrongImage />
                  )}
                </div> */}
          {
            // ------------------   CORRECT ANSWER
            disableAnswer && (
              <div
                style={{
                  alignSelf: "center",
                  marginTop: 20,
                  marginLeft: 50,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ fontSize: 20 }}>Correct Answer :{"   "}</div>
                <div style={{ fontSize: 30 }}>{displayAnswer}</div>
                {!props.currentHomeWork.isRightAnswer && (
                  <div>
                    <div style={{ fontSize: 20 }}>Your Answer :{"   "}</div>
                    <div style={{ fontSize: 30 }}>{studentAnswer}</div>
                  </div>
                )}
              </div>
            )
          }
        </div>
        <Dialog
          onClose={() => setShowHelp(false)}
          open={showHelp}
          maxWidth={false}
        >
          {props.currentHomeWork.concept === "sylb" && (
            <div style={{ padding: 16 }}>
              <Typography variant="h4">Help</Typography>
              <div>Help text sylb ::</div>
            </div>
          )}
          {props.currentHomeWork.concept === "ltrs" && (
            <div style={{ padding: 16 }}>
              <Typography variant="h4">Help</Typography>
              <div>Select the letter mentioned.</div>
            </div>
          )}
          {props.currentHomeWork.concept === "chse" &&
            (props.currentHomeWork.qType === "uppr" ||
              props.currentHomeWork.qType === "lowr") && (
              <div style={{ padding: 16 }}>
                <Typography variant="h4">Help</Typography>
                <div>
                  Select the upper case or lower case letter as mentioned.
                </div>
              </div>
            )}
          {props.currentHomeWork.concept === "chse" &&
            props.currentHomeWork.qType === "silt" && (
              <div style={{ padding: 16 }}>
                <Typography variant="h4">Help</Typography>
                <div>
                  A silent letter in a word is one that is written but not
                  pronounced, such as the "b" in "doubt".
                  <div>Example:</div>
                  <div>- Silent 'e' : Dove, Above, Cove, Give</div>
                  <div>- Silent 'b' : comb, climb, debt, plumber, numb</div>
                  <div>
                    - Silent 'n' : autumn, chimney, column, government, solemn
                  </div>
                </div>
              </div>
            )}
          {props.currentHomeWork.concept === "chse" &&
            (props.currentHomeWork.qType === "pic" ||
              props.currentHomeWork.qType === "rhy") && (
              <div style={{ padding: 16 }}>
                <Typography variant="h4">Help</Typography>
                <div>Choose Rhyming Word</div>
                <div>
                  Rhyming words are two or more words that have the same or
                  similar ending sound.
                </div>
                <div>Examples:</div>
                <div>goat, boat, coat, cat, sat, bat</div>
              </div>
            )}
          {props.currentHomeWork.concept === "chse" &&
            (props.currentHomeWork.qType === "prfx" ||
              props.currentHomeWork.qType === "sfx") && (
              <div style={{ padding: 16 }}>
                <Typography variant="h4">Help</Typography>
                <div>Prefix and Suffix</div>
                <div>
                  A prefix is a group of letters that's added to the beginning
                  of a word.
                </div>
                <div>
                  A suffix is a group of letters that's added to the end of a
                  word.
                </div>
                <div>Examples:</div>
                <div>Prefix - dis-, in-, un-, re-</div>
                <div>Suffix - -ed, -ing, -ly</div>
              </div>
            )}
          {props.currentHomeWork.concept === "ant" && (
            <div style={{ padding: 16 }}>
              <Typography variant="h4">Help</Typography>
              <div>
                An antonym is a word that means the opposite of another word.
              </div>
              <div>Examples:</div>
              <div>agree - disagree</div>
              <div>above - below</div>
              <div>fast - slow</div>
              <div>tolerant - intolerant</div>
              <div>fortunate - unfortunate</div>
            </div>
          )}
          {props.currentHomeWork.concept === "syn" && (
            <div style={{ padding: 16 }}>
              <Typography variant="h4">Help</Typography>
              <div>
                A synonym is a word that has the same or nearly the same meaning
                as another word.
              </div>
              <div>Examples:</div>
              <div>Bad: awful, terrible, horrible</div>
              <div>Good: fine, excellent, great</div>
              <div>Hot: burning, fiery, boiling</div>
              <div>Easy: Simple, effortless, straightforward</div>
              <div>Hard: difficult, challenging, tough</div>
            </div>
          )}
          {props.currentHomeWork.concept === "nou" && (
            <div style={{ padding: 16 }}>
              <Typography variant="h4">Help</Typography>
              <div>
                Noun refers to words that are used to name person, things,
                animals, places, ideas or events.
              </div>
              <div>
                Example words: dog, house, London, work, music, town, teacher,
                John.
              </div>
              <div>Example sentences:</div>
              <div>This is my dog.</div>
              <div> He lives in my house.</div>
              <div>We live in London.</div>
            </div>
          )}
          {props.currentHomeWork.concept === "verb" && (
            <div style={{ padding: 16 }}>
              <Typography variant="h4">Help</Typography>
              <div>
                Verbs express action or a state of being. Verbs are often called
                "action verbs" but non-action verbs can be used to represent a
                state of being, need, sense, or preference.
              </div>
              <div>
                Example words: (to) be, have, do, like, work, sing, can, must
              </div>
              <div>Example sentences:</div>
              <div>Let's walk to the park.</div>
              <div>Janine lives in Minneapolis.</div>
              <div>The air conditioning unit desperately needs repair.</div>
            </div>
          )}
          {props.currentHomeWork.concept === "pron" && (
            <div style={{ padding: 16 }}>
              <Typography variant="h4">Help</Typography>
              <div>
                Pronouns take the place of nouns to simplify speech and writing.
                They can otherwise function in the same way as nouns, serving as
                subjects and objects in sentences.
              </div>
              <div>Example words: I, you, he, she, some</div>
              <div>Example sentences:</div>
              <div>Tara is Indian. She is smart.</div>
              <div>George took the book from him.</div>
            </div>
          )}
          {props.currentHomeWork.concept === "prep" && (
            <div style={{ padding: 16 }}>
              <Typography variant="h4">Help</Typography>
              <div>
                Preposition is a word governing, and usually preceding, a noun
                or pronoun and expressing a relation to another word or element
                in the clause
              </div>
              <div>
                Example words: above, across, among, at, before, below, beside,
                between, by, down, in, near, of, on, to, toward, upon and within
              </div>
              <div>Example sentences:</div>
              <div>The man on the platform.</div>
              <div>She arrived after dinner.</div>
            </div>
          )}
          {props.currentHomeWork.concept === "sylb" && (
            <div style={{ padding: 16 }}>
              <Typography variant="h4">Help</Typography>
              <div>
                A syllable is one unit of sound in English. Syllables join
                consonants and vowels to form words.
              </div>
              <div>
                Syllables can have more than one letter; however, it cannot have
                more than one sound.
              </div>
              <div>
                Syllables can have more than one consonant and more than one
                vowel, as well. However, the consonant(s) and vowel(s) that
                create the syllable cannot make more than one sound.
              </div>
              <div>A syllable is only one sound.</div>

              <div>Example: </div>
              <div>
                {" "}
                * long - This word has one syllable. There is only one vowel
                sound, created by the “o.”
              </div>
              <div>
                {" "}
                * shame - This word has one syllable. Even though there are two
                vowels, only one vowel makes a sound. The long “a” sound is the
                vowel sound; the “e” is a silent “e.”
              </div>
              <div>
                {" "}
                * silent - This word has two vowels sounds; therefore it has two
                syllables. The first syllable is “si” with the long “i” sound.
                The second syllable includes the letters “lent.”
              </div>
            </div>
          )}
        </Dialog>
      </Grid>
      <Grid>
      <Snackbar
       open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert 
        key={`toastMessage-${toastMessage}`}
        onClose={handleClose} 
        severity={messageType}>
          {toastMessage}
        </Alert>
      </Snackbar>
      </Grid>
    </Grid>
  );
};

export default SelectSentence;
