import {
 Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as parentActions from "../../../actions/parent.actions";
import {
  getChildList
} from "../../../reducer/selectors/parent.entity";
import { useHistory } from "react-router-dom";

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import FeaturedPost from './../Common/featuredpost'

const tiers = [
  {
    title: 'Pre-K',
    // price: '30',
    // subheader: 'Most popular',
    description: [
      'Math',
      'Reading',
    ],
    buttonText: 'Start Practice',
    buttonVariant: 'outlined',
    practiceChildId:'5ffe679b7527784b8a6669a1',
  },
  {
    title: 'Kindergarten',
    // price: '30',
    // subheader: 'Most popular',
    description: [
      'Math',
      'Reading',
    ],
    buttonText: 'Start Practice',
    buttonVariant: 'outlined',
    practiceChildId:'6026dc8a111d1d1f01896743',
  },
  {
    title: 'First Grade',
    // subheader: 'Most popular',
    // price: '0',
    description: [
      'Math',
      'Reading',
    ],
    buttonText: 'Start Practice',
    buttonVariant: 'outlined',
    practiceChildId:'603effe253e61204f697d449',
  },
  {
    title: 'Second Grade',
    // subheader: 'Most popular',
    // price: '15',
    description: [
      'Math',
      'Reading',
    ],
      buttonText: 'Start Practice',
    buttonVariant: 'outlined',
    practiceChildId:'60a816f5fe6e8c08973c6f43',
  },
  {
    title: 'Third Grade',
    // price: '30',
    // subheader: 'Most popular',
    description: [
      'Math',
      'Reading',
    ],
    buttonText: 'Start Practice',
    buttonVariant: 'outlined',
    practiceChildId:'60a5038ffe6e8c08973c6f41',
  },
  {
    title: 'Fourth Grade',
    // price: '30',
    // subheader: 'Most popular',
    description: [
      'Math',
      'Reading',
    ],
    buttonText: 'Start Practice',
    buttonVariant: 'outlined',
    practiceChildId:'5fd818f4efcdd3200584db52',
  },
  {
    title: 'Fifth Grade',
    // price: '30',
    // subheader: 'Most popular',
    description: [
      'Math',
      'Reading',
    ],
    buttonText: 'Start Practice',
    buttonVariant: 'outlined',
    practiceChildId:'603e819853e61204f697d447',
  },
  {
    title: 'Sixth Grade',
    // price: '30',
    // subheader: 'Most popular',
    description: [
      'Math',
      'Reading',
    ],
    buttonText: 'Start Practice',
    buttonVariant: 'outlined',
    practiceChildId:'60cab2417feafd38ad17e777',
  },
];

const featuredPosts = [
  {
    title: 'Pre-K',
    curriculamList:[
        {
            courseName:'Math',
            curriculam:["Understanding Numbers","Counting pictures","Blocks","positions","Numbers"],  
        },
        {
          courseName:'Reading',
          curriculam:["Letter case","Basic Sight Words","Choose the Synonyms","Choose the Antonyms","Syllables","Rhyming Words","Beginning words sound","Ending words sound","Short vowels","Long vowels","Nouns","Find words in Sentences"],  
      }
    ],
    date: 'Nov 12',
  description:
    'This is a wider card with supporting text below as a natural lead-in to additional content.',
  image: 'https://rabbitboard.s3.amazonaws.com/appimages/web/mathsprek.png',
  imageText: 'Image Text',      
  },
  {
    title: 'Kindergarten',
    curriculamList:[
      {
          courseName:'Math',
          curriculam:["Numbers","Addition","Adding loop","Blocks","Picture Position","Comparing Pictures","Counting blocks","Shapes"],  
      },
      {
        courseName:'Reading',
        curriculam:["Letter case","Basic Sight Words","Choose the Synonyms","Choose the Antonyms","Syllables","Rhyming Words","Beginning words sound","Ending words sound","Short vowels","Long vowels","Nouns","Find words in Sentences"],  
    }
  ],
  date: 'Nov 12',
  description:
    'This is a wider card with supporting text below as a natural lead-in to additional content.',
  image: 'https://rabbitboard.s3.amazonaws.com/appimages/web/shapes.png',
  imageText: 'Image Text',
  },
  {
      title: 'First Grade',
      curriculamList:[
          {
              courseName:'Math',
              curriculam:["Addition","Subtraction","Even/odd","Compare","Place values","Blocks","Clock,Money","Shapes"],  
          },
          {
            courseName:'Reading',
            curriculam:["Letter case","Basic Sight Words","Choose the Synonyms","Choose the Antonyms","Syllables","Rhyming Words","Beginning words sound","Ending words sound","Short vowels","Long vowels","Nouns","Verbs","Reading Sentences","Find words in Sentences"],  
        },
      ],
      date: 'Nov 12',
  description:
    'This is a wider card with supporting text below as a natural lead-in to additional content.',
  image: 'https://rabbitboard.s3.amazonaws.com/appimages/web/mathsymbols.png',
  imageText: 'Image Text',
    },
    {
      title: 'Second Grade',
      curriculamList:[
          {
              courseName:'Math',
              curriculam:["Addition","Subtraction","Addition sentences","Subtraction sentences","Addition-Rule","Subtraction-Rule","Mixoperation","Place values","Even/odd"],  
          },
          {
            courseName:'Reading',
            curriculam:["Simple sentences","Syllables","Sight Words","Rhyming Words","Silent E words","Non Silent E words","Short Vowels","Long vowels","R controlled words","Soft G sentences","Non soft G sentences","Soft C sentences","Non Soft C sentences","Non soft C words","Non Soft G words","Soft C words","Soft G words","Reading Comprehension","Synonyms","Antonyms"],  
        }
      ],
      date: 'Nov 12',
  description:
    'This is a wider card with supporting text below as a natural lead-in to additional content.',
  image: 'https://rabbitboard.s3.amazonaws.com/appimages/web/abacus.png',
  imageText: 'Image Text',
    },
    {
      title: 'Third Grade',
      curriculamList:[
          {
              courseName:'Math',
              curriculam:["Addition","Subtraction","Mixed operation","Multiplication tables","Multiplication","Time","Shape","Fraction","Place values-convert","Standard form","Round off nearest 10","Equation","Shape","Even/odd","Time conversion","Decimal add -money","Add&sub sentences"],  
          },
          {
            courseName:'Reading',
            curriculam:[
              "Simple sentences","Abbreviations","Synonyms","Antonyms","Prefix","Suffix",
            "Groupwords","Nouns","Pronouns","Homophones","Capitalization Writing",
            "Identify naming words from the given sentence","Identify Title words",
            "Identify Street/Address/Cities/States/Countries","Identify days/Week/Month/Holidays",
            "Proper Nouns","Compound words","Story Comprehension"
          ],  
        }
      ],
      date: 'Nov 12',
  description:
    'This is a wider card with supporting text below as a natural lead-in to additional content.',
  image: 'https://rabbitboard.s3.amazonaws.com/appimages/web/maths2.png',
  imageText: 'Image Text',
    },
    {
      title: 'Fourth Grade',
      curriculamList:[
          {
              courseName:'Math',
              curriculam:["Addition","Subtraction","Writing words to numbers","Writing numbers to words","Standard Form","Expanded form","Tables","Multiplication","Time conversion","Equation","Division","Roman Numerals","Factors","Fraction","Rounding off","Comparing Equation","GCD","LCM","Decimal add and sub money"],  
          },
          {
            courseName:'Reading',
            curriculam:["Abbreviations","Synonyms","Antonyms","Prefix","Suffix","Groupwords","Nouns","Pronouns","Pronoun types","Homophones","Homonyms","Capitalization Writing","Identify names of people/places/Titles of people","Identify days/months/holidays/seasons","Identify Buildings and other Structures","Identify organizations","Identify Title of Books/Family members","First word quote","Identify Historical events and documents","Types of noun (Common & Proper)","Compound words","Multi Meaning words","Story Comprehension"],  
        }
      ],
      date: 'Nov 12',
  description:
    'This is a wider card with supporting text below as a natural lead-in to additional content.',
  image: 'https://rabbitboard.s3.amazonaws.com/appimages/web/protractor.png',
  imageText: 'Image Text',
    },
    {
      title: 'Fifth Grade',
      curriculamList:[
          {
              courseName:'Math',
              curriculam:["Writing words to numbers","writing numbers to words","Expanded form","Standard form","Addition","Subtraction","Multiplication","Division","Roman Numerals","Relating multiplication and division","Rounding to nearest 10,100,1000","Multiplication and division Patterns","Estimating quotient","Exponents","Time-hr to min,min to hr","Factors","Statistics mean,mode","Rounding off decimal","Percentage","Fraction"],  
          },
          {
            courseName:'Reading',
            curriculam:["Synonyms","Antonyms","Prefix","Suffix","Groupwords","Nouns","Pronouns","Types of Pronoun","Singular","Plural","Possessive","Verbs","Homophones","Prepositions","Conjunction","Punctuations","Types of nouns","Proper","Abstract","Collective","Types of verbs","Linking","Multiple","Helping","Irregular plurals","Words that do not change","Singular-plural"],  
        }
      ],
      date: 'Nov 12',
  description:
    'This is a wider card with supporting text below as a natural lead-in to additional content.',
  image: 'https://rabbitboard.s3.amazonaws.com/appimages/web/maths.png',
  imageText: 'Image Text',
    },
    {
      title: 'Sixth Grade',
      curriculamList:[
          {
              courseName:'Math',
              curriculam:["Multiplication","Division","Mixed operation","Place values-convert","Expanded form","Standard form","Decimal Placevalue","Exponents","Factors","Prime factorization","Estimating quotient","Relating Division","Multiplication patterns","Division Patterns","Rounding of decimal benchmark","GCD","LCM","Compare decimal","Decimal Ascending order","Integers","Shapes"],  
          },
          {
            courseName:'Reading',
            curriculam:["Synonyms","Antonyms","Prefix","Suffix","Groupwords","Nouns","Pronouns","Types of Pronoun","Singular","Plural","Possessive","Reflexive","Homophones","Prepositions","Conjunction","Punctuations","Singular - plural"],  
        }
      ],
      date: 'Nov 12',
  description:
    'This is a wider card with supporting text below as a natural lead-in to additional content.',
  image: 'https://rabbitboard.s3.amazonaws.com/appimages/web/maths2.png',
  imageText: 'Image Text',
    },
];

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const Courses = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const childList = useSelector(getChildList);
  // const iapInfo = useSelector(getIapInfo)
  const classes = useStyles();
  useEffect(() => {
    dispatch(parentActions.getParentInfo());
  }, []);
  return (
    <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
    <Container maxWidth="sm">
            <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
              Daily Homework Application
            </Typography>
            {/* <Typography variant="h5" align="center" color="textSecondary" paragraph>
              Our online Mentors help KIDS to complete the homework. Every weekday.
            </Typography> */}
          </Container>
          </div>
         
        <Grid container spacing={4}>
          {featuredPosts.map((post) => (
            <FeaturedPost key={post.title} post={post} />
          ))}
        </Grid>
      </main>
  );
};

export default Courses;