import {
    Button,
    Grid,
    makeStyles,
    TextField,
    useMediaQuery,
    useTheme,
  } from "@material-ui/core";
  import React, { useEffect, useState } from "react";
  import Speech from "../../../util/speech";
  import { cloneDeep } from "lodash";
  import Snackbar from "@material-ui/core/Snackbar";
  import MuiAlert from "@material-ui/lab/Alert";

  
  const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
      fontSize: 40,
      textAlign: "center",
    },
  }));
  
  const ChartMath = (props) => {
    const classes = useStyles({});
    const theme = useTheme();
  
    const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
    const [currentQuestionId, setCurrentQuestionId] = useState(undefined);
    const [disableAnswer, setDisableAnswer] = useState(false);
    const [displayAnswer, setDisplayAnswer] = useState("");
    const [answer, setAnswer] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);
    const [attemptCount, setAttemptCount] = useState(0);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [showHands, setShowHands] = useState(false);
    const [question, setQuestion] = useState("");
    const [open, setOpen] = useState(false);
    const [toastMessage, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const size = Math.sqrt((props.currentHomeWork.varList).length)
    var alpha= ["A","B","C","D","E"];
    const cellHeight = 70
    const cellWidth = 100

    useEffect(() => {
      if (
        props.currentHomeWork &&
        props.currentHomeWork.questionId &&
        props.currentHomeWork.questionId !== currentQuestionId
      ) {
        setAnswer(
          props.currentHomeWork.finalAnswer === "1"
            ? props.currentHomeWork.studentAnswer
            : ""
        );
        setCurrentQuestionId(props.currentHomeWork.questionId);
        setShowHands(true);
        setQuestion(props.currentHomeWork.firstVar);
        setAttemptCount(0);
        
        setDisableAnswer(
          props.currentHomeWork.finalAnswer === "1" ? true : false
        );
        setDisplayAnswer(
          props.currentHomeWork.finalAnswer === "1"
            ? props.currentHomeWork.answer
            : ""
        );
  
        setShowSpinner(false);
      }
    }, [props.currentHomeWork]);

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setOpen(false);
    };
  
    const handleClick = () => {
      setOpen(true);
    };
  
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
  
    const isAnswerValid = () => {
      console.log("VALIDATING ANSWER...", answer);
      if (answer) {
        isAnswerCorrect();
      } else {
        setMessage("Invalid Answer!. Enter Value.");
        setMessageType("warning");
        handleClick();
      }
    };
  
    const isAnswerCorrect = () => {
      var isCorrect = false;
      console.log("CHECKING ANSWER ~~~~~~~~~~~~~~~ ",answer.toString() === props.currentHomeWork.answer);
      const answerString = answer.toString();
      console.warn("answerString ", answerString);
      isCorrect =
        answerString &&
        answerString.length > 0 &&
        answerString === props.currentHomeWork.answer;
      if (isCorrect) {
        console.log("IS CORRECT, GO TO NEXT QUESTION");
        setMessage("Correct Answer!. Good job.");
        setMessageType("success");
        handleClick();
        props.onSubmitQuestion(
          answerString,
          attemptCount + 1,
          true,
          props.currentHomeWork.questionId
        );
        
      } else {
        console.log("WRONG ANSWER ");
        setAttemptCount(attemptCount + 1);
  
        if (attemptCount >= 2) {
          setMessage("Wrong Answer!. Try Next Question.");
          setMessageType("error");
          handleClick();
          props.onSubmitQuestion(
            answerString,
            attemptCount,
            false,
            props.currentHomeWork.questionId
          );
        } else {
          setMessage("Wrong Answer!. Try Again.");
          setMessageType("warning");
          handleClick();
        }
      }
    };
  
    const clearAnswer = () => {
      setAnswer("");
      console.log("CLEAR ANSWER CALLED");
    };
  
    const addAnswerToState = (event) => {
      setAnswer(event.target.value);
    };
  
    const toggleHelpModal = (flag) => {
      setShowHelpModal(flag);
    };

    const onAnswerChange = (value, index,offset) => {
      const studentAnswer = cloneDeep(answer);
      studentAnswer[offset+index] = (value);
      setAnswer(studentAnswer);
      console.log("answer === ",answer)

  }
  
  const getConceptSymbol = (concept) => {
    console.log("CAME HERE")
    switch (concept) {
        case "add":
            return '+'
        case "sub":
            return "-"
        case "mul":
            return "*"
        case "div":
            return "÷"
        default:
            return "+"
    }
}


    const renderRow = (offset,size) => {

        const rowData = props.currentHomeWork.varList.slice(offset * size, (offset + 1) * size)
        {alpha.map((a) => {
            console.log(" alpha --- ",alpha[offset])
         })}   
        console.log(" rowData --- ",rowData)
            
        // return <div style={{  flexDirection: "column" ,borderWidth: 2,borderColor: "black"}} >
           
        //     {/* {rowData.map((num, index) => { */}
        //        <div style={{fontSize: 30,padding: 20,borderColor: "black"}} >
        //        {alpha[offset]}
        //        {rowData.map((num, index) => { 
        //           return <row> {num} </row>
        //         })}  
        //   </div>
        // {/* })} */}
        //  </div>

        return <div style={{  flexDirection: "column" ,borderWidth: 2,borderColor: "black",marginLeft:"30"}} >
           
            {/* {rowData.map((num, index) => { */}
               <div style={{fontSize: 30,borderColor: "black",marginLeft:"30"}} >
               {alpha[offset]}
               {rowData.map((num, index) => { 
                  return <TextField
                  value= {num}
                  variant="outlined"
                  style={{
                    width: 90,
                    textAlign: "center",
                    margin: 5,
                    padding:5
                  }}
                  contentEditable="false"
                  //multiline={false}
                  disabled={true}
                  
                /> 
                })}  
          </div>
        {/* })} */}
         </div>
           


        // return <div>
        //    <div style={{  width: cellWidth+10, height: cellHeight+10,justifyContent: "center", }} >
        //         {alpha[offset]}
        //     </div>
        //      <div>
        //             {rowData.map((num, index) => {
        //             return <div style={{ width: cellWidth+10, height: cellHeight+10, borderColor: "#F3BA1D", borderWidth: 2, justifyContent: "center" }} >
        //                 {num}
        //                 </div>
        //         })}
        //     </div>
           
        //    </div>
    }
  
    return (
      <Grid
        container
        style={{
          padding: 25,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Grid container style={{ padding: 16 }}>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: mobileDevice ? "column" : "row",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => clearAnswer()}
              style={{ marginRight: 16 }}
            >
              CLEAR
            </Button>
            <Button variant="outlined" color="primary" onClick={isAnswerValid}>
              {disableAnswer ? "NEXT" : "SUBMIT"}
            </Button>
            <div
              style={{ marginLeft: 16, display: "flex", alignItems: "center" }}
            >
              {disableAnswer
                ? ""
                : attemptCount > 0
                ? "Attempts Remaining: " +
                  (props.currentHomeWork.numOfAttemptsAllowed - attemptCount)
                : "Attempts Remaining: " +
                  props.currentHomeWork.numOfAttemptsAllowed}
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignSelf: "flex-start",
              flexDirection: mobileDevice ? "column" : "row",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => props.showHelp()}
              style={{ marginRight: 16 }}
            >
              HELP
            </Button>
            <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                Speech(props.currentHomeWork.voiceOver);
              }
            }}
          >
            READ OUT
          </Button>
          </Grid>
        </Grid>
        <Grid container style={{ flexDirection: "column" }}>
        <div style={{ fontSize: 30, margin: 10, alignSelf: "center" }}>
          {/* ---------------------------- QUESTION TEXT div - ITEM  1 ---------------------------- */}
         {/* {props.currentHomeWork.question} */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 10, alignSelf: "center" }}>
            {/* ---------------------------- ANSWER TEXT INPUT div ---------------------------- */}

            <div style={{  alignSelf: "center" }}>
                <div style={{ alignSelf: "center", marginBottom: 30 }} pointerEvents="none" >
                {/* ---------------------------- QUESTION TEXT div - ITEM  1 ---------------------------- */}
                <div style={{ fontSize: 30, textAlign: "center", marginLeft: 10, marginRight: 10 }}>
                  {props.currentHomeWork.question}
              </div>
              </div>
              <div style={{marginLeft:70}}>
              <div style={{fontSize: 30}}>
               <row><span style={{marginLeft:70}}></span>0<span style={{marginLeft:70}}></span>1<span style={{marginLeft:80}}></span>2</row> 
              </div>
                            {/* {
                                renderRow(0)
                            } */}
           {props.currentHomeWork.varList && props.currentHomeWork.varList.map((item, index) => {
                                      
                                    if (index < size) {
                                            return renderRow(index, size)
                                        } else return null
                                    })}                 
                            
                        </div>
                        <div style={{fontSize: 30}}>
                          <div style={{marginLeft: 235,letterSpacing:5}}>{props.currentHomeWork.strList ? props.currentHomeWork.strList[0] : " "}</div>
                          <div style={{marginLeft: 200,letterSpacing:5}}>{getConceptSymbol(props.currentHomeWork.qType)} {props.currentHomeWork.strList ? props.currentHomeWork.strList[1] : " "}</div>
                        </div>


                        <div style={{ marginLeft:150, alignSelf: "center" }}>
            {/* ---------------------------- ANSWER TEXT INPUT VIEW ---------------------------- */}
            <TextField
              variant="outlined"
              style={{
                fontSize: 30,
                width: 200,
                textAlign: "center",
                borderColor: "green",
                borderWidth: 2,
                color: "black",
              }}
              value={answer}
              InputProps={{
                classes: {
                  input: classes.input,
                },
              }}
              multiline={false}
              disabled={disableAnswer}
              onChange={addAnswerToState}
            ></TextField>
          </div>

                        </div>
                        
                        

            
          </div>
        </div>
        <div
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {/* ------------- ANSWER IMAGE RIGHT OR WRONG */}
          {/* <div style={{ flexDirection: "row", alignSelf: "center" }}>
              {disableAnswer && props.currentHomeWork.isRightAnswer && (
                <CorrectImage />
              )}
    
              {disableAnswer && !props.currentHomeWork.isRightAnswer && (
                <WrongImage />
              )}
            </div> */}
          {
            // ------------------   CORRECT ANSWER
            disableAnswer && !props.currentHomeWork.isRightAnswer && (
              <div
                style={{
                  alignSelf: "center",
                  marginTop: 20,
                  marginLeft: 50,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ fontSize: 24 }}>Correct Answer :{"   "}</div>
                <div style={{ fontSize: 30 }}>{displayAnswer}</div>
              </div>
            )
          }
        </div>
      </Grid>
      <Grid>
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert
            key={`toastMessage-${toastMessage}`}
            onClose={handleClose}
            severity={messageType}
          >
            {toastMessage}
          </Alert>
        </Snackbar>
      </Grid>
      </Grid>
    );
  };
  
  export default ChartMath;
  