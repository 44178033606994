import RabbitboardImage from "../assets/images/rabbitboard.png"

export const serviceConstants = {

    // non-prod'[]

    RABBIT_CONCEPT_URL: 'https://t24zmjczhc.execute-api.us-east-1.amazonaws.com/nonprod/rabbit/concepts',
    RABBIT_CONCEPT_URL_APIKEY: 'YoGPXCizXOwfPhJyZv47pRmS57TF3K61cS9SX260',

    // RABBIT_HOMEWORK_URL: 'http://edulyn-dev-homework.us-east-1.elasticbeanstalk.com/rabbit/hw',
    // RABBIT_HOMEWORK_URL_APIKEY: 'sKpNYsgMlF7hDJEAh3H969wSUkJ86zna56fDDKAz',
 
    // RABBIT_WEB_URL: 'http://edulyn-dev-web.us-east-1.elasticbeanstalk.com/rabbit/web',
    // RABBIT_AWS_S3: 'https://rabbitfilesnonprod.s3.amazonaws.com',

    // // non-prod - apigateway
    // RABBIT_LOGIN_URL: 'https://d3cemvmjafk4xd.cloudfront.net/rabbit/user',
    // RABBIT_LOGIN_URL_APIKEY: 'AqPcmy2UJc1UWMVe98LTi57fKD8CQg3yMeWrnQj3',

    // RABBIT_CHILD_URL: 'https://d30ryf4hsrvsph.cloudfront.net/rabbit/child',
    // RABBIT_CHILD_URL_APIKEY: 'wZSlqTZatDLYc4RtFRDJ2QZwNcHnHiE9xi6Jm7R0',

    // RABBIT_HOMEWORK_URL: 'https://d1jhazx02fjs9g.cloudfront.net/rabbit/hw',
    // RABBIT_HOMEWORK_URL_APIKEY: 'Zkgct0zz7O5RFf3ggncrF4YluwSoBtYd1gObofit',

    // RABBIT_WEB_URL: 'https://d2qqvnwg70v0eo.cloudfront.net/rabbit/web',
    // RABBIT_WEB_URL_APIKEY: 'AqPcmy2UJc1UWMVe98LTi57fKD8CQg3yMeWrnQj3',

    // RABBIT_AWS_S3: 'https://rabbitfilesnonprod.s3.amazonaws.com',


    //prod
    RABBIT_LOGIN_URL: 'https://d16uxef3wo2z8w.cloudfront.net/rabbit/user',
    RABBIT_LOGIN_URL_APIKEY: 'aQ0vSNhAGB13LYLguaI0i22sxdbsPqIR7ePNUqy9',

    RABBIT_CHILD_URL: 'https://d2bfqp7luwcp7y.cloudfront.net/rabbit/child',
    RABBIT_CHILD_URL_APIKEY: 'VQN6CQUsPlatVOoJmYL72aMzwog3h9rR6OSeoZEg',

    RABBIT_CONCEPT_URL: 'https://d2bfqp7luwcp7y.cloudfront.net/rabbit/concepts',
    RABBIT_CONCEPT_URL_APIKEY: 'I2nIz9tBDB9wA64PBfs0e1B54iDBrNLK69zm4HFt',

    RABBIT_HOMEWORK_URL: 'https://d3fyc4fhqck4cr.cloudfront.net/rabbit/hw',
    RABBIT_HOMEWORK_URL_APIKEY: 'UNxjtu5Aw328dXmf2t3kwxsCxRNAno34ETsf2ip9',

    RABBIT_WEB_URL: 'https://d2ggkpbuhtrx2r.cloudfront.net/rabbit/web',
    RABBIT_AWS_S3: 'https://eappimages.s3.amazonaws.com',

    // RABBIT_BOARD_IAP_VERIFY_RECEIPT_URL: 'https://sandbox.itunes.apple.com/verifyReceipt',
    RABBIT_BOARD_IAP_VERIFY_RECEIPT_URL: 'https://buy.itunes.apple.com/verifyReceipt',


    // stripePublishKey:'pk_test_51dCuV2bd1IeHJuaB2aqJwcX00W7XxhpjC',
    // subscriptionsDisplay: [
    //     {
    //         childCount: 1,
    //         id: "RB_1",
    //         name: "One Child Packages",
    //         options: [{
    //             id: "RB_1_Child_1_Month",
    //             name: "One Child Profile",
    //             description: "Monthly Subscription",
    //             price: "4.99",
    //             introductoryPrice: "3.99",
    //             duration: "Month",
    //             // image: RabbitboardImage,
    //             stripeId: "price_1HHoQnK3vqFTVAdsu01e072E"
    //         }, {
    //             id: "RB_1_Child_1_Year",
    //             name: "One Child Profile",
    //             description: "Annual Subscription",
    //             price: "54.99",
    //             introductoryPrice: "44.99",
    //             duration: "Year",
    //             // image: RabbitboardImage,
    //             stripeId: "price_1Jlc6NK3vqFTVAdszmXb8ZWl"
    //         }]
    //     }, {
    //         childCount: 2,
    //         id: "RB_2_Child_1_Month",
    //         name: "Two Child Packages",
    //         options: [{
    //             id: "RabbitBoardTwoChild",
    //             name: "Two Child Profiles",
    //             description: "Monthly Subscription",
    //             price: "9.99",
    //             introductoryPrice: "",
    //             duration: "Month",
    //             // image: RabbitboardImage,
    //             stripeId: "price_1JkILRK3vqFTVAdsNDg1BYuK"
    //         }, {
    //             id: "RB_2_Child_1_Year",
    //             name: "Two Children Profiles",
    //             description: "Annual Subscription",
    //             price: "114.99",
    //             introductoryPrice: "99.99",
    //             duration: "Year",
    //             // image: RabbitboardImage,
    //             stripeId: "price_1Jlc6lK3vqFTVAdsRhJHmVYA"
    //         }]
    //     }, {
    //         childCount: 4,
    //         id: "RB_4",
    //         name: "Four Children Packages",
    //         options: [{
    //             id: "RB_4_Child_1_Month",
    //             name: "Four Child Profiles",
    //             description: "Monthly Subscription",
    //             price: "19.99",
    //             introductoryPrice: "",
    //             duration: "Month",
    //             // image: RabbitboardImage,
    //             stripeId: "price_1Jlc5JK3vqFTVAdsOz3r97J7"
    //         }, {
    //             id: "RB_4_Child_1_Year",
    //             name: "Four Child Profiles",
    //             description: "Annual Subscription",
    //             price: "199.99",
    //             introductoryPrice: "149.99",
    //             duration: "Year",
    //             // image: RabbitboardImage,
    //             stripeId: "price_1Jlc75K3vqFTVAds2LU1HfB7"
    //         }]
    //     }, {
    //         childCount: 4,
    //         id: "RB_6",
    //         name: "Six Children Packages",
    //         options: [{
    //             id: "RB_6_Child_1_Month",
    //             name: "Six Child Profiles",
    //             description: "Monthly Subscription",
    //             price: "29.99",
    //             introductoryPrice: "",
    //             duration: "Month",
    //             // image: RabbitboardImage,
    //             stripeId: "price_1Jlc5jK3vqFTVAdsUZG0QYLX"
    //         }, {
    //             id: "RB_6_Child_1_Year",
    //             name: "Six Child Profiles",
    //             description: "Annual Subscription",
    //             price: "299.99",
    //             introductoryPrice: "249.99",
    //             duration: "Year",
    //             // image: RabbitboardImage,
    //             stripeId: "price_1Jlc7NK3vqFTVAdsqKoA4Wki"
    //         }]
    //     }
    // ],

    // // STRIPE TEST subscriptions list       ------ TEST ONLY -----
    // subscriptions: [
    //     {
    //         id: "RB_1",
    //         name: "One Child Packages"
    //     },
    //     {
    //         id: "RB_2_Child_1_Month",
    //         name: "Two Child Packages"
    //     },
    //     {
    //         id: "RB_4",
    //         name: "Four Child Profile"
    //     },
    //     {
    //         id: "RB_6",
    //         name: "Six Child Profile"
    //     }, {
    //         id: "RB_1_Child_1_Month",
    //         name: "One Child Profile",
    //         description: "Monthly Subscription",
    //         price: "4.99",
    //         introductoryPrice: "3.99",
    //         duration: "Month",
    //         // image: RabbitboardImage,
    //         stripeId: "price_1HHoQnK3vqFTVAdsu01e072E"
    //     }, {
    //         id: "RabbitBoardTwoChild",
    //         name: "Two Child Profiles",
    //         description: "Monthly Subscription",
    //         price: "9.99",
    //         introductoryPrice: "",
    //         duration: "Month",
    //         // image: RabbitboardImage,
    //         stripeId: "price_1JkILRK3vqFTVAdsNDg1BYuK"
    //     }, {
    //         id: "RB_4_Child_1_Month",
    //         name: "Four Child Profiles",
    //         description: "Monthly Subscription",
    //         price: "19.99",
    //         introductoryPrice: "",
    //         duration: "Month",
    //         // image: RabbitboardImage,
    //         stripeId: "price_1Jlc5JK3vqFTVAdsOz3r97J7"
    //     }, {
    //         id: "RB_6_Child_1_Month",
    //         name: "Six Child Profiles",
    //         description: "Monthly Subscription",
    //         price: "29.99",
    //         introductoryPrice: "",
    //         duration: "Month",
    //         // image: RabbitboardImage,
    //         stripeId: "price_1Jlc5jK3vqFTVAdsUZG0QYLX"
    //     }, {
    //         id: "RB_1_Child_1_Year",
    //         name: "One Child Profile",
    //         description: "Annual Subscription",
    //         price: "54.99",
    //         introductoryPrice: "44.99",
    //         duration: "Year",
    //         // image: RabbitboardImage,
    //         stripeId: "price_1Jlc6NK3vqFTVAdszmXb8ZWl"
    //     }, {
    //         id: "RB_2_Child_1_Year",
    //         name: "Two Child Profiles",
    //         description: "Annual Subscription",
    //         price: "114.99",
    //         introductoryPrice: "99.99",
    //         duration: "Year",
    //         // image: RabbitboardImage,
    //         stripeId: "price_1Jlc6lK3vqFTVAdsRhJHmVYA"
    //     }, {
    //         id: "RB_4_Child_1_Year",
    //         name: "Four Child Profiles",
    //         description: "Annual Subscription",
    //         price: "199.99",
    //         introductoryPrice: "149.99",
    //         duration: "Year",
    //         // image: RabbitboardImage,
    //         stripeId: "price_1Jlc75K3vqFTVAds2LU1HfB7"
    //     }, {
    //         id: "RB_6_Child_1_Year",
    //         name: "Six Child Profiles",
    //         description: "Annual Subscription",
    //         price: "299.99",
    //         introductoryPrice: "249.99",
    //         duration: "Year",
    //         // image: RabbitboardImage,
    //         stripeId: "price_1Jlc7NK3vqFTVAdsqKoA4Wki"
    //     }],

    // PROD SUBSCRIPTIONS       ------ To Do ------
    stripePublishKey:'pk_live_lmUnosnnrMzmeTejZO4Qe8VP00pQL9PbxS',
    subscriptions:[{
        id: "RB_1_Child_1_Month",
        name: "One Child Profile per Month",
        description: "Monthly Subscription",
        allowedChildProfiles:1,
        price: "4.99",
        introductoryPrice: "",
        duration: "Month",
        image: RabbitboardImage,
        stripeId: "price_1MLu7uK3vqFTVAdsusos13IL"
    }, {
        id: "RB_1_Child_1_Year",
        name: "One Child Profile per Year",
        description: "Annual Subscription",
        allowedChildProfiles:1,
        price: "54.99",
        introductoryPrice: "",
        duration: "Year",
        image: RabbitboardImage,
        stripeId: "price_1MLu7uK3vqFTVAdso3FSynVn"
    }, {
        id: "RabbitBoardTwoChild",
        name: "Two Child Profiles per Month",
        description: "Monthly Subscription",
        allowedChildProfiles:2,
        price: "9.99",
        introductoryPrice: "",
        duration: "Month",
        image: RabbitboardImage,
        stripeId: "price_1MLu7uK3vqFTVAdsiqM02XbY"
    }, {
        id: "RB_2_Child_1_Year",
        name: "Two Child Profiles per Year",
        description: "Annual Subscription",
        allowedChildProfiles:2,
        price: "114.99",
        introductoryPrice: "",
        duration: "Year",
        image: RabbitboardImage,
        stripeId: "price_1MLu7uK3vqFTVAdsJPmJtpY6"
    }, {
        id: "RB_4_Child_1_Month",
        name: "Four Child Profiles per Month",
        description: "Monthly Subscription",
        allowedChildProfiles:4,
        price: "19.99",
        introductoryPrice: "",
        duration: "Month",
        image: RabbitboardImage,
        stripeId: "price_1MLu7uK3vqFTVAdsB2f6hj2x"
    }, {
        id: "RB_4_Child_1_Year",
        name: "Four Child Profiles per Year",
        description: "Annual Subscription",
        allowedChildProfiles:4,
        price: "199.99",
        introductoryPrice: "",
        duration: "Year",
        image: RabbitboardImage,
        stripeId: "price_1MLu7tK3vqFTVAds6RHJxGum"
    }, {
        id: "RB_6_Child_1_Month",
        name: "Six Child Profiles per Month",
        description: "Monthly Subscription",
        allowedChildProfiles:6,
        price: "29.99",
        introductoryPrice: "",
        duration: "Month",
        image: RabbitboardImage,
        stripeId: "price_1MLu7uK3vqFTVAdsWuEHqsxq"
    }, {
        id: "RB_6_Child_1_Year",
        name: "Six Child Profiles per Year",
        description: "Annual Subscription",
        allowedChildProfiles:6,
        price: "299.99",
        introductoryPrice: "",
        duration: "Year",
        image: RabbitboardImage,
        stripeId: "price_1MLu7tK3vqFTVAdsw19SbOV1"
    }],

    subscriptionsDisplay: [
        {
            childCount: 1,
            id: "RB_1",
            name: "One Child Packages",
            options: [{
                id: "RB_1_Child_1_Month",
                name: "One Child Profile",
                description: "Monthly Subscription",
                price: "4.99",
                introductoryPrice: "",
                duration: "Month",
                // image: RabbitboardImage,
                stripeId: "price_1MLu7uK3vqFTVAdsusos13IL"
            }, {
                id: "RB_1_Child_1_Year",
                name: "One Child Profile",
                description: "Annual Subscription",
                price: "54.99",
                introductoryPrice: "",
                duration: "Year",
                // image: RabbitboardImage,
                stripeId: "price_1MLu7uK3vqFTVAdso3FSynVn"
            }]
        }, {
            childCount: 2,
            id: "RB_2_Child_1_Month",
            name: "Two Child Packages",
            options: [{
                id: "RabbitBoardTwoChild",
                name: "Two Child Profiles",
                description: "Monthly Subscription",
                price: "9.99",
                introductoryPrice: "",
                duration: "Month",
                // image: RabbitboardImage,
                stripeId: "price_1MLu7uK3vqFTVAdsiqM02XbY"
            }, {
                id: "RB_2_Child_1_Year",
                name: "Two Children Profiles",
                description: "Annual Subscription",
                price: "114.99",
                introductoryPrice: "",
                duration: "Year",
                // image: RabbitboardImage,
                stripeId: "price_1MLu7uK3vqFTVAdsJPmJtpY6"
            }]
        }, {
            childCount: 4,
            id: "RB_4",
            name: "Four Children Packages",
            options: [{
                id: "RB_4_Child_1_Month",
                name: "Four Child Profiles",
                description: "Monthly Subscription",
                price: "19.99",
                introductoryPrice: "",
                duration: "Month",
                // image: RabbitboardImage,
                stripeId: "price_1MLu7uK3vqFTVAdsB2f6hj2x"
            }, {
                id: "RB_4_Child_1_Year",
                name: "Four Child Profiles",
                description: "Annual Subscription",
                price: "199.99",
                introductoryPrice: "",
                duration: "Year",
                // image: RabbitboardImage,
                stripeId: "price_1MLu7tK3vqFTVAds6RHJxGum"
            }]
        }, {
            childCount: 4,
            id: "RB_6",
            name: "Six Children Packages",
            options: [{
                id: "RB_6_Child_1_Month",
                name: "Six Child Profiles",
                description: "Monthly Subscription",
                price: "29.99",
                introductoryPrice: "",
                duration: "Month",
                // image: RabbitboardImage,
                stripeId: "price_1MLu7uK3vqFTVAdsWuEHqsxq"
            }, {
                id: "RB_6_Child_1_Year",
                name: "Six Child Profiles",
                description: "Annual Subscription",
                price: "299.99",
                introductoryPrice: "",
                duration: "Year",
                // image: RabbitboardImage,
                stripeId: "price_1MLu7tK3vqFTVAdsw19SbOV1"
            }]
        }
    ]
};
