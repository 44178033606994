import {
  Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as parentActions from "../../../actions/parent.actions";
import {
  getChildList,
} from "../../../reducer/selectors/parent.entity";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import FeaturedPost from './../Common/featuredpost'
import teacher from "../../../assets/images/btn-girl@2x.png";

const tiers = [
  {
    title: 'Our Mentors',
    // price: '30',
    // subheader: 'Most popular',
    //   description: [
    //     'Math',
    //     'Reading',
    //   ],
    buttonText: 'Start Practice',
    buttonVariant: 'outlined',
    practiceChildId: '5ffe679b7527784b8a6669a1',
  },

];

const featuredPosts = [
  {
    title: 'Our Teachers',
    curriculamList: [
      {
        // courseName:'Math',
        curriculam: ["Teachers are one of the MOST important influences in a child's Life. That is why it is important that every interaction a teacher has with a child need to be constructive ones. Our teacher’s acts like mentors who not only guides children but also listen attentively and supports each child by building a friendly rapport with them."],
      }
    ],
    date: 'Nov 12',
    description:
      'This is a wider card with supporting text below as a natural lead-in to additional content.',
    image: teacher,
    imageText: 'Image Text',
  },
  {
    title: 'Encourage and Support',
    curriculamList: [
      {
        // courseName:'Math',
        curriculam: ["Children perform and behave better when they have clear guidelines of what is expected of them. Also when they try their best, improve, and reach their goals they need to be appreciated for their efforts. Our teachers support and encourage children to complete everyday assigned homework’s on time and also appreciate for his/her efforts."],
      }
    ],
    date: 'Nov 12',
    description:
      'This is a wider card with supporting text below as a natural lead-in to additional content.',
    image: teacher,
    imageText: 'Image Text',
  },
  {
    title: 'Maintains a Positive Attitude',
    curriculamList: [
      {
        // courseName:'Math',
        curriculam: ["Having a positive attitude creates positive environment. As Children sees their teachers as role model in whatever they do it is important for teachers to always have a positive attitude. Our teachers creates calm, positive, caring and friendly classroom approach."],
      }
    ],
    date: 'Nov 12',
    description:
      'This is a wider card with supporting text below as a natural lead-in to additional content.',
    image: teacher,
    imageText: 'Image Text',
  },
  {
    title: 'Homework',
    curriculamList: [
      {
        // courseName:'Math',
        curriculam: ["This creates a lot of pressure on parents than kids these days as they struggle hard, helping complete their kids homework. Instead of seeing homework as a burden we need to ensure quality time that a child spend to do his/her homework that can make a positive impact in kids’ lives. For example  subjects, like math, worksheets can be very helpful that has to be done with the repetitive practicing until a kid master’s a particular concept.",
          "Join hands with our team to make “Rapid Learning as a Best Habit” thus “Doing HOMEWORK” turns into Habit not a Load for kids and their parent’s .Let us make our kids Intrinsic Learners."],
      }
    ],
    date: 'Nov 12',
    description:
      'This is a wider card with supporting text below as a natural lead-in to additional content.',
    image: teacher,
    imageText: 'Image Text',
  },

];

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const Courses = () => {
  const dispatch = useDispatch();
  const history = useHistory();
const childList = useSelector(getChildList);
  // const iapInfo = useSelector(getIapInfo)
  const classes = useStyles();
  useEffect(() => {
    dispatch(parentActions.getParentInfo());
  }, []);
  return (
    <main>


      <Grid container spacing={4}>
        {featuredPosts.map((post) => (
          <FeaturedPost key={post.title} post={post} />
        ))}
      </Grid>
    </main>
  );
};

export default Courses;