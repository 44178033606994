import {
  Button,
  Grid,
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CorrectImage from "../../../assets/images/green-tick.png";
import WrongImage from "../../../assets/images/red-cross.png";
import { cloneDeep } from "lodash";
import Speech from "../../../util/speech";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
  },
  input: {
    padding: 6,
    fontSize: 24,
    textAlign: "center",
  },
}));

const Rule = (props) => {
  const classes = useStyles({});
  const theme = useTheme();

  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentQuestionId, setCurrentQuestionId] = useState(undefined);
  const [isValid, setIsValid] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [disableAnswer, setDisableAnswer] = useState(false);
  const [displayAnswer, setDisplayAnswer] = useState("");
  const [answer, setAnswer] = useState([]);
  const [maxNumberLength, setMaxNumberLength] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [firstList, setFirstList] = useState([]);
  const [secondList, setSecondList] = useState([]);
  const [open, setOpen] = useState(false);
  const [toastMessage, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    if (
      props.currentHomeWork &&
      props.currentHomeWork.questionId &&
      props.currentHomeWork.questionId !== currentQuestionId
    ) {
      setCurrentQuestionId(props.currentHomeWork.questionId);
      setAttemptCount(0);
      setDisableAnswer(
        props.currentHomeWork.finalAnswer === "1" ? true : false
      );
      setDisplayAnswer(props.currentHomeWork.answer);
      setShowSpinner(false);

      let studentAnswer = [];
      if (props.currentHomeWork.finalAnswer === "1") {
        const submittedAnswer = props.currentHomeWork.studentAnswer.split(",");
        studentAnswer = submittedAnswer;
      } else {
        studentAnswer = props.currentHomeWork.varList.slice(
          props.currentHomeWork.varList.length / 2,
          props.currentHomeWork.varList.length
        );
      }
      setAnswer(studentAnswer);
      setFirstList(
        props.currentHomeWork.varList.slice(
          0,
          props.currentHomeWork.varList.length / 2
        )
      );
      setSecondList(
        props.currentHomeWork.varList.slice(
          props.currentHomeWork.varList.length / 2,
          props.currentHomeWork.varList.length
        )
      );
    }
  }, [props.currentHomeWork, currentQuestionId]);

  const isAnswerValid = () => {
    var isValid = true;

    if (disableAnswer) {
      props.goToNextQuestion();
    } else {
      answer.map((answer) => {
        isValid = isValid && (answer === "" ? false : true);
      });
      if (isValid) isAnswerCorrect();
      else {
        setMessage("Invalid Answer!. Enter Value.")
        setMessageType("warning")
        handleClick()
      }
    }
  };

  const isAnswerCorrect = () => {
    var isCorrect = true;
    const correctAnswer = props.currentHomeWork.ansList.slice(
      props.currentHomeWork.varList.length / 2,
      props.currentHomeWork.varList.length
    );

    answer.map((ans, index) => {
      isCorrect = isCorrect && (Number(ans) === correctAnswer[index] ? true : false);
    });
    const answerString = answer.join(",");

    if (isCorrect) {
      props.onSubmitQuestion(
        answerString,
        attemptCount + 1,
        true,
        props.currentHomeWork.questionId
      );
      setMessage("Correct Answer!. Good job.")
      setMessageType("success")
      handleClick()
    } else {
      setAttemptCount(attemptCount + 1);

      if (attemptCount >= 2) {
        // props.onShowToast("Wrong Answer!. Next Question.", "danger");
        setMessage("Wrong Answer!. Try Next Question.")
        setMessageType("error")
        handleClick()
        props.onSubmitQuestion(
          answerString,
          attemptCount,
          false,
          props.currentHomeWork.questionId
        );
      } else {
        // props.onShowToast("Wrong Answer!. Try Again.", "warning");
        setMessage("Wrong Answer!. Try Again.")
        setMessageType("warning")
        handleClick()
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const onAnswerChange = (value, index) => {
    const studentAnswer = cloneDeep(answer);
    studentAnswer[index] = value;
    setAnswer(studentAnswer);
  };

  const clearAnswer = () => {
    const currentHomeWorkQuestion = cloneDeep(props.currentHomeWork.varList);
    const blankAnswer = currentHomeWorkQuestion.slice(
      props.currentHomeWork.varList.length / 2,
      props.currentHomeWork.varList.length
    );

    setAnswer(blankAnswer);
  };

  const renderQuestion = () => {
    const correctAnswer = props.currentHomeWork.ansList;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {props.currentHomeWork &&
          props.currentHomeWork.varList.map((item, index) => {
            const qLength = props.currentHomeWork.varList.length;
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {index <= qLength / 2 - 1 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 30,
                        width: 100,
                        textAlign: "center",
                        borderWidth: 1,
                        borderColor: "black",
                        borderStyle: "solid",
                        height: 40.5,
                      }}
                    >
                      {firstList[index]}
                    </div>
                    {secondList && secondList[index] === 0 ? (
                      <div
                        style={{
                          //   borderStyle: "solid",
                          //   borderColor: "black",
                          //   borderWidth: 1,
                          width: 100,
                        }}
                      >
                        <TextField
                          name={`input-${index}`}
                          value={answer && answer[index] ? answer[index] : ""}
                          variant="outlined"
                          //   value={answer[index] ? answer[index] : ""}
                          style={{
                            textAlign: "center",
                            borderWidth: 1,
                            borderColor: "black",
                            borderStyle: "solid",
                          }}
                          InputProps={{
                            classes: {
                              input: classes.input,
                              root: classes.root,
                            },
                          }}
                          multiline={false}
                          disabled={disableAnswer}
                          onChange={(e) => {
                            onAnswerChange(
                              e.target.value.replace(/[^0-9]/g, ""),
                              index
                            );
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          fontSize: 30,
                          width: 98,
                          textAlign: "center",
                          borderWidth: 1,
                          borderColor: "black",
                          borderStyle: "solid",
                          height: 40.5,
                          backgroundColor: "white",
                        }}
                      >
                        {secondList[index]}
                      </div>
                    )}
                  </div>
                )}

                {disableAnswer &&
                  props.currentHomeWork.finalAnswer === "1" &&
                  !props.currentHomeWork.isRightAnswer &&
                  index > firstList.length - 1 && (
                    <div
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      {/* <Image source={require('../../../assets/images/red-cross.png')}
                              style={{
                                  width: 30,
                                  height: 30,
                                  marginLeft: 20
                              }} /> */}
                      {props.currentHomeWork.originalConcept !== "ratio" ? (
                        <div
                          style={{
                            marginLeft: 10,
                            fontSize: 18,
                            color: "green",
                          }}
                        >
                          {firstList[index - firstList.length]}{" "}
                          {props.currentHomeWork.firstVar}={" "}
                          {correctAnswer[index]}
                        </div>
                      ) : (
                        <div
                          style={{
                            marginLeft: 10,
                            fontSize: 18,
                            color: "green",
                          }}
                        >
                          {firstList[index - firstList.length]}{" "}
                          {props.currentHomeWork.firstVar}:{" "}
                          {correctAnswer[index]}
                        </div>
                      )}
                    </div>
                  )}
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <Grid
      container
      style={{
        padding: 25,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid container style={{ padding: 16 }}>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => clearAnswer()}
            style={{ marginRight: 16 }}
            disabled={disableAnswer}
          >
            CLEAR
          </Button>
          <Button variant="outlined" color="primary" onClick={isAnswerValid}>
            {disableAnswer ? "NEXT" : "SUBMIT"}
          </Button>
          <div
            style={{ marginLeft: 16, display: "flex", alignItems: "center" }}
          >
            {attemptCount > 0 ? "Wrong Attempts: " + attemptCount : ""}
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignSelf: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
          }}
        >
          {props.currentHomeWork && props.currentHomeWork.helpLink && <Button
            variant="outlined"
            color="primary"
            onClick={() => props.showHelp()}
            style={{ marginRight: 16 }}
          >
            HELP
          </Button>}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                Speech(props.currentHomeWork.voiceOver);
              }
            }}
          >
            READ OUT
          </Button>
          </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ marginBottom: 15 }}>
          <div style={{ fontSize: 24, textAlign: "center" }}>
            {props.currentHomeWork.question}
          </div>
          {props.currentHomeWork.originalConcept !== "ratio" && (
            <div style={{ fontSize: 30, textAlign: "center", marginTop: 10 }}>
              Rule : {props.currentHomeWork.firstVar}
            </div>
          )}
        </div>
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          {/* ---------------------------- QUESTION TEXT VIEW - ITEM  1 ---------------------------- */}
          {renderQuestion()}
        </div>
        <div
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {/* ------------- ANSWER IMAGE RIGHT OR WRONG */}
          <div style={{ flexDirection: "row", alignSelf: "center" }}>
            {disableAnswer && props.currentHomeWork.isRightAnswer && (
              <img alt="correct-answer" src={CorrectImage} />
            )}

            {disableAnswer && !props.currentHomeWork.isRightAnswer && (
              <img alt="wrong-answer" src={WrongImage} />
            )}
          </div>
          {
            // ------------------   CORRECT ANSWER
            // disableAnswer && !props.currentHomeWork.isRightAnswer && (
            //   <div
            //     style={{
            //       alignSelf: "center",
            //       marginTop: 20,
            //       marginLeft: 50,
            //       flexDirection: "row",
            //       alignItems: "center",
            //     }}
            //   >
            //     <div style={{ fontSize: 24 }}>Correct Answer :{"   "}</div>
            //     <div style={{ fontSize: 48 }}>{displayAnswer}</div>
            //   </div>
            // )
          }
        </div>
      </Grid>
      <Grid>
      <Snackbar
       open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert 
        key={`toastMessage-${toastMessage}`}
        onClose={handleClose} 
        severity={messageType}>
          {toastMessage}
        </Alert>
      </Snackbar>
      </Grid>
    </Grid>
  );
};

export default Rule;
