import { CircularProgress } from "@material-ui/core";
import React from "react";

const Loader = (props) => {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 999,
        display: props.open ? "block" : "none",
        width: "100vw",
        height: "100vh",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      }}
      id={"loader-" + props.open}
      key={`${props.open}`}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.1)",
        }}
      >
        <CircularProgress color="primary" />
      </div>
    </div>
  );
};

export default Loader;
