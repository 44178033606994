import {
  Button,
  Card,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { attemptLogin, attemptForgotPassword } from "../../../actions/login.actions";
import { Dialog } from "@material-ui/core";
import RabbitBoardLogo from "../../../images/logo_edulyn.png";
import {
  getIsLoggedIn,
  getLoginError,
} from "../../../reducer/selectors/user.entity";
import { getIsRegistrationSuccess,
  getForgotPasswordResponse, 
  getForgotPasswordFailure ,
  getForgotPasswordSuccess} from "../../../reducer/selectors/login.entity";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";


export function Login() {
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoggedIn = useSelector(getIsLoggedIn);
  const isRegistrationSuccess = useSelector(getIsRegistrationSuccess);
  const loginError = useSelector(getLoginError);

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const passwordRegex =
    /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const forgotPswdFailure = useSelector(getForgotPasswordFailure);
  const forgotPswdSuccess = useSelector(getForgotPasswordSuccess);
  const forgotPswdResponse = useSelector(getForgotPasswordResponse);
  const [showSnackbarReset, setShowSnackbarReset] = useState(false);

  useEffect(() => {
    console.log("islogged in login page...", isLoggedIn);
    if (isLoggedIn) {
      history.push("/parent");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isRegistrationSuccess) {
      setShowSnackbar(true);
    }
  }, [isRegistrationSuccess]);

  useEffect(() => {
    if (loginError ) {
      setShowSnackbar(true);
    }
  }, [loginError]);

  useEffect(() => {
    console.log("forgotPswdFailure ---> ",forgotPswdFailure," ========= ",forgotPswdSuccess,"===== ",forgotPswdResponse)
    if (forgotPswdFailure) {
      setShowForgotPassword(true);
      setIsFormInvalid(true);
      setEmailIdError("Enter Registered Email Id to change password.");
    }if (forgotPswdSuccess){
      setShowForgotPassword(false);
      setShowSnackbarReset(true)
    }
  }, [forgotPswdFailure,forgotPswdSuccess]);

const forgotPswrd = () => {
  setShowForgotPassword(true);
}

  const onSubmit = () => {
    const isEmailValid = username && emailRegex.test(username);
    const isPasswordValid = password && passwordRegex.test(password);
    if (isEmailValid && isPasswordValid) {
      setUsernameError("");
      setPasswordError("");
      setIsFormInvalid(false);

      dispatch(attemptLogin({ username, password }));
    } else {
      const passwordCharCheck = /^(?=.*[A-Z])(?=.*[a-z])[\w!@#$%^&*]{2,}$/;
      const passwordNumCheck = /^(?=.*[\d])[\w!@#$%^&*]{1,}$/;
      const passwordSpclCharCheck = /^(?=.*[!@#$%^&*])[\w!@#$%^&*]{1,}$/;
      let pswdError = `Password must contain ${
        !passwordSpclCharCheck.test(password) ? "a special character, " : ""
      }${!passwordNumCheck.test(password) ? "a number, " : ""}${
        !passwordCharCheck.test(password) ? "upper and lower case, " : ""
      }${password.length < 8 ? "minimum 8 characters" : ""}`;

      setUsernameError(
        isEmailValid ? "" : "Username is not valid. It should be an email"
      );
      setPasswordError(isPasswordValid ? "" : pswdError);
      setIsFormInvalid(true);
    }
  };

  const onSubmitForgotPswd = () => {
    const isEmailValid = emailId && emailRegex.test(emailId);
    console.log("email id ===> ",emailId)
    if (isEmailValid && emailId) {
      setEmailIdError("");
      setIsFormInvalid(false);

      dispatch(attemptForgotPassword({ emailId }));
      console.log("attemptForgotPasswordSuccess ---> ",forgotPswdFailure)
      
    } else{
      setEmailIdError(
        isEmailValid ? "" : "Email Id is not valid."
      );
     setIsFormInvalid(true);
    }
  }

  return (
    <Container maxWidth="sm">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert
          elevation={6}
          onClose={() => setShowSnackbar(false)}
          severity={loginError ? "error" : "info"}
        >
          {loginError
            ? loginError
            : "Thanks for registering with us. You'll be receiving Verification Email."}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showSnackbarReset}
        autoHideDuration={6000}
        onClose={() => setShowSnackbarReset(false)}
      >
        <Alert
          elevation={6}
          onClose={() => setShowSnackbarReset(false)}
          severity={forgotPswdSuccess ? "success" : "info"}
        >
          Please check your mail to reset the password.
        </Alert>
      </Snackbar>

      <Dialog open={showForgotPassword}>
          <Grid
            container
            spacing={2}
            style={{
              // marginTop: 100,
              padding: 25,
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              backgroundColor: "white",
              minHeight: "30vh",
            }}
          >
            <Typography color="primary" variant="h6">
            Reset link will be sent to your Email address
            </Typography>
            <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              id="emailid"
              type="email"
              label="Email"
              variant="outlined"
              error={Boolean(isFormInvalid && emailIdError)}
              helperText={
                Boolean(isFormInvalid && emailIdError) && emailIdError
              }
              
              onChange={(e) => setEmailId(e.target.value)}
            />
            </Grid>
          <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                alignContent:"center",
                justifyContent: "center",
              }}
            >
              <Grid item xs={4}>
                <Button
                  variant="outlined"
                  color="error"
                  style={{ width: "100%" }}
                  onClick={() => {
                    console.log("SERVICE CANCEL");
                    setShowForgotPassword(false);
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item={true} xs={4}>
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{ width: "100%" }}
                  onClick={() => {
                    console.log("SERVICE forgot pswd");
                    onSubmitForgotPswd();
                  }}
                >
                  SEND
                </Button>
              </Grid>
              </Grid>
          </Grid>
        </Dialog>


      <Card style={{ marginTop: "3vh" }}>
        <Grid
          container
          spacing={2}
          style={{ padding: 59, display: "flex", justifyContent: "center" }}
        >
          <img
            alt="rabbit board logo"
            src={RabbitBoardLogo}
            style={{ width: "60%", marginTop: "5vh", marginBottom: "5vh" }}
          />
          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              id="username"
              type="email"
              label="Email"
              variant="outlined"
              error={Boolean(isFormInvalid && usernameError)}
              helperText={
                Boolean(isFormInvalid && usernameError) && usernameError
              }
              onChange={(e) => setUsername(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              id="outlined-password-input"
              label="Password"
              type="password"
              autoComplete="current-password"
              variant="outlined"
              error={Boolean(isFormInvalid && passwordError)}
              helperText={
                Boolean(isFormInvalid && passwordError) && passwordError
              }
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              style={{ width: "100%" }}
              onClick={onSubmit}
            >
              Login
            </Button>
          </Grid>

          <Link
              color="inherit"
              noWrap
              //key={section.title}
              variant="body2"
             // href={section.url}
             // className={classes.toolbarLink}
             onClick={forgotPswrd}
             style={{textDecoration: 'underline', fontSize: 15}}
            >
              Forgot Password?
            </Link>


        </Grid>
      </Card>
    </Container>
  );
}
export default Login;
