export const sciencePracticeConcepts = [
    {
        title: "Second Grade",

        numOfQuestionsPerDay: 40,
        courseId: "S2",
        concepts: [
            {
                conceptName: "Select Property of Materials",
                concept: "pro",
                cTest: "pro-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Property of Materials",
                concept: "pro",
                cTest: "pro-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select States Of Matter",
                concept: "mtr",
                cTest: "mtr-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose States Of Matter",
                concept: "mtr",
                cTest: "mtr-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Physical/Chemical change",
                concept: "chng",
                cTest: "chng-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Physical/Chemical change",
                concept: "chng",
                cTest: "chng-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Mixtures",
                concept: "mxtr",
                cTest: "mxtr-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Mixtures",
                concept: "mxtr",
                cTest: "mxtr-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Classification Of Animals",
                concept: "anmlsclsf",
                cTest: "anmlsclsf-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Classification Of Animals",
                concept: "anmlsclsf",
                cTest: "anmlsclsf-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Types Of Animals",
                concept: "anmlstyp",
                cTest: "anmlstyp-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Types Of Animals",
                concept: "anmlstyp",
                cTest: "anmlstyp-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Food habits",
                concept: "anmlsfd",
                cTest: "anmlsfd-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Food habits",
                concept: "anmlsfd",
                cTest: "anmlsfd-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Food habits",
                concept: "anmlsfd",
                cTest: "anmlsfd-prfrd-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Living/Non living things",
                concept: "thngs",
                cTest: "thngs-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Living/Non living things",
                concept: "thngs",
                cTest: "thngs-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "General questions",
                concept: "gnrl",
                cTest: "gnrl-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Plants",
                concept: "plnts",
                cTest: "plnts-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Plants",
                concept: "plnts",
                cTest: "plnts-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Minerals",
                concept: "mnrls",
                cTest: "mnrls-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Minerals",
                concept: "mnrls",
                cTest: "mnrls-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Items",
                concept: "itms",
                cTest: "itms-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Items",
                concept: "itms",
                cTest: "itms-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Materials",
                concept: "mtrls",
                cTest: "mtrls-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Materials",
                concept: "mtrls",
                cTest: "mtrls-chse-any",
                accuracy: 5,
            },

        ],
    },
    {
        title: "Third Grade",

        numOfQuestionsPerDay: 40,
        courseId: "S3",
        concepts: [
            {
                conceptName: "Select Property of Materials",
                concept: "pro",
                cTest: "pro-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Property of Materials",
                concept: "pro",
                cTest: "pro-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select States Of Matter",
                concept: "mtr",
                cTest: "mtr-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose States Of Matter",
                concept: "mtr",
                cTest: "mtr-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Physical/Chemical change",
                concept: "chng",
                cTest: "chng-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Physical/Chemical change",
                concept: "chng",
                cTest: "chng-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Mixtures",
                concept: "mxtr",
                cTest: "mxtr-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Mixtures",
                concept: "mxtr",
                cTest: "mxtr-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Classification Of Animals",
                concept: "anmlsclsf",
                cTest: "anmlsclsf-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Classification Of Animals",
                concept: "anmlsclsf",
                cTest: "anmlsclsf-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Food habits",
                concept: "anmlsfd",
                cTest: "anmlsfd-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Food habits",
                concept: "anmlsfd",
                cTest: "anmlsfd-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Food habits",
                concept: "anmlsfd",
                cTest: "anmlsfd-prfrd-any",
                accuracy: 5,
            },
            {
                conceptName: "General questions",
                concept: "gnrl",
                cTest: "gnrl-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Plants",
                concept: "plnts",
                cTest: "plnts-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Plants",
                concept: "plnts",
                cTest: "plnts-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Minerals",
                concept: "mnrls",
                cTest: "mnrls-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Minerals",
                concept: "mnrls",
                cTest: "mnrls-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Items",
                concept: "itms",
                cTest: "itms-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Items",
                concept: "itms",
                cTest: "itms-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Materials",
                concept: "mtrls",
                cTest: "mtrls-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Materials",
                concept: "mtrls",
                cTest: "mtrls-chse-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Select Cells",
            //   concept: "cel",
            //   cTest: "cel-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Cells",
            //   concept: "cel",
            //   cTest: "cel-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Natural Resource",
            //   concept: "cel",
            //   cTest: "ntrlrsrc-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Natural Resource",
            //   concept: "ntrlrsrc",
            //   cTest: "ntrlrsrc-chse-any",
            //   accuracy: 5,
            // },

            // {
            //   conceptName: "Select Mixture Type",
            //   concept: "mxtrtyp",
            //   cTest: "mxtrtyp-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Mixture Type",
            //   concept: "mxtrtyp",
            //   cTest: "mxtrtyp-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Magnets",
            //   concept: "mgnt",
            //   cTest: "mgnt-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Magnets",
            //   concept: "mgnt",
            //   cTest: "mgnt-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Direction Of Force",
            //   concept: "frc",
            //   cTest: "frc-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Direction Of Force",
            //   concept: "frc",
            //   cTest: "frc-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Astronomy",
            //   concept: "astrnmy",
            //   cTest: "astrnmy-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Astronomy",
            //   concept: "astrnmy",
            //   cTest: "astrnmy-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Disaster",
            //   concept: "dstr",
            //   cTest: "dstr-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Disaster",
            //   concept: "dstr",
            //   cTest: "dstr-chse-any",
            //   accuracy: 5,
            // },
        ],
    },
    {
        title: "Fourth Grade",

        numOfQuestionsPerDay: 40,
        courseId: "S4",
        concepts: [
            {
                conceptName: "Select Property of Materials",
                concept: "pro",
                cTest: "pro-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Property of Materials",
                concept: "pro",
                cTest: "pro-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select States Of Matter",
                concept: "mtr",
                cTest: "mtr-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose States Of Matter",
                concept: "mtr",
                cTest: "mtr-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Physical/Chemical change",
                concept: "chng",
                cTest: "chng-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Physical/Chemical change",
                concept: "chng",
                cTest: "chng-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Mixtures",
                concept: "mxtr",
                cTest: "mxtr-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Mixtures",
                concept: "mxtr",
                cTest: "mxtr-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Classification Of Animals",
                concept: "anmlsclsf",
                cTest: "anmlsclsf-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Classification Of Animals",
                concept: "anmlsclsf",
                cTest: "anmlsclsf-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Food habits",
                concept: "anmlsfd",
                cTest: "anmlsfd-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Food habits",
                concept: "anmlsfd",
                cTest: "anmlsfd-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Food habits",
                concept: "anmlsfd",
                cTest: "anmlsfd-prfrd-any",
                accuracy: 5,
            },
            {
                conceptName: "General questions",
                concept: "gnrl",
                cTest: "gnrl-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Plants",
                concept: "plnts",
                cTest: "plnts-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Plants",
                concept: "plnts",
                cTest: "plnts-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Minerals",
                concept: "mnrls",
                cTest: "mnrls-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Minerals",
                concept: "mnrls",
                cTest: "mnrls-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Items",
                concept: "itms",
                cTest: "itms-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Items",
                concept: "itms",
                cTest: "itms-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Materials",
                concept: "mtrls",
                cTest: "mtrls-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Materials",
                concept: "mtrls",
                cTest: "mtrls-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Cells",
                concept: "cel",
                cTest: "cel-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Cells",
                concept: "cel",
                cTest: "cel-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Natural Resource",
                concept: "cel",
                cTest: "ntrlrsrc-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Natural Resource",
                concept: "ntrlrsrc",
                cTest: "ntrlrsrc-chse-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Select Mixture Type",
            //   concept: "mxtrtyp",
            //   cTest: "mxtrtyp-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Mixture Type",
            //   concept: "mxtrtyp",
            //   cTest: "mxtrtyp-chse-any",
            //   accuracy: 5,
            // },
            {
                conceptName: "Select Magnets",
                concept: "mgnt",
                cTest: "mgnt-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Magnets",
                concept: "mgnt",
                cTest: "mgnt-chse-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Select Direction Of Force",
            //   concept: "frc",
            //   cTest: "frc-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Direction Of Force",
            //   concept: "frc",
            //   cTest: "frc-chse-any",
            //   accuracy: 5,
            // },
            {
                conceptName: "Select Astronomy",
                concept: "astrnmy",
                cTest: "astrnmy-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Astronomy",
                concept: "astrnmy",
                cTest: "astrnmy-chse-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Select Disaster",
            //   concept: "dstr",
            //   cTest: "dstr-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Disaster",
            //   concept: "dstr",
            //   cTest: "dstr-chse-any",
            //   accuracy: 5,
            // },
        ],
    },
    {
        title: "Fifth Grade",

        numOfQuestionsPerDay: 40,
        courseId: "S5",
        concepts: [
            {
                conceptName: "Select Atoms and molecules",
                concept: "atms",
                cTest: "atms-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Atoms and molecules",
                concept: "atms",
                cTest: "atms-chse-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Select life cycle of animals",
            //   concept: "lyfcycl",
            //   cTest: "lyfcycl-slct-any",
            //   accuracy: 5,
            // },

            {
                conceptName: "Select Property of Materials",
                concept: "pro",
                cTest: "pro-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Property of Materials",
                concept: "pro",
                cTest: "pro-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Physical/Chemical change",
                concept: "chng",
                cTest: "chng-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Physical/Chemical change",
                concept: "chng",
                cTest: "chng-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Mixtures",
                concept: "mxtr",
                cTest: "mxtr-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Mixtures",
                concept: "mxtr",
                cTest: "mxtr-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Classification Of Animals",
                concept: "anmlsclsf",
                cTest: "anmlsclsf-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Classification Of Animals",
                concept: "anmlsclsf",
                cTest: "anmlsclsf-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Food habits",
                concept: "anmlsfd",
                cTest: "anmlsfd-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Food habits",
                concept: "anmlsfd",
                cTest: "anmlsfd-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Food habits",
                concept: "anmlsfd",
                cTest: "anmlsfd-prfrd-any",
                accuracy: 5,
            },
            {
                conceptName: "General questions",
                concept: "gnrl",
                cTest: "gnrl-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Plants",
                concept: "plnts",
                cTest: "plnts-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Plants",
                concept: "plnts",
                cTest: "plnts-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Minerals",
                concept: "mnrls",
                cTest: "mnrls-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Minerals",
                concept: "mnrls",
                cTest: "mnrls-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Items",
                concept: "itms",
                cTest: "itms-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Items",
                concept: "itms",
                cTest: "itms-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Materials",
                concept: "mtrls",
                cTest: "mtrls-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Materials",
                concept: "mtrls",
                cTest: "mtrls-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Cells",
                concept: "cel",
                cTest: "cel-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Cells",
                concept: "cel",
                cTest: "cel-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Natural Resource",
                concept: "cel",
                cTest: "ntrlrsrc-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Natural Resource",
                concept: "ntrlrsrc",
                cTest: "ntrlrsrc-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Mixture Type",
                concept: "mxtrtyp",
                cTest: "mxtrtyp-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Mixture Type",
                concept: "mxtrtyp",
                cTest: "mxtrtyp-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Magnets",
                concept: "mgnt",
                cTest: "mgnt-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Magnets",
                concept: "mgnt",
                cTest: "mgnt-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Direction Of Force",
                concept: "frc",
                cTest: "frc-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Direction Of Force",
                concept: "frc",
                cTest: "frc-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Astronomy",
                concept: "astrnmy",
                cTest: "astrnmy-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Astronomy",
                concept: "astrnmy",
                cTest: "astrnmy-chse-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Select Disaster",
            //   concept: "dstr",
            //   cTest: "dstr-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Disaster",
            //   concept: "dstr",
            //   cTest: "dstr-chse-any",
            //   accuracy: 5,
            // },
            {
                conceptName: "Select Ecosystem",
                concept: "eco",
                cTest: "eco-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Ecosystem",
                concept: "eco",
                cTest: "eco-chse-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Select Types Of Rocks",
            //   concept: "rcks",
            //   cTest: "rcks-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Types Of Rocks",
            //   concept: "rcks",
            //   cTest: "rcks-chse-any",
            //   accuracy: 5,
            // },
            {
                conceptName: "Select Vegetables",
                concept: "vgtbl",
                cTest: "vgtbl-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Vegetables",
                concept: "vgtbl",
                cTest: "vgtbl-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Scientific Name",
                concept: "scntfcnm",
                cTest: "scntfcnm-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Chamber in Heart",
                concept: "hrtchmbrs",
                cTest: "hrtchmbrs-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Chamber in Heart",
                concept: "hrtchmbrs",
                cTest: "hrtchmbrs-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Vitamins",
                concept: "vtmns",
                cTest: "vtmns-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Vitamins",
                concept: "vtmns",
                cTest: "vtmns-chse-any",
                accuracy: 5,
            },

            {
                conceptName: "Select Vitamins Defeciency",
                concept: "vtmnsdfnc",
                cTest: "vtmnsdfnc-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Vitamins Defeciency",
                concept: "vtmnsdfnc",
                cTest: "vtmnsdfnc-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Collide Solution",
                concept: "cld",
                cTest: "cld-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Collide Solution",
                concept: "cld",
                cTest: "cld-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Vitamins Importance",
                concept: "vtmnsimp",
                cTest: "vtmnsimp-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Vitamins Importance",
                concept: "vtmnsimp",
                cTest: "vtmnsimp-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Sources of Vitamins",
                concept: "vtmnssrc",
                cTest: "vtmnssrc-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Sources of Vitamins",
                concept: "vtmnssrc",
                cTest: "vtmnssrc-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Endrocrine Gland",
                concept: "endglnd",
                cTest: "endglnd-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Endrocrine Gland",
                concept: "endglnd",
                cTest: "endglnd-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Human Body System",
                concept: "bdysystm",
                cTest: "bdysystm-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Types Of Muscle Cells",
                concept: "mslcel",
                cTest: "mslcel-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Types Of Muscle Cells",
                concept: "mslcel",
                cTest: "mslcel-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Definitions",
                concept: "def",
                cTest: "def-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Newton's law",
                concept: "nwtnlw",
                cTest: "nwtnlw-slct-any",
                accuracy: 5,
            },
        ],
    },
    {
        title: "Sixth Grade",

        numOfQuestionsPerDay: 40,
        courseId: "S6",
        concepts: [
            {
                conceptName: "Select Biochemistry",
                concept: "biochem",
                cTest: "biochem-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Biochemistry",
                concept: "biochem",
                cTest: "biochem-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Identify Biochemistry",
                concept: "biochem",
                cTest: "biochem-rvrs-any",
                accuracy: 5,
            },
            {
                conceptName: "Biochemistry",
                concept: "biochem",
                cTest: "biochem-optns-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Kinetic/Potential Energy",
                concept: "enrgy",
                cTest: "enrgy-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Kinetic/Potential Energy",
                concept: "enrgy",
                cTest: "enrgy-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Atoms and molecules",
                concept: "atms",
                cTest: "atms-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Atoms and molecules",
                concept: "atms",
                cTest: "atms-chse-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Select life cycle of animals",
            //   concept: "lyfcycl",
            //   cTest: "lyfcycl-slct-any",
            //   accuracy: 5,
            // },
            {
                conceptName: "Select Human Organ Functions",
                concept: "orgnfns",
                cTest: "orgnfns-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Human Organ Functions",
                concept: "orgnfns",
                cTest: "orgnfns-chse-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Select Fossil Traits",
            //   concept: "trts",
            //   cTest: "trts-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Fossil Traits",
            //   concept: "trts",
            //   cTest: "trts-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Property of Materials",
            //   concept: "pro",
            //   cTest: "pro-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Property of Materials",
            //   concept: "pro",
            //   cTest: "pro-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Physical/Chemical change",
            //   concept: "chng",
            //   cTest: "chng-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Physical/Chemical change",
            //   concept: "chng",
            //   cTest: "chng-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Mixtures",
            //   concept: "mxtr",
            //   cTest: "mxtr-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Mixtures",
            //   concept: "mxtr",
            //   cTest: "mxtr-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Classification Of Animals",
            //   concept: "anmlsclsf",
            //   cTest: "anmlsclsf-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Classification Of Animals",
            //   concept: "anmlsclsf",
            //   cTest: "anmlsclsf-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Food habits",
            //   concept: "anmlsfd",
            //   cTest: "anmlsfd-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Food habits",
            //   concept: "anmlsfd",
            //   cTest: "anmlsfd-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Food habits",
            //   concept: "anmlsfd",
            //   cTest: "anmlsfd-prfrd-any",
            //   accuracy: 5,
            // },
            {
                conceptName: "General questions",
                concept: "gnrl",
                cTest: "gnrl-slct-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Select Plants",
            //   concept: "plnts",
            //   cTest: "plnts-slct-any",
            //   accuracy: 5,
            // },
            {
                conceptName: "Choose Plants",
                concept: "plnts",
                cTest: "plnts-chse-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Select Minerals",
            //   concept: "mnrls",
            //   cTest: "mnrls-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Minerals",
            //   concept: "mnrls",
            //   cTest: "mnrls-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Items",
            //   concept: "itms",
            //   cTest: "itms-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Items",
            //   concept: "itms",
            //   cTest: "itms-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Materials",
            //   concept: "mtrls",
            //   cTest: "mtrls-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Materials",
            //   concept: "mtrls",
            //   cTest: "mtrls-chse-any",
            //   accuracy: 5,
            // },
            {
                conceptName: "Select Cells",
                concept: "cel",
                cTest: "cel-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Cells",
                concept: "cel",
                cTest: "cel-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Natural Resource",
                concept: "ntrlrsrc",
                cTest: "ntrlrsrc-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Natural Resource",
                concept: "ntrlrsrc",
                cTest: "ntrlrsrc-chse-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Select Mixture Type",
            //   concept: "mxtrtyp",
            //   cTest: "mxtrtyp-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Mixture Type",
            //   concept: "mxtrtyp",
            //   cTest: "mxtrtyp-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Magnets",
            //   concept: "mgnt",
            //   cTest: "mgnt-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Magnets",
            //   concept: "mgnt",
            //   cTest: "mgnt-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Direction Of Force",
            //   concept: "frc",
            //   cTest: "frc-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Direction Of Force",
            //   concept: "frc",
            //   cTest: "frc-chse-any",
            //   accuracy: 5,
            // },
            {
                conceptName: "Select Astronomy",
                concept: "astrnmy",
                cTest: "astrnmy-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Astronomy",
                concept: "astrnmy",
                cTest: "astrnmy-chse-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Select Disaster",
            //   concept: "dstr",
            //   cTest: "dstr-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Disaster",
            //   concept: "dstr",
            //   cTest: "dstr-chse-any",
            //   accuracy: 5,
            // },
            {
                conceptName: "Select Ecosystem",
                concept: "eco",
                cTest: "eco-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Ecosystem",
                concept: "eco",
                cTest: "eco-chse-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Select Types Of Rocks",
            //   concept: "rcks",
            //   cTest: "rcks-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Types Of Rocks",
            //   concept: "rcks",
            //   cTest: "rcks-chse-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Select Vegetables",
            //   concept: "vgtbl",
            //   cTest: "vgtbl-slct-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Choose Vegetables",
            //   concept: "vgtbl",
            //   cTest: "vgtbl-chse-any",
            //   accuracy: 5,
            // },
            {
                conceptName: "Select Scientific Name",
                concept: "scntfcnm",
                cTest: "scntfcnm-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Chamber in Heart",
                concept: "hrtchmbrs",
                cTest: "hrtchmbrs-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Chamber in Heart",
                concept: "hrtchmbrs",
                cTest: "hrtchmbrs-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Vitamins",
                concept: "vtmns",
                cTest: "vtmns-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Vitamins",
                concept: "vtmns",
                cTest: "vtmns-chse-any",
                accuracy: 5,
            },

            {
                conceptName: "Select Vitamins Defeciency",
                concept: "vtmnsdfnc",
                cTest: "vtmnsdfnc-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Vitamins Defeciency",
                concept: "vtmnsdfnc",
                cTest: "vtmnsdfnc-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Collide Solution",
                concept: "cld",
                cTest: "cld-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Collide Solution",
                concept: "cld",
                cTest: "cld-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Vitamins Importance",
                concept: "vtmnsimp",
                cTest: "vtmnsimp-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Vitamins Importance",
                concept: "vtmnsimp",
                cTest: "vtmnsimp-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Sources of Vitamins",
                concept: "vtmnssrc",
                cTest: "vtmnssrc-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Sources of Vitamins",
                concept: "vtmnssrc",
                cTest: "vtmnssrc-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Endrocrine Gland",
                concept: "endglnd",
                cTest: "endglnd-slct-any",
                accuracy: 5,
            },
            // {
            //   conceptName: "Choose Endrocrine Gland",
            //   concept: "endglnd",
            //   cTest: "endglnd-chse-any",
            //   accuracy: 5,
            // },
            {
                conceptName: "Human Body System",
                concept: "bdysystm",
                cTest: "bdysystm-slct-any",
                accuracy: 5,
            },
            //  {
            //     conceptName: "Choose Types Of Muscle Cells",
            //     concept: "mslcel",
            //     cTest: "mslcel-chse-any",
            //     accuracy: 5,
            //   },
            {
                conceptName: "Select Types Of Muscle Cells",
                concept: "mslcel",
                cTest: "mslcel-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Definitions",
                concept: "def",
                cTest: "def-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Newton's law",
                concept: "nwtnlw",
                cTest: "nwtnlw-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Plate boundaries",
                concept: "bondrs",
                cTest: "bondrs-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Plate boundaries",
                concept: "bondrs",
                cTest: "bondrs-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Hazards",
                concept: "hzrds",
                cTest: "hzrds-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Hazards",
                concept: "hzrds",
                cTest: "hzrds-chse-any",
                accuracy: 5,
            },
            {
                conceptName: "Select Photosynthsis",
                concept: "phtsnths",
                cTest: "phtsnths-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Choose Photosynthsis",
                concept: "phtsnths",
                cTest: "phtsnths-chse-any",
                accuracy: 5,
            },
        ],
    },
]