import { createAction } from "@reduxjs/toolkit";
import { profileConstants } from "../constants";

export const getProfile = createAction(
  profileConstants.GET_PROFILE,
  (payload) => ({ payload })
);
export const getProfileSuccess = createAction(
  profileConstants.GET_PROFILE_SUCCESS,
  (payload) => ({ payload })
);
export const getProfileRankings = createAction(
  profileConstants.GET_PROFILE_RANKINGS,
  (payload) => ({ payload })
);
export const getProfileRankingsSuccess = createAction(
  profileConstants.GET_PROFILE_RANKINGS_SUCCESS,
  (payload) => ({ payload })
);
