import { put, takeLatest } from "redux-saga/effects";
import { setShowLoader } from "../actions";
import * as homeworkActions from "../actions/homework.actions";
import { serviceConstants } from "../constants";
import Transport from "../transport/rabbitboard.transport";

function* getHomeworkSaga(action) {
  try {
    const { childId, hwDate, subjectCd, numOfQuestionsPerDay, isLoggedIn, ctest, pageFlag, pageSrc, currentLevel, practiceConcept } =
      action.payload;
    console.log('homework saaaga...', action.payload, pageFlag, pageSrc)
    let url = serviceConstants.RABBIT_HOMEWORK_URL + "/testprepare?subjectCd=" + subjectCd + "&hwDate=" + hwDate + "&practiceConcept=" + practiceConcept + "&currentLevel=" + currentLevel;
    url = (ctest !== '' && pageFlag !== "") ? url + '&ctest=' + ctest + '&qntty=' + numOfQuestionsPerDay : url
    console.log('url..', url)
    const response = isLoggedIn
      ? ((pageFlag === "practice" || pageSrc === "ChildHomePage") ?
        (yield Transport.genericOperation(
          url,
          serviceConstants.RABBIT_HOMEWORK_URL_APIKEY,
          {},
          "GET"
        )) :
        (yield Transport.genericOperation(
          serviceConstants.RABBIT_HOMEWORK_URL +
          "/prepare?childId=" +
          childId +
          "&subjectCd=" +
          subjectCd +
          "&hwDate=" +
          hwDate +
          "&qPerDay=" +
          numOfQuestionsPerDay,
          serviceConstants.RABBIT_HOMEWORK_URL_APIKEY,
          {},
          "GET"
        ))
      )
      : yield Transport.genericOperation(
        url,
        serviceConstants.RABBIT_HOMEWORK_URL_APIKEY,
        {},
        "GET"
      );
    console.log("RESPONSE ", response);

    yield put(setShowLoader(false));

    yield put(homeworkActions.getHomeworkSuccess(response));
  } catch (e) {
    yield put(setShowLoader(false));
    console.error("getHomeworkSaga Saga ERROR ", e);
    throw e;
  }
}

function* saveStudentAnswerSaga(action) {
  console.log('save student answer saaaga...', action.payload.grade, action.payload.concept)
  try {
    const {
      childId,
      grade,
      day,
      questionId,
      studentAnswer,
      rightAnswer,
      numberOfAttempts,
      timeElapsed,
      concept,
      pageFlag,
      pageSrc,
    } = action.payload;
    const body = {
      childId: childId,
      grade: ((pageFlag === "practice" || pageSrc === "ChildHomePage") ? concept : grade),
      day: day,
      questionId: questionId,
      studentAnswer: studentAnswer,
      rightAnswer: rightAnswer,
      numOfAttempts: numberOfAttempts,
      timeElapsed: timeElapsed,
      concept: concept,
      //ctest: ctest,
    };

    const response = yield Transport.genericOperation(
      // "https://w951hqrwsd.execute-api.us-east-1.amazonaws.com/nonprod/rabbit/child" + "/parent/" + "ravi.tvn@gmail.com",
      serviceConstants.RABBIT_HOMEWORK_URL + "/submitone/" + childId,
      serviceConstants.RABBIT_HOMEWORK_URL_APIKEY,
      body,
      "PUT"
    );
    console.log("saveStudentAnswerSaga RESPONSE ", response);
  } catch (e) {
    console.error("saveStudentAnswerSaga Saga ERROR ", e);
    throw e;
  }
}

function* getRankingsSaga(action) {
  try {
    const { childId} =
      action.payload;
    let url = serviceConstants.RABBIT_HOMEWORK_URL + "/rankings?childId=" + childId;
    console.log('url..', url)
    const response = yield Transport.genericOperation(
      url,
      serviceConstants.RABBIT_HOMEWORK_URL_APIKEY,
      {},
      "GET")
    yield put(setShowLoader(false));

    yield put(homeworkActions.getRankingsSuccess(response));
  } catch (e) {
    yield put(setShowLoader(false));
    console.error("getHomeworkSaga Saga ERROR ", e);
    throw e;
  }
}

export default function* homeworkSaga() {
  try {
    yield takeLatest(homeworkActions.getHomework, getHomeworkSaga);
    yield takeLatest(homeworkActions.saveStudentAnswer, saveStudentAnswerSaga);
    yield takeLatest(homeworkActions.getRankings, getRankingsSaga);
  } catch {
    yield;
  }
}
