import {
    Button,
    Grid,
    makeStyles,
    TextField,
    useMediaQuery,
    useTheme,
  } from "@material-ui/core";
  import React, { useEffect, useState } from "react";
  import CorrectImage from "../../../assets/images/green-tick.png";
  import WrongImage from "../../../assets/images/red-cross.png";
  import Speech from "../../../util/speech";
  import Snackbar from '@material-ui/core/Snackbar';
  import MuiAlert from '@material-ui/lab/Alert';
  
  const useStyles = makeStyles(() => ({
    root: {
      borderRadius: 0,
    },
    input: {
      padding: 6,
      fontSize: 24,
      textAlign: "center",
    },
  }));
  
  const Time = (props) => {
    const classes = useStyles({});
    const theme = useTheme();
  
    const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
    const [currentQuestionId, setCurrentQuestionId] = useState(undefined);
    const [disableAnswer, setDisableAnswer] = useState(false);
    const [answer, setAnswer] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const [attemptCount, setAttemptCount] = useState(0);
    const [hours, setHours] = useState("");
    const [minutes, setMinutes] = useState("");
    const [seconds, setSeconds] = useState("");
    const [months, setMonths] = useState("");
    const [years, setYears] = useState("");
    const [open, setOpen] = useState(false);
    const [toastMessage, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
  
    useEffect(() => {
      if (
        props.currentHomeWork &&
        props.currentHomeWork.questionId &&
        props.currentHomeWork.questionId !== currentQuestionId
      ) {
        setCurrentQuestionId(props.currentHomeWork.questionId);
        setAttemptCount(0);
        setDisableAnswer(
          props.currentHomeWork.finalAnswer === "1" ? true : false
        );
        setShowSpinner(false);
        if (props.currentHomeWork.finalAnswer === "1" &&
          props.currentHomeWork.studentAnswer) 
        { if(props.currentHomeWork.qType === "mi2hr"){
              setHours(props.currentHomeWork.studentAnswer);
            }if(props.currentHomeWork.qType === "hr2mi" || props.currentHomeWork.qType === "se2mi"){
              setMinutes(props.currentHomeWork.studentAnswer);
            }if(props.currentHomeWork.qType === "mi2se"){
              setSeconds(props.currentHomeWork.studentAnswer);
            }if(props.currentHomeWork.qType === "yr2mn"){
              setMonths(props.currentHomeWork.studentAnswer);
            }if(props.currentHomeWork.qType === "mn2yr"){
              setYears(props.currentHomeWork.studentAnswer);
            }if((props.currentHomeWork.qType === "mi2hr" && props.currentHomeWork.secondVar === "rnd") || props.currentHomeWork.secondVar === 'hrmi' || props.currentHomeWork.qType === "elpsd"){
              setHours(props.currentHomeWork.studentAnswer.split(":")[0]);
              setMinutes(props.currentHomeWork.studentAnswer.split(":")[1]);
            }if(props.currentHomeWork.qType === "se2mi" && props.currentHomeWork.secondVar === "rnd"){
              setMinutes(props.currentHomeWork.studentAnswer.split(":")[0]);
              setSeconds(props.currentHomeWork.studentAnswer.split(":")[1]);
            }if((props.currentHomeWork.qType === "mn2yr" && props.currentHomeWork.secondVar === "rnd") || props.currentHomeWork.secondVar === 'yrmn'){
              setYears(props.currentHomeWork.studentAnswer.split(":")[0]);
              setMonths(props.currentHomeWork.studentAnswer.split(":")[1]);
            }
        } else {
          setHours("");
          setMinutes("");
          setSeconds("");
          setMonths("");
          setYears("");
        }
      }
    }, [props.currentHomeWork, currentQuestionId]);
  
    const isAnswerValid = () => {
      var isValid = false
      console.log("VALIDATING ANSWER");
  
      if (disableAnswer) {
        props.goToNextQuestion();
      } else {
            if(props.currentHomeWork.qType === "mi2hr" && hours){
                isValid=true
            }
            else if(props.currentHomeWork.qType === "hr2mi" &&  minutes){
              isValid=true
            }else if(props.currentHomeWork.qType === "mi2se" &&  seconds){
              isValid=true
            }else if(props.currentHomeWork.qType === "se2mi" &&  minutes){
              isValid=true
            }else if(props.currentHomeWork.qType === "yr2mn" &&  months){
              isValid=true
            }else if(props.currentHomeWork.qType === "mn2yr" &&  years){
              isValid=true
            }else if((props.currentHomeWork.secondVar === "hrmi" || props.currentHomeWork.qType === "elpsd") && (hours && minutes)){
              isValid=true
            }else if(props.currentHomeWork.secondVar === "yrmn" ){
              //&& (years && months)
              if((props.currentHomeWork.answer.split(":")[0]=== "0" || props.currentHomeWork.answer.split(":")[0]=== "00") && !years && months){
                isValid=true
              }else if((props.currentHomeWork.answer.split(":")[1]=== "0" || props.currentHomeWork.answer.split(":")[1]=== "00") && !months && years){
                isValid=true
              }else if(years && months){
                isValid=true
              }
              
            }
            else {
            //props.onShowToast("Enter Value", "warning");
            isValid = false
            }
      }
      console.log("isValid ===> ",isValid,hours,minutes)
      if(isValid){
        isAnswerCorrect();
      }else {
            //props.onShowToast("Enter Value", "warning");
        setMessage("Invalid Answer!. Enter Value.")
        setMessageType("warning")
        handleClick() 
      }
    };
  
    const isAnswerCorrect = () => {
      var isCorrect = true;
      console.log("CHECKING ANSWER");
      var answerString = "";
      var studentAnswer = "";

      if(props.currentHomeWork.qType === "mi2hr" && props.currentHomeWork.secondVar !== "rnd"){
        answerString = Number(hours).toString();
        studentAnswer =Number(hours);
        isCorrect = answerString && answerString === props.currentHomeWork.answer.split(":")[0];
      }if((props.currentHomeWork.qType === "mi2hr" && props.currentHomeWork.secondVar === "rnd") || props.currentHomeWork.secondVar === 'hrmi' || props.currentHomeWork.qType === "elpsd"){
        answerString = Number(hours).toString()+":"+Number(minutes).toString();
        studentAnswer =answerString;
        console.log("mi2hr and rnd ===> ",answerString,props.currentHomeWork.answer.split(" ").join(""),answerString.toString() === props.currentHomeWork.answer.split(" ").join(""))
        isCorrect = answerString && (answerString === props.currentHomeWork.answer.split(" ").join(""));
      }if(props.currentHomeWork.qType === "se2mi" && props.currentHomeWork.secondVar === "rnd"){
        answerString = Number(minutes).toString()+":"+Number(seconds).toString();
        studentAnswer =answerString;
        console.log("se2mi and rnd ===> ",studentAnswer,props.currentHomeWork.answer)
        isCorrect = answerString && (answerString === props.currentHomeWork.answer);
      }if((props.currentHomeWork.qType === "mn2yr" && props.currentHomeWork.secondVar === "rnd") || props.currentHomeWork.secondVar === 'yrmn'){
        answerString = Number(years).toString()+":"+Number(months).toString();
        studentAnswer =answerString;
        console.log("mn2yr and rnd ===> ",studentAnswer,props.currentHomeWork.answer)
        isCorrect = answerString && (answerString === props.currentHomeWork.answer.split(" ").join(""));
      }


      if((props.currentHomeWork.qType === "hr2mi" || props.currentHomeWork.qType === "se2mi") && props.currentHomeWork.secondVar !== "rnd"){
        answerString = Number(minutes).toString();
        studentAnswer =Number(minutes);
        isCorrect = answerString && answerString === props.currentHomeWork.answer;
      }
      
      if(props.currentHomeWork.qType === "mi2se" && props.currentHomeWork.secondVar !== "rnd"){
        answerString = Number(seconds).toString();
        studentAnswer =Number(seconds);
        isCorrect = answerString && answerString === props.currentHomeWork.answer;
      }if(props.currentHomeWork.qType === "yr2mn" && props.currentHomeWork.secondVar !== "rnd"){
        answerString = Number(months).toString();
        studentAnswer =Number(months);
        isCorrect = answerString && answerString === props.currentHomeWork.answer;
      }if(props.currentHomeWork.qType === "mn2yr" && props.currentHomeWork.secondVar !== "rnd"){
        answerString = Number(years).toString();
        studentAnswer =Number(years);
        isCorrect = answerString && answerString === props.currentHomeWork.answer;
      }
        
      console.log("STU ", answer, "  COR ");
  
      
  
      if (isCorrect) {
        console.log("IS CORRECT, GO TO NEXT QUESTION");
        props.onSubmitQuestion(
          studentAnswer,
          attemptCount + 1,
          true,
          props.currentHomeWork.questionId
        );
        setMessage("Correct Answer!. Good job.")
        setMessageType("success")
        handleClick()
      } else {
        console.log("WRONG ANSWER ");
        setAttemptCount(attemptCount + 1);
  
        if (attemptCount >= 2) {
          //props.onShowToast("Wrong Answer!. Next Question.", "danger");
          setMessage("Wrong Answer!. Try Next Question.")
          setMessageType("error")
          handleClick()
          props.onSubmitQuestion(
            studentAnswer,
            attemptCount,
            false,
            props.currentHomeWork.questionId
          );
        } else {
          //props.onShowToast("Wrong Answer!. Try Again.", "warning");
          setMessage("Wrong Answer!. Try Again.")
          setMessageType("warning")
          handleClick()
        }
      }
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
  
    const handleClick = () => {
      setOpen(true);
    };
  
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
  
    const clearAnswer = () => {
      setHours("");
      setMinutes("");
      setSeconds("");
      setMonths("");
      setYears("");
    };

    const renderInput = () => {
        if((props.currentHomeWork.qType === "mi2hr" && props.currentHomeWork.secondVar === "rnd") || props.currentHomeWork.secondVar === 'hrmi' || props.currentHomeWork.qType === "elpsd")
        {   return <div
                style={{
                flexDirection: "row",
                alignSelf: "center",
                display: "flex",
                alignItems: "center",
                marginTop: 24,
                }}
            >
                <TextField
                name={`hours-${props.currentHomeWork.questionId}`}
                value={hours}
                variant="outlined"
                //   value={answer[index] ? answer[index] : ""}
                style={{
                    textAlign: "center",
                }}
                InputProps={{
                    classes: {
                    input: classes.input,
                    },
                }}
                multiline={false}
                disabled={disableAnswer}
                onChange={(e) => {
                    console.log("ON hours CHANGE ", e.target.value, "---", e);
                    setHours(e.target.value);
                }}
                placeholder="hrs"
                />
                <div
                style={{
                    marginLeft: 16,
                    marginRight: 16,
                    fontSize: 24,
                    fontWeight: "bolder",
                }}
                >
                :
                </div>
                <TextField
                name={`minutes-${props.currentHomeWork.questionId}`}
                value={minutes}
                variant="outlined"
                //   value={answer[index] ? answer[index] : ""}
                style={{
                    textAlign: "center",
                }}
                InputProps={{
                    classes: {
                    input: classes.input,
                    },
                }}
                multiline={false}
                disabled={disableAnswer}
                onChange={(e) => {
                    console.log("ON minutes CHANGE ", e.target.value, "---", e);
                    setMinutes(e.target.value);
                }}
                placeholder="min"
                />
            </div>
          }else if(props.currentHomeWork.qType === "se2mi" && props.currentHomeWork.secondVar === "rnd")
          {   return <div
                  style={{
                  flexDirection: "row",
                  alignSelf: "center",
                  display: "flex",
                  alignItems: "center",
                  marginTop: 24,
                  }}
              >
                  <TextField
                  name={`minutes-${props.currentHomeWork.questionId}`}
                  value={minutes}
                  variant="outlined"
                  //   value={answer[index] ? answer[index] : ""}
                  style={{
                      textAlign: "center",
                  }}
                  InputProps={{
                      classes: {
                      input: classes.input,
                      },
                  }}
                  multiline={false}
                  disabled={disableAnswer}
                  onChange={(e) => {
                      console.log("ON minutes CHANGE ", e.target.value, "---", e);
                      setMinutes(e.target.value);
                  }}
                  placeholder="minutes"
                  />
                  <div
                  style={{
                      marginLeft: 16,
                      marginRight: 16,
                      fontSize: 24,
                      fontWeight: "bolder",
                  }}
                  >
                  :
                  </div>
                  <TextField
                  name={`seconds-${props.currentHomeWork.questionId}`}
                  value={seconds}
                  variant="outlined"
                  //   value={answer[index] ? answer[index] : ""}
                  style={{
                      textAlign: "center",
                  }}
                  InputProps={{
                      classes: {
                      input: classes.input,
                      },
                  }}
                  multiline={false}
                  disabled={disableAnswer}
                  onChange={(e) => {
                      console.log("ON seconds CHANGE ", e.target.value, "---", e);
                      setSeconds(e.target.value);
                  }}
                  placeholder="seconds"
                  />
              </div>
            }else if((props.currentHomeWork.qType === "mn2yr" && props.currentHomeWork.secondVar === "rnd") || props.currentHomeWork.secondVar === 'yrmn')
            {   return <div
                    style={{
                    flexDirection: "row",
                    alignSelf: "center",
                    display: "flex",
                    alignItems: "center",
                    marginTop: 24,
                    }}
                >
                    <TextField
                    name={`years-${props.currentHomeWork.questionId}`}
                    value={years}
                    variant="outlined"
                    //   value={answer[index] ? answer[index] : ""}
                    style={{
                        textAlign: "center",
                    }}
                    InputProps={{
                        classes: {
                        input: classes.input,
                        },
                    }}
                    multiline={false}
                    disabled={disableAnswer}
                    onChange={(e) => {
                        console.log("ON years CHANGE ", e.target.value, "---", e);
                        setYears(e.target.value);
                    }}
                    placeholder="years"
                    />
                    <div
                    style={{
                        marginLeft: 16,
                        marginRight: 16,
                        fontSize: 24,
                        fontWeight: "bolder",
                    }}
                    >
                    /
                    </div>
                    <TextField
                    name={`months-${props.currentHomeWork.questionId}`}
                    value={months}
                    variant="outlined"
                    //   value={answer[index] ? answer[index] : ""}
                    style={{
                        textAlign: "center",
                    }}
                    InputProps={{
                        classes: {
                        input: classes.input,
                        },
                    }}
                    multiline={false}
                    disabled={disableAnswer}
                    onChange={(e) => {
                        console.log("ON months CHANGE ", e.target.value, "---", e);
                        setMonths(e.target.value);
                    }}
                    placeholder="months"
                    />
                </div>
              }
          
          
          else if(props.currentHomeWork.qType === "hr2mi" || props.currentHomeWork.qType === "se2mi")
            {  return <div
                style={{
                    flexDirection: "row",
                    alignSelf: "center",
                    display: "flex",
                    alignItems: "center",
                    marginTop: 24,
                }}
                >
                <TextField
                    name={`minutes-${props.currentHomeWork.questionId}`}
                    value={minutes}
                    variant="outlined"
                    //   value={answer[index] ? answer[index] : ""}
                    style={{
                    textAlign: "center",
                    }}
                    InputProps={{
                    classes: {
                        input: classes.input,
                    },
                    }}
                    multiline={false}
                    disabled={disableAnswer}
                    onChange={(e) => {
                    console.log("ON hours CHANGE ", e.target.value, "---", e);
                    setMinutes(e.target.value);
                    }}
                    placeholder="mins"
                />
                </div>

          }else if(props.currentHomeWork.qType === "mi2hr")
          {  return <div
              style={{
                  flexDirection: "row",
                  alignSelf: "center",
                  display: "flex",
                  alignItems: "center",
                  marginTop: 24,
              }}
              >
              <TextField
                  name={`hours-${props.currentHomeWork.questionId}`}
                  value={hours}
                  variant="outlined"
                  //   value={answer[index] ? answer[index] : ""}
                  style={{
                  textAlign: "center",
                  }}
                  InputProps={{
                  classes: {
                      input: classes.input,
                  },
                  }}
                  multiline={false}
                  disabled={disableAnswer}
                  onChange={(e) => {
                  console.log("ON hours CHANGE ", e.target.value, "---", e);
                  setHours(e.target.value);
                  }}
                  placeholder="hrs"
              />
              </div>
        }else if(props.currentHomeWork.qType === "mi2se")
        {  return <div
            style={{
                flexDirection: "row",
                alignSelf: "center",
                display: "flex",
                alignItems: "center",
                marginTop: 24,
            }}
            >
            <TextField
                name={`seconds-${props.currentHomeWork.questionId}`}
                value={seconds}
                variant="outlined"
                //   value={answer[index] ? answer[index] : ""}
                style={{
                textAlign: "center",
                }}
                InputProps={{
                classes: {
                    input: classes.input,
                },
                }}
                multiline={false}
                disabled={disableAnswer}
                onChange={(e) => {
                console.log("ON seconds CHANGE ", e.target.value, "---", e);
                setSeconds(e.target.value);
                }}
                placeholder="secs"
            />
            </div>

      }else if(props.currentHomeWork.qType === "yr2mn")
      {  return <div
          style={{
              flexDirection: "row",
              alignSelf: "center",
              display: "flex",
              alignItems: "center",
              marginTop: 24,
          }}
          >
          <TextField
              name={`months-${props.currentHomeWork.questionId}`}
              value={months}
              variant="outlined"
              //   value={answer[index] ? answer[index] : ""}
              style={{
              textAlign: "center",
              }}
              InputProps={{
              classes: {
                  input: classes.input,
              },
              }}
              multiline={false}
              disabled={disableAnswer}
              onChange={(e) => {
              console.log("ON months CHANGE ", e.target.value, "---", e);
              setMonths(e.target.value);
              }}
              placeholder="months"
          />
          </div>

    }else if(props.currentHomeWork.qType === "mn2yr")
    {  return <div
        style={{
            flexDirection: "row",
            alignSelf: "center",
            display: "flex",
            alignItems: "center",
            marginTop: 24,
        }}
        >
        <TextField
            name={`years-${props.currentHomeWork.questionId}`}
            value={years}
            variant="outlined"
            //   value={answer[index] ? answer[index] : ""}
            style={{
            textAlign: "center",
            }}
            InputProps={{
            classes: {
                input: classes.input,
            },
            }}
            multiline={false}
            disabled={disableAnswer}
            onChange={(e) => {
            console.log("ON years CHANGE ", e.target.value, "---", e);
            setYears(e.target.value);
            }}
            placeholder="years"
        />
        </div>

  }
      };
  
    return (
      <Grid
        container
        style={{
          padding: 25,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Grid container style={{ padding: 16 }}>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: mobileDevice ? "column" : "row",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => clearAnswer()}
              style={{ marginRight: 16 }}
              disabled={disableAnswer}
            >
              CLEAR
            </Button>
            <Button variant="outlined" color="primary" onClick={isAnswerValid}>
              {disableAnswer ? "NEXT" : "SUBMIT"}
            </Button>
            <div
              style={{ marginLeft: 16, display: "flex", alignItems: "center" }}
            >
              {attemptCount > 0 ? "Wrong Attempts: " + attemptCount : ""}
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignSelf: "flex-start",
              flexDirection: mobileDevice ? "column" : "row",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => props.showHelp()}
              style={{ marginRight: 16 }}
            >
              HELP
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                  Speech(props.currentHomeWork.voiceOver);
                }
              }}
            >
              READ OUT
            </Button>
            </Grid>
        </Grid>
  
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ marginBottom: 15 }}>
            <div style={{ fontSize: 24, textAlign: "center" }}>
              {props.currentHomeWork.question}
            </div>
            { (props.currentHomeWork.qType === "add" || props.currentHomeWork.qType === "sub") &&
              <div style={{ fontSize: 30, textAlign: "center" }}>
              {props.currentHomeWork.strList}
            </div>
            }
          </div>
          <div
            style={{
              alignSelf: "center",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            {/* ---------------------------- QUESTION TEXT VIEW - ITEM  1 ---------------------------- */}
  
            {renderInput()}
          </div>
          
          <div
            style={{
              flexDirection: "row",
              justifyContent: "center",
              display: "flex",
              marginTop: 20,
            }}
          >
            {/* ------------- ANSWER IMAGE RIGHT OR WRONG */}
            <div style={{ marginLeft: 20 }}>
              {disableAnswer && props.currentHomeWork.isRightAnswer && (
                <img alt="correct-answer" src={CorrectImage} />
              )}
  
              {disableAnswer && !props.currentHomeWork.isRightAnswer && (
                <img alt="wrong-answer" src={WrongImage} />
              )}
            </div>
            {
              // ------------------   CORRECT ANSWER
              disableAnswer && !props.currentHomeWork.isRightAnswer && (
                <div
                  style={{
                    alignSelf: "center",
                    marginLeft: 50,
                    flexDirection: "row",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div style={{ fontSize: 18 }}>Correct Answer</div>
                  <div style={{ fontSize: 32, marginLeft: 16 }}>
                      {((props.currentHomeWork.qType ==="mn2yr" &&  props.currentHomeWork.secondVar ==="rnd") || props.currentHomeWork.secondVar === "yrmn") ?
                            props.currentHomeWork.answer.replace(":"," / ") :  props.currentHomeWork.answer
                       }
                    {/* {`${props.currentHomeWork.varList[0]}  :  ${props.currentHomeWork.varList[1]}`} */}
                  </div>
                </div>
              )
            }
          </div>
        </Grid>
        <Grid>
        <Snackbar
         open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert 
          key={`toastMessage-${toastMessage}`}
          onClose={handleClose} 
          severity={messageType}>
            {toastMessage}
          </Alert>
        </Snackbar>
        </Grid>
      </Grid>
    );
  };
  
  export default Time;
  