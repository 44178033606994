import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
} from "@material-ui/core";
import {
    getForgotPasswordResponse,
    getForgotPasswordFailure,
    getForgotPasswordSuccess,
} from "../../../reducer/selectors/login.entity";
import { attemptForgotPassword } from "../../../actions/login.actions";
import { getUserId } from "../../../reducer/selectors/user.entity";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ChangePassword = (props) => {
    const dispatch = useDispatch();
    const userId = useSelector(getUserId);

    const [showSnackbarReset, setShowSnackbarReset] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [emailId, setEmailId] = useState(false);
    const [emailIdError, setEmailIdError] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [isFormInvalid, setIsFormInvalid] = useState(false);

    const forgotPswdFailure = useSelector(getForgotPasswordFailure);
    const forgotPswdSuccess = useSelector(getForgotPasswordSuccess);
    const forgotPswdResponse = useSelector(getForgotPasswordResponse);

    useEffect(() => {
        console.log("change password");
    }, []);
    useEffect(() => {
        console.log(
            "forgotPswdFailure ---> ",
            forgotPswdFailure,
            " ========= ",
            forgotPswdSuccess,
            "===== ",
            forgotPswdResponse
        );
        if (forgotPswdFailure) {
            setShowForgotPassword(true);
            setIsFormInvalid(true);
            setEmailIdError("Enter Registered Email Id to change password.");
        }
        if (forgotPswdSuccess) {
            setShowForgotPassword(false);
            setShowSnackbarReset(true);
        }
    }, [forgotPswdFailure, forgotPswdSuccess]);

    const onSubmitForgotPswd = () => {
        setEmailId(userId);
        const isEmailValid = emailId && emailRegex.test(emailId);
        console.log("email id ===> ", emailId);
        if (isEmailValid && emailId) {
            setEmailIdError("");

            dispatch(attemptForgotPassword({ emailId }));
            props.onClose();
            console.log("attemptForgotPasswordSuccess ---> ", forgotPswdSuccess);
        } else {
            setEmailIdError(isEmailValid ? "" : "Email Id is not valid.");
            setIsFormInvalid(true);
        }
    };

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={() => props.onClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Change Password"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Change Password email will be sent to {userId}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.onClose()} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onSubmitForgotPswd} color="primary" autoFocus>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default ChangePassword;
