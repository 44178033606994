import React, { useState, Fragment } from 'react';
import { Grid, Button } from '@material-ui/core'
import ModalVideo from 'react-modal-video'
import { Helmet } from 'react-helmet';
import './style.scss'
import Breadcumb from '../Breadcumb';
import Funfact from '../Theme/Funfact';
import Testmonial from '../Theme/Testmonial';

// images 
import about from './../../../images/aboutus.jpg'

const menus = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'About Us',
    },
]

const AboutContent = () => {
    const [open, setOpen] = useState(false)
    return (
        <Fragment>
            <Helmet>
                <title>About us</title>
            </Helmet>
            <Breadcumb
                title="About Us"
                menus={menus}
            />
            <Grid className="aboutArea">
                <Grid container spacing={4} className="container">
                    <Grid item lg={6} md={6} xs={12}>
                        <Grid className="aboutContent">
                            <h2>About <span>Edulyn</span></h2>
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. </p>
                            <ul>
                                <li>Various versions have evolved over the years.</li>
                                <li>Sometimes by accident, sometimes on purpose. </li>
                                <li>Injected humour and the like.</li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                        <Grid style={{ background: `url(${about}) no-repeat center center / cover` }} className="aboutImg">
                            <Button
                                onClick={() => setOpen(true)}>
                                <i className="fa fa-play"></i>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <ModalVideo
                    channel='youtube'
                    isOpen={open}
                    videoId='XOStXaZ25cw'
                    onClose={() => setOpen(false)}
                />
            </Grid>
            <Funfact className="funfactAreaStyleTwo" />
            {/* <Team /> */}
            {/* <Testmonial /> */}
        </Fragment>
    )
}


export default AboutContent;
