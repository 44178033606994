import { createAction } from "@reduxjs/toolkit";
import { homeworkConstants } from "../constants";

export const getHomework = createAction(
  homeworkConstants.GET_HOMEWORK,
  (payload) => ({ payload })
);
export const getHomeworkSuccess = createAction(
  homeworkConstants.GET_HOMEWORK_SUCCESS,
  (payload) => ({ payload })
);

export const saveStudentAnswer = createAction(
  homeworkConstants.SAVE_STUDENT_ANSWER,
  (payload) => ({ payload })
);

export const getRankings = createAction(
  homeworkConstants.GET_RANKINGS,
  (payload) => ({ payload })
);

export const getRankingsSuccess = createAction(
  homeworkConstants.GET_RANKINGS_SUCCESS,
  (payload) => ({ payload })
);
