export function getChildList({ parentReducer }) {
  if (
    parentReducer &&
    parentReducer.parentInfo &&
    parentReducer.parentInfo.children
  )
    return parentReducer.parentInfo.children;
  else return [];
}

export function getIapInfo({ parentReducer }) {
  if (
    parentReducer &&
    parentReducer.parentInfo &&
    parentReducer.parentInfo.iapInfo
  )
    return parentReducer.parentInfo.iapInfo;
  else return [];
}

export function getSubscriptionInfo({ parentReducer }) {
  if (
    parentReducer &&
    parentReducer.parentInfo &&
    parentReducer.parentInfo.subscriptionInfo
  )
    return parentReducer.parentInfo.subscriptionInfo;
  else return undefined;
}

export function getIsSubscriptionActive({ parentReducer }) {
  if (
    parentReducer &&
    parentReducer.parentInfo &&
    parentReducer.parentInfo.subscriptionInfo &&
    parentReducer.parentInfo.subscriptionInfo.status === "1" &&
    parentReducer.parentInfo.subscriptionInfo.subscriptionPlanId
  )
    return true;
  else return false;
}

export function getParentPaymentInfo({ parentReducer }) {
  if (
    parentReducer &&
    parentReducer.parentInfo &&
    parentReducer.parentInfo.parentPaymentInfo
  )
    return parentReducer.parentInfo.parentPaymentInfo;
  else return undefined;
}

export function getAddNewPaymentSuccess({ parentReducer }) {
  if (parentReducer && parentReducer.addNewPaymentSuccess)
    return true
  return false
}

export function getAddNewPaymentError({ parentReducer }) {
  if (parentReducer && parentReducer.addNewPaymentError)
    return parentReducer.addNewPaymentError
  return ""
}

export function getCreateSubscriptionSuccess({ parentReducer }) {
  if (parentReducer && parentReducer.createSubscriptionSuccess)
    return true
  return false
}

export function getCreateSubscriptionError({ parentReducer }) {
  if (parentReducer && parentReducer.createSubscriptionError)
    return parentReducer.createSubscriptionError
  return ""
}
