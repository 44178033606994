import {
    Button,
    Grid,
    makeStyles,
    TextField,
    useMediaQuery,
    useTheme,
  } from "@material-ui/core";
  import React, { useEffect, useState } from "react";

  import Speech from "../../../util/speech";
  import Snackbar from '@material-ui/core/Snackbar';
  import MuiAlert from '@material-ui/lab/Alert';
  import { PieChart } from 'react-minimal-pie-chart';
  
  const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
      fontSize: 40,
      textAlign: "center",
    },
  }));
  
  const Fractions = (props) => {
    const classes = useStyles({});
    const theme = useTheme();
    const topFraFlag = Math.random() < 0.5
    const whlNumFlag = topFraFlag ? false : true
  
    const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
    const [currentQuestionId, setCurrentQuestionId] = useState(undefined);
    const [disableAnswer, setDisableAnswer] = useState(false);
    const [displayAnswer, setDisplayAnswer] = useState("");
    const [answer, setAnswer] = useState("");
    const [maxNumberLength, setMaxNumberLength] = useState(0);
    const [showSpinner, setShowSpinner] = useState(false);
    const [attemptCount, setAttemptCount] = useState(0);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [studentAnswer, setStudentAnswer] = useState("");
    const [showHands, setShowHands] = useState(false);
    const [question, setQuestion] = useState("");
    const [open, setOpen] = useState(false);
    const [toastMessage, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const [topNumber, setTopNumber] = useState("");
    const [bottomNumber, setBottomNumber] = useState("");
  
    useEffect(() => {
        console.log("student answer = ",props.currentHomeWork.studentAnswer)
      if (
        props.currentHomeWork &&
        props.currentHomeWork.questionId &&
        props.currentHomeWork.questionId !== currentQuestionId
      ) {
        setStudentAnswer(
          props.currentHomeWork.studentAnswer
            ? props.currentHomeWork.studentAnswer
            : ""
        );
        setCurrentQuestionId(props.currentHomeWork.questionId);
        setShowHands(true);
        setQuestion(props.currentHomeWork.firstVar);
        setAttemptCount(0);
        setAnswer(
          props.currentHomeWork.finalAnswer === "1"
            ? props.currentHomeWork.studentAnswer
            : ""
        );
        setDisableAnswer(
          props.currentHomeWork.finalAnswer === "1" ? true : false
        );
        setDisplayAnswer(
          props.currentHomeWork.finalAnswer === "1"
            ? props.currentHomeWork.answer
            : ""
        );
        setTopNumber((props.currentHomeWork.finalAnswer === '1') ? 
        ((props.currentHomeWork.qType=="mixd" || props.currentHomeWork.qType=="prtl")? 
        props.currentHomeWork.studentAnswer.split(' ')[1].split('/')[0] :
         props.currentHomeWork.studentAnswer.split('/')[0]) : 
         ((topFraFlag && props.currentHomeWork.qType=="prtl") ? 
          props.currentHomeWork.answer.split(' ')[1].split('/')[0] :''),
        )

        setBottomNumber((props.currentHomeWork.finalAnswer === '1') ? 
        ( (props.currentHomeWork.qType=="mixd" || props.currentHomeWork.qType=="prtl") ? 
        props.currentHomeWork.studentAnswer.split(' ')[1].split('/')[1] :  
       ( !props.currentHomeWork.studentAnswer.split('/')[1] ? " " : props.currentHomeWork.studentAnswer.split('/')[1])) : (
             props.currentHomeWork.qType=="prtl"? 
             props.currentHomeWork.answer.split(' ')[1].split('/')[1] :''))
  
        setShowSpinner(false);
      }
    }, [props.currentHomeWork]);
  
    const isAnswerValid = () => {
      var isValid = false;
      
      if(props.currentHomeWork.qType !== "shpprts") {
        if(!props.currentHomeWork.answer.split("/")[1] && !bottomNumber && topNumber){
        console.log("in bottom num zero ANSWER...", topNumber,bottomNumber," === ",props.currentHomeWork.answer.split("/")[1]);
            //setBottomNumber("0")
            isValid =true
        }
        else if(topNumber && bottomNumber){
            isValid = true
        }
      }else{
        if(answer){
          isValid=true
        }
      }
      
      console.log("VALIDATING ANSWER...", answer,topNumber," >>> ",bottomNumber," === ",props.currentHomeWork.answer.split("/")[1]);
      if (isValid){
         isAnswerCorrect();
        }
      else{
          setMessage("Invalid Answer!. Enter Value.")
          setMessageType("warning")
          handleClick() 
      }
    };
  
    const isAnswerCorrect = () => {
      var isCorrect = false;
      var answerString = '';
      console.log("CHECKING ANSWER");
      if(props.currentHomeWork.qType !== "shpprts") {
      if( (!bottomNumber || (bottomNumber==1)) && !props.currentHomeWork.answer.split("/")[1]  ){
        setAnswer(topNumber)
         answerString = topNumber;
      }else{
        setAnswer(topNumber+"/"+bottomNumber)
         answerString = topNumber+"/"+bottomNumber;
      }
    }else{
      answerString = answer
    }
      
      console.warn("answerString ", answerString,props.currentHomeWork.answer);
      isCorrect =
        answerString &&
        answerString.length > 0 &&
        answerString === props.currentHomeWork.answer;
      if (isCorrect) {
        console.log("IS CORRECT, GO TO NEXT QUESTION");
        props.onSubmitQuestion(
          answerString,
          attemptCount + 1,
          true,
          props.currentHomeWork.questionId
        );
        setMessage("Correct Answer!. Good job.")
        setMessageType("success")
        handleClick()
      } else {
        console.log("WRONG ANSWER ");
        setAttemptCount(attemptCount + 1);
  
        if (attemptCount >= 2) {
          // props.onShowToast("Wrong Answer!. Next Question.", "danger");
          setMessage("Wrong Answer!. Try Next Question.")
          setMessageType("error")
          handleClick()
          props.onSubmitQuestion(
            answerString,
            attemptCount,
            false,
            props.currentHomeWork.questionId
          );
        } else {
          // props.onShowToast("Wrong Answer!. Try Again.", "warning");
          setMessage("Wrong Answer!. Try Again.")
          setMessageType("warning")
          handleClick()
        }
      }
    };

    const rendorFractionsQuestion = () => {
        return props.currentHomeWork.strList.map(item => {
            console.log(" item from fract ===> ",item, (item.toString()==" / ")  )
           
            const itemList = item==" / " ? item.trim() : item.split('/')
            console.log(" itemList.length ===> ",itemList.length)
            if (itemList.length > 1) {
                return <div style={{ flexDirection: "column" ,margin:30}}>
                    <div style={{ textDecoration:"underline" , fontSize: 60, textAlign: 'center', color: "black" }}>{itemList[0]}</div>
                    <div style={{ fontSize: 60, textAlign: 'center', color: "black" }}>{itemList[1]}</div>
                </div>
            } else {
                return <div style={{ flexDirection: "column" }}>
                    <div style={{ fontSize: 60, marginRight: 5, textAlign: 'center', color: "black" }}>{props.currentHomeWork.strList[1]}</div>
                </div>
            }
        })

    }
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
  
    const handleClick = () => {
      setOpen(true);
    };
  
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
  
    const clearAnswer = () => {
      setAnswer("");
      setTopNumber("");
      setBottomNumber("");
      console.log("CLEAR ANSWER CALLED");
    };
  
    const addAnswerToState = (event) => {
      setAnswer(event.target.value);
    };
  
    const toggleHelpModal = (flag) => {
      setShowHelpModal(flag);
    };

    const onChngTopNumber = (event) => {
        setTopNumber(event.target.value);
      };

    const onChngBotNumber = (event) => {
        setBottomNumber(event.target.value);
      };

    const onSelection = (val) => {
      console.log("onSelection = ",val)
        setAnswer(val);
      };

      const rendorOptions = () => {
        return <div style={{ display: "inline-flex", flexDirection: "row", alignSelf:"center" }}>
            {props.currentHomeWork.strList.map((item, index) => {
              return (
                <div key={`answer-${index}-${answer[index]}`}>
                  <Button
                    variant={disableAnswer ? "outlined" : "contained"}
                    color={
                      disableAnswer
                        ? item === props.currentHomeWork.answer
                          ? "default"
                          : "secondary"
                        : answer === item
                        ? "inherit"
                        : "primary"
                    }
                    style={{
                      width: 100,
                      borderWidth: 2,
                      borderRadius: 5,
                      paddingVertical: 20,
                      justifyContent: "center",
                      margin: 5,
                    }}
                    onClick={() => onSelection(item)}
                  >
                    {<div style={{ flexDirection: "column", lineHeight: 1}}>
                          <div style={{ textDecoration:"underline" , fontSize: 30, textAlign: 'center' }}>{"_"+item.split("/")[0]+"_"}</div>
                          <div style={{ fontSize: 30, textAlign: 'center' }}>{item.split("/")[1]}</div>
                      </div>}
                  </Button>
                </div>
              );
            })}
          </div>
    };

    const renderPieChart = () => {
        var a = []
        var b = []
        props.currentHomeWork.varList.map((item,i) => { 
            console.log("@@@@@@@@@@@@@@+++++++ item  @@@@@@@@@@@@@@+++++++",item,100/props.currentHomeWork.varList.length)
            a.push(100/props.currentHomeWork.varList.length)
        if(item === 1){
            b[i]=("#3c4caa")
        }else if(item !== 1){
            b[i]=("#d9ddf2")
        }
        })
        console.log("renderPieChart >>>>>> a =======> ",a,b)
    
    
        const data = a
    
        const randomColor = () => ('#' + ((Math.random() * 0xffffff) << 0).toString(16) + '000000').slice(0, 7)
        //const randomColor = () =>( if())
    
        const pieData = data
            .filter((value) => value > 0)
            .map((value, index) => ({
                value,
                color: b[index]
            }))
    
        return <PieChart style={{ height: 400}} radius="30" segmentsShift="1" data={pieData} innerRadius={0}  />
    }
  
    return (
      <Grid
        container
        style={{
          padding: 25,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Grid container style={{ padding: 16 }}>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: mobileDevice ? "column" : "row",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => clearAnswer()}
              style={{ marginRight: 16 }}
              disabled={disableAnswer}
            >
              CLEAR
            </Button>
            <Button variant="outlined" color="primary" onClick={isAnswerValid}>
              {disableAnswer ? "NEXT" : "SUBMIT"}
            </Button>
            <div
              style={{ marginLeft: 16, display: "flex", alignItems: "center" }}
            >
              {disableAnswer
                ? ""
                : attemptCount > 0
                ? "Attempts Remaining: " +
                  (props.currentHomeWork.numOfAttemptsAllowed - attemptCount)
                : "Attempts Remaining: " +
                  props.currentHomeWork.numOfAttemptsAllowed}
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignSelf: "flex-start",
              flexDirection: mobileDevice ? "column" : "row",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => props.showHelp()}
              style={{ marginRight: 16 }}
            >
              HELP
            </Button>
            <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                Speech(props.currentHomeWork.voiceOver);
              }
            }}
          >
            READ OUT
          </Button>
          </Grid>
        </Grid>
        <Grid container style={{ flexDirection: "column" }}>
          <div style={{ fontSize: 30, margin: 10, alignSelf: "center" }}>
            {/* ---------------------------- QUESTION TEXT VIEW - ITEM  1 ---------------------------- */}
            {props.currentHomeWork.question}
          </div>
          <div style={{ fontSize: 20, margin: 5, alignSelf: "center" }}>
            {/* ---------------------------- QUESTION TEXT VIEW - ITEM  1 ---------------------------- */}
             (Reduce the answer to lowest form)
          </div>
          { props.currentHomeWork.qType === "shpprts" &&
            <div padding="5">
              {renderPieChart()}
            </div>
          }
          { props.currentHomeWork.qType !== "shpprts" &&
            <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
            >
            { props.currentHomeWork.qType !== "impr" &&
                rendorFractionsQuestion()
            }
            <div style={{ marginLeft: 20, alignSelf: "center",flexDirection: "column"  }}>
              {/* ---------------------------- ANSWER TEXT INPUT VIEW ---------------------------- */}


              { props.currentHomeWork.qType === "impr" &&
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                > 
                <div style={{fontSize:40, margin: 10,display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",}}>
                  {props.currentHomeWork.firstVar}
                <div style={{ fontSize:30, margin: 10,display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",}}> 
                    <div style={{textDecoration:"underline", textAlign: 'left', color: "black"}}>{props.currentHomeWork.secondVar ? props.currentHomeWork.secondVar.split("/")[0] : ""}</div>
                    <div>{props.currentHomeWork.secondVar ? props.currentHomeWork.secondVar.split("/")[1] : ""}</div>
                    
                </div>
=
                </div>

                <div style={{fontSize:30, display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",}} >
                                   
                                   <div>
                                      <TextField
                                        variant="outlined"
                                        style={{
                                        fontSize: 30,
                                        width: 100,
                                        textAlign: "center",
                                        borderColor: "green",
                                        borderWidth: 2,
                                        color: "black",
                                        marginTop:5,
                                        }}
                                        value={topNumber}
                                        InputProps={{
                                        classes: {
                                            input: classes.input,
                                        },
                                        }}
                                        multiline={false}
                                        disabled={disableAnswer}
                                        onChange={onChngTopNumber}
                                    ></TextField>
                                </div>
                                <div>
                                    <TextField
                                        variant="outlined"
                                        style={{
                                        fontSize: 30,
                                        width: 100,
                                        textAlign: "center",
                                        borderColor: "green",
                                        borderWidth: 2,
                                        color: "black",
                                        marginTop:5,
                                        }}
                                        value={bottomNumber}
                                        InputProps={{
                                        classes: {
                                            input: classes.input,
                                        },
                                        }}
                                        multiline={false}
                                        disabled={disableAnswer}
                                        onChange={onChngBotNumber}
                                    ></TextField>
                                    </div>
                              </div>
                </div>
              }
              { props.currentHomeWork.qType !== "mixd" && props.currentHomeWork.qType !== "shpprts" 
              && props.currentHomeWork.qType !== "prtl" && props.currentHomeWork.qType !== "impr" &&
                              <div>
                                
                                    <div>
                                    <TextField
                                        variant="outlined"
                                        style={{
                                        fontSize: 30,
                                        width: 100,
                                        textAlign: "center",
                                        borderColor: "green",
                                        borderWidth: 2,
                                        color: "black",
                                        }}
                                        value={topNumber}
                                        InputProps={{
                                        classes: {
                                            input: classes.input,
                                        },
                                        }}
                                        multiline={false}
                                        disabled={disableAnswer}
                                        onChange={onChngTopNumber}
                                    ></TextField>
                                </div>
                                <div>
                                    <TextField
                                        variant="outlined"
                                        style={{
                                        fontSize: 30,
                                        width: 100,
                                        textAlign: "center",
                                        borderColor: "green",
                                        borderWidth: 2,
                                        color: "black",
                                        }}
                                        value={bottomNumber}
                                        InputProps={{
                                        classes: {
                                            input: classes.input,
                                        },
                                        }}
                                        multiline={false}
                                        disabled={disableAnswer}
                                        onChange={onChngBotNumber}
                                    ></TextField>
                                    </div>
                                    </div>
            }
              </div>
            </div>
          }
          { props.currentHomeWork.qType === "shpprts" &&
             rendorOptions()
          }
          <div
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {/* ------------- ANSWER IMAGE RIGHT OR WRONG */}
            {/* <div style={{ flexDirection: "row", alignSelf: "center" }}>
                  {disableAnswer && props.currentHomeWork.isRightAnswer && (
                    <CorrectImage />
                  )}
        
                  {disableAnswer && !props.currentHomeWork.isRightAnswer && (
                    <WrongImage />
                  )}
                </div> */}
            {
              // ------------------   CORRECT ANSWER
              disableAnswer && !props.currentHomeWork.isRightAnswer && (
                <div
                  style={{
                    alignSelf: "center",
                    marginTop: 20,
                    marginLeft: 50,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontSize: 24 }}>Correct Answer :{"   "}</div>
                    <div style={{ flexDirection: "column", marginLeft:30}}>
                     { displayAnswer.split("/").length > 1 ? 
                     (<div style={{ flexDirection: "column", marginLeft:30}}>
                       <div style={{ textDecoration:"underline" , fontSize: 30, textAlign: 'left', color: "black" }}>{displayAnswer.split("/")[0]}</div>
                        <div style={{ fontSize: 30, textAlign: 'left', color: "black" }}>{displayAnswer.split("/")[1]}</div> 
                        </div>)
                :
                        (<div style={{  fontSize: 30, textAlign: 'left', color: "black" }}>{displayAnswer}</div>)
                }

                    </div>
                </div>
               
              )
            }
          </div>
        </Grid>
        <Grid>
        <Snackbar
         open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert 
          key={`toastMessage-${toastMessage}`}
          onClose={handleClose} 
          severity={messageType}>
            {toastMessage}
          </Alert>
        </Snackbar>
        </Grid>
      </Grid>
    );
  };
  
  export default Fractions;
  