import {
    
    Dialog,
    Grid,
    makeStyles,
    Paper,
    Typography,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useDispatch, useSelector } from "react-redux";
import {
    getHomework,
    saveStudentAnswer,
    setShowLoader,
} from "../../../actions";
import {
    getHomeWorkDay,
    getQuestionsList,
} from "../../../reducer/selectors/homework.entity";
import MultiLine from "../Operations/MultiLine";
import Mixopr from "../Operations/Mixopr";
import Nmbr from "../Operations/Nmbr";
import EvenOdd from "../Operations/EvenOdd";
import Equation from "../Operations/Equation";
import WriteNum from "../Operations/WriteNum";
import WriteWord from "../Operations/WriteWord";
import NumberPad from "../Operations/NumberPad";
import Standard from "../Operations/Standard";
import CommonIdfyAndSlct from "../Operations/CommonIdfyAndSlct";
import Expand from "../Operations/Expand";
import Sntns from "../Operations/Sntns";
import Money from "../Operations/Money";
import Blocks from "../Operations/Blocks";
import Gcd from "../Operations/Gcd";
import Lcm from "../Operations/Lcm";
import MultiTable from "../Operations/MultiTable";
import Rule from "../Operations/Rule";
import Clk from "../Operations/Clk";
import Shapes from "../Operations/Shapes";
import LanguageCommon from "../Operations/LanguageCommon";
import Homonyms from "../Operations/Homonyms";
import SelectSentence from "../Operations/SelectSentence";
import Compare from "../Operations/Compare";
import Fractions from "../Operations/Fractions";
import Decimal from "../Operations/Decimal";
import { useHistory, useLocation } from "react-router-dom";
import { getIsLoggedIn } from "../../../reducer/selectors/user.entity";
import { toInteger } from "lodash";
import ShowResults from "./ShowResults";
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import AddSub from "../Operations/AddSub";
import Listen from "../Operations/Listen";
import Time from "../Operations/Time";
import Skip from "../Operations/Skip";
import Spelling from "../Operations/Spelling";
import Complete from "../Operations/Complete";

//import Rating from "react-rating";
//import AddSub from "../Operations/AddSub";

// import Skip from "../Operations/Skip";
//import SelectSentence from "../Operations/SelectSentence";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
    },
    gridListTileRoot: {
        height: "140px !important",
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
    },
    gridListTileTile: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginLeft: 16,
    },
    gridList: {
        flexWrap: "nowrap",
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: "translateZ(0)",
        height: 160,
        marginTop: 16,
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    card: {
        height: 100,
        width: "100%",
        backgroundColor: "#f2f5ff",
    },
    cardCorrect: {
        height: 100,
        width: "100%",
        backgroundColor: "#f2f5ff",
        borderBottomColor: "green",
        borderBottomWidth: 8,
        borderBottomStyle: "solid",
    },
    cardWrong: {
        height: 100,
        width: "100%",
        backgroundColor: "#f2f5ff",
        borderBottomColor: "red",
        borderBottomWidth: 8,
        borderBottomStyle: "solid",
    },
    progressPrimary: {
        backgroundColor: "green",
    },
    progressSecondary: {
        backgroundColor: "red",
    },
}));
function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon />,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon />,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon />,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon />,
        label: 'Very Satisfied',
    },
};

const SpeedTest = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const isLoggedIn = useSelector(getIsLoggedIn);
    const questionsList = useSelector(getQuestionsList);
    const homeWorkDay = useSelector(getHomeWorkDay);
    const [currentQuestion, setCurrentQuestion] = useState(undefined);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
    const [showHelp, setShowHelp] = useState(false);
    const [startTime, setStartTime] = useState(new Date());
    const [progress, setProgress] = useState(0);
    const [buffer, setBuffer] = useState(10);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [wrongAnswers, setWrongAnswers] = useState(0);
    const [timeOver, setTimeover] = useState(false);

    const progressRef = useRef(() => { });
    
    useEffect(() => {
        console.log("location.state.pageSrc speed test ====  ",location.state)
        if (!location.state ||
            !location.state.childId ||
            !location.state.hwDate ||
            !location.state.subjectCd ||
            !location.state.numOfQuestionsPerDay
        ) {
            history.push({ pathname: "/" })
        }
        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0);
                setBuffer(10);
            } else {
                
                setProgress(correctAnswers);
                setBuffer(correctAnswers + wrongAnswers);
            }
        };
    });

    useEffect(() => {
        if (location.state &&
            location.state.childId &&
            location.state.hwDate &&
            location.state.subjectCd &&
            location.state.numOfQuestionsPerDay
        ) {
           // console.log("location.state.page....", location.state.page);
            dispatch(setShowLoader(true));
            dispatch(
                getHomework({
                    isLoggedIn: location.state.userType === 'guest' ? false : isLoggedIn,
                    childId: location.state.childId,
                    hwDate: location.state.hwDate,
                    subjectCd: location.state.subjectCd,
                    numOfQuestionsPerDay:
                        location.state.numOfQuestionsPerDay === "null"
                            ? null
                            : Number(location.state.numOfQuestionsPerDay),
                    ctest: location.state.ctest,
                    pageSrc: location.state.pageSrc,
                })
            );
        } else {
            history.push({ pathname: "/" })
        }
    }, [location.state]);

    useEffect(() => {
        if (questionsList && questionsList.length > 0) {
            setCurrentQuestion(questionsList[0]);
            setCurrentQuestionIndex(0);
            setStartTime(new Date());
        }
    }, [questionsList]);

    useEffect(() => {
        const timer = setInterval(() => {
            progressRef.current();
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const goToNextQuestion = () => {
        if (currentQuestionIndex < questionsList.length - 1) {
            const index = currentQuestionIndex + 1;
            setCurrentQuestion(questionsList[index]);
            setCurrentQuestionIndex(index);
            setStartTime(new Date());
        } else {
            setCurrentQuestionIndex(0);
            setCurrentQuestion(questionsList[0]);
            setStartTime(new Date());
        }
    };

    const onSubmitQuestion = (
        studentAnswer,
        numOfAttempts,
        isRightAnswer,
        questionId
    ) => {
        console.warn("SUBMITTED ", studentAnswer, numOfAttempts, isRightAnswer);
        let txtMsg = "Submitted";
        if (numOfAttempts === 1) {
            txtMsg = "Excellent Work. Try Next one";
        } else if (numOfAttempts === 2) {
            txtMsg = "Good Work. Try Next one";
        } else if (numOfAttempts === 3) {
            txtMsg = "Not Bad. Try Next one";
        }
        if (isRightAnswer) {
            setCorrectAnswers(correctAnswers + 1);
        } else {
            setWrongAnswers(wrongAnswers + 1);
        }
        // const currentTime = new Date();
        // const timeElapsed = currentTime - this.state.startTime;

        // console.log("TOAST CLOSED", timeElapsed);

        const currentTime = new Date();
        const timeElapsed = currentTime - startTime;

        dispatch(
          saveStudentAnswer({
            childId: location.state.childId,
            grade: location.state.courseName,
            day: homeWorkDay,
            subjectCd: location.state.courseId,
            questionId: questionId,
            studentAnswer,
            rightAnswer: isRightAnswer,
            numberOfAttempts: numOfAttempts,
            timeElapsed: timeElapsed,
            currentQuestionIndex,
            finalAnswer: "1",
            concept: location.state.courseName,
          })
        );

        goToNextQuestion();

        // if (isRightAnswer)
        //     this.showtoast(txtMsg, 'success')
        // else
        //     this.showtoast(txtMsg, 'danger')
    };

    const renderQuestion = () => {
        if (currentQuestion) {
            const { concept, qType } = currentQuestion;
            if (
                (concept === "add" ||
                  concept === "sub" ||
                  concept === "mul" ||
                  (concept === "div" && qType === "mltln")) &&
                qType !== "sntns" &&
                qType !== "soln" &&
                qType !== "sngln" &&
                qType !== "alpbts" &&
                qType !== "misnum"
              ) {
                return (
                  <MultiLine
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "mixopr") {
                return (
                  <Mixopr
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "nmbr") {
                return (
                  <Nmbr
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "evenodd") {
                return (
                  <EvenOdd
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "rndoff") {
                return (
                  <Mixopr
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "eqn") {
                return (
                  <Equation
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "wrtnum" || concept === "fct" || concept === "plc") {
                return (
                  <WriteNum
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "wrtwrd") {
                return (
                  <WriteWord
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (
                (concept === "add" ||
                  concept === "sub" ||
                  concept === "mul" ||
                  concept === "div") &&
                qType === "alpbts"
              ) {
                return (
                  <NumberPad
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "stndrd") {
                return (
                  <Standard
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (
                concept === "cmnidfyslct" ||
                ((concept === "add" ||
                  concept === "sub" ||
                  concept === "mul" ||
                  concept === "div") &&
                  qType === "soln")
              ) {
                return (
                  <CommonIdfyAndSlct
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "expnd") {
                return (
                  <Expand
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (
                concept === "sntns" ||
                ((concept === "add" ||
                  concept === "sub" ||
                  concept === "mul" ||
                  concept === "div") &&
                  qType === "sntns")
              ) {
                return (
                  <Sntns
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "mny") {
                return (
                  <Money
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "blck") {
                return (
                  <Blocks
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "gcd") {
                return (
                  <Gcd
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "lcm") {
                return (
                  <Lcm
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "multbl") {
                return (
                  <MultiTable
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "rule") {
                return (
                  <Rule
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "clk") {
                return (
                  <Clk
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "shp") {
                return (
                  <Shapes
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "cmp") {
                return (
                  <Compare
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "fra") {
                return (
                  <Fractions
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (
                concept === "decadd" ||
                concept === "decsub" ||
                concept === "decmul" ||
                concept === "decdiv"
              ) {
                return (
                  <Decimal
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "addsub") {
                return (
                  <AddSub
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "time") {
                return (
                  <Time
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "lisn" && qType !== "wrd") {
                return (
                  <Listen
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              }
              // else if (concept === "skp") {
              //   return (
              //     <Skip
              //       currentHomeWork={currentQuestion}
              //       onSubmitQuestion={onSubmitQuestion}
              //       goToNextQuestion={goToNextQuestion}
              //       onShowToast={() => {}}
              //       // this.showtoast}
              //       isComingFromParent={true}
              //       showHelp={() => setShowHelp(true)}
              //     />
              //   );
              // }
              else if (
                (concept === "syn" ||
                  concept === "ant" ||
                  concept === "ltrs" ||
                  concept === "chse" ||
                  concept === "sylb" ||
                  concept === "abre" ||
                  concept === "nou" ||
                  concept === "conjun" ||
                  concept === "pron" ||
                  concept === "verb" ||
                  concept === "prep" ||
                  concept === "hompne") &&
                (qType === "wrd" ||
                  qType === "slct" ||
                  qType === "lowr" ||
                  qType === "uppr" ||
                  qType === "srt" ||
                  qType === "hmny" ||
                  qType === "pic" ||
                  qType === "silt" ||
                  qType === "prfx" ||
                  qType === "sfx")
              ) {
                return (
                  <LanguageCommon
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (concept === "homnym") {
                return (
                  <Homonyms
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              } else if (
                (concept === "syn" ||
                  concept === "ant" ||
                  concept === "sylb" ||
                  concept === "abre" ||
                  concept === "nou" ||
                  concept === "conjun" ||
                  concept === "pron" ||
                  concept === "verb" ||
                  concept === "prep" ||
                  concept === "hompne" ||
                  concept === "plrl") &&
                qType === "idfy"
              ) {
                return (
                  <SelectSentence
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              }else if (concept === "skp") {
                return (
                  <Skip
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              }
              else if (concept === "spel" || (concept === "lisn" && qType === "wrd")) {
                return (
                  <Spelling
                    currentHomeWork={currentQuestion}
                    onSubmitQuestion={onSubmitQuestion}
                    goToNextQuestion={goToNextQuestion}
                    onShowToast={() => { }}
                    // this.showtoast}
                    isComingFromParent={true}
                    showHelp={() => setShowHelp(true)}
                  />
                );
              }else if (concept === "cmpl") {
                  return (
                    <Complete
                      currentHomeWork={currentQuestion}
                      onSubmitQuestion={onSubmitQuestion}
                      goToNextQuestion={goToNextQuestion}
                      onShowToast={() => { }}
                      // this.showtoast}
                      isComingFromParent={true}
                      showHelp={() => setShowHelp(true)}
                    />
                  );
                }
            // else if (concept === "nou") {
            //   return (
            //     <SelectSentence
            //       currentHomeWork={currentQuestion}
            //       onSubmitQuestion={onSubmitQuestion}
            //       goToNextQuestion={goToNextQuestion}
            //       onShowToast={() => {}}
            //       // this.showtoast}
            //       isComingFromParent={true}
            //       showHelp={() => setShowHelp(true)}
            //     />
            //   );
            // }

            // else if (((concept === 'add') || (concept === "sub") || (concept === "mul") || (concept === "div" && qType === "mltln")) && qType !== 'sntns' && qType !== 'sngln' && qType !== 'alpbts' && qType !== 'misnum')
            //     return <MultiLineAdd
            //         currentHomeWork={this.state.currentHomeWork}
            //         onHomeworkQuestionSubmit={this.onHomeworkQuestionSubmit}
            //         goToNextQuestion={this.goToNextQuestion}
            //         onShowToast={this.showtoast}
            //         isComingFromParent={this.props.isComingFromParent} />
            else
                return (
                    <div style={{ alignItems: "center", marginTop: "20%" }}>
                        <div>Please Refresh the page to see this Question.</div>
                        <div>Thanks</div>
                    </div>
                );
        } else return <div>Please select a question above</div>;
    };

    return (
        
        <Grid container style={{ padding: 16 }}>
            <Grid item xs={5}>
                <Typography variant="h4">
                    {location.state && location.state.courseName}
                    {!timeOver && questionsList && questionsList.length
                        ? ` ${currentQuestionIndex + 1} of  ${questionsList.length}`
                        : ""}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <CountdownCircleTimer
                    isPlaying={true}
                    duration={180}
                    colors={[
                        ["#008000", 0.25],
                        ["#CCE5CC", 0.25],
                        ["#ffa500", 0.25],
                        ["#FF0000", 0.25],
                    ]}
                >
                    {({ remainingTime }) => {
                        if (remainingTime <= 0) {
                            console.log("ACTION AFTER TIMER ENDS");
                            setTimeover(true);
                        }
                        return `${toInteger(remainingTime / 60)} m - ${remainingTime % 60
                            } s`;
                    }}
                </CountdownCircleTimer>
            </Grid>
            <Grid item xs={5}>
                <Grid
                    item
                    xs={12}
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        color: "green",
                    }}
                >
                    Correct: {correctAnswers}
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "flex-end", color: "red" }}
                >
                    Wrong: {wrongAnswers}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <ProgressBar style={{ height: "1.75rem", fontSize: "1rem" }}>
                    <ProgressBar
                        animated
                        striped
                        variant="success"
                        now={correctAnswers}
                        key={1}
                        label={correctAnswers}
                    />
                    <ProgressBar
                        animated
                        striped
                        variant="danger"
                        now={wrongAnswers}
                        key={2}
                        label={wrongAnswers}
                    />
                </ProgressBar>
                {/* <LinearProgress
          variant="buffer"
          value={progress}
          valueBuffer={buffer}
          classes={{
            barColorPrimary: classes.progressPrimary,
            bar2Buffer: classes.progressSecondary,
          }}
        /> */}
            </Grid>
            <Dialog
                onClose={() => setShowHelp(false)}
                open={showHelp}
                maxWidth={false}
            >
                <div style={{ padding: 16 }}>
                    <Typography variant="h4">Help</Typography>
                    <div>
                        <img
                            src={
                                currentQuestion && currentQuestion.helpLink
                                    ? currentQuestion.helpLink
                                    : ""
                            }
                            alt="loading..."
                        />
                    </div>
                </div>
            </Dialog>
            <Grid container style={{ padding: 16, minHeight: "60vh" }}>
                {timeOver ? (

                    <ShowResults
                        correct={correctAnswers}
                        accuracy={location.state.accuracy} />
                ) : (
                    <Paper style={{ width: "100%" }} key={`${currentQuestion ? ("" + currentQuestion.concept +
                        currentQuestion.qType + currentQuestion.questionId + currentQuestion.question) : "empty_question"}`}>{renderQuestion()}</Paper>
                )}
            </Grid>
        </Grid>
    );
};

export default SpeedTest;
