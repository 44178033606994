export function getHomeworksList({ childReducer }) {
  if (
    childReducer &&
    childReducer.childInfo &&
    childReducer.childInfo.homeWorks
  )
    return childReducer.childInfo.homeWorks;
  else return [];
}

export function getCoursesList({ childReducer }) {
  if (
    childReducer &&
    childReducer.childInfo &&
    childReducer.childInfo.child &&
    childReducer.childInfo.child.courseDetails
  )
    return childReducer.childInfo.child.courseDetails;
  else return [];
}

export function getAddChildSuccess({ childReducer }) {
  if (childReducer && childReducer.addChildSuccess)
    return childReducer.addChildSuccess;
  else return false;
}
export function getAddChildError({ childReducer }) {
  if (childReducer && childReducer.addChildError)
    return childReducer.addChildError;
  else return false;
}

export function getDeleteChildSuccess({ childReducer }) {
  if (childReducer && childReducer.deleteChildSuccess)
    return childReducer.deleteChildSuccess;
  else return false;
}
export function getUploadChildProfilePicSuccess({ childReducer }) {
  if (childReducer && childReducer.uploadChildProfilePicSuccess)
    return childReducer.uploadChildProfilePicSuccess;
  else return false;
}
export function getDeleteChildError({ childReducer }) {
  if (childReducer && childReducer.deleteChildError)
    return childReducer.deleteChildError;
  else return false;
}

export function getChildUserId({ childReducer }) {
  if (
    childReducer &&
    childReducer.childInfo &&
    childReducer.childInfo.child &&
    childReducer.childInfo.child.userId
  ) {
    console.log(
      "childReducer ---> ",
      childReducer,
      childReducer.childInfo.child.userId
    );
    return childReducer.childInfo.child.userId;
  } else return '';
}

export function getChildInfo({ childReducer }) {
  if (
    childReducer &&
    childReducer.childInfo &&
    childReducer.childInfo.child.userId
  ) {
    return childReducer.childInfo.child;
  } else {
    return {};
  }
}
