import {
  Card,
  CardActionArea,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
//import { getChildInfo } from "../../../actions";
import {
  getHomeworksList,
  getChildInfo
} from "../../../reducer/selectors/child.entity";
import { getIsLoggedIn } from "../../../reducer/selectors/user.entity";
import { useHistory, useLocation } from "react-router-dom";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(() => ({
  newHomewor: {
    color: "green",
  },
}));

const CoursePage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const homeWorksList = useSelector(getHomeworksList);
  const childInfo = useSelector(getChildInfo);
  const isLoggedIn = useSelector(getIsLoggedIn);

  useEffect(() => {
    console.log("hWork === ", homeWorksList)
    if (location && location.state && location.state.childId) {
      console.log('userType in course page', location.state.userType)
      // dispatch(getChildInfo(location.state.childId));
    }
  }, []);

  return (
    <Paper style={{marginTop:30}}>
      <Grid container spacing={3} style={{ padding: 25 }}>
        <Grid item xs={12}>
          <Breadcrumbs maxItems={2} aria-label="breadcrumb">
            <Link color="inherit" href={isLoggedIn ? "/parent" : "/"}>
              Home
            </Link>
            <Link color="inherit"
              onClick={() => {
                console.log('courseId......', location.state.courseId)
                history.push({
                  pathname: isLoggedIn ? "/child" : "/guest/child",
                  state: { childId: location.state.childId, userType: isLoggedIn ? "user" : "guest" },
                });
              }}>
              {(childInfo && isLoggedIn)
                ? childInfo.firstName + "'s page"
                : "Child"}
            </Link>
          </Breadcrumbs>
        </Grid>

        {homeWorksList &&
          location.state.courseId &&
          homeWorksList
            .filter((hw) => hw.courseId === location.state.courseId)
            .map((hWork) => (
              <Grid item md={4} xs={12}>
                <Card>
                  <CardActionArea
                    style={{
                      padding: 10,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      console.log("CLICKED",hWork.courseId);
                      history.push({
                        pathname: (location.state.userType && location.state.userType === 'guest') ? '/guest/child/homework' : '/child/homework',
                        state: {
                          childId: location.state.childId,
                          hwDate: hWork.homeWorkDate,
                          subjectCd: hWork.courseId,
                          numOfQuestionsPerDay: (location.state.numOfQuestionsPerDay ? location.state.numOfQuestionsPerDay : 20),
                          userType: location.state.userType,
                          ctest: '',
                          pageFlag: "childCourse",
                        },
                      });
                    }}
                  >
                    <Typography variant="h4">{hWork.courseId}</Typography>
                    <Typography
                      variant={hWork.status === "New" ? "h5" : "h6"}
                      style={{ color: hWork.status === "New" ? "green" : "" }}
                    >
                      {hWork.status}
                      <div>{hWork.homeWorkDate.split("T")[0]}</div>
                    </Typography>
                    {(hWork.status === "Started" && location.state.userType === 'user') &&
                      <Typography variant="h6">Q:{hWork.numOfQuestions} A:{hWork.numOfcompleted} P:{hWork.numOfPending}</Typography>
                    }
                    {/* <Grid container style={{ marginTop: 6 }}>
                      <Grid item xs={6}>
                        Questions: {homeWork.numOfQuestions}
                      </Grid>
                      <Grid item xs={6}>
                        Completed: {homeWork.numOfcompleted}
                      </Grid>
                      <Grid item xs={6}>
                        Correct: {homeWork.correctAnswered}
                      </Grid>
                      <Grid item xs={6}>
                        Wrong: {homeWork.wrongAnswered}
                      </Grid>
                    </Grid> */}
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
      </Grid>
    </Paper>
  );
};

export default CoursePage;
