export function getProfile({ profileReducer }) {
  if (
    profileReducer &&
    profileReducer.profile &&
    profileReducer.profile.childId
  )
    return profileReducer.profile;
  else return undefined;
}

export function getRankings({ profileReducer }) {
  if (
    profileReducer &&
    profileReducer.rankings &&
    profileReducer.rankings.length > 0
  )
    return profileReducer.rankings;
  else return [];
}
