import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import childSaga from "./child.saga";
import homeworkSaga from "./homework.saga";
import ParentSaga from "./parent.saga";
import loginSaga from "./login.saga";
import profileSaga from "./profile.saga";

export const sagaMiddleware = createSagaMiddleware();

export function* rootSaga() {
  yield all([
    ParentSaga(),
    childSaga(),
    homeworkSaga(),
    loginSaga(),
    profileSaga(),
  ]);
}
