import { Button, Card, CardContent, FormControl, Grid, InputLabel, makeStyles, MenuItem, Modal, Select, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "../Theme/ProductCard";
import { getCreateSubscriptionSuccess, getIsSubscriptionActive, getParentPaymentInfo } from "../../../reducer/selectors/parent.entity";
import { AddPaymentMethod } from "../ParentProfile/ParentProfile";
import { addNewPaymentMethod, createSubscription } from "../../../actions";
import { getIsLoggedIn, getUserId } from "../../../reducer/selectors/user.entity";
import { getCardLogo } from "../../../util/paymentUtil";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { serviceConstants } from "../../../constants"
import { useHistory } from "react-router-dom";

const stripePromise = loadStripe(serviceConstants.stripePublishKey);

const useStyles = makeStyles(() => ({
    menuItem: {
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "grey"
    }
}))

const SubscriptionList = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const classes = useStyles()
    const [showCreateSubscriptionModal, setShowCreateSubscriptionModal] = useState(false)
    const [selectedSubscription, setSelectedSubscription] = useState(undefined)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(undefined)
    const [newCardDetails, setNewCardDetails] = useState(undefined)

    const createSubscriptionSuccess = useSelector(getCreateSubscriptionSuccess)
    const parentPaymentInfo = useSelector(getParentPaymentInfo)
    const userId = useSelector(getUserId);
    const isSubscriptionActive = useSelector(getIsSubscriptionActive)
    const isLoggedIn = useSelector(getIsLoggedIn)

    useEffect(() => {
        if (createSubscriptionSuccess) {
            setShowCreateSubscriptionModal(false)
        }
    }, [createSubscriptionSuccess])

    const onPurchaseClick = (subscriptionId) => {
        console.log("CAME HERE ", subscriptionId)
        if (isLoggedIn) {
            setShowCreateSubscriptionModal(true)
        } else {
            history.push("/login")
        }
    }
    const showPaymentDetails = () => {
        return (<div>
            <Typography variant="h5" style={{ marginTop: 16, marginBottom: 8 }}>Select Payment Method</Typography>
            {parentPaymentInfo && parentPaymentInfo.cards && parentPaymentInfo.cards.length > 0 && (
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <FormControl
                        // error={!isValid && Boolean(errors.gender)}
                        style={{ width: "100%" }}
                    >
                        <InputLabel
                            id="demo-simple-select-required-label"
                            style={{ width: "100%" }}
                        >
                            Select Payment Method
                        </InputLabel>
                        <Select
                            style={{ width: "100%" }}
                            labelId="selectPaymentMethod"
                            id="selectPaymentMethod"
                            name="selectPaymentMethod"
                            label="Subscription"
                            value={selectedPaymentMethod} //{data.childSecondgrade}
                            onChange={(e) => {
                                setSelectedPaymentMethod(e.target.value);
                            }}
                            key={"key_payment_method_" + selectedPaymentMethod}
                        // onBlur={(e)=>this.onBlur(e,"two")}
                        >
                            {parentPaymentInfo.cards.map((paymentMethod) => <MenuItem classes={{ root: classes.menuItem }} value={paymentMethod.cardId}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                                    <div style={{ alignSelf: "center", flexDirection: "row", display: "flex", alignItems: "center" }}>
                                        <img style={{ width: 50, height: 30, marginRight: 16 }} src={getCardLogo(paymentMethod.brand)} />
                                        <Typography>**** **** **** {paymentMethod.lastFourDigits}</Typography>
                                    </div>
                                    <div>
                                        <Typography>Expiry: {paymentMethod.expDate}</Typography>
                                    </div>
                                </div>
                            </MenuItem>)}
                            <MenuItem value="NEW">Add New Payment Method</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            )}
            {(selectedPaymentMethod === "NEW" || ((!parentPaymentInfo) && selectedPaymentMethod === undefined)) && <Grid item={true} style={{ display: "flex", flexDirection: "row" }}>
                <AddPaymentMethod setNewCardDetails={setNewCardDetails} />{newCardDetails && newCardDetails.token && newCardDetails.token.id && (
                    <Button
                        variant="outlined"
                        onClick={() => {
                            console.log("NEW CARD DEtAILS, MAKE API CALLS HERE")
                            dispatch(addNewPaymentMethod({
                                userId,
                                tokenId: newCardDetails.token.id
                            }))
                        }}
                    >
                        ADD
                    </Button>
                )}
            </Grid>
            }
        </div>)
    }

    return <>
        <Elements stripe={stripePromise}>
            <Modal
                open={showCreateSubscriptionModal}
            // onClose={() => setShowCreateSubscriptionModal(false)} // To close on Click outside modal
            >
                <div style={{
                    backgroundColor: "white",
                    display: "flex",
                    width: "50vw",
                    marginTop: "25vh",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "30px",
                    marginLeft: "25vw",
                    borderRadius: 3
                }}>
                    <h5>Selected Subscription</h5>
                    {selectedSubscription && <div>
                        <h3>
                            {selectedSubscription.name}
                        </h3>
                        <span className="author">{selectedSubscription.description}</span>
                        <div className="productAction">
                            <span style={{ display: "flex", flexDirection: "row" }}>
                                {selectedSubscription.introductoryPrice ? <h4>
                                    <span style={{ textDecoration: "line-through", color: "red", marginRight: 5 }}>
                                        {selectedSubscription.price}
                                    </span>
                                    <span>{selectedSubscription.introductoryPrice}</span>
                                </h4> : <h4>
                                    {selectedSubscription.price}</h4>}<h4>/</h4><h4>{selectedSubscription.duration}</h4></span>
                        </div>
                    </div>}
                    {showPaymentDetails()}
                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                        <Button
                            variant="outlined"
                            // size="small"
                            color="error"
                            // style={{ width: "100%" }}
                            onClick={() => {
                                setShowCreateSubscriptionModal(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            // color="success"
                            style={{ color: "green", borderColor: 'green', borderWidth: 1.5, fontWeight: "600" }}
                            onClick={() => {
                                console.log("SERVICE NOIW");
                                dispatch(createSubscription({
                                    userId,
                                    subscriptionId: selectedSubscription.stripeId,
                                    cardId: selectedPaymentMethod
                                }))
                            }}
                            disabled={!selectedPaymentMethod || selectedPaymentMethod === "NEW"}
                        >
                            Purchase
                        </Button>
                    </div>
                </div>
            </Modal>
            <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
            >
                <h3 style={{ fontWeight: "600" }}>
                    {isSubscriptionActive ? "Subscription Options" : "Subscriptions"}
                </h3>
            </Grid>
            <Grid container spacing={2}
                style={{ display: "flex", justifyContent: "space-between" }}>
                {serviceConstants.subscriptionsDisplay.map(subscription => <Grid item sm={6} xs={12} md={3}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6" gutterBottom >
                                {subscription.name}
                            </Typography>
                        </CardContent>
                        <CardContent>
                            {
                                subscription.options.map(option => <ProductCard
                                    image={option.image}
                                    name={option.name}
                                    description={option.description}
                                    introductoryPrice={option.introductoryPrice}
                                    price={option.price}
                                    duration={option.duration}
                                    id={option.id}
                                    onSubmitFunction={(id) => {
                                        onPurchaseClick(id)
                                        setSelectedSubscription(option)
                                    }}
                                    showMinified={isSubscriptionActive}
                                    trialPeriod={option.trialPeriod}
                                />)
                            }
                        </CardContent>
                    </Card>
                </Grid>)}
            </Grid>
            {/* {serviceConstants.subscriptions && serviceConstants.subscriptions.length > 0 &&
                serviceConstants.subscriptions.map(subscription => <Grid item md={3}>
                    <ProductCard
                        image={subscription.image}
                        name={subscription.name}
                        description={subscription.description}
                        introductoryPrice={subscription.introductoryPrice}
                        price={subscription.price}
                        duration={subscription.duration}
                        id={subscription.id}
                        onSubmitFunction={(id) => {
                            onPurchaseClick(id)
                            setSelectedSubscription(subscription)
                        }}
                        showMinified={isSubscriptionActive}
                    />
                </Grid>)} */}
        </Elements>
    </>
}

export default SubscriptionList