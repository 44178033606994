import { loginConstants, parentConstants } from "../constants";

const initialState = {
  parentInfo: undefined,
  childList: [],
  addNewPaymentSuccess: false,
  addNewPaymentError: "",
  createSubscriptionSuccess: false,
  createSubscriptionError: "",
};

export function parentReducer(state = initialState, action) {
  switch (action.type) {
    case parentConstants.GET_PARENT_INFO_SUCCESS:
      return { ...state, parentInfo: action.payload };
    case loginConstants.ATTEMPT_LOGIN_SUCCESS:
      return { ...state, childList: action.payload.children };
    case parentConstants.ADD_NEW_PAYMENT_METHOD:
      return {
        ...state,
        addNewPaymentSuccess: false,
        addNewPaymentError: "",
      }
    case parentConstants.ADD_NEW_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        addNewPaymentSuccess: true,
        addNewPaymentError: "",
      }
    case parentConstants.ADD_NEW_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        addNewPaymentSuccess: false,
        addNewPaymentError: action.payload,
      }
    case parentConstants.CREATE_SUBSCRIPTION:
      return {
        ...state,
        createSubscriptionSuccess: false,
        createSubscriptionError: ""
      }
    case parentConstants.CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        createSubscriptionSuccess: true,
        createSubscriptionError: ""
      }
    case parentConstants.CREATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        createSubscriptionSuccess: false,
        createSubscriptionError: action.payload
      }
    default:
      return state;
  }
}
