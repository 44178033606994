import { Button, Card, Grid, Typography } from "@material-ui/core"
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getUserId, getUserInfo } from "../../../reducer/selectors/user.entity"
import { getChildList, getParentPaymentInfo } from "../../../reducer/selectors/parent.entity"
import * as parentActions from "../../../actions/parent.actions"
import { useDispatch } from "react-redux"
import { setShowLoader } from "../../../actions"
import girlPng from "../../../assets/images/btn-girl.png";
import boyPng from "../../../assets/images/student-avatar.png";
import ChangePassword from "../ChangePassword"
import {getCardLogo} from "../../../util/paymentUtil"
import { serviceConstants } from "../../../constants"

const stripePromise = loadStripe(serviceConstants.stripePublishKey);

export const AddPaymentMethod = (props) => {
    const elements = useElements()
    const stripe = useStripe()

    return (
        <div style={{ width: "100%", alignSelf: "center" }}>
            <CardElement onChange={(e) => {
                // setError(e.error);
                // setCardComplete(e.complete);
                if (e.complete) {
                    console.log(e, "---", elements.getElement(CardElement))
                    console.log(stripe.createToken(elements.getElement(CardElement)).then(response => {
                        console.log("TOKEN RESPONSE ", response)
                        props.setNewCardDetails(response)
                    }).catch((e) => console.log("TOKEN ERROR ", e)))
                }
                console.log("CARD ON CHANGE ", e)
            }}
            />
        </div>
    )

}

const ParentProfile = () => {
    const dispatch = useDispatch()

    const parentInfo = useSelector(getUserInfo)
    const userId = useSelector(getUserId)
    const parentPaymentInfo = useSelector(getParentPaymentInfo)
    const childList = useSelector(getChildList)

    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false)
    const [newCardDetails, setNewCardDetails] = useState(undefined)
    const [showInactiveChildProfiles, setShowInactiveChildProfiles] = useState(false)
    const [showChangePassword, setShowChangePassword] = useState(false)


    useEffect(() => {
        dispatch(setShowLoader(true));
        dispatch(parentActions.getParentInfo({ userId: userId }));
    }, []);

    const renderChildProfile = (child) => {
        return <Card style={{ margin: 8, padding: 8 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <Typography variant="h6">{child.firstName}, {child.lastName}</Typography>
                    <Typography>Grade: {child.grade}</Typography>
                    <Typography>Sunday Homework: {child.sundayFlag ? "YES" : "NO"}</Typography>
                </div>
                <div style={{ alignSelf: "center" }}>
                    {child.childProfilePic ? (
                        <img
                            style={{ width: 64, height: 64 }}
                            src={child.childProfilePic}
                        />
                    ) : child.gender === "female" ||
                        child.gender === "Female" ? (
                        <img src={girlPng} />
                    ) : (
                        <img src={boyPng} />
                    )}
                </div>
            </div>
            <div style={{ color: "green" }}>Success Meter or scales or graph or bar chart to full length</div>
        </Card>
    }

    return <Grid container={true} style={{ padding: 24 }}>
        <ChangePassword
            open={showChangePassword}
            onClose={() => setShowChangePassword(false)}
        />
        <Elements stripe={stripePromise}>
            {/* <Modal open={showAddPaymentModal} onClose={()=>setShowAddPaymentModal(false)}>
            <Box>
                Show Payment Form
            </Box>
            </Modal> */}
            <Grid item={true} xs={12}>
                <Typography variant="h3">
                    {parentInfo && `${parentInfo.firstName}, ${parentInfo.lastName}`}
                </Typography>
            </Grid>
            <Grid item={true} xs={12} md={6} style={{ padding: 16 }}>
                <Typography variant="h5" >Child Profiles</Typography>
                {childList &&
                    childList.length > 0 &&
                    childList.map(
                        (child) =>
                            (child.status !== "2") && (renderChildProfile(child)))}
                <Button variant="text" color="primary" onClick={() => setShowInactiveChildProfiles(!showInactiveChildProfiles)}>{showInactiveChildProfiles ? "Hide" : "Show"} inactive child profiles</Button>
                {showInactiveChildProfiles && childList &&
                    childList.length > 0 &&
                    childList.map((child) => (child.status === "2") && (renderChildProfile(child)))}
            </Grid>
            <Grid item={true} xs={12} md={6} style={{ display: "flex", flexDirection: "column", padding: 16 }}>
                <Typography variant="h5">
                    Payment Methods
                </Typography>
                {parentPaymentInfo && parentPaymentInfo.cards && parentPaymentInfo.cards.map((paymentMethod, index) => (
                    <Card style={{ marginTop: 8, marginBottom: 8, padding: 16, display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex" }}>
                            <div style={{ marginRight: 16, alignSelf: "center" }}>
                                <img style={{ width: 50, height: 30 }} src={getCardLogo(paymentMethod.brand)} />
                            </div>
                            <div>
                                <Typography>**** **** **** {paymentMethod.lastFourDigits}</Typography>
                                <Typography>Expiry: {paymentMethod.expDate}</Typography>
                            </div>
                        </div>
                        <div style={{ alignSelf: "center" }}>
                            <Button variant="text" style={{ color: "red" }}>Delete</Button>
                        </div>
                    </Card>
                ))}
                {!showAddPaymentModal && <Button variant="outlined" onClick={() => setShowAddPaymentModal(true)}>
                    Add New Payment Method
                </Button>}
                {showAddPaymentModal && (
                    <Grid item={true} style={{ display: "flex", flexDirection: "row" }}>
                        <AddPaymentMethod setNewCardDetails={setNewCardDetails} />
                        {newCardDetails && newCardDetails.token && newCardDetails.token.id ? (
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    console.log("NEW CARD DEtAILS, MAKE API CALLS HERE")
                                        dispatch(parentActions.addNewPaymentMethod({
                                            userId,
                                            tokenId: newCardDetails.token.id
                                        }))
                                }}
                            >
                                ADD
                            </Button>
                        ) : (
                            <Button variant="outlined" onClick={() => setShowAddPaymentModal(false)}>Cancel</Button>
                        )}
                    </Grid>
                )}
                <Typography variant="h5" style={{ marginTop: 48 }}>
                    Edit Profile
                </Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowChangePassword(true)}
                    style={{ marginTop: 18 }}
                >
                    Change Password
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    // onClick={() => setShowChangePassword(true)}
                    style={{ marginTop: 18 }}
                >
                    Change Subscription
                </Button>
                <Button
                    variant="text"
                    // onClick={() => setShowChangePassword(true)}
                    style={{ marginTop: 24, color: "red" }}
                >
                    Delete Account
                </Button>
            </Grid>
        </Elements>
    </Grid>
}

export default ParentProfile
