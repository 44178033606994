import apiClient from "./api.client";

export default class Transport {
  static async genericOperation(url, apiKey, postData, type) {
    const TYPE = type || "POST";

    try {
      const response = await apiClient({
        method: TYPE,
        headers: {
          "x-api-key": apiKey,
        },
        url,
        data: postData,
      });
      const { data } = response;
      return data;
    } catch (e) {
      console.error("ERRORRRR ", e);
      throw e;
    }
  }

  static async fileUpload(url, apiKey, postData, type) {
    const TYPE = type || "POST";

    try {
      const response = await apiClient({
        method: TYPE,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*",
          "x-api-key": apiKey,
          mode: "cors",
        },
        url,
        data: postData,
      });
      console.log("RESPONSE ", response);
      // const { data } = response;
      // return data;
    } catch (e) {
      console.error("ERRORRRR ", e);
      throw e;
    }
  }

  static async authOperation(postData) {
    const TYPE = "POST";

    try {
      const response = await apiClient({
        method: TYPE,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*",
          "auth0-client":
            "eyJuYW1lIjoicmVhY3QtbmF0aXZlLWF1dGgwIiwidmVyc2lvbiI6IjIuNi4wIn0=",
          mode: "cors",
        },
        url: "https://rabbitboardnonprod.us.auth0.com/oauth/token",
        data: postData,
      });
      const { data } = response;
      return data;
    } catch (e) {
      console.error("ERRORRRR ", e);
      throw e;
    }
  }
}
