import { createAction } from "@reduxjs/toolkit";
import { loginConstants } from "../constants";

export const attemptLogin = createAction(
  loginConstants.ATTEMPT_LOGIN,
  (payload) => ({ payload })
);
export const attemptLoginSuccess = createAction(
  loginConstants.ATTEMPT_LOGIN_SUCCESS,
  (payload) => ({ payload })
);
export const attemptLoginFailure = createAction(
  loginConstants.ATTEMPT_LOGIN_FAILURE,
  (payload) => ({ payload })
);
export const attemptLogout = createAction(loginConstants.ATTEMPT_LOGOUT);

export const attemptRegistration = createAction(
  loginConstants.ATTEMPT_REGISTRATION,
  (payload) => ({ payload })
);
export const attemptRegistrationSuccess = createAction(
  loginConstants.ATTEMPT_REGISTRATION_SUCCESS,
  (payload) => ({ payload })
);

export const attemptRegisterFailure = createAction(
  loginConstants.ATTEMPT_REGISTRATION_FAILURE,
  (payload) => ({ payload })
);

export const sendMessage = createAction(
  loginConstants.ATTEMPT_SEND_MESSAGE,
  (payload) => ({ payload })
);

export const attemptSendMessageSuccess = createAction(
  loginConstants.ATTEMPT_SEND_MESSAGE_SUCCESS,
  (payload) => ({ payload })
);

export const attemptSendMessageFailure = createAction(
  loginConstants.ATTEMPT_SEND_MESSAGE_FAILURE,
  (payload) => ({ payload })
);

export const attemptSendOtp = createAction(
  loginConstants.ATTEMPT_SEND_OTP,
  (payload) => ({ payload })
);

export const attempSendOtpSuccess = createAction(
  loginConstants.ATTEMPT_SEND_OTP_SUCCESS,
  (payload) => ({ payload })
);

export const attemptForgotPassword = createAction(
  loginConstants.ATTEMPT_FORGOT_PASSWORD,
  (payload) => ({ payload })
);

export const attemptForgotPasswordSuccess = createAction(
  loginConstants.ATTEMPT_FORGOT_PASSWORD_SUCCESS,
  (payload) => ({ payload })
);

export const attemptForgotPasswordFailure = createAction(
  loginConstants.ATTEMPT_FORGOT_PASSWORD_FAILURE,
  (payload) => ({ payload })
);