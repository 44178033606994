import React from "react";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import {
    Grid
} from "@material-ui/core";


const LoginRequired = () => {
    const history = useHistory();

    return (
        <Grid
            container

            style={{
                padding: 15,
                display: "flex",
                // justifyContent: "space-between",
                flexDirection: "column",
                height: "100%",
                alignContent: "center"
            }}
        >
            <div color="blue" style={{ color: "primary", fontSize: 20, alignSelf: "center", marginTop: "40" }}>
                <Link
                    color="primary"
                    noWrap
                    variant="body2"
                    onClick={() => {
                        history.push("/login")
                    }}

                    style={{ textDecoration: 'underline', fontSize: 20 }}
                >
                    Login
                </Link> to continue.
            </div>
            <div style={{ fontSize: 20, alignSelf: "center", marginTop: "10" }}>
                Not Registered?
                <Link
                    color="primary"
                    noWrap
                    variant="body2"
                    onClick={() => {
                        history.push("/register")
                    }}
                    style={{ textDecoration: 'underline', fontSize: 20 }}
                >
                    Register here.
                </Link>
            </div>
        </Grid>
    )
}
export default LoginRequired;