import React, { useEffect, useState } from "react";
import { Container, Grid, Typography, Box } from "@material-ui/core";
import poor from '../../../images/poor.jpg'
import fair from '../../../images/fair.png'
import average from '../../../images/average.png'
import good from '../../../images/good.png'
import excellent from '../../../images/excellent.jpg'
//import Rating from "react-rating";

import Rating from '@material-ui/lab/Rating';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

const ShowResults = (props) => {
    const [finalAccuracy, setFinalAccuracy] = useState("")
    const [showImage, setShowImage] = useState("")
    const [msg, setMsg] = useState("")

    useEffect(() => {
        measureAccuracy()
    }, []);

    const measureAccuracy = () => {
        console.log("props.correctAnswers / props.accuracy ", props.correct, props.accuracy)
        const measure = props.correct / props.accuracy
        if (measure >= 5) {
            setFinalAccuracy(5)
        } if (measure < 1) {
            setFinalAccuracy(.5)
        } else {
            setFinalAccuracy(measure)
        }
        console.log('measure...', measure)
        if (measure >= 5) {
            setMsg('You are Flash speed.')
            setShowImage(excellent)
        } else if (measure >= 4) {
            setMsg('Amazing.. You are Flash speed.')
            setShowImage(excellent)
        } else if (measure >= 3) {
            setMsg('Awesome..You are Fast in Math.')
            setShowImage(good)
        } else if (measure >= 2) {
            setMsg('Wow.. You are consistent.')
            setShowImage(average)
        } else if (measure >= 1) {
            console.log('measure...', measure)
            setMsg('You are picking up speed. Do it Again.')
            setShowImage(fair)
            // console.log('measure...', measure)
            // setMsg("You need lot of Practice.")
            // setShowImage(poor)
        } else if (measure < 1) {
            console.log('measure...', measure)
            setMsg("You need lot of Practice.")
            setShowImage(poor)
        }
        console.log("finalAccuracy ===> ", finalAccuracy, measure)
    }

    function IconContainer(props) {
        const { value, ...other } = props;
        return <span {...other}>{customIcons[value].icon}</span>;
    }

    const customIcons = {
        1: {
            icon: <SentimentVeryDissatisfiedIcon />,
            label: 'Very Dissatisfied',
        },
        2: {
            icon: <SentimentDissatisfiedIcon />,
            label: 'Dissatisfied',
        },
        3: {
            icon: <SentimentSatisfiedIcon />,
            label: 'Neutral',
        },
        4: {
            icon: <SentimentSatisfiedAltIcon />,
            label: 'Satisfied',
        },
        5: {
            icon: <SentimentVerySatisfiedIcon />,
            label: 'Very Satisfied',
        },
    };
    return (
        <Container maxWidth="sm">
            <Grid container style={{ padding: 16, minHeight: "80vh", width: "100%" }}>

                <Box component="fieldset" mb={3} borderColor="transparent">
                    <Rating name="pristine" value={finalAccuracy}
                        readOnly="true"
                        size="large" />
                    <Typography component="legend" variant="h4">{msg}</Typography>
                    <img
                        alt="score"
                        src={showImage}
                        style={{ width: "100%", height: 350 }}
                    />
                </Box>

            </Grid>
        </Container>
    )
}

export default ShowResults;