import React, { useEffect } from 'react';
import { Grid, Button } from '@material-ui/core';
import "./styles.scss"


const SimpleCard = (props) => {
    useEffect(() => {
        console.log('simple card...')
    }, []);
    return (
        <Grid xs={12} item>
            <Grid
                className="newCardWrap">
                <Button
                    className={props.childListStyle ? "newCardItemChildList" : "newCardItem"}
                    disabled={props.disabled}
                    onClick={props.onClickFunction}
                    classes={{
                        label: "cardButton",
                        root: props.childListStyle ? "cardRootChildList" : "cardRoot"
                    }}
                >
                    <img style={{width:196, height:196}} src={props.itemInfo.image} alt="" />
                    {props.itemInfo.name}
                </Button>
            </Grid>
        </Grid >

    );

}
export default SimpleCard;