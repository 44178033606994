import { createAction } from "@reduxjs/toolkit";
import { parentConstants } from "../constants"

export const getParentInfo = createAction(parentConstants.GET_PARENT_INFO, (payload) => ({ payload }))
export const getParentInfoSuccess = createAction(parentConstants.GET_PARENT_INFO_SUCCESS, (payload) => ({ payload }))

export const addNewPaymentMethod = createAction(parentConstants.ADD_NEW_PAYMENT_METHOD, (payload) => ({ payload }))
export const addNewPaymentMethodSuccess = createAction(parentConstants.ADD_NEW_PAYMENT_METHOD_SUCCESS, (payload) => ({ payload }))
export const addNewPaymentMethodFailure = createAction(parentConstants.ADD_NEW_PAYMENT_METHOD_FAILURE, (payload) => ({ payload }))

export const deletePaymentMethod = createAction(parentConstants.DELETE_PAYMENT_METHOD, (payload) => ({ payload }))
export const deletePaymentMethodSuccess = createAction(parentConstants.DELETE_PAYMENT_METHOD_SUCCESS, (payload) => ({ payload }))
export const deletePaymentMethodFailure = createAction(parentConstants.DELETE_PAYMENT_METHOD_FAILURE, (payload) => ({ payload }))

export const createSubscription = createAction(
    parentConstants.CREATE_SUBSCRIPTION,
    (payload) => ({ payload })
);

export const createSubscriptionSuccess = createAction(
    parentConstants.CREATE_SUBSCRIPTION_SUCCESS,
    (payload) => ({ payload })
);

export const createSubscriptionFailure = createAction(
    parentConstants.CREATE_SUBSCRIPTION_FAILURE,
    (payload) => ({ payload })
);
