import React from 'react';
import { Grid, Button, Link } from '@material-ui/core'
import './style.scss'

const ProductCard = ({
    showMinified, name, badge, duration,
    image, description, introductoryPrice,
    price, id, onSubmitFunction, trialPeriod }) => {
    return (
        <Grid className="productWrap">
            <Grid className="productImg">
                {/* <Link to={`/product-details/${id}`}> */}
                {!showMinified && image && <img src={image} alt="" width={"100%"} height={300} />}
                {/* </Link> */}
                <span className={
                    `badge ${badge === 'trending' ||
                    badge === 'featured' ||
                    badge === 'new'
                    }`

                }>{badge}</span>
            </Grid>
            <Grid className="productContent">
                <h3>
                    {/* <Link to={`/product-details/${id}`}>  */}
                    {name}
                    {/* </Link> */}
                </h3>
                <span className="author">{description}</span>
                {trialPeriod && <span style={{color: "red", marginRight: 5, fontWeight: 'bold' }} className="author">{trialPeriod} FREE TRIAL</span>}
                {(introductoryPrice || price) && <div className="productAction">
                    <span style={{ display: "flex", flexDirection: "row" }}>{introductoryPrice ? <h4><span style={{ textDecoration: "line-through", color: "red", marginRight: 5 }}>{price}</span><span>{introductoryPrice}</span></h4> : <h4>{price}</h4>}<h4>/</h4><h4>{duration}</h4></span>
                    {!showMinified && <ul>
                        <li><Button component={Link} onClick={() => {
                            if (onSubmitFunction) {
                                onSubmitFunction(id)
                            }
                        }}>Purchase</Button></li>
                    </ul>}
                </div>}
            </Grid>
        </Grid>
    );
}


export default ProductCard
