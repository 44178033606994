import { put, takeLatest } from "redux-saga/effects";
import * as loginActions from "../actions/login.actions";
import { serviceConstants } from "../constants";
import Transport from "../transport/rabbitboard.transport";

function* attemptLoginSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.RABBIT_WEB_URL +
        `/login?uname=${action.payload.username}&pwd=${action.payload.password}`,
      serviceConstants.RABBIT_LOGIN_URL_APIKEY,
      {},
      "GET"
    );
    console.log("attemptLoginSaga RESPONSE ", response);
    if (response && response.loggedIn) {
      yield put(loginActions.attemptLoginSuccess(response));
    } else {
      yield put(loginActions.attemptLoginFailure(response));
    }
  } catch (e) {
    console.error("attemptLoginSaga Saga ERROR ", e);
    yield put(loginActions.attemptLoginFailure(e));
    throw e;
  }
}

function* attemptRegistrationSaga(action) {
  try {
    const reqObj = {
      fname: action.payload.firstname,
      lname: action.payload.lastname,
      email: action.payload.email,
      mobile: action.payload.mobile,
      password: action.payload.password,
    };
    const response = yield Transport.genericOperation(
      serviceConstants.RABBIT_WEB_URL + `/newaccount`,
      serviceConstants.RABBIT_LOGIN_URL_APIKEY,
      reqObj,
      "POST"
    );
    console.log("attemptRegisterSaga RESPONSE ", response);

    if (response && response.message && response.message.code === "200") {
      yield put(loginActions.attemptRegistrationSuccess(reqObj.email));
    } else {
      yield put(
        loginActions.attemptRegisterFailure(
          response && response.message && response.message.message
            ? response.message.message
            : "Registration Request Failed, Please check details and try again"
        )
      );
    }
  } catch (e) {
    console.error("attemptRegisterSaga Saga ERROR ", e);
    yield put(
      loginActions.attemptRegisterFailure("Request Failed, Please try again.")
    );
  }
}

function* attemptSendMessageSaga(action) {
  console.log("attemptSendMessageSaga ====> ", action);
  try {
    const reqObj = {
      name: action.payload.firstname,
      emailId: action.payload.email,
      message: action.payload.message,
    };
    const response = yield Transport.genericOperation(
      serviceConstants.RABBIT_LOGIN_URL + `/contactus?deliveryFlag=false`,
      serviceConstants.RABBIT_LOGIN_URL_APIKEY,
      reqObj,
      "POST"
    );
    console.log("attemptSendMessageSaga RESPONSE ", response);

    if (response && response.message && response.message.code === "200") {
      yield put(loginActions.attemptSendMessageSuccess(reqObj.email));
    } else {
      yield put(
        loginActions.attemptSendMessageFailure(
          response && response.message && response.message.message
            ? response.message.message
            : "Send message Failed, Please check details and try again"
        )
      );
    }
  } catch (e) {
    console.error("attemptSendMessageSaga Saga ERROR ", e);
    yield put(
      loginActions.attemptRegisterFailure("Request Failed, Please try again.")
    );
  }
}

function* attemptSendOtpSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      `${serviceConstants.RABBIT_LOGIN_URL}/sendotp/}` +
        action.payload,
      serviceConstants.RABBIT_LOGIN_URL_APIKEY,
      "POST"
    );
    console.log(
      "attemptSendOtpSaga RESPONSE ",
      response,
      "----",
      response.mobileVerificationCode
    );
    if (response && response.mobileVerificationCode) {
      yield put(
        loginActions.attempSendOtpSuccess(response.mobileVerificationCode)
      );
    }
  } catch (e) {
    console.error("attemptSendOtpSaga Saga ERROR ", e);
  }
}

function* attemptForgotPasswordSaga(action) {
  console.log("attemptForgotPassword ====> ", action);
  try {
    const reqObj = {
      emailId: action.payload.email,
     };
    const response = yield Transport.genericOperation(
      serviceConstants.RABBIT_WEB_URL + `/changepassword/`
      +action.payload.emailId,
      serviceConstants.RABBIT_LOGIN_URL_APIKEY,
      reqObj,
      "POST"
    );
    console.log("attemptForgotPassword RESPONSE ", response);

    if (response && response.message && response.message.code === "200") {
      yield put(loginActions.attemptForgotPasswordSuccess(response));
    } else {
      console.log("attemptForgotPassword failure ", response.message.message);
      yield put(
        loginActions.attemptForgotPasswordFailure(
          response && response.message && response.message.message
            ? response.message.message
            : "Forgot password Failed, Please check details and try again"
        )
      );
    }
  } catch (e) {
    console.error("attemptForgotPassword Saga ERROR ", e);
    yield put(
      loginActions.attemptForgotPasswordFailure("Request Failed, Please try again.")
    );
  }
}

export default function* loginSaga() {
  try {
    yield takeLatest(loginActions.attemptLogin, attemptLoginSaga);
    yield takeLatest(loginActions.attemptRegistration, attemptRegistrationSaga);
    yield takeLatest(loginActions.sendMessage, attemptSendMessageSaga);
    yield takeLatest(loginActions.attemptSendOtp, attemptSendOtpSaga);
    yield takeLatest(loginActions.attemptForgotPassword, attemptForgotPasswordSaga);
  } catch {
    yield;
  }
}
