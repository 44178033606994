import {
  Box,
  Button,
  Card,
  CardActionArea,
  Grid,
  Paper,
  Typography,
  Modal,
  Snackbar,

} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import * as actions from "../../../actions";
import {
  getChildInfo,
  getChildUserId,
  getCoursesList,
  getDeleteChildError,
  getDeleteChildSuccess,
  getUploadChildProfilePicSuccess,
 
} from "../../../reducer/selectors/child.entity";
import { getRankings } from "../../../reducer/selectors/homework.entity";
import { useHistory, useLocation } from "react-router-dom";

import {
  getUserId,
  getIsLoggedIn,
} from "../../../reducer/selectors/user.entity";
import { Dialog } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import SpeedIcon from "@material-ui/icons/Speed";
import SelectCourse from "../SpeedTest/SelectCourse";
import { ProgressBar } from "react-bootstrap";
import Divider from "@material-ui/core/Divider";
import "./styles.css";
import { mathPracticeConcepts } from "../../../mock/mathPracticeConcepts";
import { sciencePracticeConcepts } from "../../../mock/sciencePracticeConcepts";
import { socialPracticeConcepts } from "../../../mock/socialPracticeConcepts";
import { languagePracticeConcepts } from "../../../mock/languagePracticeConcepts";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  dividerFullWidth: {
    margin: `10px 0 0 ${theme.spacing(0)}px`,
  },
}));

const ChildHomePage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  //   const homeWorksList = useSelector(getHomeworksList);
  const [showDeleteChildModal, setShowDeleteChildModal] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const childuserId = useSelector(getChildUserId);
  const userId = useSelector(getUserId);
  const childInfo = useSelector(getChildInfo);
  const childRankings = useSelector(getRankings);
  const deleteChildSuccess = useSelector(getDeleteChildSuccess);
  const uploadChildProfilePicSuccess = useSelector(
    getUploadChildProfilePicSuccess
  );
  const coursesList = useSelector(getCoursesList);
  const deleteChildError = useSelector(getDeleteChildError);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const classes = useStyles();
  const [showSpeedTestModal, setShowSpeedTestModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState();

  const speedTestSubmit = (
    childId,
    hwDate,
    subjectCd,
    numOfQuestionsPerDay,
    userType,
    course
  ) => {
    history.push({
      pathname: "/guest/speedtest",
      state: {
        childId: childId,
        hwDate: "-",
        subjectCd: subjectCd,
        numOfQuestionsPerDay: numOfQuestionsPerDay,
        userType: userType,
        ctest: course.cTest,
        courseName: course.conceptName,
        accuracy: course.accuracy,
        pageSrc: "ChildHomePage",
        srcpage: "ChildHomePage",
      },
    });
  };

  useEffect(() => {
    if (uploadChildProfilePicSuccess) {
      dispatch(actions.getChildInfo(location.state.childId));
    }
  }, [uploadChildProfilePicSuccess]);

  useEffect(() => {
    console.log('child home page...', location.state)
    if (location && location.state && location.state.childId) {
      dispatch(actions.setShowLoader(true));
      dispatch(actions.getChildInfo(location.state.childId));
      dispatch(
        actions.getRankings({ childId: location.state.childId, isLoggedIn })
      );
    }
    setShowDeleteChildModal(false);
  }, []);

  useEffect(() => {
    if (deleteChildSuccess) {
      setShowDeleteChildModal(false);
      history.push("/parent");
    }
  }, [deleteChildSuccess]);

  useEffect(() => {
    if (deleteChildError) {
      setShowSnackbar(true);
    }
  }, [deleteChildError]);

  useEffect(() => {
    if (selectedFile && selectedFile.name) {
      onFileUpload();
    }
  }, [selectedFile]);

  const onSubmit = () => {
    if (location && location.state && location.state.childId && userId) {
      dispatch(actions.setShowLoader(true));
      dispatch(
        actions.deleteChild({ childId: location.state.childId, userId: userId })
      );
    }
  };

  const getConceptName = (concept) => {
    if (concept === "Multiplication" || concept === "Multiplication -Ve") {
      return "Multiplication";
    }
    if (concept === "Select Numbers" || concept === "Count Numbers") {
      return "Numbers";
    }
    if (concept === "Shapes") {
      return "Shapes";
    }
    if (
      concept === "Addition" ||
      concept === "Addition with Alphabets" ||
      concept === "Adding 3 numbers" ||
      concept === "Adding 4 numbers" ||
      concept === "Select correct Operation" ||
      concept === "Adding Money" ||
      concept === "Decimal Addition" ||
      concept === ""
    ) {
      return "Addition";
    }
    if (
      concept === "Subtraction" ||
      concept === "Subtraction with Alphabets" ||
      concept === "Addition with Alphabets" ||
      concept === "Addition with Alphabets" ||
      concept === "Addition with Alphabets" ||
      concept === "Addition with Alphabets"
    ) {
      return "Subtraction";
    }
    if (
      concept === "Elapsed Time" ||
      concept === "Convert Hours to Minutes" ||
      concept === "Convert Minutes to Hours" ||
      concept === "Convert Minutes to Seconds" ||
      concept === "Convert Seconds to Minutes" ||
      concept === "Convert Years to Months" ||
      concept === "Convert Months to Years" ||
      concept === "Add Hours & Minutes" ||
      concept === "Subtract Hours & Minutes" ||
      concept === "Add Years & Months" ||
      concept === "Subtract Years & Months" ||
      concept === "Elapsed Time"
    ) {
      return "Time";
    }
    if (
      concept === "Select Fractions" ||
      concept === "Fraction Addition" ||
      concept === "Fraction Subtraction"
    ) {
      return "Fraction";
    }
    if (
      concept === "Exponents" ||
      concept === "Select Expression for Exponents" ||
      concept === "Select Value for Exponents" ||
      concept === "Select Exponents" ||
      concept === "Select Exponent for Value"
    ) {
      return "Exponents";
    }
    if (
      concept === "Factors" ||
      concept === "Prime Factorization" ||
      concept === "Prime / Composite"
    ) {
      return "Factors";
    }
    if (concept === "Roman Numeral" || concept === "Roman Letters") {
      return "Roman Numeral";
    }
    if (concept === "Division" || concept === "Estimate Quotient") {
      return "Division";
    }
    if (
      concept === "Mixed Operation" ||
      concept === "Negative Numbers" ||
      concept === "Three Number Multiplication"
    ) {
      return "Mixed Operation";
    }
    if (concept === "Upper case letter" || concept === "Lower case letter") {
      return "Letters";
    }
    if (concept === "Sounding words" || concept === "Sounding end words") {
      return "Sounding Words";
    }
    if (
      concept === "Spell Words" ||
      concept === "Complete word" ||
      concept === "Silent e word" ||
      concept === "Complete silent e word" ||
      concept === "Complete vowel word" ||
      concept === "Listen Words"
    ) {
      return "Words";
    }
    if (concept === "Homophones" || concept === "Select Homophones") {
      return "Homophones";
    }
    if (concept === "Prepositions" || concept === "Select Prepositions") {
      return "Prepositions";
    }
    if (concept === "Pronouns in Sentence" || concept === "Select Pronouns") {
      return "Pronouns";
    }

    if (
      concept === "Select Property of Materials" ||
      concept === "Choose Property of Materials"
    ) {
      return "Property";
    }
    if (
      concept === "Select States Of Matter" ||
      concept === "Choose States Of Matter"
    ) {
      return "StatesOfMatter";
    }


    if (
      concept === "Select Physical/Chemical change" ||
      concept === "Choose Physical/Chemical change"
    ) {
      return "PhysicalAndChemicalChange";
    }
    if (
      concept === "Select Mixtures" ||
      concept === "Choose Mixtures"
    ) {
      return "Mixtures";
    }
    if (
      concept === "Select Classification Of Animals" ||
      concept === "Choose Classification Of Animals"
    ) {
      return "ClassificationOfAnimals";
    }
    if (
      concept === "Select Types Of Animals" ||
      concept === "Choose Types Of Animals"
    ) {
      return "TypesOfAnimals";
    }
    if (
      concept === "Select Food habits" ||
      concept === "Choose Food habits" ||
      concept === "Food habits"
    ) {
      return "FoodHabbitsOfAnimals";
    }
    if (
      concept === "Select Living/Non living things" ||
      concept === "Choose Living/Non living things"
    ) {
      return "LivingAndNonLivingThings";
    }
    if (
      concept === "General questions"
    ) {
      return "GeneralQuestions";
    }
    if (
      concept === "Select Plants" ||
      concept === "Choose Plants"
    ) {
      return "Plants";
    }
    if (
      concept === "Select Minerals" ||
      concept === "Choose Minerals"
    ) {
      return "Minerals";
    }
    if (
      concept === "Select Items" ||
      concept === "Choose Items"
    ) {
      return "Items";
    }
    if (
      concept === "Select Materials" ||
      concept === "Choose Materials"
    ) {
      return "Materials";
    }
    if (
      concept === "Select Cells" ||
      concept === "Choose Cells"
    ) {
      return "Cells";
    }
    if (
      concept === "Select Natural Resource" ||
      concept === "Choose Natural Resource"
    ) {
      return "NaturalResource";
    }
    if (
      concept === "Select Mixture Type" ||
      concept === "Choose Mixture Type"
    ) {
      return "MixtureType";
    }
    if (
      concept === "Select Magnets" ||
      concept === "Choose Magnets"
    ) {
      return "Magnets";
    }
    if (
      concept === "Select Direction Of Force" ||
      concept === "Choose Direction Of Force"
    ) {
      return "DirectionOfForce";
    }
    if (
      concept === "Select Astronomy" ||
      concept === "Choose Astronomy"
    ) {
      return "Astronomy";
    }
    if (
      concept === "Select Disaster" ||
      concept === "Choose Disaster"
    ) {
      return "Disaster";
    }
    if (
      concept === "Select Ecosystem" ||
      concept === "Choose Ecosystem"
    ) {
      return "Ecosystem";
    }

    if (
      concept === "Select Ecosystem" ||
      concept === "Choose Ecosystem"
    ) {
      return "Ecosystem";
    }
    if (
      concept === "Select Types Of Rocks" ||
      concept === "Choose Types Of Rocks"
    ) {
      return "TypesOfRocks";
    }
    if (
      concept === "Select Vegetables" ||
      concept === "Choose Vegetables"
    ) {
      return "Vegetables";
    }
    if (
      concept === "Select Chamber in Heart" ||
      concept === "Choose Chamber in Heart"
    ) {
      return "NoOfChamberHearts";
    }
    if (
      concept === "Select Vitamins" ||
      concept === "Choose Vitamins"
    ) {
      return "Vitamins";
    }
    if (
      concept === "Select Vitamins Defeciency" ||
      concept === "Choose Vitamins Defeciency"
    ) {
      return "VitaminsDefeciency";
    }
    if (
      concept === "Select Vitamins Importance" ||
      concept === "Choose Vitamins Importance"
    ) {
      return "VitaminsImportance";
    }
    if (
      concept === "Select Sources of Vitamins" ||
      concept === "Choose Sources of Vitamins"
    ) {
      return "VitaminsOfSource";
    }
    if (
      concept === "Select Endrocrine Gland" ||
      concept === "Choose Endrocrine Gland"
    ) {
      return "EndrocrineGland";
    }
    if (
      concept === "Select Human Body System"
    ) {
      return "PurposeOfHumanBodySystem";
    }
    if (
      concept === "Select Types Of Muscle Cells" ||
      concept === "Choose Types Of Muscle Cells"
    ) {
      return "TypesOfMuscleCells";
    }
    if (
      concept === "Select Definitions"
    ) {
      return "Definitions";
    }
    if (
      concept === "Newton's law"
    ) {
      return "Newton'slaw";
    }



    if (
      concept === "Select Biochemistry" ||
      concept === "Choose Biochemistry" ||
      concept === "Identify Biochemistry" ||
      concept === "Biochemistry"
    ) {
      return "BioChemistry";
    }
    if (
      concept === "Select Kinetic/Potential Energy" ||
      concept === "Choose Kinetic/Potential Energy"
    ) {
      return "kineticAndPotentialEnergy";
    }
    if (
      concept === "Select Atoms and molecules" ||
      concept === "Choose Atoms and molecules"
    ) {
      return "AtomsAndMolecules";
    }
    if (
      concept === "Select life cycle of animals"
    ) {
      return "LifeCycleOfAnimals";
    }
    if (
      concept === "Select Human Organ Functions" ||
      concept === "Choose Human Organ Functions"
    ) {
      return "HumanOrganFunctions";
    }
    if (
      concept === "Select Fossil Traits" ||
      concept === "Choose Fossil Traits"
    ) {
      return "FossilTraits";
    }
    if (
      concept === "Select Property of Materials" ||
      concept === "Choose Property of Materials"
    ) {
      return "PropertyofMaterials";
    }
    if (
      concept === "Select Plate boundaries" ||
      concept === "Choose Plate boundaries"
    ) {
      return "PlateBoundaries";
    }
    if (
      concept === "Select Hazards" ||
      concept === "Choose Hazards"
    ) {
      return "Hazards";
    }
    if (
      concept === "Select Photosynthsis" ||
      concept === "Choose Photosynthsis"
    ) {
      return "PhotoSynthesis";
    }
    if (
      concept === "Select Geography" ||
      concept === "Choose Geography"
    ) {
      return "Geography";
    }
    if (
      concept === "Historical Persons"
    ) {
      return "HistoricalPersons";
    }
    if (
      concept === "American Symbols,Landmarks & Monuments"
    ) {
      return "AmericanSymbolsLandmarksAndMonuments";
    }
    if (
      concept === "Cultural Celebrations"
    ) {
      return "CulturalCelebrations";
    }
    if (
      concept === "Economics"
    ) {
      return "Economics";
    }
    if (
      concept === "Select States" ||
      concept === "Choose States"
    ) {
      return "States";
    }
    if (
      concept === "Select Capitals" ||
      concept === "Choose Capitals"
    ) {
      return "Capitals";
    }
    if (
      concept === "Select Geography" ||
      concept === "Choose Geography"
    ) {
      return "Geography";
    }
    if (
      concept === "Social Studies Skills"
    ) {
      return "SocialStudiesSkills";
    }
    if (
      concept === "Basic Economic Principles"
    ) {
      return "BasicEconomicPrinciples";
    }
    if (
      concept === "Supply And Demand"
    ) {
      return "SupplyAndDemand";
    }
    if (
      concept === "English Colonies In North America"
      || concept === "Select English Colonies of North America"
    ) {
      return "EnglishColoniesInNorthAmerica";
    }
    if (
      concept === "The American Revolution"
    ) {
      return "TheAmericanRevolution";
    }
    if (
      concept === "Early 19th Century American History"
    ) {
      return "Early19thCenturyAmericanHistory";
    }
    if (
      concept === "The Civil War And Reconstruction"
    ) {
      return "TheCivilWarAndReconstruction";
    }
    if (
      concept === "20th Century American History"
    ) {
      return "20thCenturyAmericanHistory";
    }
    if (
      concept === "Maps"
    ) {
      return "Maps";
    }
    if (
      concept === "American National Holidays"
    ) {
      return "AmericanNationalHolidays";
    }
    if (
      concept === "Banking And Finance"
    ) {
      return "BankingAndFinance";
    }
    // if (
    //   concept === "Population Of Cities" 
    // ) {
    //   return "";
    // }
    if (
      concept === "New England Colonies"
    ) {
      return "EnglishColoniesNorthAmerica";
    }
    if (
      concept === "Select Plymouth"
    ) {
      return "Plymouth";
    }
    if (
      concept === "Middle Colonies"
    ) {
      return "MiddleColonies";
    }
    if (
      concept === "Southern Colonies"
    ) {
      return "SouthernColonies";
    }
    if (
      concept === "Thirteen Colonies/Non ThirteenColonies"
    ) {
      return "ThirteenColonies";
    }
    if (
      concept === "The American Revolution" ||
      concept === "The American Revolution- The Thirteen Colonies Under BritishRule"
    ) {
      return "TheAmericanRevolution";
    }
    if (
      concept === "New British Taxes"
    ) {
      return "NewBritishTaxes";
    }
    if (
      concept === "The Boston Party"
    ) {
      return "TheBostonParty";
    }
    if (
      concept === "Preparing For War"
    ) {
      return "PreparingForWar";
    }
    if (
      concept === "The Rebellion Begins"
    ) {
      return "TheRebellionBegins";
    }
    if (
      concept === "Struggle For Independence"
    ) {
      return "StruggleForIndependence";
    }
    if (
      concept === "Conclusion And Aftermath"
    ) {
      return "ConclusionAndAfterMath";
    }
    if (
      concept === "Early Nineteenth Century American History"
    ) {
      return "EarlyNineteenththCenturyAmericanHistory";
    }
    if (
      concept === "The Lewis And Clark Expedition"
    ) {
      return "TheLewisAndClarkExpedition";
    }
    if (
      concept === "The War Of 1812 Causes"
    ) {
      return "TheWarOf1812Causes";
    }
    if (
      concept === "Ante Bellam Period"
    ) {
      return "AnteBellamPeriod";
    }
    if (
      concept === "Civil War And Reconstruction"
    ) {
      return "CivilWarAndReconstruction";
    }
    if (
      concept === "American History Of Twentieth Century"
    ) {
      return "AmericanHistoryOfTwentiethCentury";
    }
    if (
      concept === "The Great Depression"
    ) {
      return "TheGreatDepression";
    }
    if (
      concept === "World War II"
    ) {
      return "WorldWarII";
    }
    if (
      concept === "American Government"
    ) {
      return "AmericanGovernment";
    }
    if (
      concept === "Longitude And Latitude Lines"
    ) {
      return "LongitudeAndLatitudeLines";
    }
    else {
      return concept;
    }

  };

  const onFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log("ON FILE CHANGE ", e.target.files);
      setSelectedFile(e.target.files[0]);
    } else {
      setSelectedFile(undefined);
    }
  };

  const fileEncoder = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  };
  const onFileUpload = () => {
    console.log("ON FILE UPDLAO ");
    const formData = new FormData();
    // const encodedFile = fileEncoder(selectedFile)
    const body = {
      // file: selectedFile,
      userId,
      childId: location.state.childId,
    };
    formData.append("file", selectedFile, selectedFile.name);

    dispatch(
      actions.uploadChildProfilePic({
        body,
        formData,
      })
    );
  };

  const getCurrentlevel = (conceptName) => {
    let currentLevel = 1;
    childRankings && childRankings.map((rank) => {
      if (conceptName === rank.grade) {
        currentLevel = rank.rankings && rank.rankings.currentBadge
          ? Number(rank.rankings.currentBadge
            .badgeNumber) * Number(rank.rankings.currentBadge
              .currentLevel)
          : "";
      }
    });
    return currentLevel;
  };

  const fileData = () => {
    if (this.state.selectedFile) {
      return (
        <div>
          <h2>File Details:</h2>

          <p>File Name: {this.state.selectedFile.name}</p>

          <p>File Type: {this.state.selectedFile.type}</p>

          <p>
            Last Modified:{" "}
            {this.state.selectedFile.lastModifiedDate.toDateString()}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  };

  return (
    <Paper style={{ marginTop: 30 }}>
      <Modal
        open={showSpeedTestModal}
        style={{ marginTop: "25" }}
      // onClose={() => setshowSpeedTestModal(false)}    To close on Click outside modal
      >
        <SelectCourse
          onCancel={() => setShowSpeedTestModal(false)}
          speedTestSubmit={speedTestSubmit}
        />
      </Modal>
      <Grid
        container
        spacing={3}
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid
          item
          xs={6}
          style={{ display: "flex", justifyContent: "flex-start" }}
          key={`pic-${childInfo && childInfo.childProfilePic
            ? childInfo.childProfilePic + childInfo.timeStamp
            : "default-pic"
            }`}
        >
          <div
            style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              display: "flex",
            }}
          >
            <input
              id="select-profile-pic"
              type="file"
              onChange={onFileChange}
              style={{ display: "none" }}
            />
            <Button style={{ textTransform: "none" }} variant="text" size="small" color="inherit">
              <label htmlFor="select-profile-pic">Upload Profile Picture <br />
                <span style={{ color: "red", fontSize: 10 }}>(Image size should not exceed 1MB)</span></label>
            </Button>
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
        >
          {childInfo && childInfo.firstName ? (
            <Button
              variant="text"
              size="medium"
              color="secondary"
              onClick={() => history.push("/profile/" + childInfo.profileName)}
              path
            >
              <Typography color="primary" variant="h4">
                {childInfo && childInfo.firstName
                  ? childInfo.firstName + " " + childInfo.lastName
                  : "Guest Child"}
              </Typography>
            </Button>
          ) : (
            <Typography color="primary" variant="h5">
              Guest Child
            </Typography>
          )}
          {location && location.state && location.state.userType === "user" && (
            <Box component="span" m={1}>
              <Button
                variant="text"
                size="small"
                color="secondary"
                onClick={() => setShowDeleteChildModal(true)}
                path
              >
                Delete child
              </Button>
            </Box>
          )}
        </Grid>
        <Dialog open={showDeleteChildModal}>
          <Grid
            container
            spacing={2}
            style={{
              // marginTop: 100,
              padding: 25,
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              backgroundColor: "white",
              minHeight: "30vh",
            }}
          >
            <Typography color="primary" variant="h5">
              Delete Profile
            </Typography>
            <Typography>
              Child Profile {childInfo ? `"${childInfo.firstName}"` : ""}will be
              deleted permanently
            </Typography>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={4}>
                <Button
                  variant="outlined"
                  color="error"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setShowDeleteChildModal(false);
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item={true} xs={4}>
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{ width: "100%" }}
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={() => setShowSnackbar(false)}
        >
          <Alert
            elevation={6}
            onClose={() => setShowSnackbar(false)}
            severity={"error"}
          >
            {deleteChildError
              ? deleteChildError
              : "Delete request failed, please try again."}
          </Alert>
        </Snackbar>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography variant="h4">Courses</Typography>
        </Grid>
        {coursesList &&
          coursesList.map((course) => (
            <Grid item md={2} xs={2}>
              <Card style={{ width: "100%", height: "100%" }}>
                <CardActionArea
                  style={{
                    padding: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={() => {
                    history.push({
                      pathname:
                        location && location.state && location.state.userType &&
                          location && location.state && location.state.userType === "guest"
                          ? "/guest/child/course"
                          : "/child/course",
                      state: {
                        childId: location.state.childId,
                        courseId: course.courseId,
                        numOfQuestionsPerDay: course.numOfQuestionsPerDay,
                        userType: location && location.state && location.state.userType,
                        pageFlag: "childhome",
                      },
                    });
                  }}
                >
                  <Typography variant="h4">
                    {course.courseId.substring(0, 1) === "M"
                      ? "Math"
                      : course.courseId.substring(0, 1) === "R"
                        ? "Reading"
                        : (course.courseId.substring(0, 1) === "S" ? "Science" : "Social")}
                  </Typography>

                  <Typography variant="subtitle2" style={{ marginTop: 6 }}>
                    {course.numOfQuestionsPerDay
                      ? `Questions/day : ${course.numOfQuestionsPerDay}`
                      : ""}
                  </Typography>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        <Grid item md={3} xs={12}>
          {" "}
          <Card style={{ width: "100%", height: "100%" }}>
            <CardActionArea
              style={{
                padding: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                cursor: "pointer",
                width: "100%",
                height: "100%",
              }}
              onClick={() => {
                console.log("CLICKED Additional Activities");
              }}
            >
              <Typography variant="h4">Activities</Typography>

              <Typography variant="subtitle2" style={{ marginTop: 6 }}>
                Questions/day : 20
              </Typography>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={4}
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid item md={2} xs={12}>
          {isLoggedIn && location && location.state && location.state.childId &&
            mathPracticeConcepts.map((tier) => (
              <Grid>
                {tier.mathCourseId === "M" + childInfo.grade && (
                  <Grid>
                    <Card style={{ width: "100%", height: "100%" }}>
                      <CardHeader
                        subheader="Practice Math Concepts"
                        titleTypographyProps={{ align: "center" }}
                        subheaderTypographyProps={{ align: "center" }}
                        // action={tier.title === 'Pro' ? <StarIcon /> : null}
                        className={classes.cardHeader}
                      />
                      <CardContent>
                        <ul style={{ listStyle: "none" }}>
                          {tier.concepts.map((concept) => (
                            <>
                              <Link
                                variant="button"
                                display="block"
                                component="li"
                                align="left"
                                onClick={() => {
                                  history.push({
                                    pathname: "/guest/child/homework",
                                    state: {
                                      childId: location && location.state && location.state.childId,
                                      hwDate: "2021-08-08",
                                      subjectCd: tier.mathCourseId,
                                      numOfQuestionsPerDay: 60,
                                      userType: "guest",
                                      ctest: concept.cTest,
                                      pageFlag: "practice",
                                      concept: concept.concept,
                                      practiceConcept: concept.concept.split('-')[0],
                                      currentLevel: getCurrentlevel(getConceptName(concept.conceptName)),
                                    },
                                  });
                                }}
                              >
                                {concept.conceptName}
                              </Link>
                              {childRankings &&
                                childRankings.map((rank) => {
                                  const cName = getConceptName(
                                    concept.conceptName
                                  );
                                  if (cName === rank.grade) {
                                    return (
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Typography
                                            color="green"
                                            variant="caption"
                                            display="block"
                                          >
                                            {rank.rankings
                                              ? rank.rankings.currentBadge.name
                                              : ""}
                                          </Typography>
                                          <Typography
                                            align="left"
                                            color="green"
                                            variant="caption"
                                            display="block"
                                          >
                                            Level{" "}
                                            {rank.rankings && rank.rankings.currentBadge
                                              ? rank.rankings.currentBadge
                                                .currentLevel
                                              : ""}
                                            {rank.rankings && rank.rankings.currentBadge
                                              && ' (' + (rank.rankings.currentBadge
                                                .pointsAchieved % 500) + '/500)'}
                                          </Typography>
                                          <Typography
                                            variant="caption"
                                            display="block"
                                          >
                                            {rank.rankings && rank.rankings.currentBadge
                                              && (rank.rankings.currentBadge
                                                .pointsAchieved >= 10000 ? 'Completed' : "")}
                                            {rank.rankings && rank.rankings.currentBadge && '(' + rank.rankings.currentBadge
                                              .pointsAchieved + '/10000)'}
                                          </Typography>
                                        </div>
                                        <ProgressBar
                                          style={{
                                            height: "0.75rem",
                                            fontSize: "1rem",
                                          }}
                                        >
                                          <ProgressBar
                                            animated
                                            striped
                                            variant="success"
                                            now={
                                              rank.rankings &&
                                                rank.rankings.currentBadge
                                                ? (100 *
                                                  rank.rankings.currentBadge
                                                    .pointsAchieved) /
                                                2000
                                                : 0
                                            }
                                            key={1}
                                            label={
                                              rank.rankings &&
                                                rank.rankings.currentBadge
                                                ? (100 *
                                                  rank.rankings.currentBadge
                                                    .pointsAchieved) /
                                                2000
                                                : 0
                                            }
                                          />
                                        </ProgressBar>
                                        <Divider
                                          className={classes.dividerFullWidth}
                                          variant="inset"
                                          component="li"
                                        />
                                      </>
                                    );
                                  }
                                })}
                            </>
                          ))}
                        </ul>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            ))}
        </Grid>

        <Grid item md={2} xs={12}>
          {isLoggedIn &&
            languagePracticeConcepts.map((tier) => (
              <Grid>
                {tier.courseId === "R" + childInfo.grade && (
                  <Grid>
                    <Card style={{ width: "100%", height: "100%" }}>
                      <CardHeader
                        subheader="Practice Language Concepts"
                        titleTypographyProps={{ align: "center" }}
                        subheaderTypographyProps={{ align: "center" }}
                        // action={tier.title === 'Pro' ? <StarIcon /> : null}
                        className={classes.cardHeader}
                      />
                      <CardContent>
                        <ul style={{ listStyle: "none" }}>
                          {tier.concepts.map((concept) => (
                            <>
                              <Link
                                variant="button"
                                display="block"
                                component="li"
                                align="left"
                                onClick={() => {
                                  console.log(
                                    "SERVICE language",
                                    tier,
                                    concept.cTest,
                                    concept,
                                    " ==== ",
                                    concept.concept
                                  );
                                  history.push({
                                    pathname: "/guest/child/homework",
                                    state: {
                                      childId: location && location.state && location.state.childId,
                                      hwDate: "2021-08-08",
                                      subjectCd: tier.courseId,
                                      numOfQuestionsPerDay: 60,
                                      userType: "guest",
                                      ctest: concept.cTest,
                                      pageFlag: "practice",
                                      concept: concept.concept,
                                    },
                                  });
                                }}
                              >
                                {concept.conceptName}
                              </Link>
                              {childRankings &&
                                childRankings.map((rank) => {
                                  const cName = getConceptName(
                                    concept.conceptName
                                  );
                                  console.log(
                                    "@@@@@@@@@@@@@@@@@@@@@@@@ ",
                                    cName
                                  );
                                  if (cName === rank.grade) {
                                    return (
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Typography
                                            color="green"
                                            variant="caption"
                                            display="block"
                                            style={{ fontSize: 'xx-small' }}
                                          >
                                            {rank.rankings
                                              ? rank.rankings.currentBadge.name
                                              : ""}
                                          </Typography>
                                          <Typography
                                            align="left"
                                            color="green"
                                            variant="caption"
                                            display="block"
                                            style={{ fontSize: 'xx-small' }}
                                          >
                                            Level{" "}
                                            {rank.rankings
                                              ? rank.rankings.currentBadge
                                                .currentLevel
                                              : ""}
                                          </Typography>
                                          <Typography
                                            variant="caption"
                                            display="block"
                                            style={{ fontSize: 'xx-small' }}
                                          >
                                            {rank.rankings
                                              ? rank.rankings.currentBadge
                                                .pointsAchieved
                                              : ""}
                                            /500
                                          </Typography>
                                        </div>
                                        <ProgressBar
                                          style={{
                                            height: "0.75rem",
                                            fontSize: "1rem",
                                          }}
                                        >
                                          <ProgressBar
                                            animated
                                            striped
                                            variant="success"
                                            now={
                                              rank.rankings &&
                                                rank.rankings.currentBadge
                                                ? (100 *
                                                  rank.rankings.currentBadge
                                                    .pointsAchieved) /
                                                2000
                                                : 0
                                            }
                                            key={1}
                                            label={
                                              rank.rankings &&
                                                rank.rankings.currentBadge
                                                ? (100 *
                                                  rank.rankings.currentBadge
                                                    .pointsAchieved) /
                                                2000
                                                : 0
                                            }
                                          />
                                        </ProgressBar>
                                        <Divider
                                          className={classes.dividerFullWidth}
                                          variant="inset"
                                          component="li"
                                        />
                                      </>
                                    );
                                  }
                                })}
                            </>
                          ))}
                        </ul>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            ))}
        </Grid>

        <Grid item md={2} xs={16}>
          {isLoggedIn &&
            sciencePracticeConcepts.map((tier) => (
              <Grid>
                {tier.courseId === "S" + childInfo.grade && (
                  <Grid>
                    <Card style={{ width: "100%", height: "100%" }}>
                      <CardHeader
                        subheader="Practice Science Concepts"
                        titleTypographyProps={{ align: "center" }}
                        subheaderTypographyProps={{ align: "center" }}
                        // action={tier.title === 'Pro' ? <StarIcon /> : null}
                        className={classes.cardHeader}
                      />
                      <CardContent>
                        <ul style={{ listStyle: "none" }}>
                          {tier.concepts.map((concept) => (
                            <>
                              <Link
                                variant="button"
                                display="block"
                                component="li"
                                align="left"
                                onClick={() => {
                                  console.log(
                                    "SERVICE language",
                                    tier,
                                    concept.cTest,
                                    concept,
                                    " ==== ",
                                    concept.concept
                                  );
                                  history.push({
                                    pathname: "/guest/child/homework",
                                    state: {
                                      childId: location && location.state && location.state.childId,
                                      hwDate: "2021-08-08",
                                      subjectCd: tier.courseId,
                                      numOfQuestionsPerDay: 60,
                                      userType: "guest",
                                      ctest: concept.cTest,
                                      pageFlag: "practice",
                                      concept: concept.concept,
                                    },
                                  });
                                }}
                              >
                                {concept.conceptName}
                              </Link>
                              {childRankings &&
                                childRankings.map((rank) => {
                                  const cName = getConceptName(
                                    concept.conceptName
                                  );
                                  console.log(
                                    "@@@@@@@@@@@@@@@@@@@@@@@@ ",
                                    cName
                                  );
                                  if (cName === rank.grade) {
                                    return (
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Typography
                                            color="green"
                                            variant="caption"
                                            display="block"
                                          >
                                            {rank.rankings
                                              ? rank.rankings.currentBadge.name
                                              : ""}
                                          </Typography>
                                          <Typography
                                            align="left"
                                            color="green"
                                            variant="caption"
                                            display="block"
                                          >
                                            Level{" "}
                                            {rank.rankings
                                              ? rank.rankings.currentBadge
                                                .currentLevel
                                              : ""}
                                          </Typography>
                                          <Typography
                                            variant="caption"
                                            display="block"
                                          >
                                            {rank.rankings
                                              ? rank.rankings.currentBadge
                                                .pointsAchieved
                                              : ""}
                                            /500
                                          </Typography>
                                        </div>
                                        <ProgressBar
                                          style={{
                                            height: "0.75rem",
                                            fontSize: "1rem",
                                          }}
                                        >
                                          <ProgressBar
                                            animated
                                            striped
                                            variant="success"
                                            now={
                                              rank.rankings &&
                                                rank.rankings.currentBadge
                                                ? (100 *
                                                  rank.rankings.currentBadge
                                                    .pointsAchieved) /
                                                2000
                                                : 0
                                            }
                                            key={1}
                                            label={
                                              rank.rankings &&
                                                rank.rankings.currentBadge
                                                ? (100 *
                                                  rank.rankings.currentBadge
                                                    .pointsAchieved) /
                                                2000
                                                : 0
                                            }
                                          />
                                        </ProgressBar>
                                        <Divider
                                          className={classes.dividerFullWidth}
                                          variant="inset"
                                          component="li"
                                        />
                                      </>
                                    );
                                  }
                                })}
                            </>
                          ))}
                        </ul>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            ))}
        </Grid>
        <Grid item md={2} xs={12}>
          {isLoggedIn &&
            socialPracticeConcepts.map((tier) => (
              <Grid>
                {tier.courseId === "H" + childInfo.grade && (
                  <Grid>
                    <Card style={{ width: "100%", height: "100%" }}>
                      <CardHeader
                        subheader="Practice Social Concepts"
                        titleTypographyProps={{ align: "center" }}
                        subheaderTypographyProps={{ align: "center" }}
                        // action={tier.title === 'Pro' ? <StarIcon /> : null}
                        className={classes.cardHeader}
                      />
                      <CardContent>
                        <ul style={{ listStyle: "none" }}>
                          {tier.concepts.map((concept) => (
                            <>
                              <Link
                                variant="button"
                                display="block"
                                component="li"
                                align="left"
                                onClick={() => {
                                  console.log(
                                    "SERVICE language",
                                    tier,
                                    concept.cTest,
                                    concept,
                                    " ==== ",
                                    concept.concept
                                  );
                                  history.push({
                                    pathname: "/guest/child/homework",
                                    state: {
                                      childId: location && location.state && location.state.childId,
                                      hwDate: "2021-08-08",
                                      subjectCd: tier.courseId,
                                      numOfQuestionsPerDay: 60,
                                      userType: "guest",
                                      ctest: concept.cTest,
                                      pageFlag: "practice",
                                      concept: concept.concept,
                                    },
                                  });
                                }}
                              >
                                {concept.conceptName}
                              </Link>
                              {childRankings &&
                                childRankings.map((rank) => {
                                  const cName = getConceptName(
                                    concept.conceptName
                                  );
                                  console.log(
                                    "@@@@@@@@@@@@@@@@@@@@@@@@ ",
                                    cName
                                  );
                                  if (cName === rank.grade) {
                                    return (
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Typography
                                            color="green"
                                            variant="caption"
                                            display="block"
                                          >
                                            {rank.rankings
                                              ? rank.rankings.currentBadge.name
                                              : ""}
                                          </Typography>
                                          <Typography
                                            align="left"
                                            color="green"
                                            variant="caption"
                                            display="block"
                                          >
                                            Level{" "}
                                            {rank.rankings
                                              ? rank.rankings.currentBadge
                                                .currentLevel
                                              : ""}
                                          </Typography>
                                          <Typography
                                            variant="caption"
                                            display="block"
                                          >
                                            {rank.rankings
                                              ? rank.rankings.currentBadge
                                                .pointsAchieved
                                              : ""}
                                            /500
                                          </Typography>
                                        </div>
                                        <ProgressBar
                                          style={{
                                            height: "0.75rem",
                                            fontSize: "1rem",
                                          }}
                                        >
                                          <ProgressBar
                                            animated
                                            striped
                                            variant="success"
                                            now={
                                              rank.rankings &&
                                                rank.rankings.currentBadge
                                                ? (100 *
                                                  rank.rankings.currentBadge
                                                    .pointsAchieved) /
                                                2000
                                                : 0
                                            }
                                            key={1}
                                            label={
                                              rank.rankings &&
                                                rank.rankings.currentBadge
                                                ? (100 *
                                                  rank.rankings.currentBadge
                                                    .pointsAchieved) /
                                                2000
                                                : 0
                                            }
                                          />
                                        </ProgressBar>
                                        <Divider
                                          className={classes.dividerFullWidth}
                                          variant="inset"
                                          component="li"
                                        />
                                      </>
                                    );
                                  }
                                })}
                            </>
                          ))}
                        </ul>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            ))}
        </Grid>

        <Grid item md={3} xs={12}>
          {isLoggedIn && (
            <Grid>
              <Card style={{ width: "100%", height: 250 }}>
                <CardHeader
                  subheader="SPEED TEST"
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  // action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                ></CardHeader>
                <CardActionArea
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowSpeedTestModal(true);
                  }}
                >
                  <SpeedIcon color="action" style={{ fontSize: "210" }} />
                </CardActionArea>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid style={{ marginLeft: 22, height: 20 }} />
    </Paper >
  );
};

export default ChildHomePage;
