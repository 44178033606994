import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    // primary: {
    //   // Purple and green play nicely together.
    //   main: "#ffb606",
    // },
    // secondary: {
    //   // This is green.A700 as hex.
    //   main: "#11cb5f",
    // },
  },
});

export default theme;
