export function getQuestionsList({ homeworkReducer }) {
  if (homeworkReducer && homeworkReducer.questionsList)
    return homeworkReducer.questionsList;
  else return [];
}

export function getHomeWorkDay({ homeworkReducer }) {
  if (homeworkReducer && homeworkReducer.homeWorkDay)
    return homeworkReducer.homeWorkDay;
  else return "";
}

export function getRankings({ homeworkReducer }) {
  if (homeworkReducer && homeworkReducer.rankings)
    return homeworkReducer.rankings;
  else return "";
}
