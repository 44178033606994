import { loginConstants, utilsConstants } from "../constants";

const initialState = {
  isLoggedIn: false,
  userInfo: undefined,
  isLoginSuccess: false,
  loginError: "",
  hideToolbar: false,
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case loginConstants.ATTEMPT_LOGIN:
      return { ...initialState };
    case loginConstants.ATTEMPT_LOGIN_SUCCESS:
      console.log('11....',action.payload.userInfo && action.payload.userInfo.errorDtls ? true:false)
      return {
        ...state,
        isLoggedIn:
          action.payload.userInfo && action.payload.userInfo.errorDtls
            ? true
            : false,
        userInfo: action.payload.userInfo,
        isLoginSuccess: true,
      };
    case loginConstants.ATTEMPT_LOGIN_FAILURE:
      return {
        ...state,
        isLoginSuccess: false,
        loginError: "Login failed, Please check credentials and try again.",
      };
    case loginConstants.ATTEMPT_LOGOUT:
      return { ...initialState };
    case utilsConstants.HIDE_TOOLBAR:
      return { ...state, hideToolbar: action.payload ? true : false };
    default:
      return state;
  }
}
