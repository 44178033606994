
import React from 'react';
import { Grid, Button } from '@material-ui/core'
import { Link, NavLink } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import './style.scss'

const Footer = (props) => {
    const history = useHistory();
    return (
        <footer className='footerArea'>
            {/* <ScrollTop
                scrollStepInPx="50"
                delayInMs="16.66"
            /> */}
            <Grid className="container" container spacing={4}>
                {/* <Grid item xs={12}>
                    <Grid className="newsleter">
                        <h2>Subscribe to follow US</h2>
                        <form>
                            <input placeholder="Enter Your Email" type="text" />
                            <Button>Subscribe</Button>
                        </form>
                    </Grid>
                </Grid> */}
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Grid className="footerLogo">
                        <img src={props.logo} alt="" />
                        <p>Best Everyday Practice Mathematics, Reading, Science & Social</p>
                        <ul className="socialMedia">
                            <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                            <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                            <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                            <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid item lg={3} md={2} sm={3} xs={12}>
                    <h3 className="footertitle">Resources</h3>
                    <ul className="footerMenus">
                        <li><NavLink onClick={window.scrollTo(0, 0)} exact to="/privacypolicies">Privacy Policy
                        </NavLink></li>
                        <li><NavLink onClick={window.scrollTo(0, 0)} exact to="/terms">Terms & Conditions
                        </NavLink></li>
                        {/* <li><Link to="/aghgh">Support Policy</Link></li>
                        <li><Link to="/aghgh">Refund Policy</Link></li> */}
                    </ul>
                </Grid>
                <Grid item lg={3} md={2} sm={3} xs={12}>
                    <h3 className="footertitle">Useful Links</h3>
                    <ul className="footerMenus">
                        <li><Link to="/aboutus">About Us</Link></li>
                        {/* <li><Link to="/aghgh">Help Link</Link></li>
                        <li><Link to="/terms">Terms & Conditions</Link></li> */}
                        <li><Link to="/contact">Contact Us</Link></li>
                    </ul>
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <div className="footerEarningWrap">
                        <h3 className="footertitle">Our Goal</h3>
                        <p>Providing affordable education</p>
                        {/* <ul>
                            <li>
                                <span>Problems Solved</span>
                                <h4>32567</h4>
                            </li>
                            <li>
                                <span>Total Earning</span>
                                <h4>603598</h4>
                            </li>
                        </ul> */}
                    </div>

                </Grid>
                <Grid item xs={12} >
                    <p className="copyright">© 2024 EDULYN. All rights reserved.</p>
                </Grid>
            </Grid>
        </footer >
    );
}

export default Footer;
