import { put, takeLatest } from "redux-saga/effects";
import * as profileActions from "../actions/profile.actions";
import { serviceConstants } from "../constants";
import Transport from "../transport/rabbitboard.transport";
import { setShowLoader } from "../actions";

function* getProfileSaga(action) {
  console.log("action,..", action);
  try {
    const response = yield Transport.genericOperation(
      // serviceConstants.RABBIT_CHILD_URL + "/parent/" + "ravi.tvn@gmail.com",
      serviceConstants.RABBIT_CHILD_URL +
      "/profile/" +
      action.payload.profilename,
      serviceConstants.RABBIT_CHILD_URL_APIKEY,
      {},
      "GET"
    );
    if (response && response.child && response.child.childId) {
      console.log("RESPONSE ", response);
      yield put(setShowLoader(false));
      yield put(profileActions.getProfileSuccess(response.child));
      yield put(
        profileActions.getProfileRankings({ childId: response.child.childId })
      );
    }
  } catch (e) {
    console.error("getProfileSaga Saga ERROR ", e);
    throw e;
  }
}
function* getProfileRankingsSaga(action) {
  console.log("action,..", action);
  try {
    const response = yield Transport.genericOperation(
      // serviceConstants.RABBIT_CHILD_URL + "/parent/" + "ravi.tvn@gmail.com",
      serviceConstants.RABBIT_HOMEWORK_URL +
      "/rankings?childId=" +
      action.payload.childId,
      serviceConstants.RABBIT_HOMEWORK_URL_APIKEY,
      {},
      "GET"
    );
    if (response && response.rankings && response.rankings.length > 0) {
      console.log("RESPONSE ", response);
      yield put(setShowLoader(false));
      yield put(profileActions.getProfileRankingsSuccess(response.rankings));
      yield put;
    }
  } catch (e) {
    console.error("getProfileSaga Saga ERROR ", e);
    throw e;
  }
}

export default function* profileSaga() {
  try {
    yield takeLatest(profileActions.getProfile, getProfileSaga);
    yield takeLatest(profileActions.getProfileRankings, getProfileRankingsSaga);
  } catch {
    yield;
  }
}
