import React, { useEffect, useState, Fragment } from 'react';
import SectionTitle from '../SectionTitle/sectiontitle';
import { Grid, Tab, Tabs } from '@material-ui/core'
import SingleSubject from '../SingleSubject/singlesubject';
import './style.scss'




const Subjects = (props) => {
    const [value, setValue] = useState(0);
    console.log('props.practiceConceptsData..', props.practiceConceptsData)
    const [practiceConcepts, setPracticeConcepts] = useState(undefined);
    const [practiceConceptsForGrade, setPracticeConceptsForGrade] = useState(undefined);
    const [courseConcepts, setCourseConcepts] = useState();

    useEffect(() => {
        getCourseForSubject(value);
        // console.log('dtat..', props.practiceConceptsData.practiceConcepts, practiceConcepts)
    }, []);

    function handleChange(event, newValue) {
        console.log('event value,.. ', newValue)
        setValue(newValue);
        getCourseForSubject(newValue);
    }

    const getCourseForSubject = (grade) => {
        let pData = "";
        if (grade === 0) {
            pData = props.practiceConceptsData.practiceConcepts && props.practiceConceptsData.practiceConcepts.filter((subject) => subject.grade === "P")
        } else if (grade === 1) {
            pData = props.practiceConceptsData.practiceConcepts && props.practiceConceptsData.practiceConcepts.filter((subject) => subject.grade === "K")
        } else if (grade === 2) {
            pData = props.practiceConceptsData.practiceConcepts && props.practiceConceptsData.practiceConcepts.filter((subject) => subject.grade === "1")
        } else if (grade === 3) {
            pData = props.practiceConceptsData.practiceConcepts && props.practiceConceptsData.practiceConcepts.filter((subject) => subject.grade === "2")
        } else if (grade === 4) {
            pData = props.practiceConceptsData.practiceConcepts && props.practiceConceptsData.practiceConcepts.filter((subject) => subject.grade === "3")
        } else if (grade === 5) {
            pData = props.practiceConceptsData.practiceConcepts && props.practiceConceptsData.practiceConcepts.filter((subject) => subject.grade === "4")
        } else if (grade === 6) {
            pData = props.practiceConceptsData.practiceConcepts && props.practiceConceptsData.practiceConcepts.filter((subject) => subject.grade === "5")
        } else if (grade === 7) {
            pData = props.practiceConceptsData.practiceConcepts && props.practiceConceptsData.practiceConcepts.filter((subject) => subject.grade === "6")
        }
        if (pData && pData[0]) {
            setPracticeConceptsForGrade(pData[0])
            console.log('practiceConceptsForGrade[0]..', pData)
            setCourseConcepts(pData[0].courseConcepts)
            console.log('course concepts..', pData[0].courseConcepts, courseConcepts)
        } else {
            setPracticeConceptsForGrade(undefined)
            setCourseConcepts(undefined)
        }

    }

    const getSubjectName = (courseId) => {
        if (courseId.charAt(courseId) === 'M') {
            return "Mathematics";
        } else if (courseId.charAt(courseId) === 'R') {
            return "Reading";
        } else if (courseId.charAt(courseId) === 'S') {
            return "Science";
        } else if (courseId.charAt(courseId) === 'H') {
            return "Social";
        }
    }
    return (
        <Grid className="productArea ptb-104">
            <Grid container spacing={4} className="container">
                <Grid item xs={12}>
                    <SectionTitle
                        title="Daily Home Work Application"
                        subtitle="MATHEMATICS - READING - SCIENCE - SOCIAL"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        classes={{

                            //     root: 'productTabs',
                            //     flexContainer: 'productTabContainer',
                            //     indicator: 'productTabIndicator'
                        }}
                        centered={true}
                    >
                        <Tab style={{ minWidth: 10 }} label="Pre-K" />
                        <Tab style={{ minWidth: 10 }} label="Kindergarten" />
                        <Tab style={{ minWidth: 10 }} label="First Grade" />
                        <Tab style={{ minWidth: 10 }} label="Second Grade" />
                        <Tab style={{ minWidth: 10 }} label="Third Grade" />
                        <Tab style={{ minWidth: 10 }} label="Fourth Grade" />
                        <Tab style={{ minWidth: 10 }} label="Fifth Grade" />
                        <Tab style={{ minWidth: 10 }} label="Sixth Grade" />
                    </Tabs>
                </Grid>
                <Fragment>
                    {practiceConceptsForGrade && courseConcepts && courseConcepts && courseConcepts.map((course, i) =>
                        < Grid key={i} item lg={3} sm={6} xs={12} >
                            <SingleSubject
                                key={"key_" + value + "string_" + practiceConceptsForGrade.practiceChildId}
                                practiceChildId={practiceConceptsForGrade.practiceChildId}
                                numOfQuestionsPerDay={practiceConceptsForGrade.numOfQuestionsPerDay}
                                courseConcepts={course}
                                badge="new"
                                title="title"
                                subjectCd={course.courseId}
                                subjectName={getSubjectName(course.courseId)}
                            />
                        </Grid>
                    )}
                </Fragment>
            </Grid>
        </Grid >
    );
}
export default Subjects
