import {
    Button,
    Grid,
    makeStyles,
    useMediaQuery,
    useTheme,
  } from "@material-ui/core";
  import React, { useEffect, useState } from "react";
  import Snackbar from '@material-ui/core/Snackbar';
  import MuiAlert from '@material-ui/lab/Alert';
  import Speech from "../../../util/speech";
  
  
  const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
      fontSize: 40,
      textAlign: "center",
    },
  }));
  
  const CountPic = (props) => {
    const theme = useTheme();
  
    const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
    const [currentQuestionId, setCurrentQuestionId] = useState(undefined);
    const [disableAnswer, setDisableAnswer] = useState(false);
    const [displayAnswer, setDisplayAnswer] = useState("");
    const [answer, setAnswer] = useState("");

    const [showSpinner, setShowSpinner] = useState(false);
    const [attemptCount, setAttemptCount] = useState(0);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [studentAnswer, setStudentAnswer] = useState("");
    const [showHands, setShowHands] = useState(false);
    const [question, setQuestion] = useState("");
    const [open, setOpen] = useState(false);
    const [toastMessage, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const [firstNumber, setFirstNumber] = useState("");
    const [secondNumber, setSecondNumber] = useState("");
    const  width = 1000
    const  height = 1000
    const urlStringSample =  "https://eappimages.s3.amazonaws.com/regularshapes/";
  
    useEffect(() => {
        console.log("in count pic")
      if (
        props.currentHomeWork &&
        props.currentHomeWork.questionId &&
        props.currentHomeWork.questionId !== currentQuestionId
      ) {
        setStudentAnswer(
          props.currentHomeWork.studentAnswer
            ? props.currentHomeWork.studentAnswer
            : ""
        );
        setCurrentQuestionId(props.currentHomeWork.questionId);
        setShowHands(true);
        setQuestion(props.currentHomeWork.firstVar);
        setAttemptCount(0);
        setAnswer(
          props.currentHomeWork.finalAnswer === "1"
            ? props.currentHomeWork.studentAnswer
            : ""
        );
        setDisableAnswer(
          props.currentHomeWork.finalAnswer === "1" ? true : false
        );
        setDisplayAnswer(
          props.currentHomeWork.finalAnswer === "1"
            ? props.currentHomeWork.answer
            : ""
        );
  
        setShowSpinner(false);
      }
    }, [props.currentHomeWork]);
  
    const isAnswerValid = () => {
        if (disableAnswer) {
            props.goToNextQuestion();
        }else{      
                console.log("VALIDATING ANSWER...", firstNumber,secondNumber);
                if (answer){
                    isAnswerCorrect();
                    }
                else{
                    setMessage("Invalid Answer!. Enter Value.")
                    setMessageType("warning")
                    handleClick() 
                }
                };
            }
  
    const isAnswerCorrect = () => {
      var isCorrect = false;
      console.log("CHECKING ANSWER");
      const answerString = answer;
      console.warn("answerString ", answerString);
      isCorrect =
        answerString &&
        answerString.length > 0 &&
        answerString === props.currentHomeWork.answer;
      if (isCorrect) {
        console.log("IS CORRECT, GO TO NEXT QUESTION");
        props.onSubmitQuestion(
          answerString,
          attemptCount + 1,
          true,
          props.currentHomeWork.questionId
        );
        setMessage("Correct Answer!. Good job.")
        setMessageType("success")
        handleClick()
      } else {
        console.log("WRONG ANSWER ");
        setAttemptCount(attemptCount + 1);
  
        if (attemptCount >= 2) {
          // props.onShowToast("Wrong Answer!. Next Question.", "danger");
          setMessage("Wrong Answer!. Try Next Question.")
          setMessageType("error")
          handleClick()
          props.onSubmitQuestion(
            answerString,
            attemptCount,
            false,
            props.currentHomeWork.questionId
          );
        } else {
          // props.onShowToast("Wrong Answer!. Try Again.", "warning");
          setMessage("Wrong Answer!. Try Again.")
          setMessageType("warning")
          handleClick()
        }
      }
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
  
    const handleClick = () => {
      setOpen(true);
    };
  
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
  
    const clearAnswer = () => {
      setAnswer("");
      console.log("CLEAR ANSWER CALLED");
    };
  
    const addAnswerToState = (event) => {
      setAnswer(event.target.value);
    };
  
    const toggleHelpModal = (flag) => {
      setShowHelpModal(flag);
    };

    const onSelection = (val) => {
        console.log("CAME HERE onSelection = ", val);
        setAnswer(val);
      };

    const renderRowCmp = (offset) => {
        console.log("in render cmp",offset)
       
        const { currentHomeWork } = props
        const rowData = currentHomeWork.strList.slice(offset, offset + 6)
        return <div
        style={{
            display: "flex",
            justifyContent: "space-around",
            margin: 10,
            flexDirection: mobileDevice ? "column" : "row",
        }}>
            {rowData.map((num, index) => {
            const currentQuestionValue = currentHomeWork.strList[offset + index]
            console.log("props.currentHomeWork.ansList[index + offset] ", disableAnswer, props.currentHomeWork.question[index + offset], currentQuestionValue)
            if (currentQuestionValue !== 0) return <div style={{ width: 90 }} >
                <img
                    resizeMode="stretch"
                    src={currentQuestionValue}
                    style={{
                        width: .1 * width,
                        height: .1 * width,
                        padding: 10,
                    }}
                />
            </div>
            else return <div style={{ width: .1 * width, height: .1 * width, }}></div>
        })}</div>
    }

    const renderRow = (offset) => {
        console.log("in render cmp",offset)
       
        const { currentHomeWork } = props
        const rowData = currentHomeWork.varList.slice(offset, offset + 6)
        return <div
        style={{
            display: "flex",
            justifyContent: "space-around",
            margin: 10,
            flexDirection: mobileDevice ? "column" : "row",
        }}>
            {rowData.map((num, index) => {
            const currentQuestionValue = currentHomeWork.varList[offset + index]
            console.log("props.currentHomeWork.ansList[index + offset] ", disableAnswer, props.currentHomeWork.question[index + offset], currentQuestionValue)
            if (currentQuestionValue !== 0) return <div style={{ width: 90 }} >
                <img
                    resizeMode="stretch"
                    src={props.currentHomeWork.secondVar}
                    style={{
                        width: .1 * width,
                        height: .1 * width,
                        padding: 10,
                    }}
                />
            </div>
            else return <div style={{ width: .1 * width, height: .1 * width, }}></div>
        })}</div>
    }

    const renderOptions = () => {
      if( props.currentHomeWork.qType === "piccmp"){  
           return props.currentHomeWork.strAnsList.map((item, index) => {
            return (
              <div key={`answer-${index}-${answer[index]}`}>
                <img
                  onClick={() => onSelection(item)}
                  src={urlStringSample + item.toLowerCase() + ".png"}
                  border={2}
                  style={{
                    width: 100,
                    height: 100,
                    margin: 20,
                    borderColor: disableAnswer
                      ? props.currentHomeWork.isRightAnswer
                        ? "green"
                        : "red"
                      : "#4e67c7",
                    borderWidth: answer === item ? 4 : 0,
                  }}
                />
              </div>
            );
          });
        }else if(props.currentHomeWork.qType === "rprsnt"){
            return (
                <div style={{ display: "inline-flex", flexDirection: "row" }}>
                  {props.currentHomeWork.strAnsList.map((item, index) => {
                    return (
                      <div key={`answer-${index}-${answer[index]}`}>
                        <Button
                          variant={disableAnswer ? "outlined" : "contained"}
                          color={
                            disableAnswer
                              ? item === props.currentHomeWork.answer
                                ? "default"
                                : "secondary"
                              : answer === item
                              ? "inherit"
                              : "primary"
                          }
                          style={{
                            width: 100,
                            borderWidth: 2,
                            borderRadius: 5,
                            paddingVertical: 20,
                            justifyContent: "center",
                            margin: 5,
                          }}
                          onClick={() => onSelection(item)}
                        >
                          {item}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              );
        }
    }
  
    return (
      <Grid
        container
        style={{
          padding: 25,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Grid container style={{ padding: 16 }}>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: mobileDevice ? "column" : "row",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => clearAnswer()}
              style={{ marginRight: 16 }}
            >
              CLEAR
            </Button>
            <Button variant="outlined" color="primary" onClick={isAnswerValid}>
              {disableAnswer ? "NEXT" : "SUBMIT"}
            </Button>
            <div
              style={{ marginLeft: 16, display: "flex", alignItems: "center" }}
            >
              {disableAnswer
              ? ""
              : attemptCount > 0
              ? "Attempts Remaining: " +
                (props.currentHomeWork.numOfAttemptsAllowed - attemptCount)
              : "Attempts Remaining: " +
                props.currentHomeWork.numOfAttemptsAllowed}
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignSelf: "flex-start",
              flexDirection: mobileDevice ? "column" : "row",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => props.showHelp()}
              style={{ marginRight: 16 }}
            >
              HELP
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                  Speech(props.currentHomeWork.voiceOver);
                }
              }}
            >
              READ OUT
            </Button>
          </Grid>
        </Grid>
        <Grid container style={{ flexDirection: "column" }}>
          
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
           
              {/* ---------------------------- ANSWER TEXT INPUT VIEW ---------------------------- */}
            <div style={{ height: height - 650 }}>


               

                                {
                                    (props.currentHomeWork.qType === "piccmp" || props.currentHomeWork.qType === "pic") ? renderRowCmp(0) : renderRow(0)
                                }
                                {
                                    (props.currentHomeWork.qType === "piccmp" || props.currentHomeWork.qType === "pic") ? renderRowCmp(6) : renderRow(6)
                                }
                                {
                                    (props.currentHomeWork.qType === "piccmp" || props.currentHomeWork.qType === "pic") ? renderRowCmp(12) : renderRow(12)
                                }
            </div>

            <div style={{ fontSize: 30, margin: 10, alignSelf: "center" }}>
            {/* ---------------------------- QUESTION TEXT VIEW - ITEM  1 ---------------------------- */}
            {props.currentHomeWork.question}
          </div>
              
              <div
                style={{
                flexDirection: "row",
                alignSelf: "center",
                display: "flex",
                alignItems: "center",
                marginTop: 24,
                }}
            >
             
                    { renderOptions() }
            
          
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {/* ------------- ANSWER IMAGE RIGHT OR WRONG */}
            {/* <div style={{ flexDirection: "row", alignSelf: "center" }}>
                  {disableAnswer && props.currentHomeWork.isRightAnswer && (
                    <CorrectImage />
                  )}
        
                  {disableAnswer && !props.currentHomeWork.isRightAnswer && (
                    <WrongImage />
                  )}
                </div> */}
            {
              // ------------------   CORRECT ANSWER
              disableAnswer && !props.currentHomeWork.isRightAnswer && (
                <div
                  style={{
                    alignSelf: "center",
                    marginTop: 20,
                    marginLeft: 50,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontSize: 24 }}>Correct Answer :{"   "}</div>
                  {props.currentHomeWork.qType === "piccmp" &&
                  <div style={{ fontSize: 48 }}>
                    <img
                      src={
                        urlStringSample + displayAnswer.toLowerCase() + ".png"
                      }
                      style={{
                        width: 100,
                        height: 100,
                        marginRight: 10,
                      }}
                    />
                  </div>}
                  { props.currentHomeWork.qType === "rprsnt" &&
                    <div style={{ fontSize: 48 }}>{displayAnswer}</div>
                  }
                </div>
              )
            }
          </div>
        </Grid>
        <Grid>
        <Snackbar
         open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert 
          key={`toastMessage-${toastMessage}`}
          onClose={handleClose} 
          severity={messageType}>
            {toastMessage}
          </Alert>
        </Snackbar>
        </Grid>
      </Grid>
    );
  };
  
  export default CountPic;
  