import {
  Card,
  CardActionArea,
  Dialog,
  Grid,
  GridList,
  GridListTile,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomework,
  saveStudentAnswer,
  setShowLoader,
} from "../../../actions";
import {
  getHomeWorkDay,
  getQuestionsList,
} from "../../../reducer/selectors/homework.entity";
import MultiLine from "../Operations/MultiLine";
import Mixopr from "../Operations/Mixopr";
import Nmbr from "../Operations/Nmbr";
import EvenOdd from "../Operations/EvenOdd";
import Equation from "../Operations/Equation";
import WriteNum from "../Operations/WriteNum";
import WriteWord from "../Operations/WriteWord";
import NumberPad from "../Operations/NumberPad";
import Standard from "../Operations/Standard";
import CommonIdfyAndSlct from "../Operations/CommonIdfyAndSlct";
import { getQuestionType } from "../../../util/questionsUtil";
import Expand from "../Operations/Expand";
import Sntns from "../Operations/Sntns";
import Money from "../Operations/Money";
import Blocks from "../Operations/Blocks";
import Gcd from "../Operations/Gcd";
import Lcm from "../Operations/Lcm";
import MultiTable from "../Operations/MultiTable";
import Rule from "../Operations/Rule";
import Clk from "../Operations/Clk";
import Shapes from "../Operations/Shapes";
import LanguageCommon from "../Operations/LanguageCommon";
import Homonyms from "../Operations/Homonyms";
import SelectSentence from "../Operations/SelectSentence";
import Compare from "../Operations/Compare";
import Fractions from "../Operations/Fractions";
import Decimal from "../Operations/Decimal";
import { useHistory, useLocation } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { getChildInfo } from "../../../reducer/selectors/child.entity";
import { getIsLoggedIn } from "../../../reducer/selectors/user.entity";
import AddSub from "../Operations/AddSub";
import Listen from "../Operations/Listen";
import LoginRequired from "../Operations/LoginRequired";
import Time from "../Operations/Time";
import CommonScience from "../Operations/CommonScience";
import Story from "../Operations/Story";
import Skip from "../Operations/Skip";
import Spelling from "../Operations/Spelling";
import Complete from "../Operations/Complete";
import Ratio from "../Operations/Ratio";
import Percentage from "../Operations/Percentage";
import Statistics from "../Operations/Statistics";
import CountPic from "../Operations/CountPic";
import Division from "../Operations/Division";
import ChartMath from "../Operations/ChartMath";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridListTileRoot: {
    height: "140px !important",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  gridListTileTile: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginLeft: 16,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    height: 160,
    marginTop: 16,
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  card: {
    height: 100,
    width: "100%",
    backgroundColor: "#f2f5ff",
  },
  cardCorrect: {
    height: 100,
    width: "100%",
    backgroundColor: "#f2f5ff",
    borderBottomColor: "green",
    borderBottomWidth: 8,
    borderBottomStyle: "solid",
  },
  cardWrong: {
    height: 100,
    width: "100%",
    backgroundColor: "#f2f5ff",
    borderBottomColor: "red",
    borderBottomWidth: 8,
    borderBottomStyle: "solid",
  },
}));

const HomeworkPage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const isLoggedIn = useSelector(getIsLoggedIn);
  const questionsList = useSelector(getQuestionsList);
  const homeWorkDay = useSelector(getHomeWorkDay);
  const childInfo = useSelector(getChildInfo);

  const [currentQuestion, setCurrentQuestion] = useState(undefined);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [showHelp, setShowHelp] = useState(false);
  const [starttime, setStarttime] = useState(new Date());

  useEffect(() => {
    console.log('homework component..', location, location.state)
    if (location && location.state &&
      location.state.childId &&
      location.state.hwDate &&
      location.state.subjectCd &&
      location.state.numOfQuestionsPerDay
    ) {
      console.log(
        location.state.childId,
        location.state.hwDate,
        location.state.subjectCd,
        location.state.numOfQuestionsPerDay
      );
      dispatch(setShowLoader(true));
      dispatch(
        getHomework({
          isLoggedIn: isLoggedIn,
          childId: location.state.childId,
          hwDate: location.state.hwDate,
          subjectCd: location.state.subjectCd,
          numOfQuestionsPerDay:
            location.state.numOfQuestionsPerDay === "null"
              ? null
              : Number(location.state.numOfQuestionsPerDay),
          ctest: location.state.ctest ? location.state.ctest : '',
          pageFlag: location.state.pageFlag,
          currentLevel: location.state.currentLevel,
          practiceConcept: location.state.practiceConcept
        })
      );
    }
  }, [location.state]);

  useEffect(() => {
    if (questionsList && questionsList.length > 0) {
      setCurrentQuestion(questionsList[0]);
      setCurrentQuestionIndex(0);
      setStarttime(new Date());
    }
  }, [questionsList]);

  const goToNextQuestion = () => {
    if (currentQuestionIndex < questionsList.length - 1) {
      const index = currentQuestionIndex + 1;
      setCurrentQuestion(questionsList[index]);
      setCurrentQuestionIndex(index);
      setStarttime(new Date());
    } else {
      setCurrentQuestionIndex(0);
      setCurrentQuestion(questionsList[0]);
      setStarttime(new Date());
    }
  };

  const onSubmitQuestion = (
    studentAnswer,
    numOfAttempts,
    isRightAnswer,
    questionId
  ) => {
    console.warn("SUBMITTED ", studentAnswer, numOfAttempts, isRightAnswer);
    let txtMsg = "Submitted";
    if (numOfAttempts === 1) {
      txtMsg = "Excellent Work. Try Next one";
    } else if (numOfAttempts === 2) {
      txtMsg = "Good Work. Try Next one";
    } else if (numOfAttempts === 3) {
      txtMsg = "Not Bad. Try Next one";
    }
    // const currentTime = new Date();
    // const timeElapsed = currentTime - this.state.startTime;

    // console.log("TOAST CLOSED", timeElapsed);

    const currentTime = new Date();
    const timeElapsed = currentTime - starttime;

    dispatch(
      saveStudentAnswer({
        childId: location.state.childId,
        grade: location.state.subjectCd,
        day: homeWorkDay,
        subjectCd: location.state.courseId,
        questionId: questionId,
        studentAnswer,
        rightAnswer: isRightAnswer,
        numberOfAttempts: numOfAttempts,
        timeElapsed: timeElapsed,
        currentQuestionIndex,
        finalAnswer: "1",
        concept: ((location.state.pageFlag === "practice") ? location.state.concept : (currentQuestion && currentQuestion.concept)),
        ctest: location.state.ctest ? location.state.ctest : '',
        pageFlag: location.state.pageFlag,
      })
    );

    goToNextQuestion();

    // if (isRightAnswer)
    //     this.showtoast(txtMsg, 'success')
    // else
    //     this.showtoast(txtMsg, 'danger')
  };

  const renderQuestion = () => {
    console.log("isLoggedIn ========>>>>>>> ", isLoggedIn)
    if (currentQuestion) {
      const { concept, qType, questionId } = currentQuestion;
      if (questionId > 5 && !isLoggedIn) {
        return (<LoginRequired />)
      }
      if (
        (concept === "add" ||
          concept === "sub" ||
          concept === "mul" ||
          (concept === "div" && qType === "mltln")) &&
        qType !== "sntns" &&
        qType !== "soln" &&
        qType !== "sngln" &&
        qType !== "alpbts" &&
        qType !== "misnum"
      ) {
        return (
          <MultiLine
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "mixopr") {
        return (
          <Mixopr
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "nmbr" && (qType !== "rprsnt" && qType !== "piccmp" && qType !== "pic")) {
        return (
          <Nmbr
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "evenodd") {
        return (
          <EvenOdd
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "rndoff") {
        return (
          <Mixopr
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "eqn") {
        return (
          <Equation
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "wrtnum" || concept === "fct" || concept === "plc") {
        return (
          <WriteNum
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "wrtwrd") {
        return (
          <WriteWord
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (
        (concept === "add" ||
          concept === "sub" ||
          concept === "mul" ||
          concept === "div") &&
        qType === "alpbts"
      ) {
        return (
          <NumberPad
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "stndrd") {
        return (
          <Standard
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (
        concept === "cmnidfyslct" ||
        ((concept === "add" ||
          concept === "sub" ||
          concept === "mul" ||
          concept === "div") &&
          qType === "soln")
      ) {
        return (
          <CommonIdfyAndSlct
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "expnd") {
        return (
          <Expand
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (
        concept === "sntns" ||
        ((concept === "add" ||
          concept === "sub" ||
          concept === "mul" ||
          concept === "div") &&
          qType === "sntns")
      ) {
        return (
          <Sntns
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "mny") {
        return (
          <Money
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "blck") {
        return (
          <Blocks
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "gcd") {
        return (
          <Gcd
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "lcm") {
        return (
          <Lcm
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "multbl") {
        return (
          <MultiTable
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "rule") {
        return (
          <Rule
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "clk") {
        return (
          <Clk
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "shp") {
        return (
          <Shapes
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "cmp") {
        return (
          <Compare
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "fra") {
        return (
          <Fractions
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (
        concept === "decadd" ||
        concept === "decsub" ||
        concept === "decmul" ||
        concept === "decdiv"
      ) {
        return (
          <Decimal
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "addsub") {
        return (
          <AddSub
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "time") {
        return (
          <Time
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "lisn" && qType !== "wrd") {
        return (
          <Listen
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      }
      // else if (concept === "skp") {
      //   return (
      //     <Skip
      //       currentHomeWork={currentQuestion}
      //       onSubmitQuestion={onSubmitQuestion}
      //       goToNextQuestion={goToNextQuestion}
      //       onShowToast={() => {}}
      //       // this.showtoast}
      //       isComingFromParent={true}
      //       showHelp={() => setShowHelp(true)}
      //     />
      //   );
      // }
      else if (
        (concept === "syn" ||
          concept === "ant" ||
          concept === "ltrs" ||
          concept === "chse" ||
          concept === "sylb" ||
          concept === "abre" ||
          concept === "nou" ||
          concept === "conjun" ||
          concept === "pron" ||
          concept === "verb" ||
          concept === "prep" ||
          concept === "hompne") &&
        (qType === "wrd" ||
          qType === "slct" ||
          qType === "lowr" ||
          qType === "uppr" ||
          qType === "srt" ||
          qType === "hmny" ||
          qType === "pic" ||
          qType === "silt" ||
          qType === "prfx" ||
          qType === "sfx")
      ) {
        return (
          <LanguageCommon
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "homnym") {
        return (
          <Homonyms
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (
        (concept === "syn" ||
          concept === "ant" ||
          concept === "sylb" ||
          concept === "abre" ||
          concept === "nou" ||
          concept === "conjun" ||
          concept === "pron" ||
          concept === "verb" ||
          concept === "prep" ||
          concept === "hompne" ||
          concept === "plrl") &&
        qType === "idfy"
      ) {
        return (
          <SelectSentence
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "skp") {
        return (
          <Skip
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      }

      else if (concept === "pro" || concept === "mtr" || concept === "chng" || concept === "mxtr"
        || concept === "plnts" || concept === "itms" || concept === "mtrls" || concept === "anmlsclsf" || concept === "anmlstyp" || concept === "anmlsfd"
        || concept === "thngs" || concept === "gnrl" || concept === "mnrls" || concept === "ntrlrsrc" || concept === "mgnt" || concept === "astrnmy"
        || concept === "atms" || concept === "enrgy" || concept === "cel" || concept === "geo" || concept === "lndmrks" || concept === "cptls"
        || concept === "amrcgeo" || concept === "ocngeo" || concept === "asiageo" || concept === "stts" || concept === "biochem" || concept === "phtsnths"
        || concept === "hzrds" || concept === "bondrs" || concept === "nwtnlw" || concept === "def" || concept === "mslcel" || concept === "bdysystm" || concept === "endglnd"
        || concept === "vtmnssrc" || concept === "vtmnsimp" || concept === "cld" || concept === "vtmnsdfnc" || concept === "vtmns" || concept === "hrtchmbrs" || concept === "scntfcnm" || concept === "vgtbl"
        || concept === "rcks" || concept === "eco" || concept === "dstr" || concept === "frc" || concept === "mxtrtyp" || concept === "orgnfns" || concept === "lyfcycl"
        || concept === "trts" || concept === "ppltn" || concept === "" || concept === "" || concept === "" || concept === "" || concept === "") {
        return (
          <CommonScience
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      }
      else if (concept === "smplstry") {
        return (
          <Story
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      }
      else if (concept === "spel" || (concept === "lisn" && qType === "wrd")) {
        return (
          <Spelling
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "cmpl") {
        return (
          <Complete
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "ratio") {
        return (
          <Ratio
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "per") {
        return (
          <Percentage
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "sts") {
        return (
          <Statistics
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "nmbr" && (qType === "rprsnt" || qType === "piccmp" || qType === "pic")) {
        return (
          <CountPic
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "div" && qType === "rmndr") {
        return (
          <Division
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      } else if (concept === "chrtmath") {
        return (
          <ChartMath
            currentHomeWork={currentQuestion}
            onSubmitQuestion={onSubmitQuestion}
            goToNextQuestion={goToNextQuestion}
            onShowToast={() => { }}
            // this.showtoast}
            isComingFromParent={true}
            showHelp={() => setShowHelp(true)}
          />
        );
      }
      // else if (concept === "nou") {
      //   return (
      //     <SelectSentence
      //       currentHomeWork={currentQuestion}
      //       onSubmitQuestion={onSubmitQuestion}
      //       goToNextQuestion={goToNextQuestion}
      //       onShowToast={() => {}}
      //       // this.showtoast}
      //       isComingFromParent={true}
      //       showHelp={() => setShowHelp(true)}
      //     />
      //   );
      // }

      // else if (((concept === 'add') || (concept === "sub") || (concept === "mul") || (concept === "div" && qType === "mltln")) && qType !== 'sntns' && qType !== 'sngln' && qType !== 'alpbts' && qType !== 'misnum')
      //     return <MultiLineAdd
      //         currentHomeWork={this.state.currentHomeWork}
      //         onHomeworkQuestionSubmit={this.onHomeworkQuestionSubmit}
      //         goToNextQuestion={this.goToNextQuestion}
      //         onShowToast={this.showtoast}
      //         isComingFromParent={this.props.isComingFromParent} />
      else
        return (
          <div style={{ alignItems: "center", marginTop: "20%" }}>
            <div>Please Refresh the page to see this Question.</div>
            <div>Thanks</div>
          </div>
        );
    } else return <div>Please select a question above</div>;
  };

  return (
    <Grid container style={{ padding: 16 }}>
      {isLoggedIn && <Grid item xs={12}>
        <Breadcrumbs maxItems={3} aria-label="breadcrumb">
          <Link color="inherit" href={isLoggedIn ? "/parent" : "/"}>
            Home
          </Link>
          <Link
            color="inherit"
            onClick={() => {
              history.push({
                pathname: isLoggedIn ? "/child" : "/guest/child",
                state: {
                  childId: location.state.childId,
                  userType: isLoggedIn ? "user" : "guest",
                },
              });
            }}
          >
            {childInfo && isLoggedIn
              ? childInfo.firstName + "'s page"
              : "Child"}
          </Link>
          {location.state.pageFlag !== "practice" &&
            <Link
              color="inherit"
              onClick={() => {
                console.log("courseId......", location.state.courseId, location.state.pageFlag);
                history.push({
                  pathname: isLoggedIn ? "/child/course" : "/guest/child/course",
                  state: {
                    childId: location.state.childId,
                    courseId: location.state.subjectCd,
                    numOfQuestionsPerDay: location.state.numOfQuestionsPerDay,
                    userType: location.state.userType,
                  },
                });
              }}
            >
              {location.state.subjectCd} {location.state.hwDate.split("T")[0]}
            </Link>
          }
        </Breadcrumbs>
      </Grid>}

      <Grid item xs={6}>
        <Typography variant="h4">
          Question{" "}
          {questionsList && questionsList.length
            ? `${currentQuestionIndex + 1} of  ${questionsList.length}`
            : ""}
        </Typography>
        <Typography>
          (This is beta version so refreshing or going back will lose your
          changes)
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            color: "green",
          }}
        >
          Correct: {questionsList.filter((q) => q.isRightAnswer).length}
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "flex-end", color: "red" }}
        >
          Wrong:{" "}
          {
            questionsList.filter(
              (q) => q.finalAnswer === "1" && !q.isRightAnswer
            ).length
          }
        </Grid>
      </Grid>

      <Dialog
        onClose={() => setShowHelp(false)}
        open={showHelp}
        maxWidth={false}
      >
        <div style={{ padding: 16 }}>
          <Typography variant="h4">Help</Typography>
          <div>
            <img
              src={
                currentQuestion && currentQuestion.helpLink
                  ? currentQuestion.helpLink
                  : ""
              }
              alt="loading..."
            />
          </div>
        </div>
      </Dialog>
      <GridList className={classes.gridList} cols={mobileDevice ? 1.5 : 4.5}>
        {questionsList.map((question, index) => (
          <GridListTile
            key={`q-id-${index}${question.isRightAnswer}`}
            classes={{
              root: classes.gridListTileRoot,
              tile: classes.gridListTileTile,
            }}
          >
            <Card
              id={`q-id-${index}${question.isRightAnswer}`}
              className={
                question.finalAnswer === "1"
                  ? question.isRightAnswer
                    ? classes.cardCorrect
                    : classes.cardWrong
                  : classes.card
              }
              style={{
                backgroundColor:
                  currentQuestionIndex === index ? "#D9E1FF" : "#f2f5ff",
              }}
            >
              <CardActionArea
                style={{
                  padding: 10,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  cursor: "pointer",
                  height: "100%",
                }}
                onClick={() => {
                  console.log("CLICKED");
                  setCurrentQuestion(question);
                  setCurrentQuestionIndex(index);
                  setStarttime(new Date());
                }}
              >
                <Typography variant="h4">{question.questionId}</Typography>
                <Typography variant="h6">
                  {getQuestionType(
                    question.concept,
                    question.qType,
                    question.originalConcept
                  )}
                </Typography>
              </CardActionArea>
            </Card>
          </GridListTile>
        ))}
      </GridList>
      <Grid container style={{ padding: 16, minHeight: "60vh" }}>
        <Paper style={{ width: "100%" }} key={`${currentQuestion ? ("" + currentQuestion.concept +
          currentQuestion.qType + currentQuestion.questionId + currentQuestion.question) : "empty_question"}`}>{renderQuestion()}</Paper>
      </Grid>
    </Grid>
  );
};

export default HomeworkPage;
