import  {profileConstants } from "../constants";

const initialState = {
  profile: [],
  rankings: [],
};

export function profileReducer(state = initialState, action) {
  switch (action.type) {
    case profileConstants.GET_PROFILE_SUCCESS:
      return { ...state, profile: action.payload };
    case profileConstants.GET_PROFILE_RANKINGS_SUCCESS:
      return { ...state, rankings: action.payload };
    default:
      return state;
  }
}
