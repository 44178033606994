import { put, takeLatest } from "redux-saga/effects";
import * as childActions from "../actions/child.actions";
import { setShowLoader } from "../actions";
import { serviceConstants } from "../constants";
import Transport from "../transport/rabbitboard.transport";

function* getChildInfoSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      // "https://w951hqrwsd.execute-api.us-east-1.amazonaws.com/nonprod/rabbit/child" + "/parent/" + "ravi.tvn@gmail.com",
      serviceConstants.RABBIT_CHILD_URL + "/" + action.payload,
      serviceConstants.RABBIT_CHILD_URL_APIKEY,
      {},
      "GET"
    );
    console.log("RESPONSE ", response);

    yield put(childActions.getChildInfoSuccess(response));
    yield put(setShowLoader(false));
  } catch (e) {
    yield put(setShowLoader(false));
    console.error("getChildInfoSaga Saga ERROR ", e);
    throw e;
  }
}

function* addChildSaga(action) {
  try {
    const reqObj = {
      userId: action.payload.userId,
      firstName: action.payload.firstname,
      lastName: action.payload.lastname,
      // nickName:"",
      // dob:"",
      // schoolName:"",
      // schoolPincode:"",
      gender: action.payload.gender,
      grade: action.payload.grade,
      courseList: action.payload.courseList,
      cardId: action.payload.cardId,
      source: action.payload.cardId,
      planName: action.payload.planName
    };
    const response = yield Transport.genericOperation(
      serviceConstants.RABBIT_CHILD_URL + "/createchild/",
      serviceConstants.RABBIT_CHILD_URL_APIKEY,
      reqObj,
      "POST"
    );
    console.log("addChildSaga RESPONSE ", response);

    if (response && response.message && response.message.status === "200") {
      yield put(childActions.addChildSuccess(reqObj.email));
    } else {
      yield put(
        childActions.addChildFailure(
          response && response.message && response.message.message
            ? response.message.message
            : "Add child Request Failed, Please check details and try again"
        )
      );
    }
  } catch (e) {
    console.error("addChildSaga Saga ERROR ", e);
    yield put(
      childActions.addChildFailure("Add child Failed, Please try again.")
    );
  }
}

function* deleteChildSaga(action) {
  console.log("deleteChildSaga ", action);
  try {
    const reqObj = {
      userId: action.payload.userId,
      childId: action.payload.childId,
    };
  
    const response = yield Transport.genericOperation(
      serviceConstants.RABBIT_CHILD_URL + "/updatechild",
      serviceConstants.RABBIT_CHILD_URL_APIKEY,
      reqObj,
      "PUT"
    );
    console.log("deleteChildSaga RESPONSE ", response);

    if (response && response.message && response.message.status === "200") {
      yield put(childActions.deleteChildSuccess(true));
    } else {
      yield put(
        childActions.deleteChildFailure(
          response && response.message && response.message.message
            ? response.message.message
            : "Delete child Request Failed, Please check details and try again"
        )
      );
    }
  } catch (e) {
    console.error("deleteChildSaga Saga ERROR ", e);
    yield put(
      childActions.deleteChildFailure("Delete child Failed, Please try again.")
    );
  }
}

function* uploadChildProfilePicSaga(action) {
  try {
    const response = yield Transport.fileUpload(
      serviceConstants.RABBIT_CHILD_URL +
      "/profilepic/" +
      action.payload.body.childId +
      "/" +
      action.payload.body.userId,
      serviceConstants.RABBIT_CHILD_URL_APIKEY,
      action.payload.formData,
      "POST"
    );

    console.log("response uploadChildProfilePicSaga ", response);
    yield put(
      childActions.uploadChildProfilePicSuccess(
        "Profile Pic Uploaded Successfully"
      )
    );
  } catch (e) {
    console.error("uploadChildProfilePicSaga Saga ERROR ", e);
    yield put(
      childActions.uploadChildProfilePicFailure(
        "Profile Pic Upload Failed, Please try again."
      )
    );
  }
}

export default function* childSaga() {
  try {
    yield takeLatest(childActions.getChildInfo, getChildInfoSaga);
    yield takeLatest(childActions.addChild, addChildSaga);
    yield takeLatest(childActions.deleteChild, deleteChildSaga);
    yield takeLatest(
      childActions.uploadChildProfilePic,
      uploadChildProfilePicSaga
    );
  } catch {
    yield;
  }
}
