import {
  Button,
  Grid,
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Speech from "../../../util/speech";
import nickel_face from "../../../assets/images/nickel_face.png";
import penny_face from "../../../assets/images/penny_face.png";
import dime_face from "../../../assets/images/dime_face.png";
import quarter_face from "../../../assets/images/quarter_face.png";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    fontSize: 40,
    textAlign: "center",
  },
}));

const Shapes = (props) => {
  const classes = useStyles({});
  const theme = useTheme();

  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentQuestionId, setCurrentQuestionId] = useState(undefined);
  const [disableAnswer, setDisableAnswer] = useState(false);
  const [displayAnswer, setDisplayAnswer] = useState("");
  const [answer, setAnswer] = useState("");
  const [maxNumberLength, setMaxNumberLength] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [studentAnswer, setStudentAnswer] = useState("");
  const [showHands, setShowHands] = useState(false);
  const [question, setQuestion] = useState("");
  const pennySize = 19;
  const nickelSize = 21.21;
  const dimeSize = 17.9;
  const quarterSize = 24.3;
  const urlStringSample = "https://eappimages.s3.amazonaws.com/shapes/";
  const imageType = "permtr";
  const [open, setOpen] = useState(false);
  const [toastMessage, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    if (
      props.currentHomeWork &&
      props.currentHomeWork.questionId &&
      props.currentHomeWork.questionId !== currentQuestionId
    ) {
      setStudentAnswer(
        props.currentHomeWork.studentAnswer
          ? props.currentHomeWork.studentAnswer
          : ""
      );
      setCurrentQuestionId(props.currentHomeWork.questionId);
      setShowHands(true);
      setQuestion(props.currentHomeWork.firstVar);
      setAttemptCount(0);
      setAnswer(
        props.currentHomeWork.finalAnswer === "1"
          ? props.currentHomeWork.studentAnswer
          : ""
      );
      setDisableAnswer(
        props.currentHomeWork.finalAnswer === "1" ? true : false
      );
      setDisplayAnswer(
        props.currentHomeWork.finalAnswer === "1"
          ? props.currentHomeWork.answer
          : ""
      );

      setShowSpinner(false);
    }
  }, [props.currentHomeWork]);

  const isAnswerValid = () => {
    var isValid = false;
    console.log("VALIDATING ANSWER...", answer);
    if (answer){
       isAnswerCorrect();
      }
    else{
        setMessage("Invalid Answer!. Enter Value.")
        setMessageType("warning")
        handleClick() 
    }
  };

  const isAnswerCorrect = () => {
    var isCorrect = false;
    console.log("CHECKING ANSWER", answer);
    var answerString;

    if (props.currentHomeWork.secondVar === "Circle") {
      answerString = (
        Math.round(parseFloat(answer) * 100 + Number.EPSILON) / 100
      ).toString();
    } else {
      answerString = answer;
    }

    console.warn("answerString ", answerString);
    isCorrect =
      answerString &&
      answerString.length > 0 &&
      answerString === props.currentHomeWork.answer;

    if (
      props.currentHomeWork.qType === "permtr" ||
      props.currentHomeWork.qType === "fndsde"
    ) {
      isCorrect =
        answerString &&
        answerString.length > 0 &&
        Number(answerString) === Number(props.currentHomeWork.answer);
    } else if (props.currentHomeWork.qType === "fra") {
      // isCorrect = isFractionAnswerCorrect()
      // const jsonFormat = { studentAnswer: state.fractionQuestion }
      // studentAnswer = JSON.stringify(jsonFormat)
    } else {
      isCorrect =
        answerString &&
        answerString.length > 0 &&
        answerString === props.currentHomeWork.answer;
    }

    if (isCorrect) {
      console.log("IS CORRECT, GO TO NEXT QUESTION");
      props.onSubmitQuestion(
        answerString,
        attemptCount + 1,
        true,
        props.currentHomeWork.questionId
      );
      setMessage("Correct Answer!. Good job.")
      setMessageType("success")
      handleClick()
    } else {
      console.log("WRONG ANSWER ");
      setAttemptCount(attemptCount + 1);

      if (attemptCount >= 2) {
        // props.onShowToast("Wrong Answer!. Next Question.", "danger");
        setMessage("Wrong Answer!. Try Next Question.")
        setMessageType("error")
        handleClick()
        props.onSubmitQuestion(
          answerString,
          attemptCount,
          false,
          props.currentHomeWork.questionId
        );
      } else {
        // props.onShowToast("Wrong Answer!. Try Again.", "warning");
        setMessage("Wrong Answer!. Try Again.")
        setMessageType("warning")
        handleClick()
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const onSelection = (val) => {
    console.log("CAME HERE onSelection = ", val);
    setAnswer(val);
  };

  const getCoinAnswer = (value) => {
    console.log("CAME HERE");
    switch (value) {
      case "1":
        return penny_face;
      case "5":
        return nickel_face;
      case "10":
        return dime_face;
      case "25":
        return quarter_face;
      default:
        return "";
    }
  };

  const rendorMoneyCoins = () => {
    const { strList, qType, isRightAnswer, secondVar, firstVar } =
      props.currentHomeWork;
    if (qType === "slct") {
      return strList.map((item, index) => {
        return (
          <div key={`answer-${index}-${answer[index]}`}>
            <img
              onClick={() => onSelection(item)}
              src={urlStringSample + item.toLowerCase() + ".png"}
              border={2}
              style={{
                width: 135,
                height: 135,
                marginRight: 10,
                borderColor: disableAnswer
                  ? isRightAnswer
                    ? "green"
                    : "red"
                  : "#4e67c7",
                borderWidth: answer === item ? 4 : 0,
              }}
            />
          </div>
        );
      });
    } else if (qType === "permtr" || qType === "fndsde" || qType === "ara") {
      if (secondVar === "Square") {
        return (
          <div style={{ alignItems: "center" }}>
            <img
              resizeMode="contain"
              src={
                "https://eappimages.s3.amazonaws.com/shapes/" +
                secondVar.toLowerCase() +
                imageType +
                ".png"
              }
              style={{
                width: 200,
                height: 200,
              }}
            />
            {(qType === "permtr" || qType === "ara") && (
              <div
                style={{
                  marginTop: -30,
                  marginLeft: 95,
                  marginBottom: 10,
                  fontSize: 20,
                  fontWeight: "600",
                }}
              >
                {firstVar}
              </div>
            )}
            {qType === "fndsde" && (
              <div
                style={{
                  marginTop: -30,
                  marginLeft: 95,
                  marginBottom: 10,
                  fontSize: 20,
                  fontWeight: "600",
                }}
              >
                ?
              </div>
            )}
          </div>
        );
      } else if (secondVar === "Rectangle") {
        return (
          <div style={{ alignItems: "center" }}>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                resizeMode="contain"
                src={
                  "https://eappimages.s3.amazonaws.com/shapes/" +
                  secondVar.toLowerCase() +
                  imageType +
                  ".png"
                }
                style={{
                  width: 300,
                  height: 200,
                }}
              />
              {qType === "fndsde" &&
              strList[1] === props.currentHomeWork.answer ? (
                <div
                  style={{
                    marginLeft: -19,
                    marginTop: -35,
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  ?
                </div>
              ) : (
                <div
                  style={{
                    marginLeft: -19,
                    marginTop: -35,
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  {strList[1]}
                </div>
              )}
            </div>
            {qType === "fndsde" &&
            strList[0] === props.currentHomeWork.answer ? (
              <div
                style={{
                  marginTop: -50,
                  marginLeft: 135,
                  marginBottom: 10,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                ?
              </div>
            ) : (
              <div
                style={{
                  marginTop: -50,
                  marginLeft: 135,
                  marginBottom: 10,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                {strList[0]}
              </div>
            )}
          </div>
        );
      } else if (secondVar === "Circle") {
        return (
          <div style={{ alignItems: "center" }}>
            <img
              resizeMode="contain"
              src={
                "https://eappimages.s3.amazonaws.com/shapes/" +
                secondVar.toLowerCase() +
                imageType +
                ".png"
              }
              style={{
                width: 350,
                height: 250,
                marginLeft: 70,
              }}
            />
            <div
              style={{
                marginTop: -150,
                marginLeft: 150,
                marginBottom: 110,
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              {firstVar}
            </div>
          </div>
        );
      } else if (secondVar === "LeftTriangle") {
        return (
          <div style={{ alignItems: "center" }}>
            <img
              resizeMode="contain"
              src={
                "https://eappimages.s3.amazonaws.com/shapes/" +
                secondVar +
                ".png"
              }
              style={{
                width: 300,
                height: 200,
              }}
            />
            {(qType === "permtr" || qType === "ara") && (
              <div
                style={{
                  marginTop: -120,
                  marginLeft: 30,
                  marginBottom: 110,
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                {strList[0]}
              </div>
            )}
            {(qType === "permtr" || qType === "ara") && (
              <div
                style={{
                  marginTop: -65,
                  marginLeft: 120,
                  marginBottom: 10,
                  fontSize: 20,
                  fontWeight: "600",
                }}
              >
                {strList[1]}
              </div>
            )}
            {(qType === "permtr" || qType === "ara") && (
              <div
                style={{
                  marginTop: -105,
                  marginLeft: 190,
                  marginBottom: 110,
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                {strList[2]}
              </div>
            )}

            {qType === "fndsde" && (
              <div
                style={{
                  marginTop: -50,
                  marginBottom: 10,
                  fontSize: 20,
                  fontWeight: "600",
                }}
              >
                ?
              </div>
            )}
          </div>
        );
      } else if (secondVar === "RightTriangle") {
        return (
          <div style={{ alignItems: "center" }}>
            <img
              resizeMode="contain"
              src={
                "https://eappimages.s3.amazonaws.com/shapes/" +
                secondVar +
                ".png"
              }
              style={{
                width: 300,
                height: 300,
              }}
            />
            {(qType === "permtr" || qType === "ara") && (
              <div
                style={{
                  marginTop: -160,
                  marginLeft: 50,
                  marginBottom: 110,
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                {strList[0]}
              </div>
            )}
            {(qType === "permtr" || qType === "ara") && (
              <div
                style={{
                  marginTop: -55,
                  marginLeft: 150,
                  marginBottom: 10,
                  fontSize: 20,
                  fontWeight: "600",
                }}
              >
                {strList[1]}
              </div>
            )}
            {(qType === "permtr" || qType === "ara") && (
              <div
                style={{
                  marginTop: -110,
                  marginLeft: 280,
                  marginBottom: 110,
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                {strList[2]}
              </div>
            )}

            {qType === "fndsde" && (
              <div
                style={{
                  marginTop: -50,
                  marginBottom: 10,
                  fontSize: 20,
                  fontWeight: "600",
                }}
              >
                ?
              </div>
            )}
          </div>
        );
      } else if (secondVar === "Triangle") {
        return (
          <div style={{ alignItems: "center" }}>
            <img
              resizeMode="contain"
              src={
                "https://eappimages.s3.amazonaws.com/shapes/" +
                secondVar.toLowerCase() +
                imageType +
                ".png"
              }
              style={{
                width: 200,
                height: 200,
                // marginLeft: 70,
              }}
            />

            {(qType === "permtr" || qType === "ara") && (
              <div
                style={{
                  marginTop: -40,
                  marginLeft: 100,
                  marginBottom: 10,
                  fontSize: 20,
                  fontWeight: "600",
                }}
              >
                {firstVar}
              </div>
            )}

            {qType === "fndsde" && (
              <div
                style={{
                  marginTop: -50,
                  marginBottom: 10,
                  fontSize: 20,
                  fontWeight: "600",
                }}
              >
                ?
              </div>
            )}
          </div>
        );
      } else if (secondVar === "Pentagon") {
        return (
          <div style={{ alignItems: "center" }}>
            <img
              resizeMode="contain"
              source={{
                uri:
                  "https://eappimages.s3.amazonaws.com/shapes/" +
                  secondVar.toLowerCase() +
                  imageType +
                  ".png",
              }}
              style={{
                width: 300,
                height: 300,
                marginRight: 0,
              }}
            />
            {(qType === "permtr" || qType === "ara") && (
              <div
                style={{
                  marginTop: -50,
                  marginBottom: 10,
                  fontSize: 20,
                  fontWeight: "600",
                }}
              >
                {firstVar}
              </div>
            )}
            {qType === "fndsde" && (
              <div
                style={{
                  marginTop: -50,
                  marginBottom: 10,
                  fontSize: 20,
                  fontWeight: "600",
                }}
              >
                ?
              </div>
            )}
          </div>
        );
      } else if (secondVar === "Hexagon") {
        return (
          <div style={{ alignItems: "center" }}>
            <img
              resizeMode="contain"
              source={{
                uri:
                  "https://eappimages.s3.amazonaws.com/shapes/" +
                  secondVar.toLowerCase() +
                  imageType +
                  ".png",
              }}
              style={{
                width: 300,
                height: 300,
                marginRight: 0,
              }}
            />
            {(qType === "permtr" || qType === "ara") && (
              <div
                style={{
                  marginTop: -160,
                  marginLeft: -183,
                  marginBottom: 110,
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                {firstVar}
              </div>
            )}
            {qType === "fndsde" && (
              <div
                style={{
                  marginTop: -50,
                  marginBottom: 10,
                  fontSize: 20,
                  fontWeight: "600",
                }}
              >
                ?
              </div>
            )}
          </div>
        );
      } else {
        return null;
      }
    } else if (qType === "idfy") {
      return (
        <img
          resizeMode="contain"
          src={urlStringSample + firstVar.toLowerCase() + ".png"}
          style={{
            width: pennySize * 10,
            height: pennySize * 10,
            marginRight: 10,
            borderColor: disableAnswer
              ? isRightAnswer
                ? "green"
                : "red"
              : "#F3BA1D",
            borderWidth: studentAnswer === "1" ? 4 : 0,
          }}
        />
      );
    }
  };

  const rendorOptions = () => {
    console.log(
      "props.currentHomeWork.strList === ",
      props.currentHomeWork.strList
    );

    return (
      <div style={{ display: "inline-flex", flexDirection: "row" }}>
        {props.currentHomeWork.strList.map((item, index) => {
          return (
            <div key={`answer-${index}-${answer[index]}`}>
              <Button
                variant={disableAnswer ? "outlined" : "contained"}
                color={
                  disableAnswer
                    ? item === props.currentHomeWork.answer
                      ? "default"
                      : "secondary"
                    : answer === item
                    ? "inherit"
                    : "primary"
                }
                style={{
                  width: 150,
                  borderWidth: 2,
                  borderRadius: 5,
                  paddingVertical: 20,
                  justifyContent: "center",
                  margin: 5,
                }}
                onClick={() => onSelection(item)}
              >
                {item}
              </Button>
            </div>
          );
        })}
      </div>
    );
  };

  const clearAnswer = () => {
    setAnswer("");
    console.log("CLEAR ANSWER CALLED");
  };

  const addAnswerToState = (event) => {
    setAnswer(event.target.value);
  };

  const toggleHelpModal = (flag) => {
    setShowHelpModal(flag);
  };

  return (
    <Grid
      container
      style={{
        padding: 25,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid container style={{ padding: 16 }}>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => clearAnswer()}
            style={{ marginRight: 16 }}
          >
            CLEAR
          </Button>
          <Button variant="outlined" color="primary" onClick={isAnswerValid}>
            {disableAnswer ? "NEXT" : "SUBMIT"}
          </Button>
          <div
            style={{ marginLeft: 16, display: "flex", alignItems: "center" }}
          >
            {disableAnswer
              ? ""
              : attemptCount > 0
              ? "Attempts Remaining: " +
                (props.currentHomeWork.numOfAttemptsAllowed - attemptCount)
              : "Attempts Remaining: " +
                props.currentHomeWork.numOfAttemptsAllowed}
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignSelf: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
          }}
        >
          {props.currentHomeWork && props.currentHomeWork.helpLink && <Button
            variant="outlined"
            color="primary"
            onClick={() => props.showHelp()}
            style={{ marginRight: 16 }}
          >
            HELP
          </Button>}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                Speech(props.currentHomeWork.voiceOver);
              }
            }}
          >
            READ OUT
          </Button>
          </Grid>
      </Grid>
      <Grid container style={{ flexDirection: "column" }}>
        <div style={{ fontSize: 30, margin: 10, alignSelf: "center" }}>
          {/* ---------------------------- QUESTION TEXT VIEW - ITEM  1 ---------------------------- */}
          {props.currentHomeWork.question}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {rendorMoneyCoins()}
        </div>
        {props.currentHomeWork.qType === "idfy" && (
          <div
            style={{
              marginLeft: 20,
              alignSelf: "center",
              flexDirection: "row",
            }}
          >
            {rendorOptions()}
          </div>
        )}
        {(props.currentHomeWork.qType === "permtr" ||
          props.currentHomeWork.qType === "fndsde" ||
          props.currentHomeWork.qType === "ara") && (
          <div style={{ marginLeft: 20, alignSelf: "center" }}>
            {/* ---------------------------- ANSWER TEXT INPUT VIEW ---------------------------- */}
            <TextField
              variant="outlined"
              style={{
                fontSize: 30,
                width: 250,
                textAlign: "center",
                borderColor: "green",
                borderWidth: 2,
                color: "black",
                marginTop: 20,
              }}
              value={answer}
              InputProps={{
                classes: {
                  input: classes.input,
                },
              }}
              multiline={false}
              disabled={disableAnswer}
              onChange={addAnswerToState}
            ></TextField>
          </div>
        )}

        <div
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {/* ------------- ANSWER img RIGHT OR WRONG */}
          {/* <div style={{ flexDirection: "row", alignSelf: "center" }}>
                {disableAnswer && props.currentHomeWork.isRightAnswer && (
                  <Correctimg />
                )}
      
                {disableAnswer && !props.currentHomeWork.isRightAnswer && (
                  <Wrongimg />
                )}
              </div> */}
          {
            // ------------------   CORRECT ANSWER
            disableAnswer && !props.currentHomeWork.isRightAnswer && (
              <div
                style={{
                  alignSelf: "center",
                  marginTop: 20,
                  marginLeft: 50,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ fontSize: 24 }}>Correct Answer :{"   "}</div>
                {props.currentHomeWork.qType === "slct" ? (
                  <div style={{ fontSize: 48 }}>
                    <img
                      src={
                        urlStringSample + displayAnswer.toLowerCase() + ".png"
                      }
                      style={{
                        width: 100,
                        height: 100,
                        marginRight: 10,
                      }}
                    />
                  </div>
                ) : (
                  <div style={{ fontSize: 48 }}>{displayAnswer}</div>
                )}
              </div>
            )
          }
        </div>
      </Grid>
      <Grid>
      <Snackbar
       open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert 
        key={`toastMessage-${toastMessage}`}
        onClose={handleClose} 
        severity={messageType}>
          {toastMessage}
        </Alert>
      </Snackbar>
      </Grid>
    </Grid>
  );
};

export default Shapes;
