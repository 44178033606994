import {
  Button,
  Grid,
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Speech from "../../../util/speech";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    fontSize: 20,
    textAlign: "center",
  },
}));

const WriteWord = (props) => {
  const classes = useStyles({});
  const theme = useTheme();

  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentQuestionId, setCurrentQuestionId] = useState(undefined);
  const [disableAnswer, setDisableAnswer] = useState(false);
  const [displayAnswer, setDisplayAnswer] = useState("");
  const [answer, setAnswer] = useState("");
  const [maxNumberLength, setMaxNumberLength] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [studentAnswer, setStudentAnswer] = useState("");
  const [showHands, setShowHands] = useState(false);
  const [question, setQuestion] = useState("");
  const [open, setOpen] = useState(false);
  const [toastMessage, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    if (
      props.currentHomeWork &&
      props.currentHomeWork.questionId &&
      props.currentHomeWork.questionId !== currentQuestionId
    ) {
      setStudentAnswer(
        props.currentHomeWork.studentAnswer
          ? props.currentHomeWork.studentAnswer
          : ""
      );
      setCurrentQuestionId(props.currentHomeWork.questionId);
      setShowHands(true);
      setQuestion(props.currentHomeWork.firstVar);
      setAttemptCount(0);
      setAnswer(
        props.currentHomeWork.finalAnswer === "1"
          ? props.currentHomeWork.studentAnswer
          : ""
      );
      setDisableAnswer(
        props.currentHomeWork.finalAnswer === "1" ? true : false
      );
      setDisplayAnswer(
        props.currentHomeWork.finalAnswer === "1"
          ? props.currentHomeWork.answer
          : ""
      );

      setShowSpinner(false);
    }
  }, [props.currentHomeWork]);

  const isAnswerValid = () => {
    var isValid = false;
    console.log("VALIDATING ANSWER...", answer);
    if (answer){
       isAnswerCorrect();
      }
    else{
        setMessage("Invalid Answer!. Enter Value.")
        setMessageType("warning")
        handleClick() 
    }
  };

  const isAnswerCorrect = () => {
    var isCorrect = false;
    console.log("CHECKING ANSWER");
    const answerString = answer;
    console.log(
      "CHECKING ANSWER",
      answerString.replace(/ /g, "").toLowerCase() ===
        props.currentHomeWork.answer.replace(/ /g, "").toLowerCase()
    );
    console.warn(
      "answerString ",
      answerString.toLowerCase() === props.currentHomeWork.answer.toLowerCase()
    );
    isCorrect =
      answerString &&
      answerString.length > 0 &&
      answerString.replace(/ /g, "").toLowerCase() ===
        props.currentHomeWork.answer.replace(/ /g, "").toLowerCase();
    if (isCorrect) {
      console.log("IS CORRECT, GO TO NEXT QUESTION");
      props.onSubmitQuestion(
        answerString,
        attemptCount + 1,
        true,
        props.currentHomeWork.questionId
      );
      setMessage("Correct Answer!. Good job.")
      setMessageType("success")
      handleClick()
    } else {
      console.log("WRONG ANSWER ");
      setAttemptCount(attemptCount + 1);

      if (attemptCount >= 2) {
        // props.onShowToast("Wrong Answer!. Next Question.", "danger");
        setMessage("Wrong Answer!. Try Next Question.")
        setMessageType("error")
        handleClick()
        props.onSubmitQuestion(
          answerString,
          attemptCount,
          false,
          props.currentHomeWork.questionId
        );
      } else {
        // props.onShowToast("Wrong Answer!. Try Again.", "warning");
        setMessage("Wrong Answer!. Try Again.")
        setMessageType("warning")
        handleClick()
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const clearAnswer = () => {
    setAnswer("");
    console.log("CLEAR ANSWER CALLED");
  };

  const addAnswerToState = (event) => {
    setAnswer(event.target.value);
  };

  const toggleHelpModal = (flag) => {
    setShowHelpModal(flag);
  };

  return (
    <Grid
      container
      style={{
        padding: 25,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid container style={{ padding: 16 }}>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => clearAnswer()}
            style={{ marginRight: 16 }}
          >
            CLEAR
          </Button>
          <Button variant="outlined" color="primary" onClick={isAnswerValid}>
            {disableAnswer ? "NEXT" : "SUBMIT"}
          </Button>
          <div
            style={{ marginLeft: 16, display: "flex", alignItems: "center" }}
          >
            {disableAnswer
              ? ""
              : attemptCount > 0
              ? "Attempts Remaining: " +
                (props.currentHomeWork.numOfAttemptsAllowed - attemptCount)
              : "Attempts Remaining: " +
                props.currentHomeWork.numOfAttemptsAllowed}
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignSelf: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
          }}
        >
          {props.currentHomeWork && props.currentHomeWork.helpLink && <Button
            variant="outlined"
            color="primary"
            onClick={() => props.showHelp()}
            style={{ marginRight: 16 }}
          >
            HELP
          </Button>}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                Speech(props.currentHomeWork.voiceOver);
              }
            }}
          >
            READ OUT
          </Button>
          </Grid>
      </Grid>
      <Grid container style={{ flexDirection: "column" }}>
        <div style={{ fontSize: 30, margin: 10, alignSelf: "center" }}>
          {/* ---------------------------- QUESTION TEXT VIEW - ITEM  1 ---------------------------- */}
          {props.currentHomeWork.question}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 10, alignSelf: "center" }}>
            {/* ---------------------------- ANSWER TEXT INPUT VIEW ---------------------------- */}
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={2}
              variant="outlined"
              style={{
                width: 400,
                textAlign: "center",
                borderColor: "green",
                borderWidth: 2,
                color: "black",
              }}
              value={answer}
              InputProps={{
                classes: {
                  input: classes.input,
                },
              }}
              disabled={disableAnswer}
              onChange={addAnswerToState}
            ></TextField>
          </div>
        </div>
        <div
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {/* ------------- ANSWER IMAGE RIGHT OR WRONG */}
          {/* <div style={{ flexDirection: "row", alignSelf: "center" }}>
                {disableAnswer && props.currentHomeWork.isRightAnswer && (
                  <CorrectImage />
                )}
      
                {disableAnswer && !props.currentHomeWork.isRightAnswer && (
                  <WrongImage />
                )}
              </div> */}
          {
            // ------------------   CORRECT ANSWER
            disableAnswer && !props.currentHomeWork.isRightAnswer && (
              <div
                style={{
                  alignSelf: "center",
                  marginTop: 20,
                  marginLeft: 50,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ fontSize: 24 }}>Correct Answer :{"   "}</div>
                <div style={{ fontSize: 48 }}>{displayAnswer}</div>
              </div>
            )
          }
        </div>
      </Grid>
      <Grid>
      <Snackbar
       open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert 
        key={`toastMessage-${toastMessage}`}
        onClose={handleClose} 
        severity={messageType}>
          {toastMessage}
        </Alert>
      </Snackbar>
      </Grid>
    </Grid>
  );
};

export default WriteWord;
