import {
  Button,
  Grid,
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CorrectImage from "../../../assets/images/green-tick.png";
import WrongImage from "../../../assets/images/red-cross.png";
import { cloneDeep } from "lodash";
import Speech from "../../../util/speech";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Timer from "../Common/Timer";

const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    padding: 6,
    fontSize: 24,
    textAlign: "center",
  },
}));

const MultiTable = (props) => {
  const classes = useStyles({});
  const theme = useTheme();

  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentQuestionId, setCurrentQuestionId] = useState(undefined);
  const [isValid, setIsValid] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [disableAnswer, setDisableAnswer] = useState(false);
  const [displayAnswer, setDisplayAnswer] = useState("");
  const [answer, setAnswer] = useState([]);
  const [maxNumberLength, setMaxNumberLength] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [toastMessage, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    if (
      props.currentHomeWork &&
      props.currentHomeWork.questionId &&
      props.currentHomeWork.questionId !== currentQuestionId
    ) {
      setCurrentQuestionId(props.currentHomeWork.questionId);
      setAttemptCount(0);
      setDisableAnswer(
        props.currentHomeWork.finalAnswer === "1" ? true : false
      );
      setDisplayAnswer(props.currentHomeWork.answer);
      setShowSpinner(false);

      let studentAnswer = [];
      const currentHomeWorkQuestion = props.currentHomeWork.strList;
      if (props.currentHomeWork.finalAnswer === "1") {
        const submittedAnswer = props.currentHomeWork.studentAnswer.split(",");
        studentAnswer = submittedAnswer;
      } else {
        studentAnswer = currentHomeWorkQuestion.map((index) => {
          return "";
        });
      }

      setAnswer(studentAnswer);
    }
  }, [props.currentHomeWork, currentQuestionId]);

  const isAnswerValid = () => {
    var isValid = false;
    console.log("VALIDATING ANSWER");

    if (disableAnswer) {
      props.goToNextQuestion();
    } else {
      const answerString = answer.join("");
      isValid = answerString && answerString.length > 0 ? true : false;
      if (isValid) isAnswerCorrect();
      else {
        setMessage("Invalid Answer!. Enter Value.");
        setMessageType("warning");
        handleClick();
      }
    }
  };

  const isAnswerCorrect = () => {
    var isCorrect = false;
    console.log("CHECKING ANSWER");
    const answerString = answer.join(",");
    console.warn("answerString ", answerString);
    isCorrect =
      answerString &&
      answerString.length > 0 &&
      answerString === props.currentHomeWork.ansList.join(",");
    if (isCorrect) {
      console.log("IS CORRECT, GO TO NEXT QUESTION");
      props.onSubmitQuestion(
        answerString,
        attemptCount + 1,
        true,
        props.currentHomeWork.questionId
      );
      setMessage("Correct Answer!. Good job.");
      setMessageType("success");
      handleClick();
    } else {
      console.log("WRONG ANSWER ");
      setAttemptCount(attemptCount + 1);

      if (attemptCount >= 2) {
        // props.onShowToast("Wrong Answer!. Next Question.", "danger");
        setMessage("Wrong Answer!. Try Next Question.");
        setMessageType("error");
        handleClick();
        props.onSubmitQuestion(
          answerString,
          attemptCount,
          false,
          props.currentHomeWork.questionId
        );
      } else {
        // props.onShowToast("Wrong Answer!. Try Again.", "warning");
        setMessage("Wrong Answer!. Try Again.");
        setMessageType("warning");
        handleClick();
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const onAnswerChange = (value, index) => {
    const studentAnswer = cloneDeep(answer);
    studentAnswer[index] = value;
    setAnswer(studentAnswer);
  };

  const clearAnswer = () => {
    const currentHomeWorkQuestion = cloneDeep(props.currentHomeWork.strList);
    const blankAnswer = currentHomeWorkQuestion.map((index) => {
      return "";
    });

    setAnswer(blankAnswer);
  };

  const renderQuestion = () => {
    const correctAnswer = props.currentHomeWork.ansList;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {props.currentHomeWork.strList &&
          props.currentHomeWork.strList.map((item, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 5,
                }}
                key={"" + item + index}
              >
                <div style={{ fontSize: 24 }}>{item}</div>

                {(props.currentHomeWork.qType === "mulptrn" ||
                  props.currentHomeWork.qType === "divptrn") && (
                  <div
                    style={{
                      //   borderStyle: "solid",
                      //   borderColor: "black",
                      //   borderWidth: 1,
                      width: 90,
                    }}
                  >
                    <TextField
                      name={`input-${index}`}
                      value={answer && answer[index] ? answer[index] : ""}
                      variant="outlined"
                      //   value={answer[index] ? answer[index] : ""}
                      style={{
                        textAlign: "center",
                        marginLeft: 16,
                      }}
                      InputProps={{
                        classes: {
                          input: classes.input,
                        },
                      }}
                      multiline={false}
                      disabled={disableAnswer}
                      onChange={(e) => {
                        console.log("ON CHANGE ", e.target.value, "---", e);
                        onAnswerChange(
                          e.target.value.replace(/[^0-9]/g, ""),
                          index
                        );
                      }}
                    />
                  </div>
                )}
                {props.currentHomeWork.qType !== "mulptrn" &&
                  props.currentHomeWork.qType !== "divptrn" && (
                    <div
                      style={{
                        //   borderStyle: "solid",
                        //   borderColor: "black",
                        //   borderWidth: 1,
                        width: 90,
                      }}
                    >
                      <TextField
                        name={`input-${index}`}
                        value={answer && answer[index] ? answer[index] : ""}
                        variant="outlined"
                        //   value={answer[index] ? answer[index] : ""}
                        style={{
                          textAlign: "center",
                          marginLeft: 16,
                        }}
                        InputProps={{
                          classes: {
                            input: classes.input,
                          },
                        }}
                        multiline={false}
                        disabled={disableAnswer}
                        onChange={(e) => {
                          console.log("ON CHANGE ", e.target.value, "---", e);
                          onAnswerChange(
                            e.target.value.replace(/[^0-9]/g, ""),
                            index
                          );
                        }}
                      />
                    </div>
                  )}

                {/* ------------- ANSWER IMAGE RIGHT OR WRONG */}
                {disableAnswer &&
                  answer[index] === correctAnswer[index].toString() && (
                    <img
                      alt="correct-value"
                      src={CorrectImage}
                      style={{
                        width: 30,
                        height: 30,
                        marginLeft: 20,
                      }}
                    />
                  )}

                {disableAnswer &&
                  answer[index] !== correctAnswer[index].toString() && (
                    <div
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <img
                        alt="wrong-value"
                        src={WrongImage}
                        style={{
                          width: 30,
                          height: 30,
                          marginLeft: 20,
                        }}
                      />
                      <div
                        style={{ marginLeft: 10, fontSize: 18, color: "green" }}
                      >
                        {correctAnswer[index]}
                      </div>
                    </div>
                  )}
              </div>
            );
          })}
      </div>
    );
  };
  const renderRvrsQuestion = () => {
    const correctAnswer = props.currentHomeWork.ansList;
    const op = props.currentHomeWork.qType === "mulptrn" ? "x" : "÷";

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {props.currentHomeWork.strList &&
          props.currentHomeWork.strList.map((item, index) => {
            console.log("check --- >>> ", item.split(op));
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 5,
                }}
                key={"" + item + index}
              >
                {item.split(op).map((val) => {
                  console.log(
                    "check 2 --- >>>",
                    val.split("=")[0].trim() === "----",
                    "<<<"
                  );
                  if (props.currentHomeWork.secondVar === "rvrsmdle") {
                    if (val.split("=")[0].trim() === "*") {
                      return (
                        <div style={{ flexDirection: "row" }}>
                          <div
                            style={{
                              //   borderStyle: "solid",
                              //   borderColor: "black",
                              //   borderWidth: 1,
                              width: 90,
                            }}
                          >
                            <TextField
                              name={`input-${index}`}
                              value={
                                answer && answer[index] ? answer[index] : ""
                              }
                              variant="outlined"
                              //   value={answer[index] ? answer[index] : ""}
                              style={{
                                textAlign: "center",
                                marginLeft: 16,
                              }}
                              InputProps={{
                                classes: {
                                  input: classes.input,
                                },
                              }}
                              multiline={false}
                              disabled={disableAnswer}
                              onChange={(e) => {
                                console.log(
                                  "ON CHANGE ",
                                  e.target.value,
                                  "---",
                                  e
                                );
                                onAnswerChange(
                                  e.target.value.replace(/[^0-9]/g, ""),
                                  index
                                );
                              }}
                            />
                          </div>
                          <div style={{ fontSize: 24 }}>
                            {" "}
                            = {val.split("=")[1]}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div style={{ fontSize: 24 }}>
                          {val}
                          {op}
                        </div>
                      );
                    }
                  }
                  if (props.currentHomeWork.secondVar === "rvrslft") {
                    if (val.trim() === "*") {
                      return (
                        <div style={{ flexDirection: "row" }}>
                          <div
                            style={{
                              //   borderStyle: "solid",
                              //   borderColor: "black",
                              //   borderWidth: 1,
                              width: 90,
                            }}
                          >
                            <TextField
                              name={`input-${index}`}
                              value={
                                answer && answer[index] ? answer[index] : ""
                              }
                              variant="outlined"
                              //   value={answer[index] ? answer[index] : ""}
                              style={{
                                textAlign: "center",
                                marginLeft: 16,
                              }}
                              InputProps={{
                                classes: {
                                  input: classes.input,
                                },
                              }}
                              multiline={false}
                              disabled={disableAnswer}
                              onChange={(e) => {
                                console.log(
                                  "ON CHANGE ",
                                  e.target.value,
                                  "---",
                                  e
                                );
                                onAnswerChange(
                                  e.target.value.replace(/[^0-9]/g, ""),
                                  index
                                );
                              }}
                            />
                          </div>
                          <div style={{ fontSize: 24 }}>
                            {" "}
                            {op} {val.split("=")[1]}{" "}
                          </div>
                        </div>
                      );
                    } else {
                      return <div style={{ fontSize: 24 }}> {val}</div>;
                    }
                  }
                })}

                {/* ------------- ANSWER IMAGE RIGHT OR WRONG */}
                {disableAnswer &&
                  answer[index] === correctAnswer[index].toString() && (
                    <img alt="correct-answer" src={CorrectImage} />
                  )}

                {disableAnswer &&
                  answer[index] !== correctAnswer[index].toString() && (
                    <div
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <img alt="wrong-answer" src={WrongImage} />
                      <div
                        style={{ marginLeft: 10, fontSize: 18, color: "green" }}
                      >
                        Ans: {correctAnswer[index]}
                      </div>
                    </div>
                  )}
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <Grid
      container
      style={{
        padding: 25,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid container style={{ padding: 16 }}>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => clearAnswer()}
            style={{ marginRight: 16 }}
          >
            CLEAR
          </Button>
          <Button variant="outlined" color="primary" onClick={isAnswerValid}>
            {disableAnswer ? "NEXT" : "SUBMIT"}
          </Button>
          <div
            style={{ marginLeft: 16, display: "flex", alignItems: "center" }}
          >
            {attemptCount > 0 ? "Wrong Attempts: " + attemptCount : ""}
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignSelf: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
            alignItems: "center",
          }}
        >
          <Timer />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => props.showHelp()}
            style={{ marginRight: 16, marginLeft: 16 }}
          >
            HELP
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                Speech(props.currentHomeWork.voiceOver);
              }
            }}
          >
            READ OUT
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ marginBottom: 15 }}>
          <div style={{ fontSize: 24, textAlign: "center" }}>
            {props.currentHomeWork.question}
          </div>
        </div>
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          {/* ---------------------------- QUESTION TEXT VIEW - ITEM  1 ---------------------------- */}
          {(props.currentHomeWork.qType === "multbl" ||
            props.currentHomeWork.qType === "mulptrn" ||
            props.currentHomeWork.qType === "divptrn") &&
            props.currentHomeWork.secondVar !== "rvrsmdle" &&
            props.currentHomeWork.secondVar !== "rvrslft" &&
            renderQuestion()}
          {(props.currentHomeWork.qType === "mulptrn" ||
            props.currentHomeWork.qType === "divptrn") &&
            (props.currentHomeWork.secondVar === "rvrsmdle" ||
              props.currentHomeWork.secondVar === "rvrslft") &&
            renderRvrsQuestion()}
        </div>
        <div
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {/* ------------- ANSWER IMAGE RIGHT OR WRONG */}
          <div style={{ flexDirection: "row", alignSelf: "center" }}>
            {disableAnswer && props.currentHomeWork.isRightAnswer && (
              <img alt="correct-answer" src={CorrectImage} />
            )}

            {disableAnswer && !props.currentHomeWork.isRightAnswer && (
              <img alt="wrong-answer" src={WrongImage} />
            )}
          </div>
          {
            // ------------------   CORRECT ANSWER
            // disableAnswer && !props.currentHomeWork.isRightAnswer && (
            //   <div
            //     style={{
            //       alignSelf: "center",
            //       marginTop: 20,
            //       marginLeft: 50,
            //       flexDirection: "row",
            //       alignItems: "center",
            //     }}
            //   >
            //     <div style={{ fontSize: 24 }}>Correct Answer :{"   "}</div>
            //     <div style={{ fontSize: 48 }}>{displayAnswer}</div>
            //   </div>
            // )
          }
        </div>
      </Grid>
      <Grid>
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert
            key={`toastMessage-${toastMessage}`}
            onClose={handleClose}
            severity={messageType}
          >
            {toastMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};

export default MultiTable;
