import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as profileSelectors from "../../../reducer/selectors/profile.entity";
import { getProfile } from "../../../actions";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, Divider, Grid, Typography } from "@material-ui/core";
import girlPng from "../../../assets/images/btn-girl@2x.png";
import boyPng from "../../../assets/images/student-avatar@2x.png";
import { makeStyles } from "@material-ui/core";
import { serviceConstants } from "../../../constants";
import { setShowLoader } from "../../../actions";

const useStyles = makeStyles((theme) => ({
  img: {
    transition: "all 1s",
    // ease-in-out: 0;
    "&:hover": {
      cursor: "default",
      transform: "rotate(360deg)",
      transition: "all 1s ease-in-out 0s",
    },
  },
}));

export function Profile(props) {
  const dispatch = useDispatch();
  const { profilename } = useParams();
  const classes = useStyles();

  const profile = useSelector(profileSelectors.getProfile);
  const rankings = useSelector(profileSelectors.getRankings);

  useEffect(() => {
    if (profilename) {
      dispatch(setShowLoader(true));
      dispatch(getProfile({ profilename }));
      console.log("profile...", profile);
    }
  }, [profilename]);

  return (
    <Grid container spacing={2} style={{ marginTop: 16 }}>
      <Grid item xs={12} md={3} style={{ padding: 16 }}>
        <Card style={{ marginBottom: 16, padding: 16 }}>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid item xs={12}>
              <div style={{ width: 160, height: 160, borderRadius: 40 }}>
                {profile && profile.childProfilePic ? (
                  <img class="image" src={profile && profile.childProfilePic} />
                ) : profile &&
                  (profile.gender === "female" ||
                    profile.gender === "Female") ? (
                  <img class="image" src={girlPng} />
                ) : (
                  <img class="image" src={boyPng} />
                )}
                {/* <img
                  src="https://rabbitfiles.s3.amazonaws.com/ravi.tvn%40gmail.com/Sai_60fad37002bed02242b2fd58/profile.png"
                  alt="Avatar"
                  class="image"
                /> */}
              </div>
            </Grid>

            <Typography variant="h4" style={{ marginTop: 16 }}>

              {(profilename.length < 10) ? profilename : (profile && (profile.firstName + " " + profile.lastName.charAt(0)))}
            </Typography>
            <Typography variant="h6">
              Grade - {profile && profile.grade}
            </Typography>
          </Grid>
        </Card>
        {profile && profile.profileName && (
          <Card style={{ padding: 8 }}>
            {profile.courses && (
              <div>
                <Typography variant="h6">Courses</Typography>
                {profile.courses.map((course) => (
                  <div>{course.courseId}</div>
                ))}
              </div>
            )}
          </Card>
        )}
      </Grid>
      <Grid item xs={12} md={9} style={{ padding: 16 }}>
        <Card style={{ padding: 8 }}>
          <Typography variant="h5">Badges and Credits</Typography>
          {rankings && rankings.length > 0 && (
            <Grid container spacing={3} style={{ marginTop: 16 }}>
              {rankings.map(
                (ranking) =>
                  ranking.rankings &&
                  ranking.rankings.currentBadge && ranking.rankings.currentBadge.pointsAchieved > 499 && (
                    <Grid item xs={4}>
                      <Card>
                        <div
                          style={{
                            // padding: 16,
                            display: "flex",
                          }}
                        >
                          <img
                            style={{ height: "30vh", width: "100%" }}
                            className={classes.img}
                            src={
                              serviceConstants.RABBIT_AWS_S3 +
                              "/" +
                              profile.userId +
                              "/" +
                              profile.firstName +
                              "_" +
                              profile.childId +
                              "/" +
                              ranking.grade + "-" +
                              ranking.rankings.currentBadge.name +
                              ranking.rankings.currentBadge.currentLevel +
                              ".jpg"
                            }
                          />
                        </div>
                        <Divider />
                        {ranking.grade}
                      </Card>
                    </Grid>
                  )
              )}
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}

export default Profile;
