import {
  Box,
  Button,
  Grid,
  Modal,
  Snackbar,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as parentActions from "../../../actions/parent.actions";
import {
  getChildList,
  getAddNewPaymentSuccess,
  getAddNewPaymentError,
  getCreateSubscriptionSuccess,
  getCreateSubscriptionError,
  getSubscriptionInfo,
  getIsSubscriptionActive
} from "../../../reducer/selectors/parent.entity";
import { setShowLoader } from "../../../actions";
import girlPng from "../../../assets/images/btn-girl@2x.png";
import boyPng from "../../../assets/images/btn-boy@2x.png";
import { useHistory } from "react-router-dom";
import AddChild from "../AddChild/AddChild";
import {
  getAddChildError,
  getAddChildSuccess,
  getDeleteChildSuccess,
} from "../../../reducer/selectors/child.entity";
import Alert from "@material-ui/lab/Alert";
import { getUserId } from "../../../reducer/selectors/user.entity";
import SimpleCard from "../Theme/SimpleCard";
import Subscriptions from "../Subscriptions/Subscriptions";
import { serviceConstants } from "../../../constants"

const ChildList = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const [showAddChildModal, setShowAddChildModal] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const userId = useSelector(getUserId);
  const childList = useSelector(getChildList);
  const subscriptionInfo = useSelector(getSubscriptionInfo)
  const addChildSuccess = useSelector(getAddChildSuccess);
  const addChildError = useSelector(getAddChildError);
  const deleteChildSuccess = useSelector(getDeleteChildSuccess);
  const addNewPaymentSuccess = useSelector(getAddNewPaymentSuccess)
  const addNewPaymentError = useSelector(getAddNewPaymentError)
  const createSubscriptionSuccess = useSelector(getCreateSubscriptionSuccess)
  const createSubscriptionError = useSelector(getCreateSubscriptionError)
  const isSubscriptionActive = useSelector(getIsSubscriptionActive)
  const [allowAddChild, setAllowAddChild] = useState(false);
  
  useEffect(() => {
    dispatch(setShowLoader(true));
    dispatch(parentActions.getParentInfo({ userId: userId }));
    if (!childList && !subscriptionInfo) {
      setAllowAddChild(true);
    }
  }, []);

  useEffect(() => {
    if (childList.length == 0) {
      setAllowAddChild(true);
    }
  }, [childList]);

  useEffect(() => {
    if (subscriptionInfo) {
      const selSub = serviceConstants.subscriptions.find((sub) => sub.stripeId === subscriptionInfo.subscriptionPlanId)
      if (childList.length > 0) {
        if (selSub && selSub.allowedChildProfiles > childList.length) {
          setAllowAddChild(true);
        }
      } else {
        setAllowAddChild(true);
      }
    }

  }, [subscriptionInfo])

  useEffect(() => {
    if (addChildSuccess) {
      setShowAddChildModal(false);
      setShowSnackbar(true);
      dispatch(parentActions.getParentInfo({ userId: userId }));
      // MAKE CALL TO REFRESH CHILD LIST
    }
  }, [addChildSuccess]);

  useEffect(() => {
    if (addNewPaymentSuccess) {
      setShowSnackbar(true)
    }
  }, [addNewPaymentSuccess])

  useEffect(() => {
    if (deleteChildSuccess) {
      setShowSnackbar(true);
    }
  }, [deleteChildSuccess]);

  useEffect(() => {
    if (createSubscriptionSuccess) {
      setShowSnackbar(true)
    }
  }, [createSubscriptionSuccess])

  useEffect(() => {
    if (addChildError) {
      setShowSnackbar(true);
    }
  }, [addChildError]);

  const prepareItemInfo = (firstName, lastName, profilePic, gender) => {
    let img = "";
    if (profilePic) {
      img = profilePic;
    } else {
      img = (gender === 'female' || gender === 'Female') ? girlPng : boyPng;
    }
    const itemInfo = {
      name: firstName + " " + lastName,
      image: img
    }
    return itemInfo;
  }

  const alertMessage = () => {
    if (addChildSuccess)
      return "Child Profile Added"
    else if (deleteChildSuccess)
      return "Child Profile deleted successfully"
    else if (addNewPaymentSuccess)
      return "Card added Successfully"
    else if (createSubscriptionSuccess)
      return "Subscription Created Successfully"
    else if (addNewPaymentError)
      return addNewPaymentError
    else if (addChildError)
      return addChildError
    else if (createSubscriptionError)
      return createSubscriptionError
  }

  const renderSubscriptionInfo = () => {
    const selectedSubscription = serviceConstants.subscriptions.find((sub) => sub.stripeId === subscriptionInfo.subscriptionPlanId)
    console.log("SELECTED SUBSCRIPTION :", selectedSubscription, subscriptionInfo)
    return <Grid container style={{ margin: 0 }}>
      <h3 style={{ fontWeight: "600", padding: 10 }}>Current Subscription</h3>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between", padding: 10 }}
      >
        <div>
          <div>
            {subscriptionInfo.stripeSubscriptionStatus === "trialing" && <h4 style={{ color: "green" }}>In Trial Period</h4>}
            <h4>
              <span>Next Renewal Date :</span>
              <span>{subscriptionInfo.subscriptionNextRenewal}</span>
            </h4>
          </div>
        </div>
        <div>
          {selectedSubscription && <div>
            <h3>
              {selectedSubscription.name}
            </h3>
            <span className="author">{selectedSubscription.description}</span>
            <div className="productAction">
              <span style={{ display: "flex", flexDirection: "row" }}>
                {selectedSubscription.introductoryPrice ? <h4>
                  <span style={{ textDecoration: "line-through", color: "red", marginRight: 5 }}>
                    {selectedSubscription.price}
                  </span>
                  <span>{selectedSubscription.introductoryPrice}</span>
                </h4> : <h4>
                  {selectedSubscription.price}</h4>}<h4>/</h4><h4>{selectedSubscription.duration}</h4></span>
            </div>
          </div>}
        </div>
      </Grid>
    </Grid>
  }
  return (
    <Grid container spacing={3} style={{ padding: 25 }}>
      <Grid item xs={12}>
        {/* <Breadcrumbs maxItems={2} aria-label="breadcrumb">
          <Link color="inherit" href="/parent">
            Home
          </Link>
        </Breadcrumbs> */}
      </Grid>
      <Modal
        open={showAddChildModal}
      // onClose={() => setShowAddChildModal(false)}    To close on Click outside modal
      >
        <AddChild onCancel={() => setShowAddChildModal(false)} allowAddChild={allowAddChild} />
        {/* {allowAddChild && <AddChild onCancel={() => setShowAddChildModal(false)} />}
        {!allowAddChild && <Typography>Added max profiles. Please upgrade to add more profiles.</Typography>} */}
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setShowSnackbar(false)
        }}
      >
        <Alert
          elevation={6}
          onClose={() => setShowSnackbar(false)}
          severity={addChildSuccess || deleteChildSuccess || addNewPaymentSuccess || createSubscriptionSuccess ? "success" : "error"}
        >
          {alertMessage()}
        </Alert>
      </Snackbar>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h3 style={{ fontWeight: "600" }}>Child Profiles</h3>
        <Box component="span" m={1}>
          <Button
            variant="outlined"
            size="small"
            color="inherit"
            onClick={() => setShowAddChildModal(true)}
          >
            Add child
          </Button>
        </Box>
      </Grid>
      {/* ADD THIS BEFORE map filter((child) => child.childActive). */}
      {childList &&
        childList.length > 0 &&
        childList.map(
          (child) =>
            child.status !== "2" && (
              <Grid item md={4} xs={12} style={{ minWidth: 200, maxWidth: 240 }}>
                <SimpleCard
                  itemInfo={prepareItemInfo(child.firstName, child.lastName, child.childProfilePic, child.gender)}
                  onClickFunction={() => {
                    history.push({
                      pathname: `/child`,
                      state: {
                        childId: child.childId,
                        userType: "user",
                        pageSrc: "ChildHomePage",
                        grade: child.grade,
                      },
                    });
                  }}
                  childListStyle={true} />

              </Grid>
            )
        )}
      {
        // CHECK SUBSCRIPTION STATUS AND SHOW INFO
        isSubscriptionActive && renderSubscriptionInfo()
      }
      <Subscriptions />
    </Grid >
  );
};

export default ChildList;
