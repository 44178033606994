import React, { useState } from "react";
import OtpInput from "react-otp-input";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { attemptSendOtp } from "../../../actions";
import { getOTPResponse } from "../../../reducer/selectors/login.entity";

export function OTP(props) {
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");

  const apiOTP = useSelector(getOTPResponse);

  const handleValidate = () => {
    if (otp === apiOTP) {
      props.OTPSuccess(true);
    } else {
      props.OTPSuccess(false);
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h6 style={{ fontWeight: "bold", textAlign: "center" }}>
        Enter Verification Code
      </h6>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 16,
        }}
      >
        <OtpInput
          inputStyle={{ width: 32 }}
          value={otp}
          onChange={(p) => setOtp(p)}
          numInputs={6}
          separator={<span style={{ padding: 2 }}> - </span>}
        />
      </div>

      <Button
        size="small"
        variant="contained"
        color="secondary"
        onClick={() => {
          dispatch(attemptSendOtp());
        }}
      >
        Resend OTP
      </Button>
      <span style={{ padding: "20px" }} />
      <Button
        style={{ backgroundColor: "#0a800099", color: "white" }}
        size="small"
        variant="contained"
        color="success"
        onClick={() => handleValidate()}
      >
        Validate OTP
      </Button>
    </div>
  );
}

export default OTP;
