import { put, takeLatest } from "redux-saga/effects";
import * as parentActions from "../actions/parent.actions";
import { serviceConstants } from "../constants";
import Transport from "../transport/rabbitboard.transport";
import { setShowLoader } from "../actions";

function* getParentInfoSaga(action) {
  console.log("action,..", action);
  try {
    const response = yield Transport.genericOperation(
      // serviceConstants.RABBIT_CHILD_URL + "/parent/" + "ravi.tvn@gmail.com",
      serviceConstants.RABBIT_CHILD_URL +
      "/parent/" +
      action.payload.userId,
      serviceConstants.RABBIT_CHILD_URL_APIKEY,
      {},
      "GET"
    );
    console.log("RESPONSE ", response);
    yield put(setShowLoader(false));
    yield put(parentActions.getParentInfoSuccess(response));
  } catch (e) {
    console.error("getParentInfo Saga ERROR ", e);
    throw e;
  }
}

function* addNewPaymentMethodSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.RABBIT_LOGIN_URL +
      `/stripe/cardtonewcustomer/${action.payload.userId}/${action.payload.tokenId}`,
      serviceConstants.RABBIT_LOGIN_URL_APIKEY,
      {},
      "POST"
    );
    console.log("RESPONSE ", response);
    yield put(setShowLoader(false));
    if (response && response.message && response.message.code && response.message.code === "200") {
      yield put(parentActions.addNewPaymentMethodSuccess());
      yield put(parentActions.getParentInfo({ userId: action.payload.userId }))
    } else {
      yield put(parentActions.addNewPaymentMethodFailure("Add Card Request Failued"));
    }
  } catch (e) {
    console.error("addNewPaymentMethodSaga Saga ERROR ", e);
    yield put(parentActions.addNewPaymentMethodFailure(e));
    throw e;
  }
}

function* deletePaymentMethodSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.RABBIT_CHILD_URL +
      `/stripe/cardtonewcustomer/${action.payload.userId}/${action.payload.tokenId}`,
      serviceConstants.RABBIT_CHILD_URL_APIKEY,
      {},
      "POST"
    );
    console.log("RESPONSE ", response);
    yield put(setShowLoader(false));
    yield put(parentActions.deletePaymentMethodSuccess(response));
  } catch (e) {
    console.error("deletePaymentMethodSaga Saga ERROR ", e);
    yield put(parentActions.deletePaymentMethodFailure(e));
    throw e;
  }
}

// rabbit/user/stripe/createsubscription/ravi.tvn@gmail.com/price_1Jlc7NK3vqFTVAdsqKoA4Wki?description=&source=card_1Jja6xK3vqFTVAdshSUWy2Sz
function* createSubscriptionSaga(action) {
  console.log("action,createSubscriptionSaga..", action);
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.RABBIT_LOGIN_URL +
      `/stripe/createsubscription/${action.payload.userId}/${action.payload.subscriptionId}?description=&source=${action.payload.cardId}`
      ,
      "",
      {},
      "POST"
    );
    console.log("RESPONSE ", response);

    if (response && response.id && response.message && response.message.code === "200") {
      yield put(setShowLoader(false));
      yield put(parentActions.createSubscriptionSuccess(response.child));
      yield put(parentActions.getParentInfo({ userId: action.payload.userId }))
    } else {
      yield put(
        parentActions.createSubscriptionFailure("Subscription Creation Failed")
      );
    }

  } catch (e) {
    console.error("createSubscriptionSaga Saga ERROR ", e);
    parentActions.createSubscriptionFailure("Subscription Creation Failed, Please try again.")
    throw e;
  }
}

export default function* parentSaga() {
  try {
    yield takeLatest(parentActions.getParentInfo, getParentInfoSaga);
    yield takeLatest(parentActions.addNewPaymentMethod, addNewPaymentMethodSaga)
    yield takeLatest(parentActions.deletePaymentMethod, deletePaymentMethodSaga)
    yield takeLatest(parentActions.createSubscription, createSubscriptionSaga)
  } catch {
    yield;
  }
}
