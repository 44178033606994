import { homeworkConstants } from "../constants";

const initialState = {
  questionsList: [],
  homeWorkDay: "",
  rankings: []
};

export function homeworkReducer(state = initialState, action) {
  switch (action.type) {
    case homeworkConstants.GET_HOMEWORK:
      return {
        ...state,
        questionsList: [],
        homeWorkDay: "",
      };
    case homeworkConstants.GET_HOMEWORK_SUCCESS:
      return {
        ...state,
        questionsList: action.payload.homeWork.qusAnsList,
        homeWorkDay: action.payload.homeWork.day,
      };
    case homeworkConstants.SAVE_STUDENT_ANSWER:
      const list = state.questionsList;
      const {
        studentAnswer,
        numberOfAttempts,
        rightAnswer,
        currentQuestionIndex,
        finalAnswer,
      } = action.payload;
      console.log(
        "DATA ",
        studentAnswer,
        numberOfAttempts,
        rightAnswer,
        currentQuestionIndex,
        list[currentQuestionIndex]
      );
      if (list[currentQuestionIndex]) {
        console.log("QUESTION ", list[currentQuestionIndex]);
        const currentHomeWork = list[currentQuestionIndex];
        currentHomeWork.isRightAnswer = rightAnswer;
        currentHomeWork.studentAnswer = studentAnswer;
        currentHomeWork.numberOfAttempts = numberOfAttempts;
        currentHomeWork.finalAnswer = finalAnswer;
        list[currentQuestionIndex] = currentHomeWork;
      }
      return {
        ...state,
        questionsList: list,
      };
    case homeworkConstants.GET_RANKINGS_SUCCESS:
      return {
        ...state,
        rankings: action.payload.rankings,
      };
    default:
      return state;
  }
}
