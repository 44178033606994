import {
  Button,
  Card,
  Container,
  Grid,
  Snackbar,
  Typography,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getIsLoggedIn,
  getUserId,
} from "../../../reducer/selectors/user.entity";
import { getIsRegistrationSuccess } from "../../../reducer/selectors/login.entity";
import { cloneDeep } from "lodash";
import {
  getChildUserId,
  getAddChildError,
  getChildInfo,
} from "../../../reducer/selectors/child.entity";

const SelectCourse = (props) => {
  const [ctest, setCtest] = useState("");
  const [grade, setGrade] = useState("");
  const [courseConcept, setCourseConcept] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showTestTerms, setShowTestTerms] = useState(false);
  const [acceptTestTerms, setAcceptTestTerms] = useState(false);

  const location = useLocation();
  const srcpage = (location.state && location.state.pageSrc) ? location.state.pageSrc : "";


  const [errors, setErrors] = useState({
    ctest: "",
    grade: "",
  });

  const isLoggedIn = useSelector(getIsLoggedIn);
  const isRegistrationSuccess = useSelector(getIsRegistrationSuccess);
  const addChildError = useSelector(getAddChildError);
  // const userId = useSelector(getUserInfo)
  const childuserId = useSelector(getChildUserId);
  const userId = useSelector(getUserId);
  const childInfo = useSelector(getChildInfo);




  useEffect(() => {
    if (isLoggedIn && childInfo && childInfo.grade) {
      handleCourseConceptLogin("M" + childInfo.grade)

    }
    // else{
    //   speedData.map((data) => {
    //       setCourseConcept(data);        
    //   });
    // }
  }, [courseConcept.courseCd]);

  const handleCourseConceptLogin = (e) => {
    // setGrade(e);

    speedData.map((data) => {
      if (data.courseCd === e) {
        setCourseConcept(data);
      }
    });
  };
    
  const validate = () => {
    let isCurrentValid = true;
    const currentErrors = cloneDeep(errors);

    if (grade && grade.length > 0) {
      isCurrentValid = isCurrentValid && true;
      currentErrors.grade = "";
    } else if (isLoggedIn) {
      isCurrentValid = isCurrentValid && true;
      currentErrors.grade = "";
    }
    else {
      isCurrentValid = isCurrentValid && false;
      currentErrors.grade = "Select Grade";
    }
    if (ctest && ctest.length > 0) {
      isCurrentValid = isCurrentValid && true;
      currentErrors.ctest = "";
    } else {
      isCurrentValid = isCurrentValid && false;
      currentErrors.ctest = "Select Grade";
    }
    setIsValid(isCurrentValid);
    setErrors(currentErrors);
    return isCurrentValid;
  };

  const AcceptTestTerms = () => {

    const cncpts = courseConcept.concepts ? courseConcept.concepts : ""
    const selectedConcept = cncpts.filter((c) => c.cTest === ctest);
    const acrcy = Number(selectedConcept[0].accuracy)
    return (
      <Grid container
        style={{ padding: 25, display: "flex", justifyContent: "center" }}>
        <Grid
          item={true}
          xs={12}
          style={{
            marginBottom: 15,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6">SpeedTest levels based on correct answers</Typography>
        </Grid>
        <Grid
          item={true}
          xs={12}
          style={{
            marginBottom: 15,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6">Concept - {selectedConcept[0].conceptName}</Typography>
        </Grid>
        <Grid container item xs={12} spacing={3} border={1}>
          <Grid item xs={2} border={1}>
            <Typography color="error">Level</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>Attempt</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>Fair</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>Average</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>Good</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>Excellent</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={2}>
            <Typography color="primary">Answers</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>0 to {(acrcy * 1) - 1}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{(acrcy * 1)} to {(acrcy * 2) - 1}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{(acrcy * 2)} to {(acrcy * 3) - 1}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{(acrcy * 3)} to {(acrcy * 4) - 1}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{(acrcy * 4)} or above</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <FormControlLabel
            control={<Checkbox checked={acceptTestTerms} onChange={(e) => { setAcceptTestTerms(acceptTestTerms ? false : true) }} name="gilad" />}
            label="Understanding / Accepting Speed Test Terms"
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item xs={4}>
            <Button
              variant="outlined"
              color="error"
              style={{ width: "100%" }}
              onClick={() => {
                console.log("SERVICE NOIW");
                props.onCancel();
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item={true} xs={4}>
            <Button
              variant="outlined"
              color="primary"
              style={{ width: "100%" }}
              disabled={!acceptTestTerms}
              onClick={() => {
                console.log("SERVICE NOIW");
                onSubmit();
              }}
            >
              START
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const onStart = () => {
    setShowTestTerms(true)
  }
  const onSubmit = () => {

    const valid = validate();
    const concept =
      courseConcept &&
      courseConcept.concepts.find((concept) => concept.cTest === ctest);
    if (valid && concept) {
      props.speedTestSubmit(
        courseConcept.practiceChildId,
        "",
        courseConcept.courseCd,
        100,
        "guest",
        concept
      );
    }
    if (addChildError) {
      setShowSnackbar(true);
    }
  };

  const speedData = [
    {
      grade: "Pre-K",
      courseCd: "MP",
      practiceChildId: isLoggedIn && (location.state && location.state.childId) ? location.state.childId : "60fae74602bed02242b2fd5a",
      concepts: [
        {
          conceptName: "Select Numbers",
          concept: "nmbr",
          cTest: "nmbr-slct-blw10",
          accuracy: 5,
        },
        {
          conceptName: "Count Numbers",
          concept: "nmbr",
          cTest: "nmbr-hmny-blw10",
          accuracy: 5,
        },
        {
          conceptName: "Blocks",
          concept: "blck",
          cTest: "blck-blw10-blw10",
          accuracy: 5,
        },
        {
          conceptName: "Shapes",
          concept: "shp",
          cTest: "shp-tri",
          accuracy: 5,
        },
        {
          conceptName: "Addition",
          concept: "add",
          cTest: "add-blw5-blw2-mltln",
          accuracy: 5,
        }
      ],
    },
    {
      grade: "Kindergarten",
      courseCd: "MK",
      practiceChildId: isLoggedIn && (location.state && location.state.childId) ? location.state.childId : "60fae74602bed02242b2fd5a",
      concepts: [
        {
          conceptName: "Select Numbers",
          concept: "nmbr",
          cTest: "nmbr-slct-blw10",
          accuracy: 5,
        },
        {
          conceptName: "Count Numbers",
          concept: "nmbr",
          cTest: "nmbr-hmny-blw10",
          accuracy: 5,
        },
        {
          conceptName: "Blocks",
          concept: "blck",
          cTest: "blck-blw10-blw10-blw10",
          accuracy: 5,
        },
        {
          conceptName: "Addition",
          concept: "add",
          cTest: "add-blw10-blw5-mltln",
          accuracy: 3,
        },
        {
          conceptName: "Write Numbers",
          concept: "wrtnum",
          cTest: "wrtnum-rng1to10",
          accuracy: 3,
        },
        {
          conceptName: "Write Words",
          concept: "wrtwrd",
          cTest: "wrtwrd-rng1to10",
          accuracy: 3,
        },
        {
          conceptName: "Subtraction",
          concept: "sub",
          cTest: "sub-blw10-blw10-mltln",
          accuracy: 3,
        },
        {
          conceptName: "Shapes",
          concept: "shp",
          cTest: "shp-any",
          accuracy: 5,
        },
      ],
    },
    {
      grade: "First Grade",
      courseCd: "M1",
      practiceChildId: isLoggedIn && (location.state && location.state.childId) ? location.state.childId : "60fae74602bed02242b2fd5a",
      concepts: [
        {
          conceptName: "Addition",
          concept: "add",
          cTest: "add-blw100-blw10-mltln",
          accuracy: 3,
        },
        {
          conceptName: "Subtraction",
          concept: "sub",
          cTest: "sub-blw100-blw50-mltln",
          accuracy: 3,
        },
        {
          conceptName: "Even Odd",
          concept: "evenodd",
          cTest: "evenodd-blw50",
          accuracy: 6,
        },
        {
          conceptName: "Compare Numbers",
          concept: "compare",
          cTest: "cmp-blw20-blw20",
          accuracy: 6,
        },
        {
          conceptName: "Place Values",
          concept: "plc",
          cTest: "plc-val-blw99-pos1",
          accuracy: 6,
        },
        {
          conceptName: "Money",
          concept: "mny",
          cTest: "mny-slct-coin",
          accuracy: 6,
        },
        {
          conceptName: "Clock",
          concept: "clk",
          cTest: "clk-anlg-idfytime-blw1200",
          accuracy: 6,
        },
      ],
    },
    {
      grade: "Second Grade",
      courseCd: "M2",
      practiceChildId: isLoggedIn && (location.state && location.state.childId) ? location.state.childId : "60fae74602bed02242b2fd5a",
      concepts: [
        {
          conceptName: "Addition",
          concept: "add",
          cTest: "add-blw100-blw50-mltln",
          accuracy: 3,
        },
        {
          conceptName: "Subtraction",
          concept: "sub",
          cTest: "sub-blw100-blw50-mltln",
          accuracy: 3,
        },
        {
          conceptName: "Multiplication",
          concept: "mul",
          cTest: "mul-blw100-blw10-mltln",
          accuracy: 3,
        },
        {
          conceptName: "Even Odd",
          concept: "evenodd",
          cTest: "evenodd-blw100",
          accuracy: 6,
        },
        {
          conceptName: "Compare Numbers",
          concept: "compare",
          cTest: "cmp-blw50-blw50",
          accuracy: 6,
        },
        {
          conceptName: "Mixed Operation",
          concept: "mixopr",
          cTest: "mixopr-blw100-add-blw100-sub-blw100",
          accuracy: 3,
        },
        {
          conceptName: "Tables",
          concept: "multbl",
          cTest: "multbl-rng2to10",
          accuracy: 2,
        },
        {
          conceptName: "Clock",
          concept: "clk",
          cTest: "clk-anlg-idfytime-blw1200",
          accuracy: 6,
        },

      ],
    },
    {
      grade: "Third Grade",
      courseCd: "M3",
      practiceChildId: isLoggedIn && (location.state && location.state.childId) ? location.state.childId : "60fae74602bed02242b2fd5a",
      concepts: [
        {
          conceptName: "Addition",
          concept: "add",
          cTest: "add-blw1000-blw1000-mltln",
          accuracy: 3,
        },
        {
          conceptName: "Subtraction",
          concept: "sub",
          cTest: "sub-blw1000-blw500-mltln",
          accuracy: 3,
        },
        {
          conceptName: "Multiplication",
          concept: "mul",
          cTest: "mul-blw200-rng1to5-mltln",
          accuracy: 2,
        },
        {
          conceptName: "Division",
          concept: "div",
          cTest: "div-blw50-rng1to5-mltln",
          accuracy: 2,
        },
        {
          conceptName: "Clock",
          concept: "clck",
          cTest: "clk-anlg-blw500",
          accuracy: 4,
        },
        {
          conceptName: "Mixed Operation",
          concept: "mixopr",
          cTest: "mixopr-pars-blw100-add-blw100-parc-sub-blw10",
          accuracy: 2,
        },
        {
          conceptName: "Tables",
          concept: "multbl",
          cTest: "multbl-rng2to10",
          accuracy: 2,
        },
        {
          conceptName: "Add & Sub",
          concept: "addsub",
          cTest: "addsub-blw500-blw30",
          accuracy: 4,
        },
        {
          conceptName: "Standard Form",
          concept: "stndrd",
          cTest: "stndrd-blw1000-optns",
          accuracy: 4,
        },
        {
          conceptName: "Addition with Alphabets",
          concept: "add",
          cTest: "add-alpbts-blw1000-blw1000-mltln",
          accuracy: 4,
        },
        {
          conceptName: "Subtraction with Alphabets",
          concept: "sub",
          cTest: "sub-alpbts-blw1000-blw1000-mltln",
          accuracy: 4,
        },

        {
          conceptName: "Adding 3 numbers",
          concept: "add",
          cTest: "addgrp-blw100-blw100-blw100-mltln",
          accuracy: 4,
        },
        {
          conceptName: "Adding 4 numbers",
          concept: "add",
          cTest: "addgrp-blw100-blw100-blw100-blw100-mltln",
          accuracy: 4,
        },
        {
          conceptName: "Select correct Operation",
          concept: "add",
          cTest: "add-soln-blw1000-blw1000-mltln",
          accuracy: 4,
        },
        {
          conceptName: "Adding Money",
          concept: "add",
          cTest: "decadd-mony-blw1000-blw1000-mltln",
          accuracy: 4,
        },
        {
          conceptName: "Elapsed Time",
          concept: "time",
          cTest: "time-elpsd-blw12-blw60-blw12-blw60",
          accuracy: 4,
        },
      ],
    },
    {
      grade: "Fourth Grade",
      courseCd: "M4",
      practiceChildId: isLoggedIn && (location.state && location.state.childId) ? location.state.childId : "60fae74602bed02242b2fd5a",
      concepts: [
        {
          conceptName: "Addition",
          concept: "add",
          cTest: "add-blw1500-blw500-mltln",
          accuracy: 3,
        },
        {
          conceptName: "Subtraction",
          concept: "sub",
          cTest: "sub-blw1500-blw500-mltln",
          accuracy: 3,
        },

        {
          conceptName: "Multiplication",
          concept: "mul",
          cTest: "mul-blw400-rng5to10-mltln",
          accuracy: 2,
        },
        {
          conceptName: "Division",
          concept: "div",
          cTest: "div-blw200-rng5to10-mltln",
          accuracy: 2,
        },
        {
          conceptName: "Tables",
          concept: "multbl",
          cTest: "multbl-rng5to15",
          accuracy: 2,
        },
        {
          conceptName: "Clock",
          concept: "clck",
          cTest: "clk-anlg-blw1200",
          accuracy: 4,
        },
        {
          conceptName: "Round Off",
          concept: "rndoff",
          cTest: "rndoff-nrst10-blw100",
          accuracy: 4,
        },
        {
          conceptName: "Compare Numbers",
          concept: "compare",
          cTest: "cmp-blw100-blw100-eqn",
          accuracy: 6,
        },
        {
          conceptName: "Roman Numeral",
          concept: "rmnltrs",
          cTest: "rmnltrs-txt-rmn-blw100",
          accuracy: 2,
        },
        {
          conceptName: "Convert Hours to Minutes",
          concept: "time",
          cTest: "time-hmny-hr2mi-blw12",
          accuracy: 2,
        },
        {
          conceptName: "Convert Minutes to Hours",
          concept: "time",
          cTest: "time-hmny-mi2hr-blw1000",
          accuracy: 2,
        },
        {
          conceptName: "Convert Minutes to Seconds",
          concept: "time",
          cTest: "time-hmny-mi2se-blw60",
          accuracy: 2,
        },
        {
          conceptName: "Convert Seconds to Minutes",
          concept: "time",
          cTest: "time-hmny-se2mi-blw1000",
          accuracy: 2,
        },
        {
          conceptName: "Convert Years to Months",
          concept: "time",
          cTest: "time-hmny-yr2mn-blw10",
          accuracy: 2,
        },
        {
          conceptName: "Convert Months to Years",
          concept: "time",
          cTest: "time-hmny-mn2yr-blw100",
          accuracy: 2,
        },
        {
          conceptName: "Add Hours & Minutes",
          concept: "time",
          cTest: "time-add-hrmi-blw12-blw60-blw12-blw60",
          accuracy: 2,
        },
        {
          conceptName: "Subtract Hours & Minutes",
          concept: "time",
          cTest: "time-sub-hrmi-blw12-blw60-blw12-blw60",
          accuracy: 2,
        },
        {
          conceptName: "Add Years & Months",
          concept: "time",
          cTest: "time-add-yrmn-blw10-blw12-blw10-blw12",
          accuracy: 2,
        },
        {
          conceptName: "Subtract Years & Months",
          concept: "time",
          cTest: "time-sub-yrmn-blw10-blw12-blw10-blw12",
          accuracy: 2,
        },
        {
          conceptName: "Elapsed Time",
          concept: "time",
          cTest: "time-elpsd-blw12-blw60-blw12-blw60",
          accuracy: 4,
        },
        {
          conceptName: "Decimal Addition",
          concept: "decadd",
          cTest: "decadd-blw20-blw20",
          accuracy: 4,
        },
        {
          conceptName: "GCD",
          concept: "gcd",
          cTest: "gcd-blw10-blw10",
          accuracy: 4,
        },
        {
          conceptName: "LCM",
          concept: "gcd",
          cTest: "lcm-blw10-blw10",
          accuracy: 4,
        },
        {
          conceptName: "Select Fractions",
          concept: "fra",
          cTest: "fra-shpprts-crcl-blw10",
          accuracy: 4,
        },
        {
          conceptName: "Standard Form",
          concept: "stndrd",
          cTest: "stndrd-blw1000-optns",
          accuracy: 3,
        },

      ],
    },
    {
      grade: "Fifth Grade",
      courseCd: "M5",
      practiceChildId: isLoggedIn && (location.state && location.state.childId) ? location.state.childId : "60fae74602bed02242b2fd5a",
      concepts: [
        {
          conceptName: "Expressions",
          concept: "expr",
          cTest: "expo-slct-expr-blw10-blw10",
          accuracy: 5,
        },
        {
          conceptName: "Factors",
          concept: "sub",
          cTest: "fctrs-slct-type-blw100",
          accuracy: 5,
        },

        {
          conceptName: "Multiplication",
          concept: "mul",
          cTest: "mul-rng1000to9999-rng10to25-mltln",
          accuracy: 2,
        },
        {
          conceptName: "Division",
          concept: "div",
          cTest: "div-blw99999-rng5to52-mltln",
          accuracy: 2,
        },
        {
          conceptName: "Standard Form",
          concept: "stndrd",
          cTest: "stndrd-blw99999999",
          accuracy: 3,
        },
        {
          conceptName: "Expand",
          concept: "expnd",
          cTest: "expnd-blw99999999",
          accuracy: 3,
        },
        {
          conceptName: "Roman Letters",
          concept: "rmnltrs",
          cTest: "rmnltrs-txt-nmbr-blw50",
          accuracy: 5,
        },
        {
          conceptName: "Fraction Addition",
          concept: "fra",
          cTest: "fra-num1-by-blw10-add-num1-by-blw10",
          accuracy: 3,
        },
        {
          conceptName: "Fraction Subtraction",
          concept: "fra",
          cTest: "fra-num1-by-blw10-sub-num1-by-blw10",
          accuracy: 3,
        },

        {
          conceptName: "Select Expression for Exponents",
          concept: "expo",
          cTest: "expo-slct-expr-blw10-blw10",
          accuracy: 3,
        },
        {
          conceptName: "Select Value for Exponents",
          concept: "expo",
          cTest: "expo-slct-val-blw10-blw10",
          accuracy: 3,
        },
        {
          conceptName: "Select Exponents",
          concept: "expo",
          cTest: "expo-idfy-expr-blw10-blw10",
          accuracy: 3,
        },
        {
          conceptName: "Select Exponent for Value",
          concept: "expo",
          cTest: "expo-idfy-val-blw10-blw10",
          accuracy: 3,
        },
        {
          conceptName: "Prime Factorization",
          concept: "fctrs",
          cTest: "fctrs-slct-prmfctrs-blw100",
          accuracy: 2,
        },
        {
          conceptName: "Prime / Composite",
          concept: "fctrs",
          cTest: "fctrs-slct-type-blw100",
          accuracy: 2,
        },
        {
          conceptName: "Convert Hours to Minutes",
          concept: "time",
          cTest: "time-hmny-hr2mi-blw12",
          accuracy: 2,
        },
        {
          conceptName: "Convert Minutes to Hours",
          concept: "time",
          cTest: "time-hmny-mi2hr-blw1000",
          accuracy: 2,
        },
        {
          conceptName: "Convert Minutes to Seconds",
          concept: "time",
          cTest: "time-hmny-mi2se-blw60",
          accuracy: 2,
        },
        {
          conceptName: "Convert Seconds to Minutes",
          concept: "time",
          cTest: "time-hmny-se2mi-blw1000",
          accuracy: 2,
        },
        {
          conceptName: "Convert Years to Months",
          concept: "time",
          cTest: "time-hmny-yr2mn-blw10",
          accuracy: 2,
        },
        {
          conceptName: "Convert Months to Years",
          concept: "time",
          cTest: "time-hmny-mn2yr-blw100",
          accuracy: 2,
        },
        {
          conceptName: "Add Hours & Minutes",
          concept: "time",
          cTest: "time-add-hrmi-blw12-blw60-blw12-blw60",
          accuracy: 2,
        },
        {
          conceptName: "Subtract Hours & Minutes",
          concept: "time",
          cTest: "time-sub-hrmi-blw12-blw60-blw12-blw60",
          accuracy: 2,
        },
        {
          conceptName: "Add Years & Months",
          concept: "time",
          cTest: "time-add-yrmn-blw10-blw12-blw10-blw12",
          accuracy: 2,
        },
        {
          conceptName: "Subtract Years & Months",
          concept: "time",
          cTest: "time-sub-yrmn-blw10-blw12-blw10-blw12",
          accuracy: 2,
        },
        {
          conceptName: "Elapsed Time",
          concept: "time",
          cTest: "time-elpsd-blw12-blw60-blw12-blw60",
          accuracy: 4,
        },
        {
          conceptName: "Estimate Quotient",
          concept: "div",
          cTest: "div-qtnt-blw1000-blw10-mltln",
          accuracy: 6,
        },
      ],
    },
    {
      grade: "Sixth Grade",
      courseCd: "M6",
      practiceChildId: isLoggedIn && (location.state && location.state.childId) ? location.state.childId : "60fae74602bed02242b2fd5a",
      concepts: [
        {
          conceptName: "Decimal Addition",
          concept: "decadd",
          cTest: "decadd-blw100-blw100",
          accuracy: 3,
        },
        {
          conceptName: "Decimal Subtraction",
          concept: "decsub",
          cTest: "decsub-blw100-blw50",
          accuracy: 3,
        },

        {
          conceptName: "Fractions Addition",
          concept: "fra",
          cTest: "fra-num1-by-blw10-add-num1-by-blw10",
          accuracy: 4,
        },
        {
          conceptName: "Fractions Subtraction",
          concept: "fra",
          cTest: "fra-num1-by-blw10-sub-num1-by-blw10",
          accuracy: 4,
        },
        {
          conceptName: "Round Off",
          concept: "div",
          cTest: "rndoff-dec-add-blw1000-blw1000",
          accuracy: 5,
        },
        {
          conceptName: "Negative Numbers",
          concept: "mixopr",
          cTest: "mixopr-blw100-add-blw100-ngtv",
          accuracy: 5,
        },
        {
          conceptName: "Multiplication -Ve",
          concept: "expnd",
          cTest: "mixopr-blw100-mul-blw10-ngtv",
          accuracy: 5,
        },
        {
          conceptName: "GCD",
          concept: "gcd",
          cTest: "gcd-blw10-blw10-blw10",
          accuracy: 4,
        },
        {
          conceptName: "LCM",
          concept: "lcm",
          cTest: "lcm-blw30-blw30-blw30",
          accuracy: 3,
        },
        {
          conceptName: "Mixed Operation",
          concept: "mixopr",
          cTest: "mixopr-pars-blw9999-mul-blw9999-parc-add-blw999",
          accuracy: 3,
        },
        {
          conceptName: "Area Circle",
          concept: "ara",
          cTest: "shp-ara-crcl",
          accuracy: 3,
        },
        {
          conceptName: "Perimeter Circle",
          concept: "ara",
          cTest: "shp-permtr-crcl",
          accuracy: 3,
        },
        {
          conceptName: "Three Number Multiplication",
          concept: "ara",
          cTest: "mixopr-blw9999-mul-blw9999-mul-blw9999",
          accuracy: 3,
        },
      ],
    },
  ];
  const handleCourseConcept = (e) => {
    setGrade(e);
    speedData.map((data) => {
      if (data.courseCd === e) {
        setCourseConcept(data);
      }
    });
  };



  return (
    <Container maxWidth="sm">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert
          elevation={6}
          onClose={() => setShowSnackbar(false)}
          severity="error"
        >
          {addChildError}
        </Alert>
      </Snackbar>

      {(isLoggedIn && childInfo && childInfo.grade) ?
        <Grid>
          ChildHomePage

          {!showTestTerms ?
            <Card style={{ marginTop: "15" }}>
              <Grid
                container
                spacing={2}
                style={{ padding: 25, display: "flex", justifyContent: "center" }}
              >
                <Grid
                  item={true}
                  xs={12}
                  style={{
                    marginBottom: 15,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h6">SELECT CONCEPT {srcpage}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    // error={!isValid && Boolean(errors.gender)}
                    style={{ width: "100%" }}
                  >
                    <InputLabel
                      id="demo-simple-select-required-label"
                      style={{ width: "100%" }}
                    >
                      Select Concept
                    </InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      labelId="ctest"
                      id="ctest"
                      name="ctest"
                      label="Concept"
                      value={ctest} //{data.childSecondgrade}
                      onChange={(e) => {
                        console.log("ctest...", e.target.value);
                        setCtest(e.target.value);
                        //handleCourseConceptLogin("M1");

                      }}
                    // onBlur={(e)=>this.onBlur(e,"two")}
                    >
                      {courseConcept && courseConcept.concepts.map((concept) => (
                        <MenuItem value={concept.cTest}>
                          {concept.conceptName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>


                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item xs={4}>
                    <Button
                      variant="outlined"
                      color="error"
                      style={{ width: "100%" }}
                      onClick={() => {
                        console.log("SERVICE NOIW");
                        props.onCancel();
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ width: "100%" }}
                      onClick={() => {
                        console.log("SERVICE NOIW");
                        onStart()
                      }}
                    >
                      START
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card> :
            <Card>
              <AcceptTestTerms />
            </Card>}


        </Grid>
        :
        <Grid>
          Not ChildHomePage
          {!showTestTerms ? <Card style={{ marginTop: "15" }}>
            <Grid
              container
              spacing={2}
              style={{ padding: 25, display: "flex", justifyContent: "center" }}
            >
              <Grid
                item={true}
                xs={12}
                style={{
                  marginBottom: 15,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6">SELECT CONCEPT</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  error={!isValid && Boolean(errors.grade)}
                  style={{ width: "100%" }}
                >
                  <InputLabel
                    id="demo-simple-select-required-label"
                    style={{ width: "100%" }}
                  >
                    Select Grade
                  </InputLabel>
                  <Select
                    style={{ width: "100%" }}
                    labelId="childGrade"
                    id="childGrade"
                    name="childGrade"
                    label="Grade"
                    value={grade} //{data.childSecondgrade}
                    onChange={(e) => {
                      console.log("grade...", grade, e);
                      handleCourseConcept(e.target.value);
                    }}
                  // onBlur={(e)=>this.onBlur(e,"two")}
                  >
                    {speedData.map((gradesList) => {
                      return (
                        <MenuItem value={gradesList.courseCd}>
                          {gradesList.grade}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {courseConcept && (
                <Grid item xs={12}>
                  <FormControl
                    error={!isValid && Boolean(errors.gender)}
                    style={{ width: "100%" }}
                  >
                    <InputLabel
                      id="demo-simple-select-required-label"
                      style={{ width: "100%" }}
                    >
                      Select Concept
                    </InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      labelId="ctest"
                      id="ctest"
                      name="ctest"
                      label="Concept"
                      value={ctest} //{data.childSecondgrade}
                      onChange={(e) => {
                        console.log("ctest...", e.target.value);
                        setCtest(e.target.value);
                      }}
                    // onBlur={(e)=>this.onBlur(e,"two")}
                    >
                      {courseConcept.concepts.map((concept) => (
                        <MenuItem value={concept.cTest}>
                          {concept.conceptName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid item xs={4}>
                  <Button
                    variant="outlined"
                    color="error"
                    style={{ width: "100%" }}
                    onClick={() => {
                      console.log("SERVICE NOIW");
                      props.onCancel();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item={true} xs={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ width: "100%" }}
                    onClick={() => {
                      console.log("SERVICE NOIW");
                      onStart()
                    }}
                  >
                    START
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Card> :
            <Card>
              <AcceptTestTerms />
            </Card>}
        </Grid>

      }
    </Container>
  );
};
export default SelectCourse;
