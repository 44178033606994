import {
  
  Grid,
  
} from "@material-ui/core";
import React, { useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import * as parentActions from "../../../actions/parent.actions";

import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Subjects from "../Subjects";
import practiceConceptsData from "./../../../mock/practiceConcepts.json";
import SubscriptionList from "../Subscriptions/Subscriptions";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(18, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

// const products = [
//   {
//     name: 'Canlye - Multipurpose React Template',
//     badge: 'trending',
//     rating: 4.2,
//     image: image1,
//     author: 'Wpoceans',
//     price: '$49.00',
//     review: '05',
//     id: 1
//   },
//   {
//     name: 'Sportyy - Gym & Sports Template',
//     badge: 'featured',
//     rating: 5,
//     image: image2,
//     author: 'Wpoceans',
//     price: '$39.00',
//     review: '105',
//     id: 2
//   },
//   {
//     name: 'Meditouch - Medical Landing Page',
//     badge: 'new',
//     rating: 4,
//     image: image3,
//     author: 'Wpoceans',
//     price: '$29.00',
//     review: '50',
//     id: 3
//   },
//   {
//     name: 'Sportyy - Gym & Sports Template',
//     badge: 'featured',
//     rating: 4.5,
//     image: image4,
//     author: 'Wpoceans',
//     price: '$12.00',
//     review: '25',
//     id: 4
//   },
//   {
//     name: 'Neon - Multipurpose Wordpress Template',
//     badge: 'new',
//     rating: 1.5,
//     image: image5,
//     author: 'Wpoceans',
//     price: '$25.00',
//     review: '87',
//     id: 5
//   },
//   {
//     name: 'Lavelo - Wedding Wordpress Template',
//     badge: 'trending',
//     rating: 5,
//     image: image6,
//     author: 'Wpoceans',
//     price: '$150.00',
//     review: '8774',
//     id: 6
//   },
// ]

const CoursePracticeList = () => {
  const [courseConcept, setCourseConcept] = useState("");
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [selectedChildId, setSelectedChildId] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(parentActions.getParentInfo(""));
  }, []);

  const speedTestSubmit = (
    childId,
    hwDate,
    subjectCd,
    numOfQuestionsPerDay,
    userType,
    course
  ) => {
    console.log("COURSE ", course);
    history.push({
      pathname: "/guest/speedtest",
      state: {
        childId: childId,
        hwDate: "-",
        subjectCd: subjectCd,
        numOfQuestionsPerDay: numOfQuestionsPerDay,
        userType: userType,
        ctest: course.cTest,
        courseName: course.conceptName,
        accuracy: course.accuracy,
        pageSrc: "CoursePractice",
      },
    });
  };

  const onSubmit = (concept, mathCourseId, practiceChildId) => {
    setCourseConcept(concept);
    setSelectedCourseId(mathCourseId);
    setSelectedChildId(practiceChildId);
    history.push({
      pathname: "/guest/child/homework",
      state: {
        childId: selectedChildId,
        hwDate: "2021-08-08",
        subjectCd: selectedCourseId,
        numOfQuestionsPerDay: 60,
        userType: "guest",
        ctest: courseConcept.cTest,
      },
    });
  };

  return (
    <main>
      <Subjects practiceConceptsData={practiceConceptsData} />
      {/* <Fragment>
                    {products.map((product, i) => (
                        <Grid key={i} item lg={4} sm={6} xs={12}>
                            <SingleProduct
                                id={product.id}
                                badge={product.badge}
                                name={product.name}
                                rating={product.rating}
                                image={product.image}
                                author={product.author}
                                review={product.review}
                                price={product.price}
                            />
                        </Grid>
                    ))}
                </Fragment> */}
      <Grid className="productArea ptb-104">
        <Grid container spacing={3} className="container">
          <SubscriptionList />
        </Grid>
      </Grid>
    </main>
  );
};

export default CoursePracticeList;
