export const brainBustersConstants = {
    GET_BRAIN_BUSTERS_LIST_REQUEST: 'GET_BRAIN_BUSTERS_LIST_REQUEST',
    GET_BRAIN_BUSTERS_LIST_SUCCESS: 'GET_BRAIN_BUSTERS_LIST_SUCCESS',
    GET_BRAIN_BUSTERS_LIST_FAILURE: 'GET_BRAIN_BUSTERS_LIST_FAILURE',

    GET_NEW_BRAIN_BUSTERS_REQUEST: 'GET_NEW_BRAIN_BUSTERS_REQUEST',
    GET_NEW_BRAIN_BUSTERS_SUCCESS: 'GET_NEW_BRAIN_BUSTERS_SUCCESS',
    GET_NEW_BRAIN_BUSTERS_FAILURE: 'GET_NEW_BRAIN_BUSTERS_FAILURE',

    SUBMIT_NEW_BRAIN_BUSTERS_REQUEST: 'SUBMIT_NEW_BRAIN_BUSTERS_REQUEST',
    SUBMIT_NEW_BRAIN_BUSTERS_SUCCESS: 'SUBMIT_NEW_BRAIN_BUSTERS_SUCCESS',
    SUBMIT_NEW_BRAIN_BUSTERS_FAILURE: 'SUBMIT_NEW_BRAIN_BUSTERS_FAILURE',

    SET_BB_ANSWER: 'SET_BB_ANSWER',

}