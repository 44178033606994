import React from 'react';
import { Grid, Link } from '@material-ui/core';
import './style.scss'
import { useHistory } from 'react-router-dom'

const PracticeList = (props) => {

    const history = useHistory();

    return (
        <Grid className="itemDetails">
            <h3>{props.title}</h3>
            <ul>
                {props.practiceList && props.practiceList.map(p =>
                    <li><Link onClick={() => {
                        console.log("PUSHING TZO HISTORY", props.practiceChildId, props.numOfQuestionsPerDay, p.cTest, props.subjectCd)
                        history.push({
                            pathname: "/guest/child/homework",
                            state: {
                                childId: props.practiceChildId,
                                numOfQuestionsPerDay: props.numOfQuestionsPerDay,
                                userType: "guest",
                                ctest: p.cTest,
                                subjectCd: props.subjectCd,
                                hwDate: "11-27-2021"
                            },
                        });
                    }}>{p.conceptName}</Link></li>
                )}
            </ul>
        </Grid>
    );
}

export default PracticeList;
