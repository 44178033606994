import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Dialog,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Speech from "../../../util/speech";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    fontSize: 40,
    textAlign: "center",
  },
}));

const Homonyms = (props) => {
  const classes = useStyles({});
  const theme = useTheme();

  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentQuestionId, setCurrentQuestionId] = useState(undefined);
  const [disableAnswer, setDisableAnswer] = useState(false);
  const [displayAnswer, setDisplayAnswer] = useState("");
  const [answer, setAnswer] = useState("");
  const [maxNumberLength, setMaxNumberLength] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [studentAnswer, setStudentAnswer] = useState("");
  const [showHands, setShowHands] = useState(false);
  const [question, setQuestion] = useState("");
  const [showHelp, setShowHelp] = useState(false);
  const [open, setOpen] = useState(false);
  const [toastMessage, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    if (
      props.currentHomeWork &&
      props.currentHomeWork.questionId &&
      props.currentHomeWork.questionId !== currentQuestionId
    ) {
      setStudentAnswer(
        props.currentHomeWork.studentAnswer
          ? props.currentHomeWork.studentAnswer
          : ""
      );
      setCurrentQuestionId(props.currentHomeWork.questionId);
      setShowHands(true);
      setQuestion(props.currentHomeWork.firstVar);
      setAttemptCount(0);
      setAnswer(
        props.currentHomeWork.finalAnswer === "1"
          ? props.currentHomeWork.studentAnswer
          : ""
      );
      setDisableAnswer(
        props.currentHomeWork.finalAnswer === "1" ? true : false
      );
      setDisplayAnswer(
        props.currentHomeWork.finalAnswer === "1"
          ? props.currentHomeWork.answer
          : ""
      );

      setShowSpinner(false);
    }
  }, [props.currentHomeWork]);

  const isAnswerValid = () => {
    var isValid = false;
    console.log("VALIDATING ANSWER...", answer);
    if (answer){
       isAnswerCorrect();
      }
    else{
        setMessage("Invalid Answer!. Enter Value.")
        setMessageType("warning")
        handleClick() 
    }
  };

  const isAnswerCorrect = () => {
    var isCorrect = false;
    console.log("CHECKING ANSWER", answer, props.currentHomeWork.answer);
    const answerString = answer;
    console.warn("answerString ", answerString);
    isCorrect =
      answerString &&
      answerString.length > 0 &&
      answerString === props.currentHomeWork.answer;
    if (isCorrect) {
      console.log("IS CORRECT, GO TO NEXT QUESTION");
      props.onSubmitQuestion(
        answerString,
        attemptCount + 1,
        true,
        props.currentHomeWork.questionId
      );
      setMessage("Correct Answer!. Good job.")
      setMessageType("success")
      handleClick()
    } else {
      console.log("WRONG ANSWER ");
      setAttemptCount(attemptCount + 1);

      if (attemptCount >= props.currentHomeWork.numOfAttemptsAllowed - 1) {
        //props.onShowToast("Wrong Answer!. Next Question.", "danger");
        setMessage("Wrong Answer!. Try Next Question.")
        setMessageType("error")
        handleClick()
        props.onSubmitQuestion(
          answerString,
          attemptCount,
          false,
          props.currentHomeWork.questionId
        );
      } else {
        // props.onShowToast("Wrong Answer!. Try Again.", "warning");
        setMessage("Wrong Answer!. Try Again.")
        setMessageType("warning")
        handleClick()
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const onSelection = (val) => {
    setAnswer(val);
  };

  const rendorOptions = () => {
    console.log(
      "props.currentHomeWork.strList === ",
      props.currentHomeWork.strList
    );

    return (
      <div style={{ display: "inline-flex", flexDirection: "row" }}>
        {props.currentHomeWork.strAnsList.map((item, index) => {
          return (
            <div key={`answer-${index}-${answer[index]}`}>
              <Button
                variant={disableAnswer ? "outlined" : "contained"}
                color={
                  disableAnswer
                    ? item === props.currentHomeWork.answer
                      ? "default"
                      : "secondary"
                    : answer === item
                    ? "inherit"
                    : "primary"
                }
                style={{
                  width:
                    props.currentHomeWork.concept === "ltrs" ||
                    props.currentHomeWork.concept === "chse"
                      ? 60
                      : 150,
                  borderWidth: 2,
                  borderRadius: 5,
                  paddingVertical: 20,
                  justifyContent: "center",
                  margin: 5,
                  textTransform: "none",
                }}
                onClick={() => onSelection(item)}
              >
                {item}
              </Button>
            </div>
          );
        })}
      </div>
    );
  };

  const clearAnswer = () => {
    setAnswer("");
    console.log("CLEAR ANSWER CALLED");
  };

  const addAnswerToState = (event) => {
    setAnswer(event.target.value);
  };

  const toggleHelpModal = (flag) => {
    setShowHelpModal(flag);
  };

  return (
    <Grid
      container
      style={{
        padding: 25,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid container style={{ padding: 16 }}>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => clearAnswer()}
            style={{ marginRight: 16 }}
            disabled={disableAnswer}
          >
            CLEAR
          </Button>
          <Button variant="outlined" color="primary" onClick={isAnswerValid}>
            {disableAnswer ? "NEXT" : "SUBMIT"}
          </Button>
          <div
            style={{ marginLeft: 16, display: "flex", alignItems: "center" }}
          >
            {disableAnswer
              ? ""
              : attemptCount > 0
              ? "Attempts Remaining: " +
                (props.currentHomeWork.numOfAttemptsAllowed - attemptCount)
              : "Attempts Remaining: " +
                props.currentHomeWork.numOfAttemptsAllowed}
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignSelf: "flex-start",
            flexDirection: mobileDevice ? "column" : "row",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowHelp(true)}
            style={{ marginRight: 16 }}
          >
            HELP
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                Speech(props.currentHomeWork.voiceOver);
              }
            }}
          >
            READ OUT
          </Button>
          </Grid>
      </Grid>
      <Grid container style={{ flexDirection: "column" }}>
        {props.currentHomeWork.concept === "ltrs" ? (
          <div style={{ fontSize: 30, margin: 10, alignSelf: "center" }}>
            <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (props.currentHomeWork && props.currentHomeWork.voiceOver) {
                Speech(props.currentHomeWork.voiceOver);
              }
            }}
          >
            READ OUT
          </Button>
          </div>
        ) : (
          <div style={{ fontSize: 30, margin: 10, alignSelf: "center" }}>
            {/* ---------------------------- QUESTION TEXT VIEW - ITEM  1 ---------------------------- */}
            {props.currentHomeWork.question}
          </div>
        )}
        { props.currentHomeWork.secondVar &&
                                        <div style={{ width: 200, height: 200 ,alignSelf:'center'}} >
                                            <img
                                                /*source={{
                                                    uri: urlStringSample + item.toLowerCase() + '.png'
                                                }}
                                                */
                                                src={props.currentHomeWork.secondVar}
                                                style={{
                                                    flex: 1,
                                                    width: '100%',
                                                    height: '100%',
                                                    resizeMode: 'contain',
                                                    
                                                    //marginLeft: 280,
                                                }}
                                            />
                                        </div>
                                    }
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 25, display: "flex", flexDirection: "row" }}>
            {/* ---------------------------- QUESTION TEXT VIEW - ITEM  1 ---------------------------- */}
            <div style={{ flexDirection: "column", alignSelf: "center" }}>
              {props.currentHomeWork.strList &&
                props.currentHomeWork.strList.map((sntns) => {
                  return (
                    <div
                      style={{
                        marginLeft: 20,
                        marginTop: 10,
                        alignSelf: "center",
                        flexDirection: "row",
                        fontSize: 35,
                        textAlign: "center",
                      }}
                    >
                      {sntns}
                    </div>
                  );
                })}
            </div>
          </div>
          {props.currentHomeWork.qType !== "hmny" ? (
            rendorOptions()
          ) : (
            <TextField
              variant="outlined"
              style={{
                fontSize: 30,
                width: 250,
                textAlign: "center",
                borderColor: "green",
                borderWidth: 2,
                color: "black",
                marginTop: 20,
              }}
              value={answer}
              InputProps={{
                classes: {
                  input: classes.input,
                },
              }}
              multiline={false}
              disabled={disableAnswer}
              onChange={addAnswerToState}
            ></TextField>
          )}
        </div>
        <div
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {/* ------------- ANSWER IMAGE RIGHT OR WRONG */}
          {/* <div style={{ flexDirection: "row", alignSelf: "center" }}>
                  {disableAnswer && props.currentHomeWork.isRightAnswer && (
                    <CorrectImage />
                  )}
        
                  {disableAnswer && !props.currentHomeWork.isRightAnswer && (
                    <WrongImage />
                  )}
                </div> */}
          {
            // ------------------   CORRECT ANSWER
            disableAnswer && !props.currentHomeWork.isRightAnswer && (
              <div
                style={{
                  alignSelf: "center",
                  marginTop: 20,
                  marginLeft: 50,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ fontSize: 24 }}>Correct Answer :{"   "}</div>
                <div style={{ fontSize: 48 }}>{displayAnswer}</div>
              </div>
            )
          }
        </div>
        <Snackbar
       open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert 
        key={`toastMessage-${toastMessage}`}
        onClose={handleClose} 
        severity={messageType}>
          {toastMessage}
        </Alert>
      </Snackbar>
        <Dialog
          onClose={() => setShowHelp(false)}
          open={showHelp}
          maxWidth={false}
        >
          <div style={{ padding: 16 }}>
            <Typography variant="h4">Help</Typography>
            <div>
              Homonym are two words that are spelled the same and sound the same
              but have different meanings.
            </div>
            <div>Examples:</div>
            <div>Address - to speak to / location</div>
            <div>
              Bat - an implement used to hit a ball / a nocturnal flying mammal
            </div>
            <div>Kind - type / caring</div>
            <div>Lie - to recline / to tell a falsehood</div>
            <div>Match - to pair like items / a stick for making a flame</div>
          </div>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default Homonyms;
