import React from 'react';
import { Grid } from '@material-ui/core'
import './style.scss'
const SectionTitle = ({ title, subtitle }) => {
    return (
        <Grid className="sectionTitle">
            <h2>{title}</h2>
           {subtitle &&  <p>{subtitle}</p>}
        </Grid>
    );
}

export default SectionTitle
