
import MasterCardLogo from "../assets/images/MasterCard_Logo.svg"
import VisaLogo from "../assets/images/Visa_logo.svg"
import DiscoverLogo from "../assets/images/discover_logo.svg"
import AmexLogo from "../assets/images/American_Express_Logo.svg"
import DefaultCardLogo from "../assets/images/default_card.png"

export const getCardLogo = (brand) => {
    switch (brand) {
        case "MasterCard":
            return MasterCardLogo
        case "Visa":
            return VisaLogo
        case "amex":
            return AmexLogo
        case "discover":
            return DiscoverLogo
        default:
            return DefaultCardLogo
    }
}