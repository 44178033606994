export const languagePracticeConcepts = [
    {
        title: "Pre-K",

        numOfQuestionsPerDay: 40,
        courseId: "RP",
        concepts: [
            {
                conceptName: "Letters",
                concept: "ltrs",
                cTest: "ltrs-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Upper case letter",
                concept: "ltrs",
                cTest: "chse-uppr-any",
                accuracy: 5,
            },
            {
                conceptName: "Lower case letter",
                concept: "ltrs",
                cTest: "chse-lowr-any",
                accuracy: 5,
            },
            {
                conceptName: "Words",
                concept: "wrds",
                cTest: "chse-wrd-any",
                accuracy: 5,
            },
            {
                conceptName: "Syllables",
                concept: "sylb",
                cTest: "sylb-hmny",
                accuracy: 5,
            },
            // {
            //   conceptName: "Rhyming words",
            //   concept: "rhy",
            //   cTest: "chse-rhy",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Sounding words",
            //   concept: "snd",
            //   cTest: "chse-snd-frst",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Sounding end words",
            //   concept: "snd",
            //   cTest: "chse-snd-lst",
            //   accuracy: 5,
            // },
            {
                conceptName: "Synonyms",
                concept: "syn",
                cTest: "syn-wrd",
                accuracy: 5,
            },
            {
                conceptName: "Antonyms",
                concept: "ant",
                cTest: "ant-wrd",
                accuracy: 5,
            },
        ],
    },
    {
        title: "Kindergarten",

        numOfQuestionsPerDay: 40,
        courseId: "RK",
        concepts: [
            {
                conceptName: "Letters",
                concept: "ltrs",
                cTest: "ltrs-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Upper case letter",
                concept: "ltrs",
                cTest: "chse-uppr-any",
                accuracy: 5,
            },
            {
                conceptName: "Lower case letter",
                concept: "ltrs",
                cTest: "chse-lowr-any",
                accuracy: 5,
            },
            {
                conceptName: "Words",
                concept: "wrds",
                cTest: "chse-wrd-any",
                accuracy: 5,
            },
            {
                conceptName: "Syllables",
                concept: "sylb",
                cTest: "sylb-hmny",
                accuracy: 5,
            },
            {
                conceptName: "Synonyms",
                concept: "syn",
                cTest: "syn-wrd",
                accuracy: 5,
            },
        ],
    },
    {
        title: "First Grade",

        numOfQuestionsPerDay: 40,
        courseId: "R1",
        concepts: [
            {
                conceptName: "Letters",
                concept: "ltrs",
                cTest: "ltrs-slct-any",
                accuracy: 5,
            },
            {
                conceptName: "Upper case letter",
                concept: "ltrs",
                cTest: "chse-uppr-any",
                accuracy: 5,
            },
            {
                conceptName: "Lower case letter",
                concept: "ltrs",
                cTest: "chse-lowr-any",
                accuracy: 5,
            },
            {
                conceptName: "Words",
                concept: "wrds",
                cTest: "chse-wrd-any",
                accuracy: 5,
            },
            {
                conceptName: "Syllables",
                concept: "sylb",
                cTest: "sylb-hmny",
                accuracy: 5,
            },
            {
                conceptName: "Synonyms",
                concept: "syn",
                cTest: "syn-wrd",
                accuracy: 5,
            },
            {
                conceptName: "Spell Words",
                concept: "wrds",
                cTest: "spel-wrd",
                accuracy: 5,
            },
            // {
            //   conceptName: "Noun",
            //   concept: "nou",
            //   cTest: "chse-nou-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Noun",
            //   concept: "nou",
            //   cTest: "nou-idfy",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Verb",
            //   concept: "verb",
            //   cTest: "veb-any",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Verb",
            //   concept: "verb",
            //   cTest: "veb-idfy",
            //   accuracy: 5,
            // },
            {
                conceptName: "Listen Words",
                concept: "wrds",
                cTest: "lisn-wrd",
                accuracy: 5,
            },
            // {
            //   conceptName: "Homophone",
            //   concept: "hompne",
            //   cTest: "hompne-idfy",
            //   accuracy: 5,
            // },

            // {
            //   conceptName: "Homophone",
            //   concept: "hompne",
            //   cTest: "hompne-slct",
            //   accuracy: 5,
            // },
            {
                conceptName: "Prefix",
                concept: "prfx",
                cTest: "chse-prfx",
                accuracy: 5,
            },
            {
                conceptName: "Suffix",
                concept: "prfx",
                cTest: "chse-sfx",
                accuracy: 5,
            },
        ],
    },
    {
        title: "Second Grade",

        numOfQuestionsPerDay: 40,
        courseId: "R2",
        concepts: [
            {
                conceptName: "Complete word",
                concept: "wrds",
                cTest: "cmpl-wrd",
                accuracy: 5,
            },
            // {
            //   conceptName: "Complete silent e word",
            //   concept: "wrds",
            //   cTest: "cmpl-silt-e-wrd",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Complete vowel word",
            //   concept: "wrds",
            //   cTest: "cmpl-vwlt-wrd",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Complete var vowel word",
            //   concept: "wrds",
            //   cTest: "cmpl-wrd-var-vwl",
            //   accuracy: 5,
            // },
            {
                conceptName: "Silent e word",
                concept: "wrds",
                cTest: "chse-silt-e-wrd",
                accuracy: 5,
            },
            {
                conceptName: "Story",
                concept: "smplstry",
                cTest: "smplstry-qstns",
                accuracy: 5,
            },
            {
                conceptName: "Spell Words",
                concept: "wrds",
                cTest: "spel-wrd",
                accuracy: 5,
            },
            {
                conceptName: "Listen Words",
                concept: "wrds",
                cTest: "lisn-wrd",
                accuracy: 5,
            },
            // {
            //   conceptName: "Homonym",
            //   concept: "homnym",
            //   cTest: "homnym-chse",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Prefix",
            //   concept: "prfx",
            //   cTest: "chse-prfx",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Suffix",
            //   concept: "prfx",
            //   cTest: "chse-sfx",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Homophone",
            //   concept: "hompne-pic",
            //   cTest: "idfy-hompne",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Noun",
            //   concept: "nou",
            //   cTest: "chse-idfy-any",
            //   accuracy: 5,
            // },
        ],
    },
    {
        title: "Third Grade",

        numOfQuestionsPerDay: 40,
        courseId: "R3",
        concepts: [
            {
                conceptName: "Story",
                concept: "smplstry",
                cTest: "smplstry-qstns",
                accuracy: 5,
            },
            // {
            //   conceptName: "Listen Words",
            //   concept: "lisn",
            //   cTest: "lisn-wrd",
            //   accuracy: 5,
            // },
            {
                conceptName: "Homonyms",
                concept: "homnym",
                cTest: "homnym-wrd",
                accuracy: 5,
            },
            {
                conceptName: "Prefix",
                concept: "prfx",
                cTest: "chse-prfx",
                accuracy: 5,
            },
            {
                conceptName: "Suffix",
                concept: "sfx",
                cTest: "chse-sfx",
                accuracy: 5,
            },
            {
                conceptName: "Synonyms",
                concept: "syn",
                cTest: "syn-wrd",
                accuracy: 5,
            },
            {
                conceptName: "Antonyms",
                concept: "ant",
                cTest: "ant-wrd",
                accuracy: 5,
            },
            {
                conceptName: "Abbreviations",
                concept: "abre",
                cTest: "abre-slct",
                accuracy: 5,
            },
        ],
    },
    {
        title: "Fourth Grade",

        numOfQuestionsPerDay: 40,
        courseId: "R4",
        concepts: [
            {
                conceptName: "Story",
                concept: "smplstry",
                cTest: "smplstry-qstns",
                accuracy: 5,
            },
            // {
            //   conceptName: "Homonyms",
            //   concept: "homnym",
            //   cTest: "homnym-wrd",
            //   accuracy: 5,
            // },
            {
                conceptName: "Homophones",
                concept: "hompne",
                cTest: "hompne-slct",
                accuracy: 5,
            },
            {
                conceptName: "Select Homophones",
                concept: "hompne",
                cTest: "hompne-idfy",
                accuracy: 5,
            },
            {
                conceptName: "Prefix",
                concept: "prfx",
                cTest: "chse-prfx",
                accuracy: 5,
            },
            {
                conceptName: "Suffix",
                concept: "sfx",
                cTest: "chse-sfx",
                accuracy: 5,
            },
            {
                conceptName: "Synonyms",
                concept: "syn",
                cTest: "syn-wrd",
                accuracy: 5,
            },
            {
                conceptName: "Antonyms",
                concept: "ant",
                cTest: "ant-wrd",
                accuracy: 5,
            },
            // {
            //   conceptName: "Noun",
            //   concept: "nou",
            //   cTest: "nou-slct-any",
            //   accuracy: 5,
            // },
        ],
    },
    {
        title: "Fifth Grade",

        numOfQuestionsPerDay: 40,
        courseId: "R5",
        concepts: [
            // {
            //   conceptName: "Story",
            //   concept: "smplstry",
            //   cTest: "smplstry-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Homonyms",
            //   concept: "homnym",
            //   cTest: "homnym-wrd",
            //   accuracy: 5,
            // },
            {
                conceptName: "Homophones",
                concept: "hompne",
                cTest: "hompne-slct",
                accuracy: 5,
            },
            {
                conceptName: "Select Homophones",
                concept: "hompne",
                cTest: "hompne-idfy",
                accuracy: 5,
            },
            {
                conceptName: "Prefix",
                concept: "prfx",
                cTest: "chse-prfx",
                accuracy: 5,
            },
            {
                conceptName: "Suffix",
                concept: "sfx",
                cTest: "chse-sfx",
                accuracy: 5,
            },
            {
                conceptName: "Synonyms",
                concept: "syn",
                cTest: "syn-wrd",
                accuracy: 5,
            },
            {
                conceptName: "Antonyms",
                concept: "ant",
                cTest: "ant-wrd",
                accuracy: 5,
            },
            {
                conceptName: "Nouns",
                concept: "nou",
                cTest: "nou-slct",
                accuracy: 5,
            },
            {
                conceptName: "Prepositions",
                concept: "prep",
                cTest: "prep-idfy",
                accuracy: 5,
            },
            {
                conceptName: "Select Prepositions",
                concept: "prep",
                cTest: "prep-slct",
                accuracy: 5,
            },
            {
                conceptName: "Conjunction",
                concept: "conjun",
                cTest: "conjun-idfy",
                accuracy: 5,
            },
            // {
            //   conceptName: "Pronouns in Sentence",
            //   concept: "pron",
            //   cTest: "pron-idfy-any",
            //   accuracy: 5,
            // },
            {
                conceptName: "Select Pronouns",
                concept: "pron",
                cTest: "pron-slct-any",
                accuracy: 5,
            },
        ],
    },
    {
        title: "Sixth Grade",

        numOfQuestionsPerDay: 40,
        courseId: "R6",
        concepts: [
            // {
            //   conceptName: "Story",
            //   concept: "smplstry",
            //   cTest: "smplstry-qstns",
            //   accuracy: 5,
            // },
            // {
            //   conceptName: "Homonyms",
            //   concept: "homnym",
            //   cTest: "homnym-wrd",
            //   accuracy: 5,
            // },
            {
                conceptName: "Homophones",
                concept: "hompne",
                cTest: "hompne-slct",
                accuracy: 5,
            },
            {
                conceptName: "Select Homophones",
                concept: "hompne",
                cTest: "hompne-idfy",
                accuracy: 5,
            },
            {
                conceptName: "Prefix",
                concept: "prfx",
                cTest: "chse-prfx",
                accuracy: 5,
            },
            {
                conceptName: "Suffix",
                concept: "sfx",
                cTest: "chse-sfx",
                accuracy: 5,
            },
            {
                conceptName: "Synonyms",
                concept: "syn",
                cTest: "syn-wrd",
                accuracy: 5,
            },
            {
                conceptName: "Antonyms",
                concept: "ant",
                cTest: "ant-wrd",
                accuracy: 5,
            },
            {
                conceptName: "Nouns",
                concept: "nou",
                cTest: "nou-slct",
                accuracy: 5,
            },
            {
                conceptName: "Prepositions",
                concept: "prep",
                cTest: "prep-idfy",
                accuracy: 5,
            },
            {
                conceptName: "Select Prepositions",
                concept: "prep",
                cTest: "prep-slct",
                accuracy: 5,
            },
            {
                conceptName: "Conjunction",
                concept: "conjun",
                cTest: "conjun-idfy",
                accuracy: 5,
            },
            // {
            //   conceptName: "Pronouns in Sentence",
            //   concept: "pron",
            //   cTest: "pron-idfy-any",
            //   accuracy: 5,
            // },
            {
                conceptName: "Select Pronouns",
                concept: "pron",
                cTest: "pron-slct-any",
                accuracy: 5,
            },
        ],
    },
]