import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { cloneDeep } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
  getIsSendMessageSuccess,
  getSendMessageError,
} from "../../../reducer/selectors/login.entity";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import * as actions from "../../../actions";
import { Snackbar } from "@material-ui/core";
import successPNG from "../../../assets/images/success-check-mark.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  avatarSuccess: {
    margin: theme.spacing(1),
    backgroundColor: "green",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ContactUs() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const isSendMessageSuccess = useSelector(getIsSendMessageSuccess);
  const sendMessageError = useSelector(getSendMessageError);

  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState({
    firstname: "",
    email: "",
    message: "",
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    if (isSendMessageSuccess) {
      setFirstname("");
      setEmail("");
      setMessage("");
      setErrors({
        firstname: "",
        email: "",
        message: "",
      });
      setShowConfirmation(true);
      setTimeout(() => {
        setShowConfirmation(false);
      }, 3000);
    }
  }, [isSendMessageSuccess]);

  useEffect(() => {
    if (sendMessageError) {
      showSnackbar(true);
    }
  }, [sendMessageError]);

  const onSubmit = () => {
    const valid = validate();
    console.log("onSubmit contact us === ", firstname, email, message);
    if (valid) {
      dispatch(actions.sendMessage({ firstname, email, message }));
    }
  };

  const validate = () => {
    let isCurrentValid = true;
    const currentErrors = cloneDeep(errors);
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (firstname && firstname.length > 0) {
      isCurrentValid = isCurrentValid && true;
      currentErrors.firstname = "";
    } else {
      console.log("FRST ", firstname);
      isCurrentValid = isCurrentValid && false;
      currentErrors.firstname = "Please provide your first name";
    }

    if (email && emailRegex.test(email)) {
      isCurrentValid = isCurrentValid && true;
      currentErrors.email = "";
    } else {
      isCurrentValid = isCurrentValid && false;
      currentErrors.email = "Please provide a valid email";
    }

    if (message && message.length > 0) {
      isCurrentValid = isCurrentValid && true;
      currentErrors.firstname = "";
    } else {
      console.log("message ", message);
      isCurrentValid = isCurrentValid && false;
      currentErrors.message = "Please write your message";
    }

    setIsValid(isCurrentValid);
    setErrors(currentErrors);
    return isCurrentValid;
  };

  return (
    <Container component="main" maxWidth="xs">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert
          elevation={6}
          onClose={() => setShowSnackbar(false)}
          severity="error"
        >
          {sendMessageError}
        </Alert>
      </Snackbar>
      <CssBaseline />
      {showConfirmation ? (
        <div className={classes.paper}>
          <Avatar className={classes.avatarSuccess}>
            <FeedbackOutlinedIcon />
          </Avatar>
          <img src={successPNG} style={{ width: 150 }} />
          Thank you. We appreciate you reaching out to us.
        </div>
      ) : (
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <FeedbackOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Write to us
          </Typography>
          <form className={classes.form} noValidate>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item xs={8} style={{ paddingRight: 5 }}>
                <TextField
                  error={!isValid && Boolean(errors.firstname)}
                  style={{ width: "100%" }}
                  required
                  id="firstname"
                  type="firstname"
                  label="First name"
                  variant="outlined"
                  onChange={(e) => {
                    setFirstname(e.target.value);
                  }}
                  value={firstname}
                  helperText={!isValid && errors.firstname}
                />
              </Grid>
              <Grid item={true} xs={8} style={{ paddingLeft: 5 }}>
                <TextField
                  error={!isValid && Boolean(errors.email)}
                  style={{ width: "100%" }}
                  required
                  id="email"
                  type="email"
                  label="Email"
                  variant="outlined"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  helperText={!isValid && errors.email}
                />
              </Grid>
            </Grid>
            <TextField
              error={!isValid && Boolean(errors.message)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="message"
              label="Message"
              type="message"
              id="message"
              multiline
              rows={4}
              value={message}
              helperText={!isValid && errors.message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />

            <Button
              variant="outlined"
              color="primary"
              style={{ width: "100%" }}
              onClick={() => {
                console.log("Contact Us");
                onSubmit();
              }}
            >
              Send Message
            </Button>
          </form>
        </div>
      )}
      <Box mt={8}></Box>
    </Container>
  );
}
