import React, { useState } from 'react';
import { Grid, Menu, Button, TextField, InputAdornment} from '@material-ui/core'
import ScrollArea from 'react-scrollbar';

import { Link, NavLink } from 'react-router-dom'
import './style.scss'

// images 
import cart1 from './../../../images/cart/img1.jpg'
import cart2 from './../../../images/cart/img2.jpg'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

import {
    useHistory,
    useLocation,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { attemptLogout } from "./../../../actions/login.actions";


const carts = [
    {
        image: cart1,
        name: 'Brown Leather Boots',
        value: '145.10',
        id: 1
    },
    {
        image: cart2,
        name: 'Headphones Pryma',
        value: '15.10',
        id: 2
    },
    {
        image: cart1,
        name: 'Brown Leather Boots',
        value: '145.10',
        id: 3
    },
    {
        image: cart2,
        name: 'Headphones Pryma',
        value: '15.10',
        id: 4
    },
    {
        image: cart1,
        name: 'Brown Leather Boots',
        value: '145.10',
        id: 5
    },
    {
        image: cart2,
        name: 'Headphones Pryma',
        value: '15.10',
        id: 6
    },
]

const myStyle = {
    // borderRadius: "50%",
    width: "40px",
    height: "40px",
    marginBottom: 0
};

export function Header(props) {

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const menus = [
        {
            name: 'Home',
            submenu: [
                {
                    name: 'Home Style 1',
                    link: '/'
                },
                {
                    name: 'Home Style 2',
                    link: '/home-two'
                },
                {
                    name: 'Home Style 3',
                    link: '/home-three'
                },
            ],
        },
        {
            name: 'About us',
            link: '/about',
        },
        {
            name: 'Author',
            submenu: [
                {
                    name: 'Author',
                    link: '/author'
                },
                {
                    name: '404',
                    link: '/404'
                }
            ],
        },
        {
            name: 'products',
            id: 57,
            submenu: [
                {
                    name: 'Products',
                    link: '/products'
                },
                {
                    name: 'product details',
                    link: '/product-details/1'
                }
            ],
        },
        {
            name: 'Blogs',
            id: 5,
            submenu: [
                {
                    name: 'Blog',
                    link: '/blog'
                },
                {
                    name: 'Blog Details',
                    link: '/blog-details/1'
                },
            ],
        },
        {
            name: 'Contact',
            link: '/contact',
            id: 6
        }
    ]
    const [openCart, setCart] = useState(null);
    const [openSearch, setSearch] = useState(null);
    const [expanded, setExpanded] = useState('0');
    const [menu, setMenu] = useState(false);

    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleClickCart = event => {
        setCart(event.currentTarget);
    };

    const handleCloseCart = () => {
        setCart(null);
    };

    const handleClickSearch = event => {
        setSearch(event.currentTarget);
    };

    const handleCloseSearch = () => {
        setSearch(null);
    };

    const responsiveMenuHandler = () => {
        setMenu(!menu)
    }


    return (
        <header className={'headerArea'}>
            <Grid
                container
                spacing={4}
                style={{ padding: 16 }}
            >
                <Grid item lg={2} sm={4} xs={12} style={{ display: "flex", justifyContent: "center" }}>
                    {props.isLoggedIn ? (
                        <Link
                            size="large"
                            onClick={() => history.push("/parent")}
                            className="appTitle"
                        >
                            <img src={props.logo} alt="Logo" />
                        </Link>
                    ) : (
                        <Link
                            size="large"
                            onClick={() => history.push("/")}
                            className="appTitle"
                        >
                            <img src={props.logo} alt="Logo" />
                        </Link>
                    )}
                </Grid>
                <Grid item lg={6} sm={4} xs={12} style={{ display: "flex", alignItems: "center" }}>
                    <Grid container spacing={1} style={{ display: "flex", justifyContent: "center" }}>
                        {props.headerSections && props.headerSections.map(menu => (
                            <Grid item style={{ paddingLeft: 16, paddingRight: 16 }}>
                                {menu.url ? <NavLink
                                    onClick={window.scrollTo(0, 0)}
                                    exact
                                    to={menu.url}
                                >
                                    {menu.title}
                                </NavLink> : <span>
                                    {menu.title} <i className="fa fa-angle-down"></i>
                                </span>}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    {/* <li onClick={handleClickCart}>
                            <i className="fi flaticon-bag"></i>
                            <span className="value">{carts.length < 10 ? `0${carts.length}` : carts.length}</span>
                        </li>
                        <li onClick={handleClickSearch}><i className="fi flaticon-magnifying-glass"></i></li> */}
                    {props.isLoggedIn ? (
                        <Grid container spacing={1} style={{ display: "flex", justifyContent: "center" }}>
                            <Grid item>
                                <NavLink onClick={window.scrollTo(0, 0)} exact to={"/parent/profile"}>
                                    <AccountCircleOutlinedIcon />
                                </NavLink>
                            </Grid>
                            <Grid item>
                                <Button onClick={() => {
                                    dispatch(attemptLogout());
                                }} className="btn">Logout</Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={1} style={{ display: "flex", justifyContent: "center" }}>
                            <Grid item>
                                <Button onClick={() => history.push("/register")} className="btn">Register</Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={() => history.push("/login")} className="btn">Login</Button>
                            </Grid>
                        </Grid>
                    )}

                    <Menu
                        anchorEl={openSearch}
                        keepMounted
                        open={Boolean(openSearch)}
                        onClose={handleCloseSearch}
                        elevation={1}
                        getContentAnchorEl={null}
                        className="searchWrap"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        classes={{
                            paper: 'searchPaper',
                            list: 'searchLists',
                        }}
                    >
                        <li>
                            <form>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <Button><i className="fi flaticon-magnifying-glass"></i></Button>
                                        </InputAdornment>,
                                    }}
                                />
                            </form>
                        </li>
                    </Menu>
                    <Menu
                        anchorEl={openCart}
                        keepMounted
                        open={Boolean(openCart)}
                        onClose={handleCloseCart}
                        elevation={0}
                        getContentAnchorEl={null}
                        className="cartWrapper"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        classes={{
                            paper: 'cartPaper',
                            list: 'cartLists',
                        }}
                    >
                        <li className="cartList">
                            <ScrollArea
                                speed={1}
                                className="scrollbarArea"
                                contentClassName="scrollbarContent"
                                horizontal={false}
                            >
                                <ul className="cartItems">
                                    {carts.map((item, i) => (
                                        <li key={i}>
                                            <Button
                                                className="cartItem"
                                                component={Link}
                                                to="/product-details">
                                                <span className="cartImg">
                                                    <img src={item.image} alt="" />
                                                </span>
                                                <span className="cartContent">
                                                    <h4>{item.name}</h4>
                                                    <span>{`$${item.value}`}</span>
                                                </span>
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            </ScrollArea>
                            <h3>$150.10</h3>
                            <Button className="btn btnFull" component={Link} to="/cart"> View cart </Button>
                        </li>
                    </Menu>
                </Grid>
            </Grid>
        </header>
    );
}

export default Header;
